import { capitalizeFirstLetter } from '@/services/StringService';

export default ({ glossary }) => ({
  document: {
    title: ({ companyName }) => `Conversation Threads | Traits | ${companyName} | Solsten`
  },
  title: 'Conversation threads',
  pagination: ({ page, total }) => `Showing ${page} of ${total}`,
  more: { title: 'View more' },
  empty: {
    title: 'No results found',
    description: `Looks like we can't find results based on your search.`
  },
  definitions: {
    informativeness: {
      title: 'Informativeness Score',
      description: `
        <p>The Informativeness Score helps you focus on messages that are most helpful for you and provide information that you can take action on. High-scoring messages surface specific ${glossary.app} features and other concrete issues ${glossary.actors} express. Low-scoring messages are vague, short on information or irrelevant.</p>
        <p>This score is independent of the sentiment score, so a message can have low Informativeness even if it expresses a strong Sentiment. It is calculated using a Machine Learning (ML) model that has been trained on expertly labeled examples to identify the most informative messages based on linguistic and content features.</p>
      `
    },
    sentiment: {
      title: 'Sentiment Score*',
      description: `
        <p>The Sentiment Score tells you if your customer base is expressing negative, neutral, or positive attitudes towards your ${glossary.app} overall.</p>
        <p>The score is measured on a scale of 0-100.</p>
        <p>
            <ul>
                <li><b>Negative Sentiment: 0 to 33</b>, with 0 being the most negative</li>
                <li><b>Neutral Sentiment: 34 to 66</b>, with 50 representing true neutrality or ambiguity</li>
                <li><b>Positive Sentiment: 67 to 100</b>, with 100 being the most positive</li>
            </ul>
        </p>
        <p>It is calculated using an ML-powered natural language processing model. The model can handle expletives and certain edge cases such as sarcasm to some degree.</p>
      `,
      footnote: `*The overall Sentiment score and chart displays <b>all individuals</b> speaking about your ${glossary.app}.`
    }
  },
  score: {
    title: ({ score }) => `Average ${capitalizeFirstLetter(score)} Score`
  }
});
