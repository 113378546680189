export enum ScriptNameEnum {
  Dropbox = 'DROPBOX',
  GoodleDrive = 'GOOGLE_DRIVE',
  GoogleMaps = 'GOOGLE_MAPS',
  Analytics = 'ANALYTICS',
  AnalyticsLoad = 'ANALYTICS_LOAD'
}

const scriptConfigByName: {
  [key in ScriptNameEnum]: {
    src?: string;
    loadType?: 'defer' | 'async';
    type?: string;
    id?: string;
    dataAttribute?: { key: string; value: string };
    parentTag?: 'body' | 'head';
    innerHTML?: string;
  };
} = {
  [ScriptNameEnum.Dropbox]: {
    src: 'https://www.dropbox.com/static/api/2/dropins.js',
    loadType: 'defer',
    type: 'text/javascript',
    id: 'dropboxjs',
    dataAttribute: { key: 'data-app-key', value: 'tfhnhcpl0ve9ovh' }
  },
  [ScriptNameEnum.GoodleDrive]: {
    id: 'googleDrive',
    src: 'https://apis.google.com/js/api.js',
    loadType: 'defer'
  },
  [ScriptNameEnum.GoogleMaps]: {
    id: 'googleMaps',
    src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCItP-ovxAfNZ-i6lwHJ_pXNaBkK-qB8Io&libraries=places&language=en'
  },
  [ScriptNameEnum.AnalyticsLoad]: {
    id: 'analytics-load',
    src: `https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_TAG_KEY}`,
    parentTag: 'head'
  },
  [ScriptNameEnum.Analytics]: {
    id: 'analytics',
    innerHTML: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.GOOGLE_TAG_KEY}', {
          send_page_view: false
        });
    `,
    parentTag: 'head'
  }
};

export const addScript = (
  scriptName: ScriptNameEnum,
  callback: () => void = (): void => {}
): void => {
  const {
    src,
    loadType,
    type,
    dataAttribute,
    id,
    parentTag = 'body',
    innerHTML
  } = scriptConfigByName[scriptName];

  const isExists = [...document.getElementsByTagName('script')].some(script => script.id === id);

  if (isExists) {
    return;
  }

  const script = document.createElement('script');

  if (loadType) {
    script[loadType] = true;
  }
  if (type) {
    script.type = type;
  }
  if (innerHTML) {
    script.innerHTML = innerHTML;
  }
  if (src) {
    script.src = src;
  }
  if (id) {
    script.id = id;
  }
  if (dataAttribute) {
    script.setAttribute(dataAttribute.key, dataAttribute.value);
  }
  script.onload = callback;

  document[parentTag].appendChild(script);
};
