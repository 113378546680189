import { parseQuery, updateOrRemoveSearchParam } from '@/services/UrlService';

import { Cluster } from '@/redux/types';

export const clusterUrlParam = 'persona'; // persona is the same as cluster

/**
 * Updates cluster param in url and returns current search string
 * Cluster param will be incremented so count in url would start with 1 instead of 0
 * For example: cluster:1 will redirect to /some-url/?cluster=2
 */
export const updateClusterInUrl = (
  cluster: string | null,
  clusters: Array<Cluster>,
  urlParam: string = clusterUrlParam
): string => {
  const selected = clusters.find(one => one.value === cluster) || clusters[0];
  const isAllClusters = cluster === clusters[0].value || cluster === null;
  let value = null;
  if (!isAllClusters) {
    value = (parseInt(selected.value, 10) + 1).toString();
  }
  return updateOrRemoveSearchParam({ [urlParam]: value });
};

/**
 * Parses ?cluster=1 from url as 0 (?cluster=0)
 */
export const parseClusterInUrl = (urlParam: string = clusterUrlParam): string | undefined => {
  const parsedQuery = parseQuery();
  const cluster = parsedQuery[urlParam];
  return Number.isNaN(Number(cluster)) ? cluster : (Number(cluster) - 1).toString();
};
