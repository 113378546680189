import { Action } from '@/redux/types';

import { CATEGORIES } from './constants';
import {
  TraitsPageState,
  SET_TRAITS_CLUSTERS,
  SET_ASSESSMENT_VERSION,
  SET_CHART_VIEW,
  ChartViewEnum,
  ActivitiesViewEnum,
  MotivationsViewEnum
} from './types';

const initialState: TraitsPageState = {
  clusters: [],
  categories: CATEGORIES,
  scores: null,
  scoresByView: null,
  showAllSubtraits: false,
  assessmentVersion: 'v0',
  chartView: ChartViewEnum.ActualScore,
  activitiesView: ActivitiesViewEnum.Daily,
  motivationsView: MotivationsViewEnum.AllMotivators
};

export default (state = initialState, action: Action): TraitsPageState => {
  switch (action.type) {
    case SET_TRAITS_CLUSTERS:
      return {
        ...state,
        clusters: action.payload.clusters
      };
    case SET_CHART_VIEW: {
      const { chartView } = action.payload;
      return {
        ...state,
        chartView
      };
    }
    case SET_ASSESSMENT_VERSION:
      return {
        ...state,
        assessmentVersion: action.payload.assessmentVersion,
        categories:
          action.payload.assessmentVersion === 'v0'
            ? state.categories
            : state.categories.map(category => {
                if (category.name === 'Hopes & Aspirations') {
                  return {
                    ...category,
                    allTraitsCaption: 'All',
                    xAxisLabelAngle: 0
                  };
                }

                if (category.name === 'Pain Points') {
                  return {
                    ...category,
                    allTraitsCaption: 'All',
                    xAxisLabelAngle: 0
                  };
                }
                return category;
              })
      };
    default:
      return state;
  }
};
