export const LOAD_KPIS_STATUS = 'Kpis / LOAD_KPIS_STATUS';
export const LOAD_KPIS = 'Kpis / LOAD_KPIS';
export const LOAD_KPIS_STATUS_SUCCESS = 'Kpis / LOAD_KPIS_STATUS_SUCCESS';
export const LOAD_KPIS_SUCCESS = 'Kpis / LOAD_KPIS_SUCCESS';
export const LOAD_KPIS_STATUS_ERROR = 'Kpis / LOAD_KPIS_STATUS_ERROR';
export const LOAD_KPIS_ERROR = 'Kpis / LOAD_KPIS_ERROR';
export const SET_KPIS_INTEGRATION_PERCENTAGE = 'Kpis / SET_KPIS_INTEGRATION_PERCENTAGE';
export const SET_KPIS_INTEGRATION_STATUS = 'Kpis / SET_KPIS_INTEGRATION_STATUS';
export const SET_APPROXIMATE_KPIS_INTEGRATION_TIME = 'Kpis / SET_APPROXIMATE_KPIS_INTEGRATION_TIME';
export const CALCULATE_KPIS_INTEGRATION = 'Kpis / CALCULATE_KPIS_INTEGRATION';
export const SET_KPIS_UPLOAD_ERROR = 'Kpis / SET_KPIS_UPLOAD_ERROR';
export const SET_KPIS_IS_UPLOADING = 'Kpis / SET_KPIS_IS_UPLOADING';
export const LOAD_TIMELINES = 'Kpis / LOAD_TIMELINES';

export const LOAD_DASHBOARD_KPIS_SUCCESS = 'Kpis / LOAD_DASHBOARD_KPIS_SUCCESS';

export const Statuses = {
  NOT_UPLOADED: 'NOT_UPLOADED',
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  CANCELED: 'CANCELED',
  INTEGRATING: 'INTEGRATING',
  INTEGRATED: 'INTEGRATED',
  ERROR: 'ERROR'
};

export interface KpiState {
  isDataLoading: boolean;
  dataLoadingError: boolean;
  dataLoadingErrorMsg: string;
  [key: string]: any;
}

export interface KpisUploadError {
  errorCode: number;
  invalidCols: {
    [key: string]: string;
  };
  invalidRows: number[];
  message: string;
}
