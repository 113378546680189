import { Cluster } from '@/redux/types';

export const SET_TRAITS_CLUSTERS = 'TraitsPage / SET_TRAITS_CLUSTERS';
export const SET_ASSESSMENT_VERSION = 'TraitsPage / SET_ASSESSMENT_VERSION';
export const SET_CHART_VIEW = 'TraitsPage / SET_CHART_VIEW';
export interface TraitsCategory {
  name: string;
  actionCategory?: string;
  hasSubcategories?: boolean;
  altName?: string;
  code: string;
  definition: string;
  allTraitsCaption?: string; // default value, if category has subtraits, like All values, OCEAN, etc
  subtraits?: Array<string>; // list of subtraits codes to show in dropdown next to the title
  xAxisLabelAngle?: number; // angle for labels on x-axis
  xAxisCaption?: string; // caption under x-axis labels
  yAxisCaption?: string; // caption for y-axis
  withDifferentChartViews?: boolean; // ability to select actual/percentile represenation of graph
}

export interface TraitCluster extends Cluster {
  active?: boolean;
  hover?: boolean;
}
interface TraitsScoresCommon {
  [cluster: string]: Scores;
}

interface TraitsScoresTechUsage {
  [cluster: string]: { [traitsGroupName: string]: { [traitName: string]: number } };
}

export type TraitsScores = TraitsScoresWithSubtraits | TraitsScoresCommon | TraitsScoresTechUsage;

export interface TraitsScoresWithSubtraits {
  [cluster: string]: ScoresWithSubtraits;
}

export interface Scores {
  [traitName: string]: number;
}

export interface ScoresWithSubtraits {
  [traitName: string]: {
    score: number;
    subtraits: Scores;
  };
}

export interface ContentfulEntry {
  category: string;
  high: string;
  low: string;
  mid: string;
  name: string;
  traitId: string;
}

export enum ChartViewEnum {
  ActualScore = 'actual score',
  PercentileRank = 'percentile rank',
  ShowBoth = 'show actual and percentile'
}

export enum ActivitiesViewEnum {
  Daily = 'Top Daily Activities',
  Interests = 'Top Interests Activities'
}

export enum MotivationsViewEnum {
  CoreMotivators = 'Core motivators',
  AllMotivators = 'All motivators'
}

export enum MotivationsSlugs {
  'Core Motivators' = MotivationsViewEnum.CoreMotivators,
  'Motivators' = MotivationsViewEnum.AllMotivators
}

export interface TraitsScoresByView {
  [key: string]: TraitsScores;
}

export interface TraitsPageState {
  clusters: Array<TraitCluster>;
  categories: Array<TraitsCategory>;
  scores: TraitsScores | null;
  scoresByView: TraitsScoresByView | null;
  showAllSubtraits: boolean;
  assessmentVersion: string;
  chartView: ChartViewEnum;
  activitiesView: ActivitiesViewEnum;
  motivationsView: MotivationsViewEnum;
}

export interface MatchParams {
  gameSlug: string;
  categoryCode?: string;
  traitCode?: string;
  subtraitCode?: string;
}
