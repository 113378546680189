import classNames from 'classnames';
import { ReactElement } from 'react';


import CheckIcon from 'ui-lib-12traits/src/Icons/svg/frequency/check.svg?component';
import KeyGuyIcon from 'ui-lib-12traits/src/Icons/svg/keyguy/keyguy-rounded.svg?component';

import { Cluster } from '@/redux/types';

import personasStyles from '../Personas.module.scss';
import sharedStyles from '../shared.module.scss';

import styles from './FilterByPersona.module.scss';


interface Props {
  cluster: Cluster;
  onClick: (clusterValue: string) => void;
  isSelected: boolean;
  testid?: string;
}

export function ClusterSelectItem({
  cluster,
  onClick,
  isSelected,
  testid
}: Props): ReactElement {
  return <div
    className={classNames(sharedStyles['select-option'], styles['select-cluster--item'], {
      [sharedStyles['select-option--active']]: isSelected,
      [styles['select-cluster--item--selected']]: isSelected
    })}
    onClick={(): void => {
      onClick(cluster.value);
    }}
    data-testid={testid}
  >
    <span>{cluster.name}</span>
    <div
      className={classNames(styles['personas__icon-wrapper'], {
        [styles['personas__icon-wrapper--selected']]: isSelected
      })}
    >
      <KeyGuyIcon
        className={classNames(
          styles.personas__icon,
          personasStyles[`persona-${cluster.value}-fill-c`]
        )}
      />
      <CheckIcon className={styles['personas__icon-value']} />
    </div>
  </div>
}
