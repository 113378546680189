
import { isSameDay, getDayOfWeek, isSameMonth } from '@internationalized/date';
import classNames from 'classnames';
import { ReactElement, useRef } from 'react';
import { useCalendarCell, useLocale, useFocusRing, mergeProps } from 'react-aria';

import styles from './styles.module.scss';


export function CalendarCell({ state, date, currentMonth, testId }): ReactElement {
  const ref = useRef();
  const { cellProps, buttonProps, isSelected, formattedDate, isDisabled } = useCalendarCell(
    { date },
    state,
    ref
  );

  const isOutsideMonth = !isSameMonth(currentMonth, date);

  const isSelectionStart = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.start)
    : isSelected;
  const isSelectionEnd = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.end)
    : isSelected;

  const { locale } = useLocale();
  const dayOfWeek = getDayOfWeek(date, locale);
  const isRoundedLeft = isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
  const isRoundedRight =
    isSelected &&
    (isSelectionEnd || dayOfWeek === 6 || date.day === date.calendar.getDaysInMonth(date));

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <td
      {...cellProps}
      className={classNames(styles.cell__wrapper, {
        [styles.cell__wrapper_visible]: isFocusVisible,
        [styles.cell__wrapper_invisible]: !isFocusVisible
      })}
    >
      <div className={styles.lo}>
        <div
          {...mergeProps(buttonProps, focusProps)}
          ref={ref}
          data-testid={`${testId}-${date}`}
          hidden={isOutsideMonth}
          className={classNames(styles.cell, {
            [styles.cell_left]: isRoundedLeft,
            [styles.cell_right]: isRoundedRight,
            [styles.cell_selected]: isSelected,
            [styles.cell_outside]: isOutsideMonth,
            [styles.cell_start]: isSelectionStart,
            [styles.cell_end]: isSelectionEnd,
            [styles.cell_single]: state.highlightedRange
              ? isSameDay(state.highlightedRange.end, state.highlightedRange.start)
              : isSelected
          })}
        >
          <div
            className={classNames(styles.cell__date, {
              [styles.cell__date_visible]: isFocusVisible,
              [styles.cell__date_selected]: isSelectionStart || isSelectionEnd,
              [styles.cell__date_range]: isSelected && !(isSelectionStart || isSelectionEnd),
              [styles['cell__date_not-selected']]: !isSelected,
              [styles.cell__date_disabled]: isDisabled
            })}
          >
            {formattedDate}
          </div>
        </div>
      </div>
    </td>
  );
}
