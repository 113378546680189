
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import camelcaseKeys from 'camelcase-keys';
import { useMemo } from 'react';
import snakecaseKeys from 'snakecase-keys';

import { ChartPoint } from 'ui-lib-12traits/src/index';

import { useAppSelector } from '@/hooks/useApp';

import { checkIfChangeValuePositive, timeSeriesToChartData } from '@/utils/change';

import { prepareBaseQuery } from '@/redux/helpers';

import { selectGenreIds, selectWishlist } from '../wishlist/api';
import { WishlistItem } from '../wishlist/types';

export const kpisApi = createApi({
  reducerPath: 'kpisApi',
  baseQuery: fetchBaseQuery(
    prepareBaseQuery({
      endpoint: 'kpis',
      useGameJwt: false
    })
  ),
  endpoints: build => ({
    getGenresMarketChartData: build.query<
      {
        genreId: number;
        chartData: ChartPoint[];
        isPositive: boolean;
      }[],
      { genreIds: number[]; dateRange: string }
    >({
      query: params => ({
        url: '/market/kpis/data',
        params: snakecaseKeys(params)
      }),
      transformResponse: result =>
        (
          camelcaseKeys((result as { data: any }).data, { deep: true }) as {
            genreId: number;
            timeSeries: { [key: number]: number | null };
          }[]
        ).map(({ genreId, timeSeries }) => {
          const chartData = timeSeriesToChartData(timeSeries);
          return {
            genreId,
            chartData,
            isPositive: checkIfChangeValuePositive(chartData)
          };
        })
    })
  })
});

export const { useGetGenresMarketChartDataQuery } = kpisApi;

export const useGetWishlistWithChartData = () => {
  const genreIds = useAppSelector(selectGenreIds);
  const wishlist = useAppSelector(selectWishlist) as WishlistItem[] | undefined;
  const { data } = useGetGenresMarketChartDataQuery(
    { genreIds, dateRange: 'month' },
    {
      skip: !genreIds.length
    }
  );

  const wishlistWithChartData = useMemo(
    () =>
      wishlist?.map(item => {
        const { chartData = [], isPositive = false } =
          data?.find(({ genreId }) => genreId === item.genreId) || {};
        return {
          ...item,
          chartData,
          isPositive
        };
      }),
    [wishlist, data]
  );

  return { data: wishlistWithChartData };
};
