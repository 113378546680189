import CoreApiService from '@/services/Api/CoreApiService';
import { storeRegistry } from '@/services/StoreRegistryService';
import { selectGameJwt } from '@/redux/game/selectors';

export default class SegmentsApiService extends CoreApiService {
  constructor(config: {
    version: 'v1';
    isCamelCaseResponse?: boolean;
    shouldSendSnakeCase?: boolean;
    useGameJwt?: boolean;
  }) {
    const { isCamelCaseResponse = false, shouldSendSnakeCase, version, useGameJwt = true } = config;
    super({ isCamelCaseResponse, shouldSendSnakeCase, version, useGameJwt });
    this.api = `${process.env.BASE_URL}/segments`;
    const token = selectGameJwt(storeRegistry.getState());
    this.setHeaders({ Authorization: `Bearer ${token}` });
  }
}
