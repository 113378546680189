import { Action } from '@/services/Api/GameService/types';

export const LOAD_ACTIONS = 'ActionsPage/LOAD_ACTIONS';
export const LOAD_ACTIONS_REQUEST = 'ActionsPage/LOAD_ACTIONS_REQUEST';
export const LOAD_ACTIONS_SUCCESS = 'ActionsPage/LOAD_ACTIONS_SUCCESS';
export const ADD_ACTION = 'ActionsPage/ADD_ACTION';
export const ADD_ACTION_REQUEST = 'ActionsPage/ADD_ACTION_REQUEST';
export const ADD_ACTION_SUCCESS = 'ActionsPage/ADD_ACTION_SUCCESS';
export const UPDATE_ACTION = 'ActionsPage/UPDATE_ACTION';
export const UPDATE_ACTION_REQUEST = 'ActionsPage/UPDATE_ACTION_REQUEST';
export const UPDATE_ACTION_SUCCESS = 'ActionsPage/UPDATE_ACTION_SUCCESS';
export const DELETE_ACTION = 'ActionsPage/DELETE_ACTION';
export const DELETE_ACTION_REQUEST = 'ActionsPage/DELETE_ACTION_REQUEST';
export const DELETE_ACTION_SUCCESS = 'ActionsPage/DELETE_ACTION_SUCCESS';
export const SET_ACTIONS_CATEGORY_INDEX = 'ActionsPage/SET_ACTIONS_CATEGORY_INDEX';
export const SET_ACTIONS_STATUS_ID = 'ActionsPage/SET_ACTIONS_STATUS_ID';
export const LOAD_PROGRESS = 'ActionsPage/LOAD_PROGRESS';
export const LOAD_PROGRESS_REQUEST = 'ActionsPage/LOAD_PROGRESS_REQUEST';
export const LOAD_PROGRESS_SUCCESS = 'ActionsPage/LOAD_PROGRESS_SUCCESS';
export const SET_PROGRESS_TIMEFRAME = 'ActionsPage/SET_PROGRESS_TIMEFRAME';
export const SHOW_ACTION_DETAILS = 'ActionsPage/SHOW_ACTION_DETAILS';
export const SET_FILTERS = 'ActionsPage/SET_FILTERS';
export const SHOW_FILTERS = 'ActionsPage/SHOW_FILTERS';
export const RELOAD_ACTIONS = 'ActionsPage/RELOAD_ACTIONS';
export const VOTE_ACTION = 'ActionsPage/VOTE_ACTION';
export const VOTE_ACTION_REQUEST = 'ActionsPage/VOTE_ACTION_REQUEST';
export const VOTE_ACTION_SUCCESS = 'ActionsPage/VOTE_ACTION_SUCCESS';
export const UNLOCK_ACTION = 'ActionsPage/UNLOCK_ACTION';
export const UNLOCK_ACTION_REQUEST = 'ActionsPage/UNLOCK_ACTION_REQUEST';
export const UNLOCK_ACTION_ERROR = 'ActionsPage/UNLOCK_ACTION_ERROR';

export interface ActionsPageState {
  loading: boolean;
  actionsData: ActionsData;
  perPage: number;
  currentCategoryIndex: number;
  currentStatusId: number;
  currentActionId: string;
  showLaunchDetails: boolean;
  bestAction: any;
  progress: Progress;
  timeFrame: string;
  bestActionError: boolean;
  bestActionErrorMessage: string;
  filters: Filters;
  showFilters: boolean;
}

export interface Filters {
  [key: string]: Array<{
    label: string;
    value: string;
  }>;
}
export interface ActionsData {
  actions: Array<Action>;
  page: number;
  totalActions: number;
  hasMore: boolean;
}

export interface Progress {
  completed: number;
  started: number;
}
