import { ChartPoint } from 'ui-lib-12traits/src/index';

import { max } from '@/services/ArrayService';
import { getValueWithUnits } from '@/services/StringService';

export const timeSeriesToChartData = (
  timeSeries: { [key: number]: number | null } | null,
  withNull: boolean = false
): ChartPoint[] =>
  timeSeries
    ? Object.entries(timeSeries).map(
        ([x, y]) =>
          ({
            x: x.toString(),
            y: y === null ? (withNull ? null : 0) : Number(y)
          }) as ChartPoint
      )
    : [];

export const getChangeValue = (chartData: ChartPoint[]): number => {
  const values = chartData.map(v => v.y);
  const earliestValue = values[0] || 0;
  const latestValue = values.slice(-1)[0] || 0;

  return latestValue - earliestValue;
};

export const checkIfChangeValuePositive = (chartData: ChartPoint[]): boolean =>
  getChangeValue(chartData) >= 0;

export const formatChartValue = (value: number, units: string = ''): string => {
  if (value < 100) {
    return getValueWithUnits(value, units, 2);
  }
  if (value < 10000) {
    return getValueWithUnits(value, units, 0);
  }
  const valueWithCommas = Math.round(value).toLocaleString('en-GB');
  return units === '$' ? `$${valueWithCommas}` : `${valueWithCommas}${units}`;
};

export const formatMetricChange = (
  value: number,
  units: string = '',
  withNumberAbbreviation: boolean = false
): string => {
  let formattedValue = '';
  const roundValue = value < 10 ? Math.round(value * 100) / 100 : Math.round(value);

  if (withNumberAbbreviation && value >= 1000) {
    formattedValue = Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short'
    }).format(roundValue);
  } else {
    formattedValue = roundValue.toLocaleString('en-GB');
  }

  return units === '$' ? `$${formattedValue}` : `${formattedValue}${units}`;
};

export const calculateMaxY = (data: ChartPoint[]): number => max(data.map(({ y }) => y)) || 0;

export const calculatePercentChange = (earliestValue: number, latestValue: number): number =>
  (Math.abs(latestValue - earliestValue) * 100) / Math.max(earliestValue, latestValue) || 0;

export const getChangeConfig = (
  data: { [key: string]: number | null } | null,
  hoveredDate: string | null
): {
  value: number;
  changeValue: number;
  percentChange: number;
  overallChangeValue: number;
} => {
  let value = 0;
  let changeValue = 0;
  let percentChange = 0;

  let overallChangeValue = 0;

  if (data) {
    const notNullValues = Object.values(data || {}).filter(v => v !== null);
    const earliestValue = notNullValues[0] || 0;
    const latestValue = notNullValues.slice(-1)[0] || 0;

    value = latestValue;
    changeValue = latestValue - earliestValue;
    overallChangeValue = changeValue;
    percentChange = calculatePercentChange(earliestValue, latestValue);
  }

  if (data && hoveredDate) {
    const hoveredValue = data[hoveredDate] || 0;
    const prevIndex = Object.keys(data).indexOf(hoveredDate) - 1;
    const prevValue = Object.values(data)[prevIndex === -1 ? 0 : prevIndex] || 0;

    value = hoveredValue;
    changeValue = hoveredValue - prevValue;
    percentChange = calculatePercentChange(prevValue, hoveredValue);
  }

  return {
    value,
    changeValue,
    percentChange,
    overallChangeValue
  };
};
