import { ContentTypeEnum } from '@/redux/game/types';

export default ({ user: { firstName } }) => ({
  title: `Hi ${firstName}, let's launch an assessment.`,
  description: {
    fragments: {
      first: 'Discover the needs and preferences of your audience.'
    }
  },
  actors: {
    gaming: 'Players',
    medical: 'Patients',
    product: 'Users'
  },
  actor: ((audienceType) =>  ({
    gaming: 'player',
    medical: 'patient',
    product: 'user'
  })[audienceType]),
  audience: {
    title: 'Who is your audience?',
    tooltip:
      'This tailors the assessment specifically to your industry and how we will refer to your audience.'
  },
  target: {
    title: audienceType => {
      const target = audienceType === ContentTypeEnum.Gaming ? 'game' : 'product';

      return `What ${target} do you want to assess?`;
    },
    placeholder: audienceType => {
      const target = audienceType === ContentTypeEnum.Gaming ? 'game' : 'product';

      return `Select your ${target}`;
    }
  },
  formatters: {
    time: period => `Assessed ${period}`
  },
  actions: {
    proceed: completed => (completed ? 'Next' : 'Get started')
  },
  type: {
    title: audienceType => {
      const target = {
        [ContentTypeEnum.Product]: 'users',
        [ContentTypeEnum.Gaming]: 'players',
        [ContentTypeEnum.Medical]: 'patients'
      };

      return `What do you want to learn about your ${target[audienceType]}?`;
    }
  }
});
