import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../helpers';

export const benchmarkApi = createApi({
  reducerPath: 'benchmarkApi',
  baseQuery: fetchBaseQuery(prepareBaseQuery({ endpoint: 'traits', useGameJwt: false })),
  tagTypes: ['Benchmark', 'Report'],
  endpoints: builder => ({
    getReports: builder.query({
      query: ({ sortDirection, sortField } = {}) => {
        const params = new URLSearchParams();

        if (sortDirection) params.append('sort_direction', sortDirection);
        if (sortField) params.append('sort_field', sortField);

        return `charts/reports?${String(params)}`;
      },
      providesTags: ['Benchmark']
    }),
    getReport: builder.query({
      query: (slug) => `charts/reports/${slug}`,
      providesTags: ['Report']
    }),
    createReport: builder.mutation({
      query: newReport => ({
        url: '/charts/reports',
        method: 'POST',
        body: newReport
      })
    }),
    removeReport: builder.mutation({
      query: reportId => ({
        url: `/charts/reports/${reportId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Benchmark']
    }),
    updateChart: builder.mutation({
      query: (payload) => ({
        url: '/scores',
        method: 'POST',
        body: payload
      }),
    }),
    patchCharts: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/charts/reports/${id}`,
        method: 'PATCH',
        body: payload,
      }),
    })
  })
});

export const { useGetReportsQuery, useCreateReportMutation, useRemoveReportMutation, useUpdateChartMutation, useGetReportQuery, usePatchChartsMutation } =
  benchmarkApi;
