import { prepareAction } from '@/redux/helpers';
import { Action } from '@/redux/types';

import {
  LOAD_COMPARISON_OVERVIEW_REQUEST,
  LOAD_COMPARISON_OVERVIEW_SUCCESS,
  LOAD_COMPARISON_OVERVIEW_ERROR,
  UPDATE_GRAPHS
} from './types';

export const loadComparisonOverviewRequest = (): Action =>
  prepareAction(LOAD_COMPARISON_OVERVIEW_REQUEST);

export const loadComparisonOverviewSuccess = (data: any): Action =>
  prepareAction(LOAD_COMPARISON_OVERVIEW_SUCCESS, { data });

export const loadComparisonOverviewError = (error: string): Action =>
  prepareAction(LOAD_COMPARISON_OVERVIEW_ERROR, { error });

export const updateGraphs = (graphs: any): Action => prepareAction(UPDATE_GRAPHS, graphs);
