/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */

import { useEffect, useRef, useState } from 'react';
import { GlobalButton } from 'ui-lib-12traits/src/index';
import classNames from 'classnames';
import camelcaseKeys from 'camelcase-keys';
import CrossIcon from 'ui-lib-12traits/src/Icons/svg/common/cross.svg?component';
import isEqual from 'lodash.isequal';
import StepContainer from '@/components/AutomateAssessment/StepContainer';
import styles from './index.module.scss';

const placeholders = [
  'Battle axe',
  'Helmets',
  'Health potion',
  'Gems',
  'Rare relic',
  'Dragon',
  'Pet dog',
  'Painting',
  'Limited edition outfit',
  'Quest guide'
];

const GameItemsStep = ({
  onNext,
  isActive,
  setActiveStep,
  values = {} as StaticValues,
  title,
  withNumber = true,
  template,
  onClose,
  status,
  updateStepValues,
  setErrorCount,
  errorCount,
  order,
  ...step
}) => {
  const [inputValues, setValues] = useState({});
  const indexs = Array.from(Array(10).keys());
  const count = Object.values(inputValues).filter(Boolean).length;
  const errorCountRef = useRef(errorCount);

  if (!isEqual(errorCountRef.current, errorCount)) {
    errorCount.current = errorCount;
  }
  useEffect(() => {
    const { gameItems } = camelcaseKeys(values);
    if (status === 'completed' && gameItems) {
      const obj = {};
      gameItems.map((item, i) => {
        obj[i] = item;
        return obj;
      });
      setValues(obj);
    }
  }, [status, values.gameItems]);

  useEffect(() => {
    setErrorCount({
      ...errorCount,
      [template]: count >= 5
    });
  }, [errorCountRef.current, count]);

  const onChange = value => {
    setValues(value);

    updateStepValues(
      {
        id: step.id,
        game_items: Object.values(value).filter(Boolean)
      },
      template
    );
  };

  return (
    <StepContainer
      title={title}
      titleExpanded="Specify 5-10 game items you want player preferences for."
      id={order}
      isExpanded={isActive}
      template={template}
      onExpand={setActiveStep}
      withNumber
      collapsedElement={
        <div className={styles.card__value}>
          <span>{count} items added</span>
        </div>
      }
      invalid={errorCount?.[template] === false}
    >
      <div className={styles.container}>
        <div className={styles.step_header}>
          Keeping item names less than 25 characters will ensure they&apos;re easy to read in the
          explorer.
        </div>
        <div className={styles.step_inputs}>
          {indexs.map((_, i) => {
            const isActive =
              indexs.filter(
                index => !inputValues[index] || !Object.keys(inputValues).includes(index.toString())
              )[0] === i || inputValues[i];

            return (
              <Input
                key={i}
                isActive={isActive}
                i={i}
                inputValues={inputValues}
                setValues={onChange}
                focused={i === 0 && !inputValues[i]}
              />
            );
          })}
        </div>
      </div>
      {Object.values(inputValues).filter(Boolean).length >= 5 && (
        <GlobalButton
          title="Next"
          className={styles.button}
          onClick={() =>
            onNext({
              game_items: Object.values(inputValues).filter(Boolean)
            })
          }
        />
      )}
    </StepContainer>
  );
};

const Input = ({ setValues, inputValues, i, isActive, focused }) => {
  const inputRef = useRef(null);

  return (
    <div
      className={classNames(styles.input_wrapper, {
        [styles.input_wrapper_active]: isActive,
        [styles.input_filled]: !!inputRef.current?.value
      })}
      key={i}
      onClick={() => inputRef.current?.focus()}
    >
      <span>{i + 1}.</span>
      <input
        defaultValue={inputValues[i]}
        ref={inputRef}
        onBlur={e => setValues({ ...inputValues, ...{ [i]: e.currentTarget.value } })}
        key={i}
        placeholder={placeholders[i]}
        id={`${i}`}
        /* eslint-disable jsx-a11y/no-autofocus */
        autoFocus={focused}
      />
      {inputValues[i] && (
        <CrossIcon
          onClick={e => {
            e.preventDefault();
            inputRef.current.value = '';
            setValues({ ...inputValues, ...{ [i]: '' } });
          }}
          width={20}
          height={20}
        />
      )}
    </div>
  );
};

export default GameItemsStep;
