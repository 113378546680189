import { createSelector } from 'reselect';

import { Creative } from '@/services/Api/AdsService/types';

import { TagBuilderTag } from '@/components/Frequency/FrequencyBuilderPage/TagBuilder/types';
import { SortBuildsFieldEnum, SortFieldEnum } from '@/components/Frequency/types';

import { RootState } from '../rootStore';
import {
  selectDetailsFTUE,
  selectLibraryFTUE,
  selectDetailsSTUE,
  selectLibrarySTUE,
  selectSortField,
  selectBuildSortField
} from '../user/selectors';

import { AdAccountSync } from './types';

const selectCreativesCount = (state: RootState): number => state.frequency.creativesCount;

const selectCreativesLimit = (state: RootState): number => state.frequency.creativesLimit;

export const selectIsDeleted = (state: RootState): string => state.frequency.isDeleted;

export const selectIsUploadDisabled = createSelector(
  selectCreativesCount,
  selectCreativesLimit,
  (count: number, limit: number) => limit >= 0 && count >= limit
);

export const selectFrequencyPurchased = (state: RootState): boolean | null =>
  state.frequency.frequencyPurchased;

export const selectResonanceEnabled = (state: RootState): boolean =>
  state.frequency.enableResonanceScore || false;

export const selectResonanceEnabledCreativeLibrary = (state: RootState): boolean | null =>
  state.frequency.enableResonanceScore;

export const selectIsLibraryFTUEOn = createSelector(
  selectResonanceEnabled,
  selectLibraryFTUE,
  (isResonanceEnabled, isLibraryFTUE) => (isLibraryFTUE ? isResonanceEnabled : false)
);

export const selectIsLibrarySTUEOn = createSelector(
  selectResonanceEnabled,
  selectLibraryFTUE,
  selectDetailsFTUE,
  selectLibrarySTUE,
  (isResonanceEnabled, isLibraryFTUE, isDetailsFTUE, isLibrarySTUE) =>
    !isLibraryFTUE && !isDetailsFTUE && isLibrarySTUE ? isResonanceEnabled : false
);

export const selectIsDetailsFTUEOn = createSelector(
  selectResonanceEnabled,
  selectDetailsFTUE,
  (isResonanceEnabled, isDetailsFTUE) => (isDetailsFTUE ? isResonanceEnabled : false)
);

export const selectIsDetailsSTUEOn = createSelector(
  selectResonanceEnabled,
  selectLibraryFTUE,
  selectDetailsFTUE,
  selectDetailsSTUE,
  (isResonanceEnabled, isLibraryFTUE, isDetailsFTUE, isDetailsSTUE) =>
    !isLibraryFTUE && !isDetailsFTUE && isDetailsSTUE ? isResonanceEnabled : false
);

export const selectCreativesLibrarySortSortField = createSelector(
  selectResonanceEnabled,
  selectSortField,
  (isResonanceEnabled, sortField) =>
    sortField ||
    (isResonanceEnabled === null
      ? ''
      : isResonanceEnabled
        ? SortFieldEnum.ResonanceScore
        : SortFieldEnum.Metrics)
);

export const selectBuildsLibrarySortSortField = createSelector(
  selectResonanceEnabled,
  selectBuildSortField,
  (isResonanceEnabled, sortField) =>
    sortField ||
    (isResonanceEnabled === null
      ? ''
      : isResonanceEnabled
        ? SortBuildsFieldEnum.AudienceName
        : SortBuildsFieldEnum.Name)
);

export const selectFrequencyIsUploading = (state: RootState): boolean =>
  state.frequency.isUploading;

export const selectFrequencyIsSyncing = (state: RootState): boolean => state.frequency.isSyncing;

export const selectFrequencySyncIds = (state: RootState): string[] =>
  state.frequency.syncData.map(({ adNetworkAccountId }) => adNetworkAccountId);

export const selectFrequencySyncData = (state: RootState): AdAccountSync[] =>
  state.frequency.syncData;

export const selectSelectedDashboardId = (state: RootState): string =>
  state.frequency.selectedDashboardId;

export const selectIsReviewSurveyFlowOpened = (state: RootState): boolean =>
  state.frequency.isReviewSurveyFlowOpened;

export const selectFrequencyBuildId = (state: RootState): string => state.frequency.build.id;

export const selectFrequencyBuildName = (state: RootState): string => state.frequency.build.name;

export const selectFrequencyBuildSelectedTags = (state: RootState): TagBuilderTag[] =>
  state.frequency.build.selectedTags;

export const selectFrequencyBuildCoveredCreative = (state: RootState): Creative =>
  state.frequency.build.coverCreative;

export const selectFrequencyBuildPinnedCreativeIds = (state: RootState): string[] =>
  state.frequency.build.pinnedCreativeIds;

export const selectFrequencyBuildUploadedCreatives = (state: RootState): Creative[] =>
  state.frequency.build.uploadedCreatives;

export const selectFrequencyBuildSelectedAudienceId = (state: RootState): string =>
  state.frequency.build.selectedAudienceId;

export const selectFrequencyBuildSelectedSegmentId = (state: RootState): string =>
  state.frequency.build.selectedSegmentId;

export const selectFrequencyBuildSelectedPersona = (state: RootState): string =>
  state.frequency.build.selectedPersona;
export const selectFrequencyBuildResonanceScore = (state: RootState): string =>
  state.frequency.build.resonanceScore;

export const selectFrequencyIsBuilderPageVisited = (state: RootState): boolean =>
  state.frequency.isBuilderPageVisited;

export const selectFrequencyNeedFetchBuildData = (state: RootState): boolean =>
  state.frequency.needFetchBuildData;
