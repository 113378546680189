import ContentfulApiService from '@/services/Api/ContentfulApiService';

import { Namespace } from '@/redux/game/types';

export const fetchEngagement = (namespace: Namespace, isNonGamingCompany: boolean): Promise<any> =>
  new ContentfulApiService(namespace, isNonGamingCompany).getEntries(
    'clusterInsightsUserEngagement'
  );

export const fetchAcquisition = (namespace: Namespace, isNonGamingCompany: boolean): Promise<any> =>
  new ContentfulApiService(namespace, isNonGamingCompany).getEntries(
    'clusterInsightsUserAcquisition'
  );

export const fetchGdprData = (namespace: Namespace, isNonGamingCompany: boolean): Promise<any> =>
  new ContentfulApiService(namespace, isNonGamingCompany).getEntries('gdpr');

export const fetchCoppaData = (namespace: Namespace, isNonGamingCompany: boolean): Promise<any> =>
  new ContentfulApiService(namespace, isNonGamingCompany).getEntries('coppa');
