import { SET_GAME_SLUG } from '@/redux/game/types';
import { Action } from '@/redux/types';

import {
  LOAD_TEAM_SURVEY,
  LOAD_SURVEY_SUCCESS,
  LOAD_SURVEY_STATUS_SUCCESS,
  LOAD_SURVEY_STATUS_ERROR,
  LOAD_SURVEY_ERROR,
  SET_SURVEY_INTEGRATION_PERCENTAGE,
  SET_SURVEY_INTEGRATION_STATUS,
  SET_APPROXIMATE_SURVEY_INTEGRATION_TIME,
  SurveyState
} from './types';

const initialState: SurveyState = {
  team: [],
  status: null,
  data: null,
  statusError: false,
  statusErrorMsg: '',
  surveyIntegrationPercentage: 0,
  surveyIntegrationStatus: null,
  approximateSurveyIntegrationTime: 0,
  hoveredStep: null
};

export default (state = initialState, action: Action): SurveyState => {
  switch (action.type) {
    case LOAD_TEAM_SURVEY:
      return {
        ...state,
        team: action.payload.data
      };
    case LOAD_SURVEY_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload.status
      };
    case LOAD_SURVEY_SUCCESS:
      return {
        ...state,
        data: action.payload.data
      };
    case LOAD_SURVEY_STATUS_ERROR:
      return {
        ...state,
        statusError: true,
        statusErrorMsg: action.payload.errorMsg
      };
    case LOAD_SURVEY_ERROR:
      return {
        ...state,
        surveyError: true,
        surveyErrorMsg: action.payload.errorMsg
      };
    case SET_GAME_SLUG:
      return {
        ...state,
        data: null
      };
    case SET_SURVEY_INTEGRATION_PERCENTAGE:
      return {
        ...state,
        surveyIntegrationPercentage: action.payload.percentage
      };
    case SET_SURVEY_INTEGRATION_STATUS:
      return {
        ...state,
        surveyIntegrationStatus: action.payload.status
      };
    case SET_APPROXIMATE_SURVEY_INTEGRATION_TIME:
      return {
        ...state,
        approximateSurveyIntegrationTime: action.payload.time
      };
    case 'Survey / HOVERED_STEP':
      return {
        ...state,
        hoveredStep: action.payload.step
      };
    default:
      return state;
  }
};
