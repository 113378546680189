import { prepareAction } from '@/redux/helpers';
import { Action } from '@/redux/types';

import {
  LOAD_GAME,
  SET_GAME,
  RESET_GAME,
  RESET_GAME_SLUG,
  SET_GAME_SLUG,
  SET_DASHBOARD_CONFIG,
  RESET_DASHBOARD_CONFIG,
  DashboardConfig
} from './types';

export const loadGame = (gameSlug: string): Action => prepareAction(LOAD_GAME, { gameSlug });

export const setGame = (gameSlug: string): Action => prepareAction(SET_GAME, { gameSlug });

export const resetGame = (): Action => prepareAction(RESET_GAME);

export const setGameSlug = (gameSlug: string): Action => prepareAction(SET_GAME_SLUG, { gameSlug });

export const unsetGameSlug = (): Action => prepareAction(RESET_GAME_SLUG);

export const setDashboardConfig = (dashboardConfig: DashboardConfig): Action =>
  prepareAction(SET_DASHBOARD_CONFIG, { dashboardConfig });

export const resetDashboardConfig = (): Action => prepareAction(RESET_DASHBOARD_CONFIG);
