import { Dispatch } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchAcquisition, fetchEngagement } from '@/services/Api/ContentfulService';

import { selectNamespace } from '@/redux/game/selectors';
import { selectIsNonGamingCompany } from '@/redux/games/selectors';

import { RootState } from '../rootStore';

import { setContentfulEntries } from './slice';

export const traitsContentfulApi = createApi({
  reducerPath: 'traitsPageContentfulApi',
  baseQuery: async (
    { body: { entries } }: { body: { entries: string[] } },
    { getState, dispatch }: { getState: () => RootState; dispatch: Dispatch }
  ) => {
    const namespace = selectNamespace(getState());
    const isNonGamingCompany = selectIsNonGamingCompany(getState()) as boolean;

    const data = {} as { engagement: unknown; acquisition: unknown };

    if (entries.includes('clusterInsightsUserEngagement'))
      data.engagement = await fetchEngagement(namespace, isNonGamingCompany);

    if (entries.includes('clusterInsightsUserAcquisition'))
      data.acquisition = await fetchAcquisition(namespace, isNonGamingCompany);

    dispatch(setContentfulEntries({ data }));

    return { data };
  },
  tagTypes: ['Traits-Contentful'],
  endpoints: (builder: any) => ({
    getContenfulEntry: builder.query({
      query: ({ entries = [] }: { entries: string[] }) => ({ body: { entries } })
    })
  })
});

export const { useGetContenfulEntryQuery } = traitsContentfulApi;
