import { Template } from '@/pages/Navigator/AssessmentAutomationV2/types';

export const LOAD_TEAM_SURVEY = 'Survey / LOAD_TEAM_SURVEY';
export const LOAD_SURVEY_STATUS = 'Survey / LOAD_SURVEY_STATUS';
export const LOAD_SURVEY = 'Survey / LOAD_SURVEY';
export const LOAD_SURVEY_STATUS_SUCCESS = 'Survey / LOAD_SURVEY_STATUS_SUCCESS';
export const LOAD_SURVEY_SUCCESS = 'Survey / LOAD_SURVEY_SUCCESS';
export const LOAD_SURVEY_STATUS_ERROR = 'Survey / LOAD_SURVEY_STATUS_ERROR';
export const LOAD_SURVEY_ERROR = 'Survey / LOAD_SURVEY_ERROR';
export const SET_SURVEY_INTEGRATION_PERCENTAGE = 'Survey / SET_SURVEY_INTEGRATION_PERCENTAGE';
export const SET_SURVEY_INTEGRATION_STATUS = 'Survey / SET_SURVEY_INTEGRATION_STATUS';
export const SET_APPROXIMATE_SURVEY_INTEGRATION_TIME =
  'Survey / SET_APPROXIMATE_SURVEY_INTEGRATION_TIME';
export const CALCULATE_SURVEY_INTEGRATION = 'Survey / CALCULATE_SURVEY_INTEGRATION';

export const Statuses = {
  NOT_UPLOADED: 'NOT_UPLOADED',
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  CANCELED: 'CANCELED',
  INTEGRATING: 'INTEGRATING',
  INTEGRATED: 'INTEGRATED',
  ERROR: 'ERROR'
};

export const TeamAssessementsStatues = {
  NEW: 0,
  DOWNLOADING: 1,
  PROCESSING: 2,
  READY: 3,
  ERROR: 4
} as const;

export interface AssessementTeam {
  id: string;
  companyId: string;
  surveyId: number;
  gcsFolder: string;
  status: keyof typeof TeamAssessementsStatues;
  errorMessage: string;
  reportData: {
    teamAverage: Record<string, number>;
    teamDistribution: Record<string, number>;
    teamAll: Array<Record<string, number>>;
    lifecycle: Record<string, boolean>;
  };
}

export type TeamSurvey = Array<AssessementTeam>;

export interface SurveyState {
  team: TeamSurvey;
  status: number | null;
  data: any;
  statusError: boolean;
  statusErrorMsg: string;
  surveyIntegrationPercentage: number;
  surveyIntegrationStatus: string | null;
  approximateSurveyIntegrationTime: number;
  hoveredStep: Template | null;
}
