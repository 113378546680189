import { ReactElement, useRef } from 'react';

import { useButton, useFocusRing, mergeProps } from 'react-aria';

import styles from './styles.module.scss';

export function Button(props): ReactElement {
  const ref = useRef();
  const { buttonProps } = useButton(props, ref);
  const { focusProps } = useFocusRing();
  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      type="button"
      ref={ref}
      className={styles.button}
      data-testid={props.testId}
    >
      {props.children}
    </button>
  );
}
