import { Action } from '@/redux/types';

import { prepareAction } from '../helpers';

import { INITIAL_REQUEST, LOAD_COMPANY } from './types';

export const initialRequest = (): Action => prepareAction(INITIAL_REQUEST);

export const loadCompany = (isWithReloadKpis: boolean = true): Action =>
  prepareAction(LOAD_COMPANY, { isWithReloadKpis });
