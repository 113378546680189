import { getWeeksInMonth, endOfMonth } from '@internationalized/date';
import { ReactElement } from 'react';

import { useCalendarGrid, useLocale } from 'react-aria';
import { v4 as uuidv4 } from 'uuid';

import { CalendarCell } from './CalendarCell';
import styles from './styles.module.scss';



export function CalendarGrid({ state, offset = {} }): ReactElement {
  const { locale } = useLocale();
  const startDate = state.visibleRange.start.add(offset);
  const endDate = endOfMonth(startDate);
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    {
      startDate,
      endDate
    },
    state
  );

  const weeksInMonth = getWeeksInMonth(startDate, locale);

  return (
    <table
      style={{ height: `${28 * weeksInMonth}px` }}
      {...gridProps}
      cellPadding="0"
      className={styles.calendar__grid}
    >
      <thead {...headerProps} className={styles.calendar__head}>
        <tr>
          {weekDays.map(day => (
            <th key={uuidv4()}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map(weekIndex => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map(date =>
                date ? (
                  <CalendarCell
                    testId="calendar-cell"
                    key={date}
                    state={state}
                    date={date}
                    currentMonth={startDate}
                  />
                ) : (
                  <td key={uuidv4()} aria-label="Pick a date" />
                )
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
