

import useOutsideClick from '@12traits/useoutsideclick';
import classNames from 'classnames';
import React, {
  ReactElement,
  ChangeEvent,
  useState,
  KeyboardEvent,
  useRef,
  ReactNode
} from 'react';
import ReactDatePicker from 'react-datepicker';

import CheckIcon from 'ui-lib-12traits/src/Icons/svg/channels/circle-check.svg?component';
import ArrowUp from 'ui-lib-12traits/src/Icons/svg/common/angle-up.svg?component';
import CrossIcon from 'ui-lib-12traits/src/Icons/svg/common/cross.svg?component';
import InfoIcon from 'ui-lib-12traits/src/Icons/svg/common/error.svg?component';
import PlusIcon from 'ui-lib-12traits/src/Icons/svg/common/plus.svg?component';
import SearchIcon from 'ui-lib-12traits/src/Icons/svg/common/search.svg?component';
import ArrowIcon from 'ui-lib-12traits/src/Icons/svg/common/triangle_small.svg?component';
import CalendarIcon from 'ui-lib-12traits/src/Icons/svg/frequency/calendar.svg?component';
import EnterIcon from 'ui-lib-12traits/src/Icons/svg/navigator/enter.svg?component';
import { ButtonIcon, KeyboardListNavigation, Tooltip } from 'ui-lib-12traits/src/index';


import styles from './SurveyInput.module.scss';

import type { Placement } from 'tippy.js';

interface CustomDatePickerInputProps extends React.HTMLProps<HTMLInputElement> {
  isFocused: boolean;
  withClearIcon: boolean;
  onClear: () => void;
}

function CustomDatePickerInput({
  value,
  isFocused,
  withClearIcon,
  onClear,
  onClick,
  type,
  placeholder,
  inputWrapperClassName
}: CustomDatePickerInputProps): ReactElement {
  return <div
    className={classNames(
      inputWrapperClassName,
      styles['wrapper-input'],
      styles[`wrapper-input_${type}`],
      {
        [styles['wrapper-input--focused']]: isFocused
      }
    )}
  >
    <input placeholder={placeholder} onClick={onClick} className={styles.input} value={value} />
    {withClearIcon && value && (
      <ButtonIcon accessibleLabel="Close" onClick={onClear}>
        <CrossIcon className={styles['icon-clear']} />
      </ButtonIcon>
    )}
    <CalendarIcon className={styles['icon-calendar']} />
  </div>
}

interface Option<T> {
  prefix?: ReactElement;
  name: string;
  value: T;
  className?: string;
}

interface Props<T> {
  className?: string;
  withBorder?: boolean;
  value?: string;
  label?: ReactNode;
  name?: string;
  defaultValue?: string;
  selectedDate?: Date | null;
  pattern?: RegExp | '';
  options?: Option<T>[];
  placeholder?: string;
  withSearchIcon?: boolean;
  withNewAddedItem?: boolean;
  withNewAddedItemNotConsideringOptions?: boolean;
  withClearIcon?: boolean;
  withEnterIcon?: boolean;
  withArrowIcon?: boolean;
  focusedAfterClickOption?: boolean;
  errorTooltipContent?: ReactNode;
  errorTooltipIconClass?: string;
  zIndex?: number;
  autoFocus?: boolean;
  readOnly?: boolean;
  type?: 'search' | 'field' | 'textarea' | 'datepicker';
  values?: { name: string; value: T }[];
  onInputChange?: (value: string) => void;
  onDatePickerChange?: (value: Date) => void;
  onClickOption?: (value: T) => void;
  onClickNewOption?: (value: string) => void;
  onInputEnter?: (value: string) => void;
  onClear?: () => void;
  onClickOutside?: () => void;
  disabled?: boolean;
  errorTooltipClassname?: string;
  tooltipPlacement?: Placement;
  inputClassName?: string;
  withFocusedBorder?: boolean;
  inputWrapperClassName?: string;
  withCheckIcon?: boolean;
  addOnPressEnter?: boolean;
}

function SurveyInput<T extends string | number>({
  options = [],
  values = [],
  label,
  name,
  pattern = / /gm,
  placeholder,
  withBorder,
  value,
  defaultValue,
  selectedDate = null,
  withSearchIcon = false,
  withClearIcon = true,
  withEnterIcon = false,
  withArrowIcon = false,
  withCheckIcon = false,
  disabled = false,
  autoFocus = false,
  focusedAfterClickOption,
  readOnly = false,
  addOnPressEnter = false,
  withNewAddedItem,
  withNewAddedItemNotConsideringOptions = false,
  className,
  tooltipPlacement,
  errorTooltipClassname,
  errorTooltipContent,
  errorTooltipIconClass,
  zIndex,
  type = 'field',
  inputClassName,
  withFocusedBorder = true,
  inputWrapperClassName,
  onDatePickerChange = (): void => {},
  onInputChange = (): void => {},
  onClickOption = (): void => {},
  onClickNewOption = (): void => {},
  onInputEnter = (): void => {},
  onClear = (): void => {},
  onClickOutside = (): void => {}
}: Props<T>): ReactElement {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [hasValue, setHasValue] = useState<boolean>(false);
  const [hovered, setHovered] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (): void => {
    setIsFocused(false);
    onClickOutside();
  };

  useOutsideClick(ref, handleClickOutside);

  const handleFocus = (): void => {
    if (type === 'field' || type === 'search') {
      setIsFocused(!isFocused);
    } else {
      setIsFocused(true);
    }
  };

  const handleClickOption = (val: T) => (): void => {
    onClickOption(val);
    if (!focusedAfterClickOption) {
      setIsFocused(false);
    }
  };

  const handleClickNewOption = (val: string) => (): void => {
    onClickNewOption(val);
    setIsFocused(false);
  };

  const handleKeyDownInput = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && refInput.current) {
      onInputEnter(refInput.current.value);
      setIsFocused(false);
      refInput.current.blur();
    }
    if (e.key === 'Escape' && refInput.current) {
      setIsFocused(false);
      refInput.current.blur();
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (!e.key.replace(pattern, '')) {
      e.preventDefault();
    }
  };

  const handleClear = (): void => {
    onClear();
  };

  const handleDatePickerChange = (date: Date): void => {
    setIsFocused(false);
    onDatePickerChange(date);
  };

  const handleChangeMinDate = (): Date => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    return currentDate;
  };

  const renderInput = (
    <input
      onFocus={handleFocus}
      className={classNames(styles.input, inputClassName)}
      ref={refInput}
      placeholder={placeholder}
      value={value}
      name={name}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      readOnly={readOnly}
      defaultValue={defaultValue}
      onKeyDown={handleKeyDownInput}
      onKeyPress={handleKeyPress}
      disabled={disabled}
      onChange={(event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value) {
          setHasValue(true);
        } else {
          setHasValue(false);
        }
        onInputChange(event.target.value);
      }}
    />
  );

  return (
    <div
      data-testid={`survey-input-wrapper ${name}`}
      className={classNames(styles.wrapper, className)}
      ref={ref}
    >
      {label && <div className={styles.label}>{label}</div>}
      {type !== 'datepicker' && (
        <div
          className={classNames(
            styles['wrapper-input'],
            styles[`wrapper-input_${type}`],
            inputWrapperClassName,
            {
              [styles['wrapper-input--focused']]: isFocused && withFocusedBorder,
              [styles['wrapper-input--focused-without-border']]: isFocused && !withFocusedBorder,
              [styles['wrapper-input--focused_with-options']]: isFocused && options.length,
              [styles['wrapper-input--with-border']]: withBorder,
              [styles['wrapper-input_with-values']]: values.length,
              [styles['wrapper-input_disabled']]: disabled
            }
          )}
        >
          {withSearchIcon && !values.length && <SearchIcon className={styles['icon-search']} />}
          {(type === 'field' || type === 'search') && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {values.length ? (
                <span className={styles.values}>
                  {withSearchIcon && values.length && (
                    <SearchIcon className={styles['icon-search']} />
                  )}
                  {values.map(item => (
                    <span className={styles.values__item} key={item.value}>
                      {item.name}
                      <CrossIcon
                        onClick={handleClickOption(item.value as T)}
                        className={styles.values__icon}
                      />
                    </span>
                  ))}
                  {renderInput}
                </span>
              ) : (
                renderInput
              )}
            </>
          )}
          {type === 'textarea' && (
            <textarea
              name={name}
              disabled={disabled}
              placeholder={placeholder}
              className={classNames(styles.input, styles.input_textarea)}
              value={value}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => {
                onInputChange(event.target.value);
              }}
              onFocus={handleFocus}
            />
          )}
          {((value && options.length && addOnPressEnter && isFocused) ||
            (value &&
              (!options.length || withNewAddedItemNotConsideringOptions) &&
              withNewAddedItem &&
              isFocused)) && (
            <div className={styles.enter}>
              Enter to add
              <EnterIcon className={styles['icon-enter-arrow']} />
            </div>
          )}
          {withEnterIcon && hasValue && isFocused && (
            <EnterIcon className={styles['icon-enter-arrow']} />
          )}
          {withClearIcon && (value || selectedDate) && (
            <ButtonIcon accessibleLabel="Clear" onClick={handleClear}>
              <CrossIcon className={styles['icon-clear']} />
            </ButtonIcon>
          )}
          {withArrowIcon && (
            <ButtonIcon
              accessibleLabel={isFocused ? 'Close' : 'Open'}
              onClick={handleFocus}
              className={styles['btn-arrow']}
            >
              <ArrowIcon
                className={classNames(styles['icon-arrow'], {
                  [styles['icon-arrow_reversed']]: isFocused
                })}
              />
            </ButtonIcon>
          )}

          {errorTooltipContent && (
            <Tooltip
              className={classNames(errorTooltipClassname, styles['error-tooltip'])}
              visible={isFocused || hovered}
              content={errorTooltipContent}
              arrowClass={styles['error-tooltip__arrow']}
              placement={tooltipPlacement || 'right'}
              {...(zIndex ? { zIndex } : {})}
            >
              <span
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                role="button"
                tabIndex={0}
                className={errorTooltipIconClass}
              >
                <InfoIcon className={styles['error-tooltip__icon']} />
              </span>
            </Tooltip>
          )}
          {withCheckIcon && <CheckIcon data-testid="check-icon" />}
        </div>
      )}
      {type === 'datepicker' && (
        <ReactDatePicker
          placeholderText={placeholder}
          popperClassName="survey-date-picker__popper"
          disabled={disabled}
          customInput={
            <CustomDatePickerInput
              isFocused={isFocused}
              withClearIcon={withClearIcon}
              onClear={handleClear}
              type={type}
            />
          }
          formatWeekDay={(nameOfDay): string => nameOfDay.toString().substring(0, 1)}
          dateFormat="MMMM d, yyyy"
          // todayButton="Today"
          wrapperClassName="survey-date-picker"
          className="survey-date-picker__input"
          calendarClassName="survey-date-picker__calendar"
          showPopperArrow={false}
          popperPlacement="bottom"
          popperModifiers={[
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom']
              }
            }
          ]}
          selected={selectedDate}
          minDate={handleChangeMinDate()}
          onInputClick={handleFocus}
          onChange={handleDatePickerChange}
          renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }): ReactElement => (
            <div className="survey-date-picker__header">
              <div className="survey-date-picker__month-date">
                {monthDate.toLocaleString('en-US', {
                  month: 'long',
                  year: 'numeric'
                })}
              </div>
              <ButtonIcon
                accessibleLabel="Next Month"
                onClick={decreaseMonth}
                className="survey-date-picker__btn-change-month"
              >
                <ArrowUp className="survey-date-picker__arrow survey-date-picker__arrow_prev" />
              </ButtonIcon>
              <ButtonIcon
                accessibleLabel="Previous Month"
                onClick={increaseMonth}
                className="survey-date-picker__btn-change-month"
              >
                <ArrowUp className="survey-date-picker__arrow survey-date-picker__arrow_next" />
              </ButtonIcon>
            </div>
          )}
        />
      )}
      {isFocused && (
        <KeyboardListNavigation isActive listRef={listRef}>
          <div ref={listRef} className={styles.dropdown}>
            {options.map(item => (
              <div
                key={item.value}
                tabIndex={0}
                role="button"
                className={classNames(styles.dropdown__item, item.className)}
                onKeyDown={(e): void =>
                  e.key === 'Enter' ? handleClickOption(item.value)() : undefined
                }
                onClick={handleClickOption(item.value)}
              >
                {item.prefix}
                {item.name}
              </div>
            ))}
            {type === 'search' && value && !options.length && withNewAddedItem && (
              <div
                tabIndex={0}
                role="button"
                className={styles.dropdown__item}
                onKeyDown={(e): void =>
                  e.key === 'Enter' ? handleClickNewOption(value || '')() : undefined
                }
                onClick={handleClickNewOption(value || '')}
              >
                <PlusIcon className={styles['plus-icon']} />
                {value}
              </div>
            )}
          </div>
        </KeyboardListNavigation>
      )}
    </div>
  );
}

export default SurveyInput;
