import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareBaseQuery } from '@/redux/helpers';

type ComparisonOptions = {
  custom_survey_data: any;
  kpis: Record<string, any>;
  scores: Record<string, any>;
  traits: Record<string, any>;
};

export const configApi = createApi({
  reducerPath: 'configApi',
  tagTypes: ['Config'],
  baseQuery: fetchBaseQuery(
    prepareBaseQuery({
      endpoint: 'compare'
    })
  ),
  endpoints: build => ({
    getComparisonOptions: build.query<ComparisonOptions, { gameId: string }>({
      query: ({ gameId }) => ({
        url: '/config/comparison/options',
        params: { dashboard_id: gameId }
      }),
      transformResponse: (response: any) => response.data,
      providesTags: ['Config']
    })
  })
});
export const { useGetComparisonOptionsQuery } = configApi;
