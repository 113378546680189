import { GenreDetails } from '@/components/Navigator/DetailsModal/types';
import { ViewEnum } from '@/components/Navigator/types';

import { Namespace } from '../game/types';
import { Segment } from '../types';

export const LOAD_GAMES_REQUEST = 'Games / LOAD_GAMES_REQUEST';
export const LOAD_GAMES_SUCCESS = 'Games / LOAD_GAMES_SUCCESS';
export const LOAD_GAMES_ERROR = 'Games / LOAD_GAMES_ERROR';
export const SET_CURRENT_CLUSTER_NAME = 'Games / SET_CURRENT_CLUSTER_NAME';
export const RESET_GAMES_STATE = 'Games / RESET_GAMES_STATE';

export const NON_GAMING_COMPANY_TYPE = 1;
export const MEDICAL_COMPANY_TYPE = 2;

export interface Game {
  id: string;
  name: string;
  jwt: string;
  clusters: number; // amount of clusters
  segments: Segment[];
  api_key: string;
  ingestion_api_key: string;
  thumbnail_url: string;
  cover_url: string;
  status: number;
  slug: string;
  genres?: GenreDetails[];
  dashboard_config: ApiDashboardConfig;
  kpis_upload_status: KPIsUploadStatus;
  pcs: number;
  isFavorite: boolean;
  revenueNow: number;
  revenueBefore: number;
  lastViewedAt: number;
  kpisGroupedByClusters: {
    lifetimeRevenue?: {
      avgValuesByClusters: { [personaCode: string]: number };
    };
    sessionCount: {
      avgValuesByClusters: { [personaCode: string]: number };
    };
  } | null;
}

export interface ApiDashboardConfig {
  kpis_enabled: boolean;
  empty_player_ids_file: boolean;
  recommended_actions_enabled: boolean;
  fb_targeting_enabled: boolean;
  assessment_version: string;
  overview_only_personas: boolean;
  enable_lookalike: boolean;
  enable_high_value_blade: boolean;
  enable_performance_metrics: boolean;
  enable_kpi_charts: boolean;
  namespace: Namespace;
}

export interface GamesState {
  list: Array<Game>;
  loading: boolean;
  error: boolean;
  errorMsg: string;
  companyName: string;
  isNonGamingCompany: boolean | null;
  viewType: ViewEnum;
}

export interface KPIsUploadStatus {
  game_id: string | null;
  type: string | null;
  status: number | null;
  status_info: string | null;
  is_manual: boolean | null;
  batches_processed: number | null;
  batches_total: number | null;
  is_api_set_up: boolean | null;
  filename: string | null;
  gcs_filename: string | null;
  user_id: null | null;
  upload_id: string | null;
  ingested_at: number | null;
}
