import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { ReactElement, useLayoutEffect, useRef, useState } from 'react';
import AppleStoreIcon from 'ui-lib-12traits/src/Icons/svg/channels/apple-store.svg?component';
import GoogleAppStore from 'ui-lib-12traits/src/Icons/svg/channels/google-play-store.svg?component';
import TwitterIcon from 'ui-lib-12traits/src/Icons/svg/channels/twitter.svg?component';
import YouTubeIcon from 'ui-lib-12traits/src/Icons/svg/channels/youtube.svg?component';
import ExitIcon from 'ui-lib-12traits/src/Icons/svg/common/exit-2.svg?component';
import ArrowIcon from 'ui-lib-12traits/src/Icons/svg/common/rounded-arrow.svg?component';
import KeyGuyIcon from 'ui-lib-12traits/src/Icons/svg/keyguy/keyguy-rounded.svg?component';
import { Tooltip } from 'ui-lib-12traits/src/index';

import { ConversationThread } from '@/pages/Traits/ConversationThreadsPage/types';
import { ChannelsEnum, getScoreColorClassName } from '../helpers';
import styles from './ConversationThreadsListItem.module.scss';

interface Props {
  item: ConversationThread;
}

function ConversationThreadsListItem({ item }: Props): ReactElement {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(24);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const getChannelIcon = (source: string): string | React.SVGAttributes<SVGElement> | undefined => {
    if (!source) {
      return 'N/A';
    }
    if (source === ChannelsEnum.AppleAppStore) {
      return <AppleStoreIcon />;
    }
    if (source === ChannelsEnum.YouTube) {
      return <YouTubeIcon />;
    }
    if (source === ChannelsEnum.Twitter) {
      return <TwitterIcon />;
    }
    if (source === ChannelsEnum.GooglePlayStore) {
      return <GoogleAppStore />;
    }
    return 'N/A';
  };

  const getHighlightedMessage = (message: string): string => {
    let highlightedMessage = message;
    item.highlightedTerms?.forEach(term => {
      const regex = new RegExp(`\\b${term}(?![\\w-])`, 'gi');
      highlightedMessage = highlightedMessage.replaceAll(
        regex,
        `<span style="font-weight:900">${term}</span>`
      );
    });

    return highlightedMessage;
  };

  const reviewDate = new Date(item.timestamp * 1000);

  const listItemRef = useRef(null);

  useLayoutEffect(() => {
    if (listItemRef.current) {
      const next = listItemRef.current.getBoundingClientRect();
      setHeight(next.height);
    }
  }, [listItemRef]);

  return (
    <div className={styles.item__wrapper} key={item.id} data-testid="list-item-wrapper">
      <div className={styles.item__content} data-testid="list-item-content">
        <div
          onClick={(): void | null => (height >= 92 ? setIsExpanded(!isExpanded) : null)}
          className={classNames(styles.content__text, {
            [styles.content__text_large]: height >= 92
          })}
        >
          <div
            ref={listItemRef}
            className={classNames(styles.content__comment, {
              [styles.content__comment_large]: height >= 92 && !isExpanded
            })}
            dangerouslySetInnerHTML={{ __html: getHighlightedMessage(item.message) }}
            data-testid="list-item-comment"
          />
          {height >= 92 && (
            <span className={styles['view-more']}>
              {isExpanded ? 'View less' : 'View more'}
              <ArrowIcon
                className={classNames(styles['view-more__icon'], {
                  [styles['view-more__icon_expanded']]: isExpanded
                })}
              />
            </span>
          )}
        </div>
        <div
          className={styles.content__channel}
          onMouseOver={(): void => {
            setIsHovered(true);
          }}
          onFocus={(): void => {
            setIsHovered(true);
          }}
          onMouseLeave={(): void => {
            setIsHovered(false);
          }}
        >
          {isHovered && item.messageUrl ? (
            <>
              <a target="_blank" rel="noreferrer" href={item.messageUrl}>
                <ExitIcon className={styles['back-icon']} />
              </a>
              <div className={styles['back-title']}>Go to text</div>
            </>
          ) : (
            <>
              <div className={styles.channel__icon} data-testid="list-item-icon">
                {getChannelIcon(item.source)}
              </div>
              <div className={styles.content__date} data-testid="list-item-date">
                {dayjs(
                  new Date(
                    reviewDate.getUTCFullYear(),
                    reviewDate.getUTCMonth(),
                    reviewDate.getUTCDate()
                  )
                ).format('MMM DD')}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.content__data}>
        <div className={styles.content__values}>
          {' '}
          <div className={styles.content__personas}>
            {item.clusterScores ? (
              <>
                <div className={styles.personas__title} data-testid="list-item-personas-title">
                  Personas who likely said this:
                </div>
                <div className={styles.personas__values} data-testid="list-item-personas-values">
                  {item?.clusterScores
                    .sort((a, b) => a.cluster - b.cluster)
                    .map(persona => (
                      <div key={persona.cluster} className={styles.personas__persona}>
                        <Tooltip
                          className={styles.personas__tooltip}
                          placement="bottom"
                          content={`Persona ${persona.cluster + 1}`}
                          arrowClass={styles['personas__tooltip-arrow']}
                        >
                          <div className={styles['personas__icon-wrapper']}>
                            <KeyGuyIcon
                              className={classNames(
                                styles.personas__icon,
                                styles[`persona-${persona.cluster}-fill-c`]
                              )}
                            />
                            <div className={styles['personas__icon-value']}>
                              {persona.cluster + 1}
                            </div>
                          </div>
                        </Tooltip>
                        <div className={styles.personas__value}>
                          {Number.isInteger(persona.score)
                            ? persona.score
                            : persona.score.toFixed(1)}
                          %
                        </div>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <div className={styles.personas__title} data-testid="list-item-personas-title">
                Not enough data to confidently assign personas.
              </div>
            )}
          </div>
          <div
            className={classNames(styles.content__scores, {
              [styles.content__scores_higher]: item.clusterScores
            })}
          >
            <div className={styles.informativeness} data-testid="list-item-informativeness">
              <span className={styles.score_header}>Informativeness: </span>
              <span
                className={classNames(
                  styles[
                    `score_${getScoreColorClassName(+(100 * item.informativenessScore).toFixed(0))}`
                  ],
                  styles.score
                )}
                data-testid="list-item-informativeness-score"
              >
                {item.informativenessScore !== undefined
                  ? (100 * item.informativenessScore).toFixed(0)
                  : 'N/A'}
              </span>
            </div>
            <div className={styles.sentiment} data-testid="list-item-sentiment">
              <span className={styles.score_header}>Sentiment: </span>
              <span
                className={classNames(
                  styles[
                    `score_${getScoreColorClassName(+(100 * item.sentimentScore).toFixed(0))}`
                  ],
                  styles.score
                )}
                data-testid="list-item-sentiment-score"
              >
                {item.sentimentScore !== undefined ? (100 * item.sentimentScore).toFixed(0) : 'N/A'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConversationThreadsListItem;
