import { Action } from '@/redux/types';

import { DEFAULT_BUILD_NAME } from './constants';
import {
  SET_CREATIVES_COUNT,
  SET_CREATIVES_LIMIT,
  SET_UNDO_DELETE,
  SET_FREQUENCY_PURCHASED,
  FrequencyState,
  SET_FREQUENCY_ENABLE_RESONANCE,
  SET_FREQUENCY_IS_UPLOADING,
  SET_FREQUENCY_IS_SYNCING,
  SET_FREQUENCY_SYNC_DATA,
  SET_FREQUENCY_SELECTED_DASHBOARD_ID,
  SET_FREQUENCY_IS_REVIEW_SURVEY_FLOW_OPENED,
  SET_FREQUENCY_BUILD_NAME,
  SET_FREQUENCY_BUILD_COVER_CREATIVE,
  SET_FREQUENCY_BUILD_PINNED_CREATIVE_IDS,
  SET_FREQUENCY_BUILD_UPLOADED_CREATIVES,
  SET_FREQUENCY_BUILD_SELECTED_TAGS,
  SET_FREQUENCY_BUILD_SELECTED_AUDIENCE_ID,
  SET_FREQUENCY_BUILD_SELECTED_SEGMENT_ID,
  SET_FREQUENCY_BUILD_SELECTED_PERSONA,
  SET_FREQUENCY_IS_BUILDER_PAGE_VISITED,
  SET_FREQUENCY_BUILD_RESONANCE_SCORE,
  SET_FREQUENCY_BUILD_ID,
  SET_FREQUENCY_NEED_FETCH_BUILD_DATA
} from './types';

const initialState: FrequencyState = {
  creativesCount: 0,
  creativesLimit: 0,
  frequencyPurchased: null,
  enableResonanceScore: null,
  isUploading: false,
  isSyncing: false,
  syncData: [],
  selectedDashboardId: '',
  isDeleted: '',
  isReviewSurveyFlowOpened: false,
  build: {
    id: '',
    name: DEFAULT_BUILD_NAME,
    pinnedCreativeIds: [],
    coverCreative: null,
    selectedAudienceId: '',
    selectedPersona: '',
    selectedSegmentId: '',
    selectedTags: [],
    uploadedCreatives: [],
    resonanceScore: ''
  },
  isBuilderPageVisited: false,
  needFetchBuildData: true
};

export default (state = initialState, { type, payload }: Action): FrequencyState => {
  switch (type) {
    case SET_CREATIVES_COUNT: {
      return { ...state, creativesCount: payload.creativesCount };
    }

    case SET_CREATIVES_LIMIT: {
      return { ...state, creativesLimit: payload.creativesLimit };
    }

    case SET_FREQUENCY_PURCHASED: {
      return { ...state, frequencyPurchased: payload.frequencyPurchased };
    }

    case SET_FREQUENCY_ENABLE_RESONANCE: {
      return { ...state, enableResonanceScore: payload.enableResonanceScore };
    }

    case SET_FREQUENCY_IS_UPLOADING: {
      return { ...state, isUploading: payload.isUploading };
    }

    case SET_FREQUENCY_IS_SYNCING: {
      return { ...state, isSyncing: payload.isSyncing };
    }

    case SET_FREQUENCY_SYNC_DATA: {
      return { ...state, syncData: payload.syncData };
    }

    case SET_FREQUENCY_SELECTED_DASHBOARD_ID: {
      return { ...state, selectedDashboardId: payload.selectedDashboardId };
    }

    case SET_UNDO_DELETE: {
      return { ...state, isDeleted: payload.isDeleted };
    }
    case SET_FREQUENCY_IS_REVIEW_SURVEY_FLOW_OPENED: {
      return { ...state, isReviewSurveyFlowOpened: payload.isReviewSurveyFlowOpened };
    }

    case SET_FREQUENCY_BUILD_ID: {
      return { ...state, build: { ...state.build, id: payload.id } };
    }

    case SET_FREQUENCY_BUILD_NAME: {
      return { ...state, build: { ...state.build, name: payload.buildName } };
    }

    case SET_FREQUENCY_BUILD_COVER_CREATIVE: {
      return { ...state, build: { ...state.build, coverCreative: payload.coverCreative } };
    }

    case SET_FREQUENCY_BUILD_PINNED_CREATIVE_IDS: {
      return { ...state, build: { ...state.build, pinnedCreativeIds: payload.pinnedCreativeIds } };
    }

    case SET_FREQUENCY_BUILD_UPLOADED_CREATIVES: {
      return { ...state, build: { ...state.build, uploadedCreatives: payload.uploadedCreatives } };
    }

    case SET_FREQUENCY_BUILD_SELECTED_TAGS: {
      return { ...state, build: { ...state.build, selectedTags: payload.selectedTags } };
    }

    case SET_FREQUENCY_BUILD_SELECTED_AUDIENCE_ID: {
      return {
        ...state,
        build: { ...state.build, selectedAudienceId: payload.selectedAudienceId }
      };
    }

    case SET_FREQUENCY_BUILD_SELECTED_SEGMENT_ID: {
      return {
        ...state,
        build: { ...state.build, selectedSegmentId: payload.selectedSegmentId }
      };
    }

    case SET_FREQUENCY_BUILD_SELECTED_PERSONA: {
      return {
        ...state,
        build: { ...state.build, selectedPersona: payload.selectedPersona }
      };
    }

    case SET_FREQUENCY_BUILD_RESONANCE_SCORE: {
      return {
        ...state,
        build: { ...state.build, resonanceScore: payload.resonanceScore }
      };
    }

    case SET_FREQUENCY_IS_BUILDER_PAGE_VISITED: {
      return {
        ...state,
        isBuilderPageVisited: payload.isBuilderPageVisited
      };
    }

    case SET_FREQUENCY_NEED_FETCH_BUILD_DATA: {
      return { ...state, needFetchBuildData: payload.needFetchBuildData };
    }

    default:
      return state;
  }
};
