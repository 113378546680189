import camelcaseKeys from 'camelcase-keys';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  ACTION_STATUS_NEW,
  ACTION_STATUS_SKIPPED,
  ACTION_STATUS_COMPLETED,
  ACTION_STATUS_IN_PROGRESS
} from '@/pages/Traits/ActionsPage/constants';

import { mapFiltersToParams } from '@/services/ActionsService';
import {
  fetchActions,
  addAction,
  updateAction,
  deleteAction,
  fetchActionsProgress,
  voteAction,
  unvoteAction,
  unlockAction
} from '@/services/Api/GameService';

import { selectCurrentClusterCode } from '@/redux/cluster/slice';
import {
  selectActionsPerPage,
  selectTimeFrame,
  selectActionsCurrentStatusId,
  selectActionsCurrentCategoryName,
  selectActionsData,
  selectFilters
} from '@/redux/pages/actionsPage/selectors';
import store from '@/redux/rootStore';
import { Action } from '@/redux/types';

import {
  loadActionsRequest,
  loadActionsSuccess,
  addActionRequest,
  addActionSuccess,
  updateActionSuccess,
  updateActionRequest,
  deleteActionRequest,
  deleteActionSuccess,
  loadProgressRequest,
  loadProgressSuccess,
  loadProgress,
  voteActionRequest,
  voteActionSuccess,
  reloadActions,
  unlockActionRequest,
  unlockActionError
} from './actions';
import {
  LOAD_ACTIONS,
  ADD_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
  LOAD_PROGRESS,
  VOTE_ACTION,
  RELOAD_ACTIONS,
  UNLOCK_ACTION
} from './types';

function* doAddAction({ payload }: Action) {
  yield put(addActionRequest());
  try {
    const state = store.getState();
    const category = selectActionsCurrentCategoryName(state);
    const result = yield call(addAction, { ...payload, category });
    yield put(
      addActionSuccess({
        ...camelcaseKeys(result.data.action, { deep: true }),
        category,
        quickAdd: payload.quickAdd,
        added: true
      })
    );
  } catch (e) {
    // do nothing
  }
}

function* doUpdateAction({ payload }: Action) {
  yield put(updateActionRequest());
  try {
    const state = store.getState();
    const { actions } = selectActionsData(state);
    const currentStatusId = selectActionsCurrentStatusId(state);
    const { id, fields } = payload;
    const result = yield call(updateAction, payload.id, payload.fields);
    yield put(updateActionSuccess(camelcaseKeys(result.data.action, { deep: true })));
    if (payload.fields.status !== undefined) {
      yield put(loadProgress());
    }
    // to reload actions and trigger showing of empty list
    if (
      (fields.status === ACTION_STATUS_COMPLETED && Object.keys(fields).includes('rating')) ||
      (fields.status === ACTION_STATUS_IN_PROGRESS && fields.statusChanged)
    ) {
      const isListEmpty = !actions.filter(
        action => action.status === currentStatusId && action.id !== id
      ).length;
      if (isListEmpty) {
        yield put(reloadActions());
      }
    }
  } catch (e) {
    // do nothing
  }
}

function* doDeleteAction({ payload }: Action) {
  yield put(deleteActionRequest());
  try {
    yield call(deleteAction, payload.id);
    yield put(deleteActionSuccess(payload.id));
  } catch (e) {
    // do nothing
  }
}

function* doLoadActions({ payload }: Action) {
  yield put(loadActionsRequest());
  try {
    const state = store.getState();
    const cluster = selectCurrentClusterCode(state);
    const { allActions, statuses, sort, page, objectives } = payload;
    const limit = allActions ? 100000 : selectActionsPerPage(state);
    const offset = limit ? (page - 1) * limit : 0;
    const category = objectives || selectActionsCurrentCategoryName(state);
    const filters = mapFiltersToParams(selectFilters(state));
    const params = {
      offset,
      limit,
      statuses,
      objectives: category,
      selected_cluster: cluster,
      ...filters
    };

    const result = yield call(fetchActions, allActions ? { ...params, sort } : params);
    const totalActions = result.data.total_count;
    const hasMore = allActions ? false : offset + limit < totalActions;
    yield put(
      loadActionsSuccess(
        camelcaseKeys(result.data.actions, { deep: true }),
        page,
        totalActions,
        hasMore
      )
    );
  } catch (e) {
    // do nothing
  }
}

function* doReloadActions() {
  yield put(loadActionsRequest());
  try {
    const state = store.getState();
    const currentStatusId = selectActionsCurrentStatusId(state);
    const isCompletedActions = currentStatusId === ACTION_STATUS_COMPLETED;
    const statuses =
      currentStatusId === ACTION_STATUS_NEW
        ? [ACTION_STATUS_NEW, ACTION_STATUS_SKIPPED]
        : [currentStatusId];

    const { actions, page } = selectActionsData(state);

    const cluster = selectCurrentClusterCode(state);

    const perPage = selectActionsPerPage(state);
    const limit = actions.length > perPage ? actions.length : perPage;
    const category = selectActionsCurrentCategoryName(state);
    const filters = mapFiltersToParams(selectFilters(state));

    const params = {
      offset: 0,
      limit,
      selected_cluster: cluster,
      objectives: category,
      statuses,
      ...filters
    };

    const result = yield call(
      fetchActions,
      isCompletedActions ? { ...params, sort: 'completed_at' } : params
    );
    const totalActions = result.data.total_count;
    const hasMore = isCompletedActions ? false : limit < totalActions;
    yield put(
      loadActionsSuccess(
        camelcaseKeys(result.data.actions, { deep: true }),
        page,
        totalActions,
        hasMore
      )
    );
  } catch (e) {
    // do nothing
  }
}

function* doLoadProgress() {
  yield put(loadProgressRequest());
  try {
    const state = store.getState();
    const timeFrame = selectTimeFrame(state);
    const cluster = selectCurrentClusterCode(state);
    const result = yield call(fetchActionsProgress, cluster, timeFrame);
    yield put(loadProgressSuccess(result.data.progress));
  } catch (e) {
    // do nothing
  }
}

function* doVoteAction({ payload }: Action) {
  yield put(voteActionRequest());
  try {
    const { id, value } = payload;

    if (value) {
      yield call(voteAction, id, value);
    } else {
      yield call(unvoteAction, id);
    }
    yield put(voteActionSuccess());
    yield put(reloadActions());
  } catch (e) {
    // do nothing
  }
}

function* doUnlockAction({ payload: { actionId } }: Action) {
  yield put(unlockActionRequest());

  try {
    yield call(unlockAction, actionId);
    // no logics here
    // this request is only sent for now make sure it works correctly
    // since the endpoint response needs to be updated
  } catch (e) {
    yield put(unlockActionError(e.message));
  }
}

function* Saga() {
  yield takeEvery(LOAD_ACTIONS, doLoadActions);
  yield takeEvery(ADD_ACTION, doAddAction);
  yield takeEvery(UPDATE_ACTION, doUpdateAction);
  yield takeEvery(DELETE_ACTION, doDeleteAction);
  yield takeEvery(LOAD_PROGRESS, doLoadProgress);
  yield takeEvery(RELOAD_ACTIONS, doReloadActions);
  yield takeEvery(VOTE_ACTION, doVoteAction);
  yield takeEvery(UNLOCK_ACTION, doUnlockAction);
}

export default Saga;
