import cn from 'classnames';
import * as React from 'react';


import styles from './AssessmentAutomationPageV2.module.scss';

// * Progress component ---
// * last step is 99% and only after submission is 100%

const Progress = React.memo(({ className, currentProgress, nodeRef }: any) => (
  <div
    className={cn(styles.progress, className, {
      // [styles.progress_animation]: showAnimation
    })}
  >
    <div
      ref={nodeRef}
      className={styles['progress-filled']}
      style={{
        width: `${currentProgress}%`
      }}
    />
  </div>
));

export default Progress;
