import { Creative } from '@/services/Api/AdsService/types';

import { prepareAction } from '@/redux/helpers';
import { Action } from '@/redux/types';

import { TagItem } from '@/components/Frequency/types';


import {
  LOAD_FREQUENCY_PERMISSIONS,
  SET_CREATIVES_COUNT,
  SET_UNDO_DELETE,
  SET_CREATIVES_LIMIT,
  SET_FREQUENCY_PURCHASED,
  SET_FREQUENCY_ENABLE_RESONANCE,
  SET_FREQUENCY_IS_UPLOADING,
  SET_FREQUENCY_SYNC_DATA,
  CHECK_SYNC_STATUS,
  START_CHECK_SYNC_STATUS,
  SET_FREQUENCY_IS_SYNCING,
  SET_FREQUENCY_SELECTED_DASHBOARD_ID,
  SET_FREQUENCY_IS_REVIEW_SURVEY_FLOW_OPENED,
  SET_FREQUENCY_BUILD_NAME,
  SET_FREQUENCY_BUILD_COVER_CREATIVE,
  SET_FREQUENCY_BUILD_PINNED_CREATIVE_IDS,
  SET_FREQUENCY_BUILD_UPLOADED_CREATIVES,
  SET_FREQUENCY_BUILD_SELECTED_TAGS,
  SET_FREQUENCY_BUILD_SELECTED_AUDIENCE_ID,
  SET_FREQUENCY_BUILD_SELECTED_SEGMENT_ID,
  SET_FREQUENCY_BUILD_SELECTED_PERSONA,
  SET_FREQUENCY_IS_BUILDER_PAGE_VISITED,
  SET_FREQUENCY_BUILD_RESONANCE_SCORE,
  SET_FREQUENCY_BUILD_ID,
  SET_FREQUENCY_NEED_FETCH_BUILD_DATA
} from './types';

export const loadFrequencyPermissions = (data: any): Action =>
  prepareAction(LOAD_FREQUENCY_PERMISSIONS, data);

export const setCreativesCount = (creativesCount: number): Action =>
  prepareAction(SET_CREATIVES_COUNT, { creativesCount });

export const setCreativesLimit = (creativesLimit: number): Action =>
  prepareAction(SET_CREATIVES_LIMIT, { creativesLimit });

export const setToUndoDelete = (isDeleted: string): Action =>
  prepareAction(SET_UNDO_DELETE, { isDeleted });

export const setFrequencyPurchased = (frequencyPurchased: boolean): Action =>
  prepareAction(SET_FREQUENCY_PURCHASED, { frequencyPurchased });

export const setResonanceEnabled = (enableResonanceScore: boolean): Action =>
  prepareAction(SET_FREQUENCY_ENABLE_RESONANCE, { enableResonanceScore });

export const setFrequencyIsUploading = (isUploading: boolean): Action =>
  prepareAction(SET_FREQUENCY_IS_UPLOADING, { isUploading });

export const setFrequencySyncData = (syncData: any[]): Action =>
  prepareAction(SET_FREQUENCY_SYNC_DATA, { syncData });

export const checkSyncStatus = (): Action => prepareAction(CHECK_SYNC_STATUS);

export const startCheckSyncStatus = (): Action => prepareAction(START_CHECK_SYNC_STATUS);

export const setFrequencyIsSyncing = (isSyncing: boolean): Action =>
  prepareAction(SET_FREQUENCY_IS_SYNCING, { isSyncing });

export const setFrequencySelectedDashboardId = (selectedDashboardId: string): Action =>
  prepareAction(SET_FREQUENCY_SELECTED_DASHBOARD_ID, { selectedDashboardId });

export const setIsReviewSurveyFlowOpened = (isReviewSurveyFlowOpened: boolean): Action =>
  prepareAction(SET_FREQUENCY_IS_REVIEW_SURVEY_FLOW_OPENED, { isReviewSurveyFlowOpened });

export const setFrequencyBuildId = (id: string): Action =>
  prepareAction(SET_FREQUENCY_BUILD_ID, { id });

export const setFrequencyBuildName = (buildName: string): Action =>
  prepareAction(SET_FREQUENCY_BUILD_NAME, { buildName });

export const setFrequencyBuildCoverCreative = (coverCreative: Creative | null): Action =>
  prepareAction(SET_FREQUENCY_BUILD_COVER_CREATIVE, { coverCreative });

export const setFrequencyBuildPinnedCreativeIds = (pinnedCreativeIds: string[]): Action =>
  prepareAction(SET_FREQUENCY_BUILD_PINNED_CREATIVE_IDS, { pinnedCreativeIds });

export const setFrequencyBuildUploadedCreatives = (uploadedCreatives: Creative[]): Action =>
  prepareAction(SET_FREQUENCY_BUILD_UPLOADED_CREATIVES, { uploadedCreatives });

export const setFrequencyBuildSelectedTags = (selectedTags: TagItem[]): Action =>
  prepareAction(SET_FREQUENCY_BUILD_SELECTED_TAGS, { selectedTags });

export const setFrequencyBuildSelectedAudienceId = (selectedAudienceId: string): Action =>
  prepareAction(SET_FREQUENCY_BUILD_SELECTED_AUDIENCE_ID, { selectedAudienceId });

export const setFrequencyBuildSelectedSegmentId = (selectedSegmentId: string): Action =>
  prepareAction(SET_FREQUENCY_BUILD_SELECTED_SEGMENT_ID, { selectedSegmentId });

export const setFrequencyBuildSelectedPersona = (selectedPersona: string): Action =>
  prepareAction(SET_FREQUENCY_BUILD_SELECTED_PERSONA, { selectedPersona });

export const setFrequencyBuildResonanceScore = (resonanceScore: string): Action =>
  prepareAction(SET_FREQUENCY_BUILD_RESONANCE_SCORE, { resonanceScore });

export const setFrequencyIsBuilderPageVisited = (isBuilderPageVisited: boolean): Action =>
  prepareAction(SET_FREQUENCY_IS_BUILDER_PAGE_VISITED, { isBuilderPageVisited });

export const setFrequencyNeedFetchBuildData = (needFetchBuildData: boolean): Action =>
  prepareAction(SET_FREQUENCY_NEED_FETCH_BUILD_DATA, { needFetchBuildData });
