import { Action } from '@/redux/types';

import { ViewEnum } from '@/components/Navigator/types';

import {
  LOAD_GAMES_SUCCESS,
  LOAD_GAMES_REQUEST,
  LOAD_GAMES_ERROR,
  GamesState,
  SET_CURRENT_CLUSTER_NAME,
  RESET_GAMES_STATE
} from './types';

const initialState: GamesState = {
  list: [],
  loading: false,
  error: false,
  errorMsg: '',
  companyName: '',
  isNonGamingCompany: null,
  viewType: ViewEnum.Carousel
};

export default (state = initialState, action: Action): GamesState => {
  switch (action.type) {
    case LOAD_GAMES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_GAMES_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        loading: false,
        companyName: action.payload.companyName,
        isNonGamingCompany: action.payload.isNonGamingCompany
      };
    case LOAD_GAMES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload.errorMsg
      };
    case SET_CURRENT_CLUSTER_NAME: {
      const { gameSlug, segmentId, clusterCode, clusterName } = action.payload;
      return {
        ...state,
        list: [
          ...state.list.map(game =>
            game.slug === gameSlug
              ? {
                  ...game,
                  segments: game.segments.map(segment =>
                    segment.id === segmentId
                      ? {
                          ...segment,
                          clusters: segment.clusters.map(cluster =>
                            cluster.value === clusterCode
                              ? { ...cluster, name: clusterName }
                              : cluster
                          )
                        }
                      : segment
                  )
                }
              : game
          )
        ]
      };
    }
    case RESET_GAMES_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};
