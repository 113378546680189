import { ContentTypeEnum } from '@/redux/game/types';

export default () => ({
  title: 'How will you deliver the reward?',
  options: (key: string, preffered: boolean) =>
    `${
      {
        deeplink: 'Deep link',
        api: 'API',
        manual: 'Manual'
      }[key]
    } ${preffered ? '(preferred)' : ''}`,

  deeplink: {
    description: ({ contentType }) => {
      const isGaming = contentType === ContentTypeEnum.Gaming;
      const subject = isGaming ? 'game' : 'product';
      const { [contentType as ContentTypeEnum]: audience } = {
        [ContentTypeEnum.Gaming]: 'players',
        [ContentTypeEnum.Medical]: 'patients',
        [ContentTypeEnum.Product]: 'users'
      };

      return `A deep link allows ${audience} to receive their reward and jump directly back into your ${subject} after completing the assessment.`;
    },
    placeholder: 'https://domainname.com/reward?token={TOKEN}',
    reminder: 'Ensure link matches supported formats',
    disclaimer: {
      title: 'Supported link formats:',
      formats: [
        { label: 'Web', pattern: 'https://domainname.com/reward?token={TOKEN}' },
        { label: 'Mobile', pattern: 'domainname://redeem?token={TOKEN}' }
      ],
      observation:
        '{TOKEN} is optional. It enables unique one-time-use links for participants to collect rewards.'
    }
  },
  api: {
    disclaimer: {
      title: `Utilize the Solsten API to send rewards to any UserID that has completed the assessment.`,
      items: {
        first: {
          fragments: {
            first: 'API documentation can be found ',
            second: 'here.'
          }
        },
        second: 'Your API key is available after your assessment request is complete.'
      },
      observation: 'This information will also be sent in an email.'
    }
  },
  manual: {
    disclaimer: {
      title: ({ contentType }) => {
        const { [contentType as ContentTypeEnum]: audience } = {
          [ContentTypeEnum.Gaming]: 'players',
          [ContentTypeEnum.Medical]: 'patients',
          [ContentTypeEnum.Product]: 'users'
        };

        return `By selecting manual delivery, you acknowledge that your team will use an alternative method to reward ${audience}.`;
      },
      items: {
        first: `To do this, provide UserIDs during assessment setup.`,
        second: 'The list of those who completed the assessment will be in your audience dashboard.'
      },
      itemsPreferences: {
        first: `To do this, provide PlayerIDs during assessment setup.`,
        second: 'The list of those who completed the assessment will be in your assessment manager.'

      },
      observation: 'This information will also be sent in an email.'
    }
  }
});
