import { Action } from '@/redux/types';

import {
  SAVE_JWT,
  ERASE_AUTH,
  CLEAR_ERRORS,
  SAVE_USER_EMAIL,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  SET_IS_LOG_OUT,
  AuthState
} from './types';

export const initialState: AuthState = {
  jwt: '',
  exp: NaN,
  isAuthenticated: false,
  email: '',
  loading: false,
  error: false,
  errorMsg: '',
  firstLogin: false,
  isLogOut: false
};

const reducer = (state = initialState, { type, payload }: Action): AuthState => {
  switch (type) {
    case SAVE_JWT:
      return {
        ...state,
        jwt: payload.jwt,
        exp: payload.exp,
        isAuthenticated: true
      };
    case SAVE_USER_EMAIL:
      return {
        ...state,
        email: payload.email
      };
    case ERASE_AUTH:
      return { ...initialState, isLogOut: state.isLogOut };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: ''
      };
    case AUTH_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: ''
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        firstLogin: payload.firstLogin,
        loading: false,
        error: false,
        errorMsg: ''
      };
    case AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: payload.errorMsg
      };
    case SET_IS_LOG_OUT:
      return {
        ...state,
        isLogOut: payload.isLogOut
      };
    default:
      return state;
  }
};

export default reducer;
