import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { selectAppJwt } from '@/redux/auth/selectors';
import { selectGameJwt } from '@/redux/game/selectors';
import { RootState } from '@/redux/rootStore';

import { Action } from './types';

export const prepareAction = (type: string, payload: {} = {}): Action => ({ type, payload });

export const prepareBaseQuery = ({
  version = 'v1',
  endpoint,
  useGameJwt = true,
  headersEnhance
}: {
  version?: 'v1' | 'v2';
  endpoint: string;
  useGameJwt?: boolean;
  headersEnhance?: FetchBaseQueryArgs['prepareHeaders'];
}): Parameters<typeof fetchBaseQuery>[0] => ({
  baseUrl: `${process.env.BASE_URL}/${endpoint}/${version}`,
  prepareHeaders: (headers, api) => {
    const { getState } = api;
    const token = useGameJwt
      ? selectGameJwt(getState() as RootState)
      : selectAppJwt(getState() as RootState);

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    if (headersEnhance) {
      return headersEnhance(headers, api);
    }

    return headers;
  }
});
