import { call, put, delay, select, takeEvery } from 'redux-saga/effects';

import { type Timeline } from 'ui-lib-12traits/src/index';

import { Statuses } from '@/services/Api/GameService/types';
import {
  fetchEngagementAvailableTimelines,
  fetchKpi,
  fetchKpiStatus
} from '@/services/Api/KpisService';
import { parseClusterInUrl } from '@/services/ClustersService';
import { parseSegmentInUrl } from '@/services/SegmentsService';

import {
  setPulseActiveTimeline,
  setPulseAvailableTimelines,
  loadEngagementRate,
  loadEngagementRateRequest,
  loadEngagementRateSuccess
} from '@/redux/pages/overviewPage/actions';
import { Action } from '@/redux/types';


import { selectGameId } from '../game/selectors';
import { selectGames } from '../games/selectors';

import {
  loadKpis,
  loadKpisStatus,
  loadKpisSuccess,
  loadKpisError,
  loadKpisStatusSuccess,
  loadKpisStatusError,
  setKpisIntegrationPercentage,
  setKpisIntegrationStatus,
  calculateKpisIntegration,
  setKpisUploadError,
  setKpisIsUploading
} from './actions';
import { errorDataMapper } from './mappers';
import {
  LOAD_KPIS,
  LOAD_KPIS_STATUS,
  CALCULATE_KPIS_INTEGRATION,
  Statuses as kpisIntegrationStatuses,
  LOAD_KPIS_SUCCESS,
  LOAD_TIMELINES
} from './types';


function* getKpi(action: Action) {
  const { dashboardId } = action.payload || {};
  if (dashboardId) {
    const dashboards = yield select(selectGames);
    const currentDashboard =
      dashboards.find(({ id }: { id: string }) => id === dashboardId) || null;
    try {
      const result = yield call(
        fetchKpi,
        currentDashboard ? currentDashboard.jwt : undefined,
        action.payload.segment,
        action.payload.cluster
      );

      yield put(loadKpisSuccess(result.data.kpis, dashboardId));
      yield put(setKpisIntegrationPercentage(100, dashboardId));
      yield put(setKpisIntegrationStatus(kpisIntegrationStatuses.INTEGRATED, dashboardId));
    } catch (e) {
      yield put(loadKpisError(e.message, dashboardId));
    }
  }
}

function* loadTimelines() {
  yield put(loadEngagementRateRequest());
  const dashboardId = yield select(selectGameId);
  if (dashboardId) {
    const { data: timelines }: { data: { [key: string]: boolean } } = yield call(
      fetchEngagementAvailableTimelines,
      dashboardId
    );
    const availableTimelines = Object.keys(timelines).filter(
      (timeline: string) => !!timelines[timeline]
    );
    const activeTimeline = yield select(state => state.overviewPage.pulse.activeTimeline);
    const newActiveTimeline = availableTimelines.includes(activeTimeline)
      ? activeTimeline
      : availableTimelines[0];

    yield put(setPulseAvailableTimelines(availableTimelines as Timeline[]));
    yield put(setPulseActiveTimeline(newActiveTimeline));
    if (newActiveTimeline) {
      yield put(loadEngagementRate(newActiveTimeline));
    } else {
      yield put(loadEngagementRateSuccess(null));
    }
  }
}

function* getStatus(action: Action) {
  const { dashboardId } = action.payload || {};
  if (dashboardId) {
    const dashboards = yield select(selectGames);
    const currentDashboard =
      dashboards.find(({ id }: { id: string }) => id === dashboardId) || null;
    try {
      const result = yield call(
        fetchKpiStatus,
        currentDashboard ? currentDashboard.jwt : undefined
      );
      const {
        data: { status, error_details: errorDetails }
      } = result;
      const kpisIntegrationPercentage = yield select(
        state => state.kpis[dashboardId].kpisIntegrationPercentage
      );
      const isKpisUploading = yield select(state => state.kpis[dashboardId].kpisIsUploading);

      const cluster = parseClusterInUrl() || undefined;
      const segment = parseSegmentInUrl() || undefined;

      if (isKpisUploading && errorDetails) {
        const mappedErrorDetails = errorDataMapper(errorDetails);
        yield put(setKpisUploadError(mappedErrorDetails, dashboardId));
        yield put(setKpisIsUploading(false, dashboardId));
      }

      yield put(loadKpisStatusSuccess(status, dashboardId));

      switch (status) {
        case Statuses.NEVER_UPLOADED:
          yield put(setKpisIntegrationStatus(kpisIntegrationStatuses.NOT_UPLOADED, dashboardId));
          break;
        case Statuses.PROCESSING:
          yield put(setKpisIntegrationStatus(kpisIntegrationStatuses.INTEGRATING, dashboardId));
          if (kpisIntegrationPercentage === 0) {
            yield put(setKpisIntegrationPercentage(99, dashboardId));
          }
          yield delay(5000);
          yield put(loadKpisStatus(dashboardId));
          break;
        case Statuses.UPDATING:
          yield put(setKpisIntegrationStatus(kpisIntegrationStatuses.INTEGRATING, dashboardId));
          if (kpisIntegrationPercentage === 0) {
            yield put(setKpisIntegrationPercentage(99, dashboardId));
          }
          yield delay(5000);
          yield put(loadKpisStatus(dashboardId));
          break;
        case Statuses.AVAILABLE:
          yield put(loadKpis(dashboardId, segment, cluster));

          break;
        default:
          yield delay(5000);
          yield put(loadKpisStatus(dashboardId));
          break;
      }
    } catch (e) {
      yield put(setKpisIntegrationStatus(kpisIntegrationStatuses.ERROR, dashboardId));
      yield put(loadKpisStatusError(e.message, dashboardId));
    }
  }
}

function* integrateKpis(action: Action) {
  const { dashboardId } = action.payload || {};
  if (dashboardId) {
    yield put(setKpisUploadError(null, dashboardId));
    const initialIntegrationStop = 99;
    const approximateKpisIntegrationTime = yield select(
      state => state.kpis[dashboardId].approximateKpisIntegrationTime
    );

    const kpisIntegrationPercentage = yield select(
      state => state.kpis[dashboardId].kpisIntegrationPercentage
    );
    const quarter = (approximateKpisIntegrationTime / initialIntegrationStop) * 25;
    if (kpisIntegrationPercentage >= initialIntegrationStop) {
      yield put(setKpisIntegrationPercentage(initialIntegrationStop, dashboardId));
      yield put(loadKpisStatus(dashboardId));
    } else {
      yield delay(quarter * 1000);
      yield put(setKpisIntegrationPercentage(kpisIntegrationPercentage + 25, dashboardId));
      yield put(calculateKpisIntegration(dashboardId));
    }
  }
}

function* Saga() {
  yield takeEvery(LOAD_KPIS, getKpi);
  yield takeEvery(LOAD_KPIS_STATUS, getStatus);
  yield takeEvery(CALCULATE_KPIS_INTEGRATION, integrateKpis);
  yield takeEvery(LOAD_KPIS_SUCCESS, loadTimelines);
  yield takeEvery(LOAD_TIMELINES, loadTimelines);
}

export default Saga;
