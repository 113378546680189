import update from 'immutability-helper';

import type { State } from './Feedback.types';

export const getInitialState = () => ({ attachments: [] });

export const drop = ({ files, limit }: { files: File[]; limit: number }) => {
  const concat = (attachments: File[]) => attachments.concat(files).slice(0, limit);

  return (state: State) => update(state, { attachments: { $apply: concat } });
};

export const remove = (index: number) => (state: State) =>
  update(state, { attachments: { $splice: [[index, 1]] } });
