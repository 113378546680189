import { prepareAction } from '@/redux/helpers';
import { Filter } from '@/redux/pages/segmentsPage/types';
import { Action } from '@/redux/types';

import {
  SET_SEGMENT_CODE,
  UPDATE_SEGMENT,
  UPDATE_SEGMENT_REQUEST,
  UPDATE_SEGMENT_ERROR,
  DELETE_SEGMENT,
  DELETE_SEGMENT_REQUEST,
  DELETE_SEGMENT_SUCCESS,
  DELETE_SEGMENT_ERROR
} from './types';

export const setSegmentCode = (segmentCode: string): Action =>
  prepareAction(SET_SEGMENT_CODE, segmentCode);

export const updateSegment = (
  title: string,
  description: string,
  segmentId: string,
  filters: Filter[]
): Action => prepareAction(UPDATE_SEGMENT, { title, description, segmentId, filters });

export const updateSegmentRequest = (): Action => prepareAction(UPDATE_SEGMENT_REQUEST);
export const updateSegmentError = (): Action => prepareAction(UPDATE_SEGMENT_ERROR);

export const deleteSegment = (segmentId: string): Action =>
  prepareAction(DELETE_SEGMENT, { segmentId });
export const deleteSegmentRequest = (): Action => prepareAction(DELETE_SEGMENT_REQUEST);
export const deleteSegmentSuccess = (): Action => prepareAction(DELETE_SEGMENT_SUCCESS);
export const deleteSegmentError = (): Action => prepareAction(DELETE_SEGMENT_ERROR);
