export enum ErrorEnum {
  adNetworkConnectionError,
  uploadCreativeError,
  generalError,
  connectionError,
  viewedCreativeNoLongerExistsOnUpdating,
  viewedCreativeNoLongerExistsOnOpen,
  viewedAssessmentNoLongerExistsOnOpen,
  reactError
}
