import CoreApiService from '@/services/Api/CoreApiService';
import { storeRegistry } from '@/services/StoreRegistryService';

import { selectCurrentSegment } from '@/redux/segment/selectors';

export default class SurveyApiService extends CoreApiService {
  constructor(
    config: {
      isCamelCaseResponse?: boolean;
      shouldSendSnakeCase?: boolean;
      token?: string;
    } = {}
  ) {
    const { isCamelCaseResponse = false, shouldSendSnakeCase, token } = config;

    super({ version: '', useGameJwt: true, isCamelCaseResponse, shouldSendSnakeCase, token });
    this.api = `${process.env.BASE_URL}/sba`;

    const segment = selectCurrentSegment(storeRegistry.getState());
    if (segment) {
      this.params = {
        segment: segment && segment.id
      };
    }
  }

  protected params: { segment?: string }; // eslint-disable-line camelcase
}
