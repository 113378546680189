import { ReactElement } from 'react';

import { PrivateRoute } from '@/route';

interface Props {
  path: string | string[];
  page: React.ComponentType<any>;
  layout: React.ComponentType<any>;
}

function ChartsRoute(props: Props): ReactElement {
  return <PrivateRoute {...props} />
}

export default ChartsRoute;
