import AdsApiService from '@/services/Api/AdsApiService';
import { ApiResponse } from '@/services/Api/types';
import { storeRegistry } from '@/services/StoreRegistryService';

import { selectAppJwt } from '@/redux/auth/selectors';
import { setError } from '@/redux/errors/actions';
import { selectGameJwt } from '@/redux/game/selectors';
import store, { RootState } from '@/redux/rootStore';

import { ErrorEnum } from '@/components/Error/types';
import { AdAccount, NetworkStatusEnum } from '@/components/Frequency/FrequencyAdNetworksPage/types';
import { Campaign, CreativeType } from '@/components/Frequency/types';

import {
  CreativeAudiences,
  SyncAllAdNetworks,
  StackData,
  CreativesData,
  Creative,
  Collection,
  Collections,
  ReviewBody,
  Review,
  NetworkType,
  Builds,
  Build,
  KPI,
  AdNetwork,
  StacksData,
  CreativeAssetsInfo,
  CreativeDetails,
  UploadedFileInfo,
  CreativeDetailsFilters,
  Tag,
  ResonatingTag,
  TagsDataResponse,
  FacebookAccount,
  FacebookTargetingCategories
} from './types';

export const fetchFbAuth = async (
  useGameJwt?: boolean,
  withoutLoginRedirect?: boolean
): Promise<ApiResponse<FacebookAccount[]>> =>
  new AdsApiService({ useGameJwt, withoutLoginRedirect, isCamelCaseResponse: false })
    .disableLoginRedirect()
    .setEndpoint('/facebook/adaccounts')
    .get();

export const fetchTargetingCategories = async (): Promise<FacebookTargetingCategories> =>
  new AdsApiService({ isCamelCaseResponse: false })
    .setEndpoint('/facebook/targeting-options/categories')
    .get()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const postFbCustomAudiencesData = async (
  formData: FormData
): Promise<
  ApiResponse<{
    upload_id: string;
  }>
> =>
  new AdsApiService({ isCamelCaseResponse: false })
    .setEndpoint('/facebook/customaudiences/data')
    .setBody(formData, true)
    .post()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const postFbAudiences = async (
  endpoint: string,
  data: {
    name: string;
    adaccount: string | null;
    segment: string;
    cluster: string;
    fbTargeting: { [name: string]: any };
    upload_id: string | null;
    lookalike: { countries: string[]; ratio: number };
  }
): Promise<
  ApiResponse<{
    payload: {
      type: number;
    };
    type: string;
  }>
> =>
  new AdsApiService({ isCamelCaseResponse: false })
    .setEndpoint(`/facebook/${endpoint}`)
    .setBody(data)
    .post()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const fetchAdNetworkLogout = async (
  network: NetworkType,
  useGameJwt?: boolean
): Promise<void> =>
  new AdsApiService({ useGameJwt, isCamelCaseResponse: false })
    .setEndpoint(`/${network}/logout`)
    .post()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const fetchAdNetworkLogin = async (
  network: NetworkType,
  success: string,
  error: string,
  useGameJwt: boolean = true
): Promise<string> => {
  const state: RootState = storeRegistry.getState() as RootState;
  const service = new AdsApiService({ isCamelCaseResponse: false })
    .setEndpoint(`/${network}/auth`)
    .setParams({
      jwt: useGameJwt ? selectGameJwt(state) : selectAppJwt(state),
      success_redirect_to: success,
      error_redirect_to: error
    });
  return `${service.getUri()}?${service.getQuery()}`;
};

export const fetchAdAccounts = async (network: NetworkType): Promise<ApiResponse<AdAccount[]>> =>
  new AdsApiService({ useGameJwt: false, withoutLoginRedirect: true })
    .setEndpoint(`/${network}/adaccounts`)
    .get();

export const changeAdAccount = async (
  network: NetworkType,
  adAccountId: string,
  adAccountName: string,
  enabled: boolean,
  dashboardId?: string
): Promise<void> =>
  new AdsApiService({ useGameJwt: false, isCamelCaseResponse: false, shouldSendSnakeCase: true })
    .setEndpoint(`/${network}/adaccounts/settings`)
    .setBody({ adAccountId, adAccountName, enabled, dashboardId })
    .post()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.adNetworkConnectionError })));

export const fetchAdNetworkStatus = async (
  network: NetworkType
): Promise<
  ApiResponse<{ status: NetworkStatusEnum; email?: string | undefined; lastSyncTime: number }>
> =>
  new AdsApiService({ useGameJwt: false, isCamelCaseResponse: false })
    .setEndpoint(`/${network}/status`)
    .get()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const syncCreatives = async (network: NetworkType, id: string): Promise<void> =>
  new AdsApiService({ useGameJwt: false, isCamelCaseResponse: false })
    .setEndpoint(`/${network}/adaccounts/${id}/syncCreatives`)
    .post()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.adNetworkConnectionError })));

export const removeCreatives = async (network: NetworkType, id: string): Promise<void> =>
  new AdsApiService({ useGameJwt: false, isCamelCaseResponse: false })

    .setEndpoint(`/${network}/adaccounts/${id}/creatives`)
    .delete()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.adNetworkConnectionError })));

export const fetchStacks = async (params: {
  limit: number;
  offset?: number;
  sortDashboardId?: string;
  sortField?: string;
  sortKpiId?: string;
  sortDirection?: string;
  query?: string;
  tags?: Array<string>;
  colors?: Array<string>;
  creativeTypes?: CreativeType[];
  adNetworks?: string[];
  sortSegmentId?: string;
  sortCluster?: string;
  dashboardId?: string;
  collectionId?: string;
}): Promise<ApiResponse<StacksData>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/stacks`)
    .setParams(params)
    .get()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const fetchTags = async (): Promise<ApiResponse<TagsDataResponse>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, isCamelCaseResponse: true })
    .setEndpoint(`/tags`)
    .get()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const fetchProcessingCreatives = async (): Promise<ApiResponse<{ ids: string[] }>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, isCamelCaseResponse: false })
    .setEndpoint(`/creatives/processing`)
    .get();

export const updateCreative = async (
  id: string,
  data: { nickname?: string; dashboardId?: string; segmentId?: string; orientation?: string }
): Promise<ApiResponse<Creative>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setBody(data)
    .setEndpoint(`/creatives/${id}`)
    .put()
    .catch(() =>
      store.dispatch(setError({ type: ErrorEnum.viewedCreativeNoLongerExistsOnUpdating }))
    );

export const addCreativeTags = async (
  id: string,
  data: { tags: { name: string; type: string; visionId: string; slug: string }[] }
): Promise<void> =>
  new AdsApiService({
    version: 'v2',
    useGameJwt: false,
    isCamelCaseResponse: false,
    shouldSendSnakeCase: true
  })
    .setBody(data)
    .setEndpoint(`/creatives/${id}/tags`)
    .post();

export const removeCreativeTags = async (
  id: string,
  data: { tags: { name: string; type: string; visionId: string; slug: string }[] }
): Promise<void> =>
  new AdsApiService({
    version: 'v2',
    useGameJwt: false,
    shouldSendSnakeCase: true
  })
    .setBody(data)
    .setEndpoint(`/creatives/${id}/tags`)
    .delete()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const fetchCreativeDetails = async (
  id: string,
  filters: CreativeDetailsFilters
): Promise<ApiResponse<CreativeDetails>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setParams(filters)
    .setEndpoint(`/creatives/${id}`)
    .get()
    .catch(() =>
      store.dispatch(setError({ type: ErrorEnum.viewedCreativeNoLongerExistsOnUpdating }))
    );

export const removeCreative = async (id: string): Promise<void> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, isCamelCaseResponse: false })
    .setEndpoint(`/creatives/${id}`)
    .delete()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const uploadFile = async (data: { name: string }): Promise<ApiResponse<UploadedFileInfo>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, isCamelCaseResponse: true })
    .setEndpoint('/uploads')
    .setBody(data)
    .post()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.uploadCreativeError })));

export const uploadCreative = (uploadUrl: string, file: File): Promise<Response> =>
  fetch(uploadUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': ''
    }
  });

export const addCreatives = async (data: CreativeAssetsInfo[]): Promise<ApiResponse<Creative[]>> =>
  new AdsApiService({
    version: 'v2',
    useGameJwt: false,
    shouldSendSnakeCase: true
  })
    .setEndpoint('/creatives')
    .setBody(data, true)
    .post()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.uploadCreativeError })));

export const getFileProviderAccessToken = async (
  provider: 'google' | 'dropbox'
): Promise<ApiResponse<{ token: string }>> =>
  new AdsApiService({
    version: 'v2',
    useGameJwt: false,
    withoutLoginRedirect: true
  })
    .setEndpoint(`/files/${provider}/token`)
    .get();

export const addCreativesByFileProvider = async (
  provider: 'google' | 'dropbox',
  data: { url: string; type: 'folder' | 'file' }[]
): Promise<ApiResponse<{ name: string; id: string; source: string }[]>> =>
  new AdsApiService({
    version: 'v2',
    useGameJwt: false,
    shouldSendSnakeCase: true
  })
    .setEndpoint(`/files/${provider}/upload`)
    .setBody(data, true)
    .post()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.uploadCreativeError })));

export const getConnectedAdNetworks = (): Promise<ApiResponse<AdNetwork[]>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false })
    .setEndpoint('/connectedAdNetworks')
    .get()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const getCreativesKPIs = (): Promise<ApiResponse<{ kpis: KPI[] }>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false })
    .setEndpoint('/creatives/config/kpis')
    .get()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const getAdNetworksStatus = async (): Promise<ApiResponse<SyncAllAdNetworks>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false })
    .setEndpoint(`/adNetworksSyncStatus`)
    .get()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const removeCreativeStack = async (id: string): Promise<void> =>
  new AdsApiService({ version: 'v2', useGameJwt: false })
    .setEndpoint(`/stacks/${id}`)
    .delete()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const addCreativesToStack = async (
  stackID: string,
  creativeIds: string[]
): Promise<ApiResponse<StackData>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/stacks/${stackID}/creatives`)
    .setBody({ creativeIds })
    .post()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const removeCreativesFromStack = async (
  stackID: string,
  creativeIds: string[]
): Promise<ApiResponse<StackData>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/stacks/${stackID}/creatives`)
    .setBody({ creativeIds })
    .delete()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const fetchCreatives = async (params: {
  limit: number;
  offset?: number;
  sortDashboardId?: string;
  sortField?: string;
  sortKpiId?: string;
  sortDirection?: string;
  query?: string;
  tags?: Array<string>;
  colors?: Array<string>;
  creativeTypes?: CreativeType[];
  adNetworks?: string[];
  sortSegmentId?: string;
  sortCluster?: string;
  stack?: boolean;
  dashboardId?: string;
  collectionId?: string;
}): Promise<ApiResponse<CreativesData>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/creatives`)
    .setParams(params)
    .get()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.connectionError })));

export const fetchCampaigns = (params: {
  sortKpiId?: number;
  sortDirection?: string;
  adNetworks?: string[];
  dashboardId?: string;
}): Promise<ApiResponse<Campaign[]>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setParams(params)
    .setEndpoint('/campaigns')
    .get()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const fetchCreativeAudiences = (
  creativeId: string,
  params: {
    dashboardId?: string;
    segmentId?: string;
    limit: number;
    offset: number;
    sortDirection: string;
    sortField: string;
  }
): Promise<ApiResponse<CreativeAudiences>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setParams(params)
    .setEndpoint(`/creatives/${creativeId}/audiences`)
    .get()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const undoStacksMerge = (stackId: string): Promise<ApiResponse<CreativeAudiences>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/stacks/${stackId}/merge`)
    .delete()
    .catch(() => store.dispatch(setError({ type: ErrorEnum.generalError })));

export const fetchSearchHistory = (): Promise<ApiResponse<string[] | null>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false })
    .setEndpoint('/creatives/searchHistory')
    .get();

export const fetchCollections = (
  params: {
    limit?: number;
    offset?: number;
    query?: string;
    name?: string;
  } = {}
): Promise<ApiResponse<Collections>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false })
    .setParams(params)
    .setEndpoint('/collections')
    .get();

export const fetchCollectionDetails = (id: string): Promise<ApiResponse<Collection>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false }).setEndpoint(`/collections/${id}`).get();

export const createCollection = (data: {
  name: string;
  creatives: string[];
  description?: string;
  dashboardId?: string;
  segmentId?: string;
  cluster?: string;
  coverCreativeId?: string;
}): Promise<ApiResponse<Collection>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setBody(data)
    .setEndpoint(`/collections`)
    .post();

export const addCreativesToCollection = (
  collectionId: string,
  creativesIds: string[]
): Promise<void> =>
  new AdsApiService({ version: 'v2', useGameJwt: false })
    .setEndpoint(`/collections/${collectionId}/creatives`)
    .setBody({ creatives: creativesIds })
    .post();

export const updateCollection = (
  id: string,
  data: {
    name?: string;
    description?: string;
    cluster?: string;
    segmentId?: string;
    dashboardId?: string;
    coverCreativeId?: string;
  }
): Promise<ApiResponse<Collection>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/collections/${id}`)
    .setBody(data)
    .put();

export const removeCreativesFromCollection = (
  collectionId: string,
  creativesIds: string[]
): Promise<void> =>
  new AdsApiService({ version: 'v2', useGameJwt: false })
    .setEndpoint(`/collections/${collectionId}/creatives`)
    .setBody({ creatives: creativesIds })
    .delete();

export const fetchCollectionCreatives = (
  id: string,
  params?: { limit?: number; offset?: number }
): Promise<ApiResponse<CreativesData>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/collections/${id}/creatives`)
    .setParams(params || {})
    .get();

export const deleteCollection = (id: string): Promise<StackData> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/collections/${id}`)
    .delete();

export const undoCollectionDelete = (id: string): Promise<void> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/collections/${id}`)
    .post();

export const addReview = async (id: string, data: ReviewBody): Promise<void> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint('/reviews')
    .setBody(data)
    .setParams(id ? { id } : {})
    .post();

export const cancelReview = async (id: string): Promise<void> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/reviews/${id}/cancel`)
    .post();

export const getReviewsDistinctCustomObjectives = async (): Promise<
  ApiResponse<{
    distinctCustomObjectives: string[];
  }>
> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint('/reviews/distinctCustomObjectives')
    .get();

export const getReviewsDistinctCustomCreativeStatuses = async (): Promise<
  ApiResponse<{
    distinctCustomCreativeStatuses: string[];
  }>
> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint('/reviews/distinctCustomCreativeStatuses')
    .get();

export const requestMoreReviews = async (): Promise<void> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint('/reviews/requestMoreReviews')
    .get();

export const getAvailableReviewRequests = async (): Promise<
  ApiResponse<{ availableRequests: number; maxAllowedReviews: number }>
> =>
  new AdsApiService({ version: 'v2', useGameJwt: false })
    .setEndpoint('/reviews/availableRequests')
    .get();

export const giveHighFive = async (id: string, count: number): Promise<void> =>
  new AdsApiService({ version: 'v2', useGameJwt: false })
    .setEndpoint(`/reviews/${id}/highFives`)
    .setParams({ count })
    .put();

export const fetchReviews = async (creativeId?: string): Promise<ApiResponse<Review[]>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setParams(creativeId ? { creativeId } : {})
    .setEndpoint('/reviews')
    .get();

export const fetchResonatingTags = async (data: {
  limit?: number;
  offset?: number;
  useNewCollection?: boolean;
  personas: {
    dashboardId?: string;
    segmentId?: string;
    cluster?: number;
  }[];
}): Promise<ApiResponse<ResonatingTag[]>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint('/tags/resonating')
    .setBody(data)
    .post();

export const fetchCalculateResonance = async (
  dashboardId: string,
  cluster: number,
  tags: string[]
): Promise<ApiResponse<{ resonanceScore: number }>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint('/tags/resonance')
    .setBody({
      persona: { dashboardId, cluster },
      tags
    })
    .post();

export const fetchSimilarCreatives = async (data: {
  tags: string[];
  dashboardId?: string;
  segmentId?: string;
  cluster?: number;
  limit?: number;
  match_all?: boolean;
}): Promise<ApiResponse<Creative[]>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setParams(data)
    .setEndpoint('/tags/creatives')
    .get();

export const fetchBuilds = async (params: {
  limit: number;
  offset: number;
  sortField: string;
  sortDirection: 'asc' | 'desc' | string;
  query?: string;
}): Promise<ApiResponse<Builds>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint('/tagclouds')
    .setParams(params)
    .get();

export const fetchBuild = async (id: string): Promise<ApiResponse<Build>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/tagclouds/${id}`)
    .get();

export const addBuild = async (data: {
  name: string;
  tags: Omit<Tag, 'children'>[];
  pinnedCreativeIds: string[];
  uploadedCreativeIds: string[];
  coverCreativeId: string | null;
  persona: {
    dashboardId?: string;
    segmentId?: string;
    cluster?: number;
  };
}): Promise<ApiResponse<Build>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint('/tagclouds')
    .setBody(data)
    .post();

export const editBuild = async (
  id: string,
  data: {
    name: string;
    tags: Omit<Tag, 'children'>[];
    pinnedCreativeIds: string[];
    uploadedCreativeIds: string[];
    coverCreativeId: string | null;
    persona: {
      dashboardId?: string;
      segmentId?: string;
      cluster?: number;
    };
  }
): Promise<ApiResponse<Build>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/tagclouds/${id}`)
    .setBody(data)
    .put();

export const removeBuild = async (id: string): Promise<ApiResponse<Build>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint(`/tagclouds/${id}`)
    .delete();

export const checkBuildName = async (name: string): Promise<ApiResponse<{ name: string }>> =>
  new AdsApiService({ version: 'v2', useGameJwt: false, shouldSendSnakeCase: true })
    .setEndpoint('/tagclouds/check-name')
    .setParams({ name })
    .get();
