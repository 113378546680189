import SurveyApiService from '@/services/Api/SurveyApiService';
import { ApiResponse } from '@/services/Api/types';

import { Affinity, FirstHeard, TopService } from './types';

export const fetchScores = async (
  trait: string,
  segment?: string,
  clusterCode?: string,
  token?: string
): Promise<any> => {
  const request = new SurveyApiService({ token }).setEndpoint(`/projects/scores/${trait}`);
  if (segment) {
    request.setParams({ segment });
  }
  if (clusterCode) {
    request.setParams({ cluster: clusterCode });
  }
  return request.get();
};

export const fetchClusterScores = async (
  trait: string,
  segmentCode?: string,
  clusterCode?: string,
  limit: number = 7
): Promise<any> => {
  const request = new SurveyApiService().setEndpoint(`/clusters/scores/${trait}`);
  if (segmentCode) {
    request.setParams({ ...(segmentCode === 'all' ? {} : { segment: segmentCode }), limit });
  }
  if (clusterCode) {
    request.setParams({ cluster: clusterCode, limit });
  }
  request.setParams({ limit });
  return request.get();
};

export const fetchClustersProfile = async (clusterCode: string | null): Promise<any> => {
  if (!clusterCode) {
    return new SurveyApiService().setEndpoint('/v2/audience/profile').get();
  }
  return new SurveyApiService()
    .setEndpoint('/v2/audience/profile')
    .setParams({ cluster: clusterCode })
    .get();
};

export const fetchClustersTraits = async (clusterCode: string | null): Promise<any> => {
  if (!clusterCode) {
    return new SurveyApiService().setEndpoint('/v2/audience/traits').get();
  }
  return new SurveyApiService()
    .setEndpoint('/v2/audience/traits')
    .setParams({ cluster: clusterCode })
    .get();
};

export const fetchClustersExperience = async (clusterCode: string | null): Promise<any> => {
  const request = new SurveyApiService().setEndpoint('/v2/audience/experience');
  if (clusterCode) {
    request.setParams({ cluster: clusterCode });
  }
  return request.get();
};

export const getPlayerIdsUrl = (segment?: string, clusterCode?: string): object => {
  const api = new SurveyApiService().setEndpoint('/v2/downloads/player-ids');
  api.setHeaders({ Accept: 'text/csv' });
  if (segment) {
    api.setParams({ segment });
  }
  if (clusterCode) {
    api.setParams({ cluster: clusterCode });
  }
  return api;
};

export const getPredictedPlayerIdsUrl = (): string => {
  const api = new SurveyApiService().setEndpoint('/v2/downloads/player-ids-predicted');
  api.setHeaders({ Accept: 'text/csv' });

  return `${api.getUri()}?${api.getQuery()}`;
};

export const fetchUniqueAndSimilar = async (clusterCode?: string): Promise<any> => {
  const request = new SurveyApiService().setEndpoint('/clusters/unique-and-similar');

  if (clusterCode) {
    request.setParams({ cluster: clusterCode });
  }

  return request.get();
};

export const audienceBoost = (): any => {
  const request = new SurveyApiService().setEndpoint('/v2/audience-boost');
  return request;
};

export const fetchAudienceBoost = async (): Promise<any> => {
  const request = audienceBoost();
  return request.get();
};

export const fetchClusterFavorites = async (
  affinity: 'app' | 'movie' | 'brand',
  segment: string | undefined,
  clusterCode: string
): Promise<ApiResponse<Affinity[]>> => {
  const request = new SurveyApiService().setEndpoint(`/clusters/${affinity}/favorites`);

  if (segment) {
    request.setParams({ segment });
  }
  if (clusterCode) {
    request.setParams({ cluster: clusterCode === 'all' ? 0 : clusterCode });
  }

  return request.get();
};

export const fetchClusterTopServices = async (
  clusterCode: string
): Promise<{ services: TopService[] }> =>
  new SurveyApiService()
    .setEndpoint(`/clusters/top-services`)
    .setParams({
      ...(clusterCode !== 'all' && { cluster: clusterCode })
    })
    .get();

export const fetchTopFirstHeard = async (
  clusterCode: string
): Promise<{ firstHeard: FirstHeard[] }> =>
  new SurveyApiService({ isCamelCaseResponse: true })
    .setEndpoint(`/clusters/top-first-heard`)
    .setParams({
      cluster: clusterCode === 'all' ? 0 : clusterCode
    })
    .get();

export const fetchImpactIndicatorOptions = async (
  params: { cluster?: number | undefined; segment?: string | undefined } = {}
): Promise<any> => {
  const apiService = new SurveyApiService().setEndpoint('/v2/traits/impacted-by/targets');

  return apiService.setParams(params).get();
};

export const fetchImpactIndicator = async (params = {}): Promise<any> => {
  const apiService = new SurveyApiService().setEndpoint('/v2/traits/impacted-by');
  return apiService.setParams(params).get();
};

export const fetchReverseIndicator = async (category: string, persona?: number): Promise<any> => {
  const apiService = new SurveyApiService().setEndpoint('/v2/traits/impact-on');

  const params: { category: string; cluster?: number } = { category };
  if (persona !== undefined) {
    params.cluster = persona;
  }

  return apiService.setParams(params).get();
};

export const fetchClusterGames = async (
  clusterCode: string,
  fbTargeting?: boolean
): Promise<{ games: any }> => {
  const response = new SurveyApiService().setEndpoint('/clusters/games');
  if (clusterCode !== 'all') {
    response.setParams({ cluster: clusterCode });
  }

  if (typeof fbTargeting !== 'undefined') {
    response.setParams({ with_facebook_targeting: fbTargeting });
  }

  return response.get();
};
