import AssessmentSurveyApiService from '../AssessmentSurveyApiService';
import { ApiResponse } from '../types';

import { SurveyBody, Assessment } from './types';

import type {
  ActiveAssessmentResponse,
  AssessmentID,
  UpdatedAssessmentResponse,
  Step,
  StaticValues
} from '@/pages/Navigator/AssessmentAutomationV2/types';

export const createAssessmentSurvey = (data: SurveyBody): Promise<ApiResponse<{ id: string }>> =>
  new AssessmentSurveyApiService({ version: 'v2', shouldSendSnakeCase: true })
    .setEndpoint('/surveys')
    .setBody(data)
    .post();

export const updateAssessmentSurvey = (id: string, data: SurveyBody): Promise<ApiResponse<any>> =>
  new AssessmentSurveyApiService({ version: 'v2', shouldSendSnakeCase: true })
    .setEndpoint(`/surveys/${id}`)
    .setBody(data)
    .put();

export const cancelAssessment = async (id: string): Promise<void> =>
  new AssessmentSurveyApiService({ version: 'v2' }).setEndpoint(`/surveys/${id}`).delete();

export const fetchAssessments = async (params: {
  limit?: number;
  offset?: number;
  sortField?: string;
  sortDirection?: 'asc' | 'desc';
  query?: string;
  id?: string;
}): Promise<ApiResponse<Assessment[]>> =>
  new AssessmentSurveyApiService({ version: 'v2', shouldSendSnakeCase: true })
    .setEndpoint(`/surveys`)
    .setParams(params)
    .get();

export const fetchExistingGames = (): Promise<ApiResponse<Assessment[]>> =>
  new AssessmentSurveyApiService({ version: 'v3', shouldSendSnakeCase: true })
    .setEndpoint(`/games`)
    .get();

export const getAssessmentSurvey = (id: AssessmentID): Promise<ActiveAssessmentResponse> =>
  new AssessmentSurveyApiService({ version: 'v3', shouldSendSnakeCase: true })
    .setEndpoint(`/assessments/${id}`)
    .get();

export const updateAssessment = (
  id: AssessmentID,
  data: Step
): Promise<UpdatedAssessmentResponse> =>
  new AssessmentSurveyApiService({ version: 'v3', shouldSendSnakeCase: true })
    .setEndpoint(`/assessments/${id}`)
    .setBody(data)
    .patch();

export const deleteAssessmentSurvey = (id: AssessmentID): Promise<ActiveAssessmentResponse> =>
  new AssessmentSurveyApiService({ version: 'v3', shouldSendSnakeCase: true })
    .setEndpoint(`/assessments/${id}`)
    .delete();

export const createAssessment = (data: StaticValues): Promise<UpdatedAssessmentResponse> =>
  new AssessmentSurveyApiService({ version: 'v3', shouldSendSnakeCase: true })
    .setEndpoint('/assessments')
    .setBody(data)
    .post();

export const closeAssessment = (id: AssessmentID): Promise<UpdatedAssessmentResponse> =>
  new AssessmentSurveyApiService({ version: 'v3', shouldSendSnakeCase: true })
    .setEndpoint(`/assessments/${id}/close`)
    .patch();

export const requestAssessmentOnboarding = (
  id: AssessmentID,
  scheduling: boolean = false
): Promise<UpdatedAssessmentResponse> =>
  new AssessmentSurveyApiService({ version: 'v3', shouldSendSnakeCase: true })
    .setEndpoint(`/assessments/${id}/onboarding`)
    .setBody({ scheduling })
    .patch();

export const submitAssessmentSurvey = (id: AssessmentID): Promise<any> =>
  new AssessmentSurveyApiService({ version: 'v3', shouldSendSnakeCase: true })
    .setEndpoint(`/assessments/${id}/submit`)
    .patch();

export const getAssessmentUsersId = (id: AssessmentID): Promise<ActiveAssessmentResponse> =>
  new AssessmentSurveyApiService({ version: 'v3', shouldSendSnakeCase: true })
    .setEndpoint(`/assessments/${id}/userIds`)
    .get();
