/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from 'react';

import { generatePath, useHistory } from 'react-router-dom';

import { useAppSelector } from '@/hooks/useApp';
import DocumentTitle from '@/hocs/DocumentTitle';
import { activeAssessmentAutomationPath, homePagePath } from '@/route/paths';

import { createAssessment as createAssessmentSurvey } from '@/services/Api/AssessmentSurveyService';

import { selectCompanyName } from '@/redux/user/selectors';
import AudienceStep from '@/components/AutomateAssessment/AudienceStep';

import { StaticValues } from './types';

function AssessmentAutomationPage() {
  const history = useHistory();
  const companyName = useAppSelector(selectCompanyName);
  const [formData, setFormData] = useState<StaticValues>({
    assessmentType: '360',
    contentType: 'gaming',
    isNewProject: false,
    projectName: '',
    audienceName: ''
  });

  useEffect(() => {
    if (formData.projectName.length > 0 && formData.audienceName.length > 0) {
      (async function createAssessment() {
        try {
          const { data } = await createAssessmentSurvey(formData);
          const path = generatePath(activeAssessmentAutomationPath, { id: data.assessment.id });

          history.push(path);
        } catch (error) {
          console.error('err', error);
        }
      })();
    }
  }, [formData.projectName]);

  const onCloseAssessment = () => {
    history.push(generatePath(homePagePath));
  };

  return (
    <DocumentTitle title={`Assessment request | ${companyName} | Solsten`}>
      <AudienceStep
        onClose={onCloseAssessment}
        onNext={setFormData}
        title="Request Type"
        isActive
        withNumber={false}
        updateStepValues={() => {}}
      />
    </DocumentTitle>
  );
}

export default AssessmentAutomationPage;
