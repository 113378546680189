/* eslint-disable no-param-reassign */
import { useQuery } from '@tanstack/react-query';
import { GlobalButton } from 'ui-lib-12traits/src/index';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import filter from 'ramda.filter';
import sort from 'ramda.sort';
import prop from 'ramda.prop';
import classNames from 'classnames';
import camelcaseKeys from 'camelcase-keys';
import isEqual from 'lodash.isequal';
import { useAppSelector } from '@/hooks/useApp';
import { getGameProfile } from '@/features/user-preferences/api/api';
import { StaticValues } from '@/pages/Navigator/AssessmentAutomationV2/types';
import DropdownSelect from '@/features/common/components/Select';
import useGetTaxonomy from '@/features/user-preferences/api/use-get-taxonomy';
import { navigatorGameProfile } from '@/route/paths';
import { fetchAssessments } from '@/services/Api/AssessmentSurveyService';
import { selectGameSlug } from '@/redux/game/selectors';
import StepContainer from '@/components/AutomateAssessment/StepContainer';
import { map, pipe } from '@/components/AutomateAssessment/AudienceStep/AudienceStep';
import styles from './index.module.scss';

const ExistingFeaturesStep = ({
  onNext,
  isActive,
  setActiveStep,
  values = {} as StaticValues,
  title,
  withNumber = true,
  template,
  onClose,
  status,
  updateStepValues,
  baseStepValues,
  setErrorCount,
  errorCount,
  order,
  ...step
}) => {
  const { id: assessmentID } = useParams<{ id: string }>();
  const slug = useAppSelector(selectGameSlug);
  const [features, setFeatures] = useState([]);
  const errorCountRef = useRef(errorCount);

  if (!isEqual(errorCountRef.current, errorCount)) {
    errorCount.current = errorCount;
  }

  const {
    data: featuresData,
    refetch,
    isLoading
  } = useQuery({
    queryKey: ['selectedGame'],
    queryFn: getGameProfile,
    enabled: !!slug
  });

  useEffect(() => {
    if (slug) refetch();
  }, [slug]);

  const { data: assessments, isLoading: isLoadingAssessments } = useQuery({
    queryKey: ['assessments'],
    queryFn: fetchAssessments
  });

  const { data } = useGetTaxonomy({
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const recentAssessments = (assessments?.data ?? [])
    .filter(
      item =>
        item.assessmentType === '360' &&
        item.id !== assessmentID &&
        item?.projectName === baseStepValues.projectName &&
        item?.dailyResponses?.total
    )
    .sort((a, b) => (+a.updatedAt > +b.updatedAt ? 1 : -1));

  const history = useHistory();
  const dauMax = Math.floor(
    (recentAssessments[recentAssessments.length - 1]?.dailyResponses?.total || 650) / 130
  );

  useEffect(() => {
    if (status !== 'completed') {
      setFeatures(featuresData?.data.features ?? []);
    }
  }, [featuresData?.data?.features?.length, status]);

  useEffect(() => {
    const { existingFeaturesIds } = camelcaseKeys(values);

    if (status === 'completed' && existingFeaturesIds) {
      const features = featuresData?.data.features.filter(d => existingFeaturesIds.includes(d.id));
      setFeatures(features ?? []);
    }
  }, [status, featuresData?.data.features, values?.existingFeaturesIds]);

  useEffect(() => {
    if (!isLoadingAssessments && !isLoading) {
      setErrorCount({
        ...errorCount,
        [template]: features?.length >= 5 && features?.length <= dauMax
      });
    }
  }, [
    errorCountRef.current,
    features,
    dauMax,
    values?.existingFeaturesIds,
    isLoadingAssessments,
    isLoading
  ]);

  const options = useMemo(
    () =>
      featuresData?.data?.features.map(d => ({
        ...d,
        value: d.id,
        checked: !!features.find(o => o.id === d.id),
        label: d.name
      })),
    [featuresData?.data.features, features]
  );

  return (
    <StepContainer
      title={title}
      titleExpanded={`What ${dauMax === 5 ? 5 : `5-${dauMax}`} live features should we assess?`}
      id={order}
      isExpanded={isActive}
      template={template}
      onExpand={setActiveStep}
      invalid={errorCount?.[template] === false}
      withNumber
      collapsedElement={
        <div className={styles.card__value}>
          <span>{features?.length} features identified</span>
        </div>
      }
    >
      <div className={styles.container}>
        <p className={styles.features_count}>
          <b>
            {dauMax >= featuresData?.data.features?.length
              ? featuresData?.data.features.length
              : `More than ${dauMax}`}{' '}
            features
          </b>{' '}
          found.{' '}
          {features?.length === 0 ? (
            <>Complete your game&apos;s profile to craft your unique assessment.</>
          ) : (
            <>
              Review your{' '}
              <a
                href={slug ? `${generatePath(navigatorGameProfile, { gameSlug: slug })}?tab=1` : ''}
              >
                game&apos;s feature profile
              </a>{' '}
              if you think features are missing.
            </>
          )}
        </p>
        {options?.length >= 5 && (
          <div className={styles.input_wrapper}>
            <div className={styles.input_header}>
              <p className={styles.label}>Features:</p>
              {features?.length > dauMax && (
                <p className={styles.error}>
                  Remove {features.length - dauMax} feature{features.length - dauMax > 1 ? 's' : ''}
                </p>
              )}
              {features.length < 5 && featuresData?.data.features?.length >= 5 && (
                <p className={styles.error}>{dauMax === 5 ? 'Select 5 features' : 'A minimum of 5 is required'}</p>
              )}
            </div>
            {!isLoading && <DropdownSelect
              options={options}
              as="search"
              wrapperProps={{
                style: { position: 'relative', marginBottom: '24px' }
              }}
              labelProps={{
                className: classNames(styles.head, {
                  [styles.head_error]:
                    dauMax < features?.length ||
                    (features.length < 5 && featuresData?.data.features?.length >= 5)
                })
              }}
              dynamicDropdownPosition={false}
              inputProps={{ className: styles.input }}
              multiple
              onSelect={option => {
                if (options.find(o => o.id === option.id)?.checked) {
                  setFeatures(features.filter(o => o.id !== option.id));
                  updateStepValues(
                    {
                      id: step.id,
                      existing_features_ids: (features.filter(o => o.id !== option.id) ?? []).map(
                        f => f.id
                      )
                    },
                    template
                  );
                } else {
                  setFeatures([...features, option]);
                  updateStepValues(
                    {
                      id: step.id,
                      existing_features_ids: ([...features, option] ?? []).map(f => f.id)
                    },
                    template
                  );
                }
              }}
              listContainerProps={{ className: styles.list }}
              resetCondition={!!features?.length && !isLoading}
              key={features.length + slug + options.length}
              renderOption={option => (
                <div
                  className={classNames(styles.option, {
                    [styles.option_hidden]: features.find(o => o.id === option.id)
                  })}
                >
                  <span>{option.label}</span>
                  <div className={styles.category_name}>
                    {
                      data?.data.items.filter(
                        d =>
                          d.children.find(c => c.id === option?.parent_id) ||
                          d.id === option?.parent_id
                      )[0]?.name
                    }
                  </div>
                </div>
              )}
              renderTags={({ options, removeTag }) =>
                pipe(
                  filter(prop('checked')),
                  sort((a, b) => a.label.localeCompare(b.label)),
                  map((selectedOption: any, i) => (
                    <div key={selectedOption.id} className={styles.feature_tag}>
                      <div className={styles.name}>{selectedOption.label}</div>
                      <button
                        type="button"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setFeatures(features.filter(o => o.id !== selectedOption.id));
                          updateStepValues(
                            {
                              id: step.id,
                              existing_features_ids: (
                                features.filter(o => o.id !== selectedOption.id) ?? []
                              ).map(f => f.id)
                            },
                            template
                          );
                          removeTag(selectedOption);
                        }}
                      />
                    </div>
                  ))
                )(options)
              }
            />}
          </div>
        )}
        {dauMax >= features?.length && featuresData?.data.features?.length >= 5 && (
          <GlobalButton
            onClick={() =>
              onNext({
                existing_features_ids: (features ?? []).map(f => f.id)
              })
            }
            className={styles.button}
            disabled={features.length < 5}
            title="Next"
          />
        )}
        {featuresData?.data.features?.length < 5 && (
          <GlobalButton
            onClick={() =>
              history.push(`${generatePath(navigatorGameProfile, { gameSlug: slug })}?tab=1`)
            }
            className={styles.button}
            title="Go to your game’s profile to complete"
          />
        )}
      </div>
    </StepContainer>
  );
};

export default ExistingFeaturesStep;
