export const ACTION_STATUS_NEW = 0;

export const ACTION_STATUS_IN_PROGRESS = 1;

export const ACTION_STATUS_COMPLETED = 3;

export const ACTION_STATUS_SKIPPED = 4;

const ACTION_CATEGORY_ENGAGEMENT_INDEX = 0;

const ACTION_CATEGORY_ACQUISITION_INDEX = 1;

export const ActionsStatuses = [
  {
    name: 'New actions',
    value: ACTION_STATUS_NEW.toString()
  },
  {
    name: 'In-Progress',
    value: ACTION_STATUS_IN_PROGRESS.toString()
  },
  {
    name: 'Launched',
    value: ACTION_STATUS_COMPLETED.toString()
  }
];

export const ActionsCategories = [
  {
    name: 'User Engagement',
    value: ACTION_CATEGORY_ENGAGEMENT_INDEX.toString()
  },
  {
    name: 'User Acquisition',
    value: ACTION_CATEGORY_ACQUISITION_INDEX.toString()
  }
];
