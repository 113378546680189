import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import createSagaMiddleware from 'redux-saga';

import { saveState, loadState } from '@/services/LocalStorageService';

import { benchmarkApi } from './benchmark';
import { cartApi } from './cart/api';
import { configApi } from './config/api';
import { coreApi } from './CoreServiceApi/api';
import { kpisApi } from './KpisServiceApi/api';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { traitsContentfulApi } from './traits-page/api-contentful';
import { UPLOAD_COMPANY_AVATAR } from './user/types';
import { wishlistApi } from './wishlist/api';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});
// for redux debugging https://github.com/zalmoxisus/redux-devtools-extension#usage
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
  }
}

const sagaMiddleware = createSagaMiddleware();
const savedState = loadState();

const store = configureStore({
  reducer: rootReducer,
  preloadedState: savedState,
  enhancers: [sentryReduxEnhancer],
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [UPLOAD_COMPANY_AVATAR]
      }
    }).concat(
      sagaMiddleware,
      wishlistApi.middleware,
      kpisApi.middleware,
      configApi.middleware,
      coreApi.middleware,
      cartApi.middleware,
      benchmarkApi.middleware,
      traitsContentfulApi.middleware
    )
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

store.subscribe(() => {
  saveState({
    auth: store.getState().auth
  });
});

sagaMiddleware.run(rootSaga);

export default store;
