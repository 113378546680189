import camelcaseKeys from 'camelcase-keys';
import { all, call, put, takeLatest, SagaReturnType } from 'redux-saga/effects';

import { DEFAULT_ID } from '@/constants';

import { getDashboards } from '@/services/Api/CoreService';
import { fetchTeamAssessments } from '@/services/Api/TeamAssessmentSurveyService';
import { fetchCompany, fetchUserInfo } from '@/services/Api/UserService';
import {
  getSelectedNavigatorDashboardSlug,
  getSelectedTraitsDashboardSlug,
  getSelectedSegment,
  getFrequencyDashboardId
} from '@/services/LocalStorageService';

import { loadDashboardsKpisSuccess } from '@/redux/kpis/actions';
import { Statuses as kpisIntegrationStatuses } from '@/redux/kpis/types';
import { loadTeamSurvey } from '@/redux/survey/actions';
import { Action } from '@/redux/types';

// Load Dashboard

// Load User

import {
  loadUserInfoSuccess,
  loadUserPreferences,
  setIsLoading as setUserIsLoading,
  setUserHaveGames,
  setUserCompany
} from '@/redux/user/actions';

// Load Cart
import { eraseAuth } from '../auth/actions';
import { loadFrequencyPermissions, setFrequencySelectedDashboardId } from '../frequency/actions';
import { DataTypeEnum, getNamespaceDataType } from '../game/types';
import { loadGamesSuccess, loadGamesRequest, loadGamesError } from '../games/actions';
import { NON_GAMING_COMPANY_TYPE, MEDICAL_COMPANY_TYPE } from '../games/types';
import { setNavigatorDashboardSlug } from '../navigator/actions';
import { setSegmentCode } from '../segment/actions';
import { setTraitsDashboardSlug } from '../traits/actions';

import { INITIAL_REQUEST, LOAD_COMPANY } from './types';

interface FetchedDashboardData {
  id: string;
  slug: string;
  kpisUploadStatus: {
    data?: unknown;
    status: number;
    errorDetails: string | null;
  };
}

function* getCompany({ payload: { isWithReloadKpis } }: Action) {
  let company = null;
  try {
    yield put(loadGamesRequest());

    const [
      { data },
      {
        data: { dashboards }
      }
    ]: [SagaReturnType<typeof fetchCompany>, SagaReturnType<typeof getDashboards>] = yield all([
      call(fetchCompany),
      call(getDashboards)
    ]);
    dashboards.sort((a, b) => a.name.localeCompare(b.name));
    const traits = dashboards.filter(item => {
      const dataType = getNamespaceDataType(item.dashboard_config.namespace);
      return dataType === DataTypeEnum.Trait;
    });
    const genresAndCrafted = dashboards.filter(item => {
      const dataType = getNamespaceDataType(item.dashboard_config.namespace);
      return dataType === DataTypeEnum.Genre || dataType === DataTypeEnum.Crafted;
    });

    company = data;

    const { companyType, name } = company;

    const isNonGamingCompany =
      companyType === NON_GAMING_COMPANY_TYPE || companyType === MEDICAL_COMPANY_TYPE;

    const updatedGames = dashboards.map(gameItem => ({
      ...gameItem,
      segments: camelcaseKeys(gameItem.segments)
    }));

    yield put(setUserHaveGames(!!dashboards.length));
    yield put(loadGamesSuccess(updatedGames, name, isNonGamingCompany));
    if (isWithReloadKpis) {
      const dashboardsKpisData = (
        camelcaseKeys(dashboards, {
          deep: true
        }) as FetchedDashboardData[]
      ).reduce((obj, dashboard) => {
        const {
          id,
          kpisUploadStatus: { status, errorDetails },
          slug
        } = dashboard;
        const dashboardData = {
          statusError: false,
          statusErrorMsg: '',
          kpisError: false,
          kpisErrorMsg: '',
          kpisIntegrationPercentage: 0,
          kpisIntegrationStatus: kpisIntegrationStatuses.NOT_UPLOADED,
          approximateKpisIntegrationTime: 0,
          kpisUploadError: null,
          kpisIsUploading: false,
          status,
          errorDetails,
          data: null
        };

        return { ...obj, [id]: { ...dashboardData, slug } };
      }, {});
      yield put(loadDashboardsKpisSuccess(dashboardsKpisData));
      if (traits.length) {
        const selectedTraitsDashboardSlug = getSelectedTraitsDashboardSlug();
        const isInTraitsDashboardList = traits.find(
          trait => trait.slug === selectedTraitsDashboardSlug
        );
        yield put(
          setTraitsDashboardSlug(
            isInTraitsDashboardList ? selectedTraitsDashboardSlug! : traits[0].slug
          )
        );
      }
      if (genresAndCrafted.length) {
        const selectedGenreSlug = getSelectedNavigatorDashboardSlug();
        const isInGenresAndCraftedList = traits.find(trait => trait.slug === selectedGenreSlug);
        yield put(
          setNavigatorDashboardSlug(
            isInGenresAndCraftedList ? selectedGenreSlug! : genresAndCrafted[0].slug
          )
        );
      }
      const selectedFrequencyDashboardId = getFrequencyDashboardId();
      yield put(setFrequencySelectedDashboardId(selectedFrequencyDashboardId || DEFAULT_ID));
      const selectedSegment = getSelectedSegment();
      yield put(setSegmentCode(selectedSegment || ''));
    }
  } catch (e) {
    yield all([put(loadGamesError(e.message)), put(eraseAuth())]);
  }
  return company;
}

function* initialRequest() {
  try {
    const userResponse = yield call(fetchUserInfo);
    const { featurePermissions } = userResponse.data.permissions;

    let teamAssessementsResponse = null;
    if (featurePermissions.teamAssessementView) {
      teamAssessementsResponse = yield call(fetchTeamAssessments, {});
    }

    const res = yield call(getCompany, { payload: { isWithReloadKpis: true } });
    yield put(setUserCompany(res));
    if (teamAssessementsResponse) yield put(loadTeamSurvey(teamAssessementsResponse.data));
    yield put(loadUserInfoSuccess(userResponse.data));
    yield put(loadUserPreferences());
    yield put(loadFrequencyPermissions(res));
    yield put(setUserIsLoading(false));
  } catch (err) {
    // if token is expired
    localStorage.removeItem('state');
  }
}

function* Saga() {
  yield takeLatest(INITIAL_REQUEST, initialRequest);
  yield takeLatest(LOAD_COMPANY, getCompany);
}

export default Saga;
