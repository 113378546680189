import { loginPath } from '@/route/paths';

import ApiAbstract, { ResponseJson } from '@/services/Api/ApiAbstract';
import { storeRegistry } from '@/services/StoreRegistryService';

import { selectAppJwt } from '@/redux/auth/selectors';
import { selectGameJwt } from '@/redux/game/selectors';
import { selectCurrentSegment } from '@/redux/segment/selectors';

export default class CoreApiService extends ApiAbstract {
  protected version: string;

  protected withoutLoginRedirect: boolean;

  constructor(
    config: {
      version?: string;
      useGameJwt?: boolean;
      withoutLoginRedirect?: boolean;
      isCamelCaseResponse?: boolean;
      shouldSendSnakeCase?: boolean;
      token?: string;
    } = {}
  ) {
    const {
      version = 'v1',
      useGameJwt,
      withoutLoginRedirect,
      isCamelCaseResponse,
      shouldSendSnakeCase,
      token
    } = config;

    super(isCamelCaseResponse, shouldSendSnakeCase);
    this.version = version;
    this.withoutLoginRedirect = withoutLoginRedirect || false;

    this.api = `${process.env.BASE_URL}/core`;

    const segment = selectCurrentSegment(storeRegistry.getState());
    this.setHeaders({
      Authorization: `Bearer ${
        token ||
        (useGameJwt
          ? selectGameJwt(storeRegistry.getState())
          : selectAppJwt(storeRegistry.getState()))
      }`
    });

    if (segment) {
      this.params = {
        segment: segment && segment.id
      };
    }
  }

  setBody(body: object, withoutSegment?: boolean): this {
    const segment = selectCurrentSegment(storeRegistry.getState());
    const result = withoutSegment
      ? super.setBody(body)
      : super.setBody({ ...body, segment: segment && segment.id });
    return result;
  }

  public getUri(): string | undefined {
    if (this.endpoint) {
      if (this.version === '') {
        return `${this.api}${this.endpoint}`;
      }

      return `${this.api}/${this.version}${this.endpoint}`;
    }
    return undefined;
  }

  public disableLoginRedirect(): this {
    this.withoutLoginRedirect = true;
    return this;
  }

  protected handleResponse(responseStatus: number, responseUrl: string): ResponseJson {
    // if token is expired - redirect to login page
    if (
      responseStatus === 401 &&
      !this.withoutLoginRedirect &&
      window.location.href !== loginPath
    ) {
      window.location.href = loginPath;
    }
    return super.handleResponse(responseStatus, responseUrl);
  }
}
