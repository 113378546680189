import camelcaseKeys from 'camelcase-keys';
import { call, put, all, takeLatest, select } from 'redux-saga/effects';

import { fetchTargetingCategories, fetchFbAuth } from '@/services/Api/AdsService';
import { FacebookAccount, FacebookTargetingCategories } from '@/services/Api/AdsService/types';
import { fetchKpi } from '@/services/Api/KpisService';
import { fetchClusterGames, fetchClusterScores } from '@/services/Api/SurveyService';
import { ApiResponse } from '@/services/Api/types';

import { selectCurrentClusterCode } from '@/redux/cluster/slice';
import { selectNewFbTargeting } from '@/redux/game/selectors';
import { selectCurrentSegmentId, selectDefaultSegmentId } from '@/redux/segment/selectors';
import { Action } from '@/redux/types';


import {
  loadTargetingSummaryRequest,
  loadTargetingSummarySuccess,
  loadTargetingSummaryError,
  loadTargetingCategoriesRequest,
  loadTargetingCategoriesSuccess,
  loadTargetingCategoriesError,
  loadTargetingGamesRequest,
  loadTargetingGamesSuccess,
  loadTargetingGamesError,
  getFbAccountsRequest,
  getFbAccountsSuccess,
  getFbAccountsError,
  getFbClusterKpisRequest,
  getFbClusterKpisSuccess,
  getFbClusterKpisError
} from './actions';
import {
  LOAD_TARGETING_SUMMARY,
  LOAD_TARGETING_CATEGORIES,
  LOAD_TARGETING_GAMES,
  FB_AUDIENCE_GET_ACCOUNTS,
  FB_AUDIENCE_GET_CLUSTER_KPIS
} from './types';

function* getTargetingSummary(): Generator {
  try {
    yield put(loadTargetingSummaryRequest());
    const segmentId = yield select(selectCurrentSegmentId);
    const defaultSegmentId = yield select(selectDefaultSegmentId);
    const clusterCode = yield select(selectCurrentClusterCode);
    const newFbTargeting = yield select(selectNewFbTargeting);
    const args = [
      segmentId === defaultSegmentId ? undefined : segmentId,
      clusterCode !== 'all' ? clusterCode : undefined,
      newFbTargeting
    ];

    const [result, resultDemographics] = yield all([
      call(fetchClusterScores, 'summary', ...args),
      call(fetchClusterScores, 'demographics', ...args)
    ]);

    yield put(
      loadTargetingSummarySuccess({
        targetSummary: result,
        demographics: camelcaseKeys(resultDemographics, { deep: true })
      })
    );
  } catch (e) {
    yield put(loadTargetingSummaryError(e.message));
  }
}

function* getTargetingCategories() {
  try {
    yield put(loadTargetingCategoriesRequest());
    const result: FacebookTargetingCategories = yield call(fetchTargetingCategories);
    yield put(loadTargetingCategoriesSuccess(result));
  } catch (e) {
    yield put(loadTargetingCategoriesError(e.message));
  }
}

function* getTargetingGames() {
  try {
    yield put(loadTargetingGamesRequest());
    const clusterCode: string = yield select(selectCurrentClusterCode);
    const { games } = yield call(fetchClusterGames, clusterCode, true);
    yield put(loadTargetingGamesSuccess(games));
  } catch (e) {
    yield put(loadTargetingGamesError((<Error>e).message));
  }
}

function* getFacebookAccounts() {
  try {
    yield put(getFbAccountsRequest());
    const result: ApiResponse<FacebookAccount[]> = yield call(fetchFbAuth);
    yield put(getFbAccountsSuccess(result.data));
  } catch (e) {
    yield put(getFbAccountsError((<Error>e).message));
  }
}

function* getKpis(action: Action) {
  try {
    const {
      payload: { segment, clusterCode }
    } = action;
    yield put(getFbClusterKpisRequest());
    const result = yield call(fetchKpi, undefined, segment, clusterCode);
    yield put(getFbClusterKpisSuccess(result.data));
  } catch (e) {
    yield put(getFbClusterKpisError((<Error>e).message));
  }
}

function* Saga() {
  yield takeLatest(LOAD_TARGETING_SUMMARY, getTargetingSummary);
  yield takeLatest(LOAD_TARGETING_CATEGORIES, getTargetingCategories);
  yield takeLatest(LOAD_TARGETING_GAMES, getTargetingGames);
  yield takeLatest(FB_AUDIENCE_GET_ACCOUNTS, getFacebookAccounts);
  yield takeLatest(FB_AUDIENCE_GET_CLUSTER_KPIS, getKpis);
}

export default Saga;
