import CoreApiService from '@/services/Api/CoreApiService';

export default class MediaApiService extends CoreApiService {
  constructor(
    config: {
      version?: 'v1';
      isCamelCaseResponse?: boolean;
      shouldSendSnakeCase?: boolean;
    } = {}
  ) {
    const { isCamelCaseResponse = true, shouldSendSnakeCase, version } = config;
    super({ isCamelCaseResponse, shouldSendSnakeCase, version });
    this.api = `${process.env.BASE_URL}/media`;
  }
}
