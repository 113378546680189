import classNames from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';


import Icon from 'ui-lib-12traits/src/Icons/svg/common/cross.svg?component';
import { ButtonIcon } from 'ui-lib-12traits/src/index';

import styles from './Attachment.module.scss';

import type { Props } from './Attachment.types';

export function Attachment({ onRemove: onClick, attachment, ...props }: Props) {
  const src = useMemo(() => URL.createObjectURL(attachment), [attachment]);
  const className = useMemo(() => classNames(styles.container, props.className), [props.className]);
  const onLoad = useCallback(() => URL.revokeObjectURL(src), [src]);

  useEffect(() => () => URL.revokeObjectURL(src), [src]);

  return (
    <li className={className}>
      <img alt={attachment.path} onLoad={onLoad} src={src} />
      <ButtonIcon onClick={onClick}>
        <Icon />
      </ButtonIcon>
    </li>
  );
}

export default Attachment;
