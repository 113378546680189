import { Game } from '@/redux/games/types';

import { DashboardConfig } from './types';

export const mapDataToDashboardConfig = ({
  dashboard_config: config,
  kpis_upload_status: upload
}: Game): DashboardConfig => ({
  hasKpis: config.kpis_enabled,
  namespace: config.namespace,
  hasPlayerIds: !config.empty_player_ids_file,
  hasFbTargeting: config.fb_targeting_enabled,
  hasActions: config.recommended_actions_enabled,
  assessmentVersion: config.assessment_version,
  hasOnlyPersonas: config.overview_only_personas,
  hasLookalike: config.enable_lookalike,
  hasHighValueBlade: config.enable_high_value_blade,
  hasPerformanceMetrics: config.enable_performance_metrics,
  isKpisUploaded: !!Number(upload?.status || 0)
});
