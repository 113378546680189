import { Action, ActionUpdate } from '@/services/Api/GameService/types';

import { prepareAction } from '@/redux/helpers';
import { Action as ReduxAction } from '@/redux/types';

import {
  LOAD_ACTIONS,
  LOAD_ACTIONS_REQUEST,
  LOAD_ACTIONS_SUCCESS,
  ADD_ACTION,
  ADD_ACTION_REQUEST,
  ADD_ACTION_SUCCESS,
  SET_ACTIONS_CATEGORY_INDEX,
  SET_ACTIONS_STATUS_ID,
  UPDATE_ACTION,
  UPDATE_ACTION_REQUEST,
  UPDATE_ACTION_SUCCESS,
  DELETE_ACTION,
  DELETE_ACTION_SUCCESS,
  DELETE_ACTION_REQUEST,
  LOAD_PROGRESS,
  LOAD_PROGRESS_REQUEST,
  LOAD_PROGRESS_SUCCESS,
  SET_PROGRESS_TIMEFRAME,
  SHOW_ACTION_DETAILS,
  SHOW_FILTERS,
  SET_FILTERS,
  VOTE_ACTION,
  VOTE_ACTION_REQUEST,
  VOTE_ACTION_SUCCESS,
  RELOAD_ACTIONS,
  Filters,
  UNLOCK_ACTION,
  UNLOCK_ACTION_REQUEST,
  UNLOCK_ACTION_ERROR
} from './types';

export const loadActions = (params: {
  category?: string;
  statuses?: Array<number>;
  page?: number;
  allActions?: boolean;
  sort?: string;
  objectives?: string;
}): ReduxAction => {
  const {
    category = '',
    statuses = [],
    page = 1,
    allActions = false,
    sort = '',
    objectives
  } = params;
  return prepareAction(LOAD_ACTIONS, { category, page, statuses, allActions, sort, objectives });
};

export const loadActionsRequest = (): ReduxAction => prepareAction(LOAD_ACTIONS_REQUEST);

export const loadActionsSuccess = (
  actions: Array<Action>,
  page: number,
  total: number,
  hasMore: boolean
): ReduxAction => prepareAction(LOAD_ACTIONS_SUCCESS, { actions, page, total, hasMore });

export const reloadActions = (): ReduxAction => prepareAction(RELOAD_ACTIONS);

export const addAction = (action: Action): ReduxAction => prepareAction(ADD_ACTION, action);

export const addActionRequest = (): ReduxAction => prepareAction(ADD_ACTION_REQUEST);

export const addActionSuccess = (action: Action): ReduxAction =>
  prepareAction(ADD_ACTION_SUCCESS, action);

export const updateAction = (id: string, fields: ActionUpdate): ReduxAction =>
  prepareAction(UPDATE_ACTION, { id, fields });

export const updateActionRequest = (): ReduxAction => prepareAction(UPDATE_ACTION_REQUEST);

export const updateActionSuccess = (action: Action): ReduxAction =>
  prepareAction(UPDATE_ACTION_SUCCESS, action);

export const deleteAction = (id: string): ReduxAction => prepareAction(DELETE_ACTION, { id });

export const deleteActionRequest = (): ReduxAction => prepareAction(DELETE_ACTION_REQUEST);

export const deleteActionSuccess = (id: string): ReduxAction =>
  prepareAction(DELETE_ACTION_SUCCESS, { id });

export const setActionsCategoryIndex = (categoryIndex: number): ReduxAction =>
  prepareAction(SET_ACTIONS_CATEGORY_INDEX, categoryIndex);

export const setActionsStatusId = (statusId: number): ReduxAction =>
  prepareAction(SET_ACTIONS_STATUS_ID, statusId);

export const showActionDetails = (
  actionId: string | null = null,
  showLaunchDetails: boolean = false
): ReduxAction => prepareAction(SHOW_ACTION_DETAILS, { actionId, showLaunchDetails });

export const loadProgress = (): ReduxAction => prepareAction(LOAD_PROGRESS);

export const loadProgressRequest = (): ReduxAction => prepareAction(LOAD_PROGRESS_REQUEST);

export const loadProgressSuccess = (progress: {
  completed: number;
  started: number;
}): ReduxAction => prepareAction(LOAD_PROGRESS_SUCCESS, { progress });

export const setProgressTimeFrame = (timeFrame: string): ReduxAction =>
  prepareAction(SET_PROGRESS_TIMEFRAME, { timeFrame });

export const showActionsFilters = (showFilters: boolean): ReduxAction =>
  prepareAction(SHOW_FILTERS, { showFilters });

export const setFilters = (data: Filters): ReduxAction =>
  prepareAction(SET_FILTERS, { filters: data });

export const voteAction = (id: string, value?: 1 | -1): ReduxAction =>
  prepareAction(VOTE_ACTION, { id, value });

export const voteActionRequest = (): ReduxAction => prepareAction(VOTE_ACTION_REQUEST);

export const voteActionSuccess = (): ReduxAction => prepareAction(VOTE_ACTION_SUCCESS);

export const unlockAction = (actionId: string): ReduxAction =>
  prepareAction(UNLOCK_ACTION, { actionId });

export const unlockActionRequest = (): ReduxAction => prepareAction(UNLOCK_ACTION_REQUEST);

export const unlockActionError = (error: string): ReduxAction =>
  prepareAction(UNLOCK_ACTION_ERROR, { error });
