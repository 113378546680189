import MediaApiService from '../MediaApiService';

import type { Bucket, Upload } from './types';
import type { ApiResponse } from '../types';

export const upload = ({ bucket, file }: Upload): Promise<ApiResponse<string>> => {
  const send = ({ data: { publicUrl, uploadUrl } }: Promise<ApiResponse<Bucket>>) => {
    const deliver = () => publicUrl;

    return window
      .fetch(uploadUrl, {
        headers: { 'Content-Type': '' },
        method: 'PUT',
        body: file
      })
      .then(deliver);
  };

  return new MediaApiService()
    .setEndpoint('/upload')
    .setBody({ filename: file.name, type: bucket }, true)
    .post()
    .then(send);
};
