import { ContentTypeEnum } from '@/redux/game/types';

export default ({ company }) => ({
  title: 'How should we brand your assessment?',
  branding: {
    type: {
      solsten: 'Solsten branding',
      other: 'Uploaded image'
    },
    status: {
      uploaded: 'Uploaded logo'
    },
    logo: {
      other: {
        title: 'Your logo',
        subtitle: 'Company logo'
      }
    }
  },
  disclaimer: [
    `<strong>Thanks for taking the time to complete our assessment and we hope you have fun sharing your interests!</strong>`,
    ({ contentType }) => {
      const isGaming = contentType === ContentTypeEnum.Gaming;
      const partners = isGaming ? 'game developers' : 'companies';
      const audience = isGaming ? 'players' : 'users';
      const experience = `${isGaming ? 'gaming ' : ''}experience`;

      return `We're Solsten. We work with ${partners} to help them know more about their ${audience}. By completing the<br />assessment, <strong>you're helping</strong> Solsten and ${company.name} create the best ${experience} for you and...`;
    }
  ],
  options: {
    upload: {
      title: 'Upload a new logo by dragging it above or',
      action: 'browse your files'
    },
    remove: {
      title: 'Remove your logo by',
      action: 'clicking here'
    },
    skip: {
      title: "Don't want to brand your assessment?",
      action: "Use Solsten's branding"
    }
  }
});
