import { prepareAction } from '@/redux/helpers';
import { Action } from '@/redux/types';

import {
  SAVE_JWT,
  ERASE_AUTH,
  CLEAR_ERRORS,
  SAVE_USER_EMAIL,
  LOGIN,
  LOGOUT,
  NEW_PASS,
  FORGOT_PASS,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  SET_IS_LOG_OUT
} from './types';

export const saveJWT = (jwt: string, exp: number): Action => prepareAction(SAVE_JWT, { jwt, exp });

export const eraseAuth = (): Action => prepareAction(ERASE_AUTH);

export const clearErrors = (): Action => prepareAction(CLEAR_ERRORS);

export const saveUserEmail = (email: string): Action => prepareAction(SAVE_USER_EMAIL, { email });

export const authRequest = (): Action => prepareAction(AUTH_REQUEST);

export const authSuccess = (data: { message: string }, firstLogin: boolean): Action =>
  prepareAction(AUTH_SUCCESS, { url: data.message, firstLogin });

export const authError = (error: string): Action => prepareAction(AUTH_ERROR, { errorMsg: error });

export const forgotPass = (email: string): Action => prepareAction(FORGOT_PASS, { email });

export const newPass = (pass: string, token: string): Action =>
  prepareAction(NEW_PASS, { pass, token });

export const login = (email: string, pass: string): Action => prepareAction(LOGIN, { email, pass });

export const logout = (): Action => prepareAction(LOGOUT);

export const setIsLogOut = (isLogOut: boolean): Action =>
  prepareAction(SET_IS_LOG_OUT, { isLogOut });
