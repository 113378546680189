export default () => ({
  title: 'How will you reward your participants?',
  scenarios: {
    none: 'No reward'
  },
  actions: {
    proceed: {
      title: 'Next'
    }
  },
  contact: {
    title: 'Contact your customer success manager',
    input: {
      title: 'Describe your request',
      placeholder: 'Tell us what prompted this request...'
    },
    actions: {
      submit: 'Send',
      wait: 'Sending...'
    },
    disclaimer: ' Please do not include any sensitive information.'
  }
});
