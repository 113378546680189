import camelcaseKeys from 'camelcase-keys';
import { call, put, select, all, debounce } from 'redux-saga/effects';

import { fetchComparisonOverview } from '@/services/Api/CoreService';
import { fetchComparisonValue } from '@/services/Api/GameService';

import { SET_CLUSTER_CODE } from '@/redux/cluster/types';
import {
  loadComparisonOverviewRequest,
  loadComparisonOverviewSuccess,
  loadComparisonOverviewError,
  updateGraphs
} from '@/redux/pages/comparisonPage/actions';
import {
  selectComparisonGraphs,
  selectIsInitialSettingsLoaded
} from '@/redux/pages/comparisonPage/selectors';

import { getComparisonParams } from './helpers';
import { ComparisonGraph, ComparisonGraphSelection, ComparisonParams } from './types';

function* getComparisonValues() {
  const isSettingsLoaded = yield select(selectIsInitialSettingsLoaded);

  if (isSettingsLoaded) {
    yield put(loadComparisonOverviewRequest());
    try {
      const params = getComparisonParams();

      if (params.left.cluster && params.right.cluster) {
        const result = yield call(fetchComparisonOverview, params);

        yield put(loadComparisonOverviewSuccess(camelcaseKeys(result.data, { deep: true })));
      }
    } catch (e) {
      yield put(loadComparisonOverviewError(e.message));
    }
  }
}

function* doUpdateGraphs() {
  const graphs = yield select(selectComparisonGraphs);

  const requestData: (ComparisonParams & ComparisonGraphSelection)[] = graphs.map(
    (graph: ComparisonGraph) => {
      const params = getComparisonParams();
      return {
        ...params,
        type: graph.type,
        key: graph.type === 'kpi' ? graph.label : graph.key
      };
    }
  );

  const response = yield all(requestData.map(data => call(fetchComparisonValue, data)));

  const newGraphs = graphs.map((graphsPair: ComparisonGraph) => {
    const { data } = response.find((one: any) => one.data.key === graphsPair.key);
    return {
      ...graphsPair,
      leftValue: data?.left?.value || '?',
      rightValue: data?.right?.value || '?'
    };
  });

  yield put(updateGraphs(newGraphs));
}

function* Saga() {
  const onChangeRequired = [SET_CLUSTER_CODE];
  yield debounce(100, onChangeRequired, getComparisonValues);
  yield debounce(100, onChangeRequired, doUpdateGraphs);
}

export default Saga;
