import classNames from 'classnames';
import { ReactElement } from 'react';


import AppleStoreIcon from 'ui-lib-12traits/src/Icons/svg/channels/apple-store.svg?component';
import GoogleAppStore from 'ui-lib-12traits/src/Icons/svg/channels/google-play-store.svg?component';
import TwitterIcon from 'ui-lib-12traits/src/Icons/svg/channels/twitter.svg?component';
import YouTubeIcon from 'ui-lib-12traits/src/Icons/svg/channels/youtube.svg?component';
import CheckICon from 'ui-lib-12traits/src/Icons/svg/common/mark.svg?component';


import { useAppSelector } from '@/hooks/useApp';

import { updateOrRemoveSearchParam } from '@/services/UrlService';

import { selectSentimentAvailableChannels } from '@/redux/pages/overviewPage/selectors';

import { ChannelsEnum } from '../../helpers';
import sharedStyles from '../shared.module.scss';

import styles from './FilterByChannel.module.scss';

interface Props {
  channels: string[];
}

function FilterByChannel({ channels }: Props): ReactElement {
  const activeChannels = useAppSelector(selectSentimentAvailableChannels);
  const OPTIONS = [
    {
      name: 'Apple App Store',
      id: ChannelsEnum.AppleAppStore,
      icon: <AppleStoreIcon />
    },
    {
      name: 'Google Play Store',
      id: ChannelsEnum.GooglePlayStore,
      icon: <GoogleAppStore />
    },
    {
      name: 'Twitter',
      id: ChannelsEnum.Twitter,
      icon: <TwitterIcon />
    },
    {
      name: 'YouTube',
      id: ChannelsEnum.YouTube,
      icon: <YouTubeIcon />
    }
  ];

  const availiableChannels = OPTIONS.filter(item => activeChannels.includes(item.id));

  const onClickHandler = (channel: ChannelsEnum): void => {
    if (channels.includes(channel)) {
      const newChannels = [...channels].filter(item => item !== channel);
      updateOrRemoveSearchParam({ sources: newChannels });
    } else if (!channels.includes(channel)) {
      const newChannels = [...channels, channel];
      updateOrRemoveSearchParam({ sources: newChannels });
    }
  };

  return (
    <div className={styles.filter__wrapper}>
      <div className={styles.filter__heading}>
        <div className={styles.filter__title} data-testid="filter-channel-title">
          Filter by channels
        </div>
        {!!channels.length && (
          <button
            type="button"
            onClick={(): void => {
              updateOrRemoveSearchParam({ sources: [] });
            }}
            className={styles['filter__clear-btn']}
            data-testid="filter-channel-clear-button"
          >
            Clear filter
          </button>
        )}
      </div>
      <div className={styles.filter__channels}>
        {availiableChannels.map(channel => (
          <div
            key={channel.id}
            onClick={(): void => onClickHandler(channel.id)}
            className={classNames(sharedStyles['select-option'], styles.channel, {
              [sharedStyles['select-option--active']]: channels.includes(channel.id)
            })}
            data-testid="filter-channel-channel-item"
          >
            <div className={styles.channel__icon}>
              {!channels.includes(channel.id) ? channel.icon : <CheckICon />}
            </div>
            {channel.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilterByChannel;
