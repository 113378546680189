import CoreApiService from '@/services/Api/CoreApiService';

export default class SocialListeningApi extends CoreApiService {
  constructor(config: {
    version: 'v1' | 'v2';
    isCamelCaseResponse?: boolean;
    shouldSendSnakeCase?: boolean;
    useGameJwt?: boolean;
  }) {
    const { isCamelCaseResponse = true, shouldSendSnakeCase, version, useGameJwt } = config;
    super({ isCamelCaseResponse, shouldSendSnakeCase, version, useGameJwt });
    this.api = `${process.env.BASE_URL}/social-listening`;
  }
}
