import { Action } from '@/redux/types';

import { NavigatorState, SET_NAVIGATOR_DASHBOARD_SLUG } from './types';

const initialState: NavigatorState = {
  dashboardSlug: ''
};

export default (state = initialState, { type, payload }: Action): NavigatorState => {
  switch (type) {
    case SET_NAVIGATOR_DASHBOARD_SLUG: {
      return { ...state, dashboardSlug: payload.dashboardSlug };
    }
    default:
      return state;
  }
};
