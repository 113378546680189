export const SAVE_JWT = 'Auth / SAVE_JWT';
export const ERASE_AUTH = 'Auth / ERASE_AUTH';
export const CLEAR_ERRORS = 'Auth / CLEAR_ERRORS';
export const SAVE_USER_EMAIL = 'Auth / SAVE_USER_EMAIL';
export const FORGOT_PASS = 'Auth / FORGOT_PASS';
export const NEW_PASS = 'Auth / NEW_PASS';
export const LOGIN = 'Auth / LOGIN =';
export const LOGOUT = 'Auth / LOGOUT';
export const AUTH_REQUEST = 'Auth / AUTH_REQUEST';
export const AUTH_SUCCESS = 'Auth / AUTH_SUCCESS';
export const AUTH_ERROR = 'Auth / AUTH_ERROR';
export const SET_IS_LOG_OUT = 'AUTH / SET_IS_LOG_OUT';

export interface AuthState {
  isAuthenticated: boolean;
  jwt: string;
  exp: number;
  email: string;
  loading: boolean;
  error: boolean;
  errorMsg: string;
  firstLogin: boolean;
  isLogOut: boolean;
}
