/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import cn from 'classnames';
import prop from 'ramda.prop';
import filter from 'ramda.filter';

import ArrowIcon from 'ui-lib-12traits/src/Icons/svg/common/triangle_small.svg?component';
import useOutsideClick from '@12traits/useoutsideclick';

import useDropdownPosition from '../../hooks/use-dropdown-position';

import { fuzzyFilter } from '../../utils';
import styles from './index.module.scss';

type Props<T> = {
  options: Array<T>;
  onSelect: (option: T | Array<T>) => void;
  multiple?: boolean;
  limit?: number;
  groupBy?: (option: T) => void;
  open?: any;
  as?: 'search' | 'button';
  placeholder?: string;
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  dynamicDropdownPosition?: boolean;
  iconLeft?: React.ReactNode;
  children?: React.ReactNode;
  onSelectAll?: (options: Array<T>) => void;
  onChange?: (val: string) => void;
  onOutsideClick?: () => void;
  resetCondition?: boolean;
  renderLabel?: (val: { options: Array<T>; selectedCount: number }) => React.ReactNode;
  renderOption?: (option: T) => React.ReactNode;
  renderAllOption?: (options: Array<T>) => React.ReactNode;
  renderGroup?: (group: any) => React.ReactNode;
  renderTags?: ({
    options,
    removeTag
  }: {
    options: Array<T>;
    removeTag: (v: T) => void;
  }) => React.ReactNode;
  wrapperProps?: React.HTMLProps<HTMLDivElement>;
  buttonProps?: Omit<React.HTMLProps<HTMLButtonElement>, 'onClick'>;
  listContainerProps?: Omit<React.HTMLProps<HTMLDivElement>, 'ref'>;
  labelProps?: React.HTMLProps<HTMLLabelElement>;
  inputProps?: React.HTMLProps<HTMLInputElement>;
};

export default function DropdownSelect<
  T extends { label: string; value: string; name: string; checked: boolean }
>({
  options,
  onSelect,
  multiple = false,
  limit = options.length - 1,
  groupBy,
  open = '',
  as = 'button',
  placeholder = '',
  label = '',
  defaultValue = '',
  disabled = false,
  dynamicDropdownPosition = true,
  iconLeft,
  children,
  onSelectAll,
  onChange,
  onOutsideClick,
  resetCondition = false,
  renderLabel,
  renderOption,
  renderAllOption,
  renderGroup,
  renderTags,
  wrapperProps = {},
  buttonProps = {},
  listContainerProps = {},
  inputProps,
  labelProps
}: Props<T>) {
  const [value, setValue] = useState('');
  const [list, setList] = useState<Array<T>>(options);
  const [isVisible, setIsVisible] = useState<string>(open ?? '');
  const { dropdownRef, listRef, headRef } = useDropdownPosition(!!isVisible, {
    dynamic: dynamicDropdownPosition
  });
  useOutsideClick(dropdownRef, () => {
    if (open && onOutsideClick) {
      onOutsideClick();
    }

    setIsVisible('');
  });

  useEffect(() => {
    if (open !== '') {
      setIsVisible(open);
    }
  }, [open]);

  useEffect(() => {
    if (resetCondition) {
      setList(options);
    }
  }, [resetCondition]);

  function handleSelect(upcomingOption: T) {
    setValue('');

    if (multiple) {
      const copy = list.map(option =>
        option.value === upcomingOption.value ? { ...option, checked: !option.checked } : option
      );
      setList(copy);

      onSelect({ ...upcomingOption, checked: !upcomingOption.checked });
    } else {
      const copy = list.map(option =>
        option.value === upcomingOption.value
          ? { ...option, checked: true }
          : { ...option, checked: false }
      );
      setList(copy);

      onSelect({ ...upcomingOption, checked: true });
    }

    setIsVisible('open');
  }

  function toggleAll() {
    if (!onSelectAll) return;

    if (list.some(l => !l.checked) || list.every(l => !l.checked)) {
      const copy = list.map(l => ({ ...l, checked: true }));

      setList(copy);
      onSelectAll(copy);
    } else {
      const copy = list.map(l => ({ ...l, checked: false }));

      setList(copy);
      onSelectAll(copy);
    }
  }

  function removeTag(selectoption: T) {
    // * means all selected tags
    if (Array.isArray(selectoption)) {
      const copy = list.slice();
      const unchecked = copy.map(x => ({ ...x, checked: false }));
      setList(unchecked);
      return;
    }

    const copy = list.slice();
    const findIndex = copy.findIndex(c => c.value === selectoption.value);
    if (findIndex >= 0) {
      copy[findIndex] = { ...copy[findIndex], checked: false };
      setList(copy);
    }
  }

  function handleChange(e) {
    const { value } = e.target;
    setValue(value);

    if (typeof onChange === 'function') {
      onChange(value);
    }
  }

  return (
    <div {...wrapperProps} ref={dropdownRef}>
      {as === 'button' ? (
        <button
          ref={headRef}
          className={cn({ [styles.button_active]: isVisible })}
          {...buttonProps}
          type="button"
          onClick={() => setIsVisible(prev => (prev.length === 0 ? 'open' : ''))}
          disabled={disabled}
        >
          {list.filter(l => l.checked).length === 0 ? (
            defaultValue
          ) : typeof renderLabel === 'function' ? (
            <>
              {renderLabel({
                options: list.filter(l => l.checked).slice(0, limit),
                selectedCount: list.filter(l => l.checked).length
              })}

              {!list.every(l => l.checked) &&
              multiple &&
              list.slice(limit, list.length - 1).some(l => l.checked) &&
              list.filter(l => l.checked).length > limit ? (
                <span>
                  {` +${list.filter(l => l.checked).slice(limit, list.length - 1).length}`}
                </span>
              ) : null}
            </>
          ) : (
            <>
              {list
                .filter(option => option.checked)
                .slice(0, limit)
                .map((opt, i) => (
                  <span key={opt.value + opt.label}>{opt.label}</span>
                ))}
            </>
          )}
          <ArrowIcon width={16} height={16} className={!isVisible ? styles.rotate : ''} />
        </button>
      ) : (
        <label
          ref={headRef}
          htmlFor="input"
          {...labelProps}
          onClick={e => {
            e.preventDefault();
            setIsVisible(prev => (prev ? '' : 'open'));
          }}
        >
          <span>{label}</span>
          <div
            className={cn(styles.input_wrapper, {
              [styles.wrapperInputIconButton]: isVisible,
              [styles.input_wrapper_empty]: filter(prop('checked'))(list).length === 0 && !isVisible
            })}
          >
            <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', width: '97%' }}>
              {iconLeft && isVisible && filter(prop('checked'))(list).length === 0 && iconLeft}
              {typeof renderTags === 'function' ? renderTags({ options: list, removeTag }) : null}

              <input
                id="input"
                type="text"
                value={value}
                onChange={handleChange}
                placeholder={filter(prop('checked'))(list).length === 0 ? placeholder : ''}
                {...inputProps}
              />
            </div>

            <ArrowIcon
              width={16}
              height={16}
              color="#133D66"
              className={cn(styles.input_arrow, { [styles.rotate]: !isVisible })}
            />
          </div>
        </label>
      )}

      {isVisible ? (
        <div {...listContainerProps} ref={listRef}>
          <ul>
            {onSelectAll && value === '' ? (
              <li>
                <button type="button" onClick={toggleAll}>
                  {typeof renderAllOption === 'function' ? renderAllOption(list) : 'Select All'}
                </button>
              </li>
            ) : null}

            {typeof groupBy === 'function'
              ? Object.entries(Object.groupBy(fuzzyFilter(list, value), groupBy)).map(
                  ([groupName, options]) => (
                    <>
                      {typeof renderGroup === 'function' ? (
                        renderGroup(groupName)
                      ) : (
                        <span>{groupName}</span>
                      )}
                      {options?.map((option, i) => (
                        <li key={option.value + option.label}>
                          <button
                            type="button"
                            onClick={() => {
                              handleSelect(option);
                            }}
                          >
                            {typeof renderOption === 'function'
                              ? renderOption(option)
                              : option.label}
                          </button>
                        </li>
                      ))}
                    </>
                  )
                )
              : list.map((option, i) => (
                  <li key={option.value + option.label}>
                    <button type="button" onClick={() => handleSelect(option)}>
                      {typeof renderOption === 'function' ? renderOption(option) : option.label}
                    </button>
                  </li>
                ))}
          </ul>

          {children}
        </div>
      ) : null}
    </div>
  );
}
