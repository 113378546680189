import { DashboarUserRoles } from '@/redux/user/types';

export const isUserCanUpdateSegment = (role?: string): boolean =>
  role === DashboarUserRoles.ADMIN || role === DashboarUserRoles.MANAGER;

export const isUserCanUploadKpi = (role?: string): boolean => role === DashboarUserRoles.ADMIN;

export const isUserCanEditPersonaDescription = (role?: string): boolean =>
  role === DashboarUserRoles.ADMIN ||
  role === DashboarUserRoles.MANAGER ||
  role === DashboarUserRoles.CONTRIBUTOR;

export const isUserCanDownloadPlayersIds = (role?: string): boolean =>
  role === DashboarUserRoles.ADMIN ||
  role === DashboarUserRoles.MANAGER ||
  role === DashboarUserRoles.CONTRIBUTOR;

export const isUserCanEditJourney = (role?: string): boolean =>
  role === DashboarUserRoles.ADMIN ||
  role === DashboarUserRoles.MANAGER ||
  role === DashboarUserRoles.CONTRIBUTOR;

export const isUserCanAddAction = (role?: string): boolean =>
  role === DashboarUserRoles.ADMIN ||
  role === DashboarUserRoles.MANAGER ||
  role === DashboarUserRoles.CONTRIBUTOR;
