import { Action } from '@/redux/types';

import { prepareSourceUpdate, removeIntegrationSource } from './helpers';
import { SocialListeningActionTypes, SocialListeningSources, SocialListeningState } from './types';

const initialState: SocialListeningState = {
  gameId: '',
  enabled: true,
  isLoaded: false,
  sources: [
    {
      socialListeningSource: SocialListeningSources.Twitter,
      enabled: false
    },
    {
      socialListeningSource: SocialListeningSources.AppleAppStore,
      enabled: false
    },
    {
      socialListeningSource: SocialListeningSources.GooglePlayStore,
      enabled: false
    },
    {
      socialListeningSource: SocialListeningSources.Youtube,
      enabled: false
    }
  ]
};

export default (state = initialState, { type, payload }: Action): SocialListeningState => {
  const updateSingleSource = prepareSourceUpdate(payload, state.sources);
  switch (type) {
    case SocialListeningActionTypes.SetSocialListeningLoaded: {
      return { ...state, isLoaded: payload };
    }
    case SocialListeningActionTypes.UpdateSocialListening:
      return { ...state, ...payload };

    case SocialListeningActionTypes.UpdateTwitterIntegration:
      return {
        ...state,
        sources: updateSingleSource(SocialListeningSources.Twitter)
      };

    case SocialListeningActionTypes.UpdateYoutubeIntegration:
      return {
        ...state,
        sources: updateSingleSource(SocialListeningSources.Youtube)
      };

    case SocialListeningActionTypes.UpdateAppleAppStoreIntegration:
      return {
        ...state,
        sources: updateSingleSource(SocialListeningSources.AppleAppStore)
      };

    case SocialListeningActionTypes.UpdateGooglePlayStoreIntegration:
      return {
        ...state,
        sources: updateSingleSource(SocialListeningSources.GooglePlayStore)
      };

    case SocialListeningActionTypes.RemoveIntegrationSource:
      return removeIntegrationSource(state, payload);
    default:
      return state;
  }
};
