
import { today, getLocalTimeZone, CalendarDate } from '@internationalized/date';
import classNames from 'classnames';
import { KeyboardEvent, ReactElement, ReactNode, useCallback, useState } from 'react';
import { I18nProvider } from 'react-aria';

import { InputText } from 'ui-lib-12traits/src/index';

import { updateOrRemoveSearchParam } from '@/services/UrlService';

import DateRangePicker from '@/components/Ui/DateRangePicker';

import { getInputValue, getCalendarValueFromUrl, getUrlValue } from '../../helpers';
import sharedStyles from '../shared.module.scss';

import styles from './FilterByDate.module.scss';

interface Props {
  dateFrom: string;
  dateTo: string;
}

function FilterByDate({ dateFrom, dateTo }: Props): ReactElement {
  const [selectedButton, setSelectedButton] = useState<string>('');
  const [startDate, setStartDate] = useState<CalendarDate>(getCalendarValueFromUrl(dateFrom));
  const [endDate, setEndDate] = useState<CalendarDate>(getCalendarValueFromUrl(dateTo));
  const [startInputValue, setStartInputValue] = useState<string>(
    getInputValue(getCalendarValueFromUrl(dateFrom))
  );
  const [endInputValue, setEndInputValue] = useState<string>(
    getInputValue(getCalendarValueFromUrl(dateTo))
  );

  const DATE_OPTIONS = [
    {
      title: 'Yesterday',
      end: today(getLocalTimeZone()).subtract({ days: 1 }),
      start: today(getLocalTimeZone()).subtract({ days: 1 })
    },
    {
      title: 'Last Week',
      end: today(getLocalTimeZone()),
      start: today(getLocalTimeZone()).subtract({ weeks: 1 })
    },
    {
      title: 'Last 2 Weeks',
      end: today(getLocalTimeZone()),
      start: today(getLocalTimeZone()).subtract({ weeks: 2 })
    },
    {
      title: 'Last Month',
      end: today(getLocalTimeZone()),
      start: today(getLocalTimeZone()).subtract({ months: 1 })
    },
    {
      title: 'Last Quarter',
      end: today(getLocalTimeZone()),
      start: today(getLocalTimeZone()).subtract({ months: 3 })
    },
    {
      title: 'This Month',
      end: today(getLocalTimeZone()),
      start: today(getLocalTimeZone()).subtract({
        days: today(getLocalTimeZone()).day - 1
      })
    },
    {
      title: 'Custom',
      end: endDate,
      start: startDate
    }
  ];

  const onSelectDate = (item: any): void => {
    updateOrRemoveSearchParam({ dateTo: getUrlValue(item.end), dateFrom: getUrlValue(item.start) });
    return selectedButton === item.title ? setSelectedButton('') : setSelectedButton(item.title);
  };

  const onChange = (value: any): void => {
    setEndDate(value.end);
    setStartDate(value.start);
    setEndInputValue(getInputValue(value.end));
    setStartInputValue(getInputValue(value.start));
    updateOrRemoveSearchParam({
      dateFrom: getUrlValue(value.start),
      dateTo: getUrlValue(value.end)
    });
  };

  const onChangeInputValue = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    const changeFunctions = {
      startInputValue: setStartInputValue,
      endInputValue: setEndInputValue
    };

    if (e.target.name === 'startInputValue' || e.target.name === 'endInputValue') {
      const currentOnChange = changeFunctions[e.target.name];
      currentOnChange(e.target.value);
    }
  }, []);

  const renderCustomDateRange = (): ReactNode => {
    const onKeyDownHandler = (event: KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Enter') {
        if (/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(startInputValue)) {
          const dateValues = startInputValue.split('/');
          const formattedDate = new CalendarDate(+dateValues[2], +dateValues[0], +dateValues[1]);
          const urlDate = `${dateValues[2]}-${dateValues[0]}-${dateValues[1]}`;
          if (formattedDate.compare(today(getLocalTimeZone())) <= 0) {
            setStartDate(formattedDate);
            updateOrRemoveSearchParam({ dateFrom: urlDate });
          }
        }
        if (/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(endInputValue)) {
          const dateValues = endInputValue.split('/');
          const formattedDate = new CalendarDate(+dateValues[2], +dateValues[0], +dateValues[1]);
          const urlDate = `${dateValues[2]}-${dateValues[0]}-${dateValues[1]}`;
          if (formattedDate.compare(today(getLocalTimeZone())) <= 0) {
            setEndDate(formattedDate);
            updateOrRemoveSearchParam({ dateTo: urlDate });
          }
        }
      }
    };
    return (
      <div className={styles['date-range__wrapper']}>
        <div className={styles['date-range__inputs']}>
          <div className={styles['date-range__input-wrapper']}>
            <InputText
              wrapperClassName={styles.input__wrapper}
              rightIconClassName={styles.input__icon}
              name="startInputValue"
              title="Start date"
              placeholder="mm/dd/yyyy"
              value={startInputValue}
              className={styles['date-range__input']}
              onChange={onChangeInputValue}
              onKeyDown={onKeyDownHandler}
              showTooltipOnCreate
              errorMessage={
                !/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(startInputValue)
                  ? 'Please format: mm/dd/yyyy'
                  : ''
              }
              tooltipClassname={styles.tooltip}
              tooltipArrowClassname={styles.tooltip__arrow}
              data-testid="filter-date-start-input"
            />
          </div>
          <div className={styles['date-range__input-wrapper']}>
            <InputText
              wrapperClassName={styles.input__wrapper}
              rightIconClassName={styles.input__icon}
              name="endInputValue"
              title="End date"
              placeholder="mm/dd/yyyy"
              value={endInputValue}
              className={styles['date-range__input']}
              onChange={onChangeInputValue}
              onKeyDown={onKeyDownHandler}
              showTooltipOnCreate
              errorMessage={
                !/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(endInputValue)
                  ? 'Please format: mm/dd/yyyy'
                  : ''
              }
              tooltipClassname={styles.tooltip}
              tooltipArrowClassname={styles.tooltip__arrow}
              data-testid="filter-date-end-input"
            />
          </div>
        </div>
        <div>
          <I18nProvider locale="en-Us">
            <DateRangePicker
              value={{ end: endDate, start: startDate }}
              defaultValue={{ end: endDate, start: startDate }}
              maxValue={today(getLocalTimeZone())}
              onChange={onChange}
            />
          </I18nProvider>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.filter__wrapper}>
      <div className={styles.filter__heading}>
        <div className={styles.filter__title} data-testid="filter-date-title">
          Select date range
        </div>
      </div>
      <div className={styles.buttons}>
        {DATE_OPTIONS.map(item => (
          <div
            key={item.title}
            onClick={(): void => onSelectDate(item)}
            className={classNames(sharedStyles['select-option'], styles.button, {
              [sharedStyles['select-option--active']]: selectedButton === item.title
            })}
            data-testid={`filter-date-tab-${item.title}`}
          >
            {item.title}
          </div>
        ))}
      </div>
      {selectedButton === 'Custom' && renderCustomDateRange()}
    </div>
  );
}

export default FilterByDate;
