import { SortSettingType } from 'ui-lib-12traits/src/index';

import { AuthState } from '@/redux/auth/types';
import { BuildState } from '@/redux/frequency/types';
import { ChartViewEnum } from '@/redux/pages/traitsPage/types';

import { DotUpdatesEnum } from '@/components/Frequency/types';

export const loadState = (): { auth: AuthState } | undefined => {
  const serializedState = localStorage.getItem('state');
  if (serializedState === null) {
    return undefined;
  }
  const result = JSON.parse(serializedState);
  return result ? { auth: { ...result.auth, isLogOut: false } } : result;
};

export const saveState = (state: { auth: AuthState }): void => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem('state', serializedState);
};

const getObjectFromLocalStorage = (objectName: string): any => {
  const savedState = localStorage.getItem(objectName);
  return savedState ? JSON.parse(savedState) : {};
};

export const saveDashboardOverviewBlocksOrder = (gameSlug: string, newOrder: string[]): void => {
  const parsedState = getObjectFromLocalStorage('overviewBlocksOrder');

  const updatedState = {
    ...parsedState,
    [gameSlug]: newOrder
  };
  localStorage.setItem('overviewBlocksOrder', JSON.stringify(updatedState));
};

export const getDashboardOverviewBlocksOrder = (gameSlug: string): string[] => {
  const parsedState = getObjectFromLocalStorage('overviewBlocksOrder');

  return parsedState[gameSlug] || [];
};

export const saveFrequencyOverviewBlocksOrder = (newOrder: string[]): void => {
  localStorage.setItem('frequencyOverviewBlocksOrder', JSON.stringify(newOrder));
};

export const getFrequencyOverviewBlocksOrder = (): string[] =>
  getObjectFromLocalStorage('frequencyOverviewBlocksOrder');

export const saveDashboardSegmentsView = (gameSlug: string, view: string): void => {
  const parsedState = getObjectFromLocalStorage('segmentsView');

  const updatedState = {
    ...parsedState,
    [gameSlug]: view
  };
  localStorage.setItem('segmentsView', JSON.stringify(updatedState));
};

export const getDashboardSegmentsView = (gameSlug: string): string => {
  const parsedState = getObjectFromLocalStorage('segmentsView');

  return parsedState[gameSlug] || '';
};

export const saveDotUpdates = (value: DotUpdatesEnum): void => {
  localStorage.setItem('dotUpdates', value);
};

export const getDotUpdates = (): DotUpdatesEnum | null =>
  localStorage.getItem('dotUpdates') as DotUpdatesEnum | null;

export const saveFrequencyBuild = (build: Omit<BuildState, 'id'> | null): void => {
  localStorage.setItem('frequencyBuild', JSON.stringify(build));
};

export const getFrequencyBuild = (): BuildState | null =>
  localStorage.getItem('frequencyBuild')
    ? JSON.parse(localStorage.getItem('frequencyBuild') as string)
    : null;

export const saveFrequencySavedBuildsSort = (sort: SortSettingType): void => {
  localStorage.setItem('frequencySavedBuildsSort', JSON.stringify(sort));
};

export const getFrequencySavedBuildsSort = (): SortSettingType | null => {
  const value = localStorage.getItem('frequencySavedBuildsSort');
  return value ? JSON.parse(value) : null;
};

export const saveFrequencyDashboardId = (value: string): void => {
  localStorage.setItem('frequencyDashboardId', value);
};

export const getFrequencyDashboardId = (): string | null =>
  localStorage.getItem('frequencyDashboardId') || null;

export const saveSSOLoginIdp = (value: string): void => {
  localStorage.setItem('ssoLoginIdp', value);
};

export const getSSOLoginIdp = (): string | null => localStorage.getItem('ssoLoginIdp');

export const saveCreativeReviewIsRequested = (value: string): void => {
  localStorage.setItem('creativeReviewIsRequested', value);
};

export const getCreativeReviewIsRequested = (): string | null =>
  localStorage.getItem('creativeReviewIsRequested');

export const saveIsFoldedHomeGraph = (value: boolean): void => {
  localStorage.setItem('isFoldedHomeGraph', `${value}`);
};

export const getIsFoldedHomeGraph = (): boolean =>
  (JSON.parse(localStorage.getItem('isFoldedHomeGraph') as string) as boolean) || false;

export const saveAssessmentFTUEStep = (value: string): void => {
  localStorage.setItem('assessmentFTUEStep', value);
};

export const getAssessmentFTUEStep = (): string | null =>
  localStorage.getItem('assessmentFTUEStep');

export const saveReferrerUrl = (url: string): void => localStorage.setItem('referrer', url);

export const getReferrerUrl = (): string | null => localStorage.getItem('referrer');

export const saveLastVisitedUrl = (url: string): void => localStorage.setItem('lastVisited', url);

export const getLastVisitedUrl = (): string | null => localStorage.getItem('lastVisited');

export const saveActiveMenuTab = (value: string): void =>
  localStorage.setItem('activeMenuTab', value);

export const getActiveMenuTab = (): string | null => localStorage.getItem('activeMenuTab');

export const getActiveMenuTabForTitle = (): string => {
  const activeTab = localStorage.getItem('activeMenuTab');

  return activeTab ? `${activeTab[0].toUpperCase()}${activeTab.slice(1)}` : '';
};

export const saveSelectedTraitsDashboardSlug = (gameSlug: string): void =>
  localStorage.setItem('selectedTraitsDashboardSlug', gameSlug);

export const getSelectedTraitsDashboardSlug = (): string | null =>
  localStorage.getItem('selectedTraitsDashboardSlug');

export const saveSelectedNavigatorDashboardSlug = (navigatorDashboardSlug: string): void =>
  localStorage.setItem('SelectedNavigatorDashboardSlug', navigatorDashboardSlug);

export const getSelectedNavigatorDashboardSlug = (): string | null =>
  localStorage.getItem('selectedNavigatorDashboardSlug');

export const getSelectedGameSlug = (): string | null => localStorage.getItem('selectedGameSlug');

export const saveSelectedSegment = (value: string): void =>
  localStorage.setItem('selectedSegment', value);

export const getSelectedSegment = (): string | null => localStorage.getItem('selectedSegment');

export const saveSentimentChartHiddenFilters = (value: any): void => {
  localStorage.setItem('sentimentChartHiddenFilters', JSON.stringify(value));
};

export const getSentimentChartHiddenFilters = (): any | null => {
  const value = localStorage.getItem('sentimentChartHiddenFilters');
  return value ? JSON.parse(value) : null;
};

export const saveIsSentimentChartLegendFolded = (value: boolean): void => {
  localStorage.setItem('isSentimentChartLegendFolded', `${value}`);
};

export const getIsSentimentChartLegendFolded = (): boolean =>
  JSON.parse(localStorage.getItem('isSentimentChartLegendFolded') as string) as boolean;

export const saveSentimentChartTimeline = (value: string): void =>
  localStorage.setItem('sentimentChartTimeline', value);

export const getSentimentChartTimeline = (): string | null =>
  localStorage.getItem('sentimentChartTimeline');

export const saveSentimentChartChannel = (value: string): void =>
  localStorage.setItem('sentimentChartChannel', value);

export const getSentimentChartChannel = (): string | null =>
  localStorage.getItem('sentimentChartChannel');

export const saveLastViewedChart = (value: any): void =>
  localStorage.setItem('lastViewedChart', JSON.stringify(value));

export const getLastViewedChart = (): any | null => {
  const value = localStorage.getItem('lastViewedChart');
  return value ? JSON.parse(value) : null;
};

export const saveChartListSort = (sort: string): void => {
  localStorage.setItem('chartListSort', sort);
};

export const getChartListSort = (): string | null => localStorage.getItem('chartListSort');

export const saveChartDateRange = (value: any): void => {
  localStorage.setItem('chartDateRange', JSON.stringify(value));
};

export const getChartDateRange = (): any | null => {
  const value = localStorage.getItem('chartDateRange');
  return value ? JSON.parse(value) : null;
};

export const saveIsSentimentChartGlossaryVisited = (value: boolean): void => {
  localStorage.setItem('isSentimentChartGlossaryVisited', `${value}`);
};

export const getIsSentimentChartGlossaryVisited = (): boolean =>
  JSON.parse(localStorage.getItem('isSentimentChartGlossaryVisited') as string) as boolean;

export const saveTraitsChartView = (view: ChartViewEnum): void => {
  localStorage.setItem('traitsChartView', view);
};

export const getTraitsChartView = (): ChartViewEnum | null =>
  localStorage.getItem('traitsChartView') as ChartViewEnum | null;

export const saveImpactIndicatorFilter = (value: string): void =>
  localStorage.setItem('impactIndicatorFilter', value);

export const getImpactIndicatorFilter = (): string | null =>
  localStorage.getItem('impactIndicatorFilter');

export const saveImpactIndicatorSettings = (value: string): void =>
  localStorage.setItem('impactIndicatorSettings', value);

export const getImpactIndicatorSettings = (): string | null =>
  localStorage.getItem('impactIndicatorSettings');

export const saveImpactIndicatorDropdownValue = (value: string): void =>
  localStorage.setItem('impactIndicatorDropdownValue', value);

export const getImpactIndicatorDropdownValue = (): string | null =>
  localStorage.getItem('impactIndicatorDropdownValue');
