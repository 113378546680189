import { type Timeline } from 'ui-lib-12traits/src/index';

import { prepareAction } from '@/redux/helpers';
import { Action } from '@/redux/types';

import {
  type Channel,
  type SentimentFilter,
  RESET_OVERVIEW_PAGE_STATE,
  SET_ACTIVE_PULSE_PERSONA,
  SET_HOVERED_PULSE_PERSONA,
  SET_PULSE_ACTIVE_TIMELINE,
  SET_PULSE_AVAILABLE_TIMELINES,
  SET_PULSE_HOVERED_DATE,
  SET_SENTIMENT_ACTIVE_TIMELINE,
  SET_SENTIMENT_AVAILABLE_TIMELINES,
  SET_SENTIMENT_ACTIVE_CHANNEL,
  SET_SENTIMENT_AVAILABLE_CHANNELS,
  SET_SENTIMENT_HOVERED_DATE,
  SET_SENTIMENT_HOVERED_FILTER,
  SET_SENTIMENT_ACTIVE_FILTERS,
  LOAD_TOP_GAMES,
  LOAD_TOP_GAMES_REQUEST,
  LOAD_TOP_GAMES_ERROR,
  LOAD_TOP_GAMES_SUCCESS,
  LOAD_PCS,
  LOAD_PCS_REQUEST,
  LOAD_PCS_SUCCESS,
  LOAD_PCS_ERROR,
  LOAD_KPIS_LEADERBOARD,
  LOAD_KPIS_LEADERBOARD_SUCCESS,
  LOAD_KPIS_LEADERBOARD_REQUEST,
  LOAD_KPIS_LEADERBOARD_ERROR,
  LOAD_EXPERIENCE_REQUEST,
  LOAD_EXPERIENCE,
  LOAD_EXPERIENCE_SUCCESS,
  LOAD_EXPERIENCE_ERROR,
  LOAD_ENGAGEMENT_RATE,
  LOAD_ENGAGEMENT_RATE_REQUEST,
  LOAD_ENGAGEMENT_RATE_SUCCESS,
  LOAD_ENGAGEMENT_RATE_ERROR,
  LOAD_SENTIMENT_REQUEST,
  LOAD_SENTIMENT_SUCCESS,
  LOAD_SENTIMENT_ERROR,
  LOAD_UNIQUE_AND_SIMILAR,
  LOAD_UNIQUE_AND_SIMILAR_REQUEST,
  LOAD_UNIQUE_AND_SIMILAR_SUCCESS,
  LOAD_UNIQUE_AND_SIMILAR_ERROR,
  LOAD_GENRE_AVERAGES,
  LOAD_GENRE_AVERAGES_REQUEST,
  LOAD_GENRE_AVERAGES_SUCCESS,
  LOAD_GENRE_AVERAGES_ERROR,
  SET_SHOWING_HEADERBAR,
  SET_SELECTED_PULSE_PERSONA,
  SET_ACTIVE_PULSE_PERSONAS_PER_SEGMENT,
  SET_ACTIVE_PULSE_PERSONAS_PER_SEGMENT_SUCCESS,
  LOAD_MARKET_SIZE_DATA,
  SET_MARKET_SIZE_DATA,
  RESET_MARKET_SIZE_DATA,
  SET_SENTIMENT_PREV_GAME_JWT,
  SET_SENTIMENT_PREV_ACTIVE_CHANNEL,
  SET_SENTIMENT_PREV_ACTIVE_TIMELINE,
  LOAD_MARKET_GENRES_DATA,
  SET_MARKET_GENRES_DATA,
  LOAD_MARKET_SPEND_DATA,
  SET_MARKET_SPEND_DATA,
  SET_MARKET_PERSONA_NUMBER,
  LOAD_MARKET_GENRES_ERROR,
  LOAD_MARKET_SIZE_ERROR,
  LOAD_MARKET_SPEND_ERROR,
  RESET_MARKET_DATA,
  RESET_MARKET_SPEND_DATA,
  RESET_MARKET_GENRES_DATA
} from './types';

export const resetState = (): Action => prepareAction(RESET_OVERVIEW_PAGE_STATE);

export const setHoveredPulsePersona = (value: string | null): Action =>
  prepareAction(SET_HOVERED_PULSE_PERSONA, { value });

export const setActivePulsePersona = (value: string, segmentId: string): Action =>
  prepareAction(SET_ACTIVE_PULSE_PERSONA, { value, segmentId });

export const setSelectedPulsePersona = (value: string | null): Action =>
  prepareAction(SET_SELECTED_PULSE_PERSONA, { value });

export const setPulseActiveTimeline = (activeTimeline: Timeline): Action =>
  prepareAction(SET_PULSE_ACTIVE_TIMELINE, { activeTimeline });

export const setPulseAvailableTimelines = (availableTimelines: Array<Timeline>): Action =>
  prepareAction(SET_PULSE_AVAILABLE_TIMELINES, { availableTimelines });

export const setPulseHoveredDate = (hoveredDate: string | null): Action =>
  prepareAction(SET_PULSE_HOVERED_DATE, { hoveredDate });

export const loadTopGames = (personaCode: any): Action =>
  prepareAction(LOAD_TOP_GAMES, { personaCode });

export const loadTopGamesRequest = (): Action => prepareAction(LOAD_TOP_GAMES_REQUEST);

export const loadTopGamesSuccess = (data: any): Action =>
  prepareAction(LOAD_TOP_GAMES_SUCCESS, { data });

export const loadTopGamesError = (error: string): Action =>
  prepareAction(LOAD_TOP_GAMES_ERROR, { error });

export const loadPcs = (segmentId: string | undefined): Action =>
  prepareAction(LOAD_PCS, { segmentId });

export const loadPcsRequest = (): Action => prepareAction(LOAD_PCS_REQUEST);

export const loadPcsSuccess = (data: any): Action => prepareAction(LOAD_PCS_SUCCESS, { data });

export const loadPcsError = (error: string): Action => prepareAction(LOAD_PCS_ERROR, { error });

export const loadKpisLeaderboard = (kpiCode: string, segmentId: string | undefined): Action =>
  prepareAction(LOAD_KPIS_LEADERBOARD, { kpiCode, segmentId });

export const loadKpisLeaderboardRequest = (): Action =>
  prepareAction(LOAD_KPIS_LEADERBOARD_REQUEST);

export const loadKpisLeaderboardSuccess = (data: any): Action =>
  prepareAction(LOAD_KPIS_LEADERBOARD_SUCCESS, { data });

export const loadKpisLeaderboardError = (error: string): Action =>
  prepareAction(LOAD_KPIS_LEADERBOARD_ERROR, { error });

export const loadExperience = (personaCode: any): Action =>
  prepareAction(LOAD_EXPERIENCE, { personaCode });

export const loadExperienceRequest = (): Action => prepareAction(LOAD_EXPERIENCE_REQUEST);

export const loadExperienceSuccess = (data: any): Action =>
  prepareAction(LOAD_EXPERIENCE_SUCCESS, { data });

export const loadExperienceError = (error: string): Action =>
  prepareAction(LOAD_EXPERIENCE_ERROR, { error });

export const loadEngagementRate = (timeline: Timeline): Action =>
  prepareAction(LOAD_ENGAGEMENT_RATE, { timeline });

export const loadEngagementRateRequest = (): Action => prepareAction(LOAD_ENGAGEMENT_RATE_REQUEST);

export const loadEngagementRateSuccess = (data: any): Action =>
  prepareAction(LOAD_ENGAGEMENT_RATE_SUCCESS, { data });

export const loadEngagementRateError = (error: string): Action =>
  prepareAction(LOAD_ENGAGEMENT_RATE_ERROR, { error });

export const setSentimentActiveTimeline = (activeTimeline: Timeline): Action =>
  prepareAction(SET_SENTIMENT_ACTIVE_TIMELINE, { activeTimeline });

export const setSentimentAvailableTimelines = (availableTimelines: Array<Timeline>): Action =>
  prepareAction(SET_SENTIMENT_AVAILABLE_TIMELINES, { availableTimelines });

export const setSentimentActiveChannel = (activeChannel: Channel): Action =>
  prepareAction(SET_SENTIMENT_ACTIVE_CHANNEL, { activeChannel });

export const setSentimentPrevActiveChannel = (activeChannel: Channel): Action =>
  prepareAction(SET_SENTIMENT_PREV_ACTIVE_CHANNEL, activeChannel);

export const setSentimentPrevActiveTimeline = (activeTimeline: Channel): Action =>
  prepareAction(SET_SENTIMENT_PREV_ACTIVE_TIMELINE, activeTimeline);

export const setSentimentAvailableChannels = (availableChannels: Channel[]): Action =>
  prepareAction(SET_SENTIMENT_AVAILABLE_CHANNELS, { availableChannels });

export const setSentimentHoveredDate = (hoveredDate: string | null): Action =>
  prepareAction(SET_SENTIMENT_HOVERED_DATE, { hoveredDate });

export const setSentimentHoveredFilter = (hoveredFilter: SentimentFilter | null): Action =>
  prepareAction(SET_SENTIMENT_HOVERED_FILTER, { hoveredFilter });

export const setSentimentActiveFilters = (filter: SentimentFilter, status: boolean): Action =>
  prepareAction(SET_SENTIMENT_ACTIVE_FILTERS, { filter, status });

export const setSentimentPrevGameJwt = (jwt: string): Action =>
  prepareAction(SET_SENTIMENT_PREV_GAME_JWT, jwt);

export const loadSentimentRequest = (): Action => prepareAction(LOAD_SENTIMENT_REQUEST);

export const loadSentimentSuccess = ({ data, releases }: { data: any; releases: any }): Action =>
  prepareAction(LOAD_SENTIMENT_SUCCESS, { data, releases });

export const loadSentimentError = (error: string): Action =>
  prepareAction(LOAD_SENTIMENT_ERROR, { error });

export const loadUniqueAndSimilar = (personaCode: any): Action =>
  prepareAction(LOAD_UNIQUE_AND_SIMILAR, { personaCode });

export const loadUniqueAndSimilarRequest = (): Action =>
  prepareAction(LOAD_UNIQUE_AND_SIMILAR_REQUEST);

export const loadUniqueAndSimilarSuccess = (data: any): Action =>
  prepareAction(LOAD_UNIQUE_AND_SIMILAR_SUCCESS, { data });

export const loadUniqueAndSimilarError = (error: string): Action =>
  prepareAction(LOAD_UNIQUE_AND_SIMILAR_ERROR, { error });

export const loadGenreAverages = (
  gameId: string,
  traits: string[],
  personaCode: any,
  segmentId?: string
): Action => prepareAction(LOAD_GENRE_AVERAGES, { personaCode, segmentId, traits, gameId });

export const loadGenreAveragesRequest = (): Action => prepareAction(LOAD_GENRE_AVERAGES_REQUEST);

export const loadGenreAveragesSuccess = (data: any): Action =>
  prepareAction(LOAD_GENRE_AVERAGES_SUCCESS, { data });

export const loadGenreAveragesError = (error: string): Action =>
  prepareAction(LOAD_GENRE_AVERAGES_ERROR, { error });

export const setShowingHeaderbar = (isShowing: boolean): Action =>
  prepareAction(SET_SHOWING_HEADERBAR, isShowing);

export const setActivePulsePersonasPerSegment = (): Action =>
  prepareAction(SET_ACTIVE_PULSE_PERSONAS_PER_SEGMENT);

export const setActivePulsePersonasPerSegmentSuccess = (
  data: { [key: string]: string[] } | null
): Action => prepareAction(SET_ACTIVE_PULSE_PERSONAS_PER_SEGMENT_SUCCESS, { data });

export const loadMarketSizeData = (cluster: any): Action =>
  prepareAction(LOAD_MARKET_SIZE_DATA, { cluster });

export const loadMarketGenresData = (cluster: any): Action =>
  prepareAction(LOAD_MARKET_GENRES_DATA, { cluster });

export const setMarketGenresData = (genres: any): Action =>
  prepareAction(SET_MARKET_GENRES_DATA, { genres });

export const setMarketSizeData = (data: any): Action =>
  prepareAction(SET_MARKET_SIZE_DATA, { data });

export const resetMarketData = (): Action => prepareAction(RESET_MARKET_DATA);

export const resetMarketSizeData = (): Action => prepareAction(RESET_MARKET_SIZE_DATA);

export const resetMarketSpendData = (): Action => prepareAction(RESET_MARKET_SPEND_DATA);

export const resetMarketGenresData = (): Action => prepareAction(RESET_MARKET_GENRES_DATA);

export const loadMarketSpendData = (cluster: any): Action =>
  prepareAction(LOAD_MARKET_SPEND_DATA, { cluster });

export const setMarketSpendData = (spend: any): Action =>
  prepareAction(SET_MARKET_SPEND_DATA, { spend });

export const setMarketPersonaNumber = (personas: any): Action =>
  prepareAction(SET_MARKET_PERSONA_NUMBER, { personas });

export const loadMarketSpendError = (error: string): Action =>
  prepareAction(LOAD_MARKET_SPEND_ERROR, { error });

export const loadMarketSizeError = (error: string): Action =>
  prepareAction(LOAD_MARKET_SIZE_ERROR, { error });

export const loadMarketGenresError = (error: string): Action =>
  prepareAction(LOAD_MARKET_GENRES_ERROR, { error });
