import { Segment } from '../../types';

export const LOAD_SEGMENTS = 'LOAD_SEGMENTS';
export const LOAD_SEGMENTS_REQUEST = 'LOAD_SEGMENTS_REQUEST';
export const LOAD_SEGMENTS_SUCCESS = 'LOAD_SEGMENTS_SUCCESS';
export const LOAD_SEGMENTS_ERROR = 'LOAD_SEGMENTS_ERROR';
export const ADD_NEW_SEGMENT = 'ADD_NEW_SEGMENT';
export const ADD_NEW_SEGMENT_REQUEST = 'ADD_NEW_SEGMENT_REQUEST';
export const ADD_NEW_SEGMENT_SUCCESS = 'ADD_NEW_SEGMENT_SUCCESS';
export const ADD_NEW_SEGMENT_ERROR = 'ADD_NEW_SEGMENT_ERROR';
export const SET_SHOW_CREATE_SEGMENT = 'SET_SHOW_CREATE_SEGMENT';
export const SET_SHOW_CREATED_CONFIRMATION = 'SET_SHOW_CREATED_CONFIRMATION';

export interface SegmentsPageState {
  list: Segment[];
  loading: boolean;
  error: boolean;
  errorMsg: string;
  showCreateSegment: boolean;
  showCreatedConfirmation: boolean;
}

export interface Filter {
  key?: string;
  type: string;
  min?: number;
  max?: number;
  values?: string[];
  traits?: string[];
  question_id?: number; // eslint-disable-line camelcase
  option_ids?: number[]; // eslint-disable-line camelcase
  numeric_values?: any; // eslint-disable-line camelcase
}
