import ConfettiGenerator from 'confetti-js';
import React, { ReactElement, useEffect, useRef } from 'react';


import styles from './SurveyConfetti.module.scss';

function SurveyConfetti({ delay = 0 }: { delay?: number }): ReactElement {
  const ref = useRef();
  useEffect(() => {
    const t = setTimeout(() => {
      const confettiSettings = {
        target: 'confetti-canvas',
        max: 200,
        rotate: true,
        props: ['square'],
        clock: 33,
        colors: [
          [179, 173, 255],
          [152, 145, 252],
          [17, 144, 201]
        ],
        respawn: false
      };
      ref.current = new ConfettiGenerator(confettiSettings);
      ref.current.render();
    }, delay);

    return () => {
      clearTimeout(t);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <canvas id="confetti-canvas" />
    </div>
  );
}

export default SurveyConfetti;
