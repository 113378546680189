/* eslint-disable no-param-reassign */
import { useEffect, useMemo, useRef, useState } from 'react';
import { GlobalButton } from 'ui-lib-12traits/src/index';
import filter from 'ramda.filter';
import sort from 'ramda.sort';
import prop from 'ramda.prop';
import { useQuery } from '@tanstack/react-query';
import isEqual from 'lodash.isequal';
import CheckIcon from 'ui-lib-12traits/src/Icons/svg/frequency/check.svg?component';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import camelcaseKeys from 'camelcase-keys';
import { useAppSelector } from '@/hooks/useApp';
import {
  filterNodesWithParentAndChildren,
  normalizeTaxonomyTree
} from '@/features/user-preferences/utils';
import useGetTaxonomy from '@/features/user-preferences/api/use-get-taxonomy';
import { TaxonomyNode } from '@/features/user-preferences/types';
import Select from '@/features/common/components/Select';
import { StaticValues } from '@/pages/Navigator/AssessmentAutomationV2/types';
import { getGameProfile } from '@/features/user-preferences/api/api';
import { fetchAssessments } from '@/services/Api/AssessmentSurveyService';
import { selectGameSlug } from '@/redux/game/selectors';
import StepContainer from '@/components/AutomateAssessment/StepContainer';
import { map, pipe } from '@/components/AutomateAssessment/AudienceStep/AudienceStep';
import styles from './index.module.scss';

const NewFeatureStep = ({
  onNext,
  isActive,
  setActiveStep,
  values = {} as StaticValues,
  title,
  withNumber = true,
  template,
  onClose,
  status,
  updateStepValues,
  setErrorCount,
  errorCount,
  order,
  baseStepValues,
  ...step
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { id: assessmentID } = useParams<{ id: string }>();
  const errorCountRef = useRef(errorCount);
  const slug = useAppSelector(selectGameSlug);

  if (!isEqual(errorCountRef.current, errorCount)) {
    errorCount.current = errorCount;
  }

  const { data: featuresData, isLoading: isLoadingExisting } = useQuery({
    queryKey: ['selectedGame'],
    queryFn: getGameProfile,
    enabled: !!slug
  });

  const { data: assessments, isLoadingAssessments } = useQuery({
    queryKey: ['assessments'],
    queryFn: fetchAssessments
  });

  const recentAssessments = (assessments?.data ?? [])
    .filter(
      item =>
        item.assessmentType === '360' &&
        item.id !== assessmentID &&
        item?.projectName === baseStepValues.projectName &&
        item?.dailyResponses?.total
    )
    .sort((a, b) => (+a.updatedAt > +b.updatedAt ? 1 : -1));

  const dauMax = Math.floor(
    (recentAssessments[recentAssessments.length - 1]?.dailyResponses?.total || 650) / 130
  );

  const { data, isLoading } = useGetTaxonomy({
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    select: data => {
      const featuresList = pipe(
        (t: Array<TaxonomyNode>) => normalizeTaxonomyTree(t),
        filterNodesWithParentAndChildren
      )(data.data.items);

      const normalizedData = data?.data?.items.reduce(
        (acc, curr) => [
          ...acc,
          ...curr.children.reduce(
            (acc, curr) => [...acc, ...(curr.children.length > 0 ? curr.children : [curr])],
            []
          )
        ],
        []
      );

      return {
        ...data,
        data: { normalizedData, featuresList, originalData: data.data.items }
      };
    }
  });

  useEffect(() => {
    const { newFeaturesIds } = camelcaseKeys(values);
    if (status === 'completed' && newFeaturesIds) {
      const features = data?.data?.normalizedData.filter(d => newFeaturesIds.includes(d.id));

      setSelectedOptions(features ?? []);
    }
  }, [status, data?.data?.normalizedData, values.newFeaturesIds]);

  useEffect(() => {
    setErrorCount({
      ...errorCount,
      [template]: selectedOptions?.length >= 5 && selectedOptions?.length <= dauMax
    });
  }, [errorCountRef.current, selectedOptions?.length, dauMax, isLoading, isLoadingAssessments]);

  const options = useMemo(() => {
    const existingIds = (featuresData?.data.features ?? []).map(f => f.id);
    return (data?.data?.normalizedData ?? [])
      .filter(d => !(existingIds ?? [])?.includes(d.id))
      .map(d => ({
        ...d,
        value: d.id,
        checked: !!selectedOptions.find(o => o.id === d.id),
        label: d.name
      }));
  }, [data?.data?.normalizedData, selectedOptions, featuresData?.data.features]);

  return (
    <StepContainer
      title={title}
      titleExpanded={`What ${
        dauMax === 5 ? 5 : `5-${dauMax}`
      } features are you considering for your game?`}
      id={order}
      isExpanded={isActive}
      template={template}
      onExpand={setActiveStep}
      withNumber
      collapsedElement={
        <div className={styles.card__value}>
          <span>{selectedOptions.length} features added</span>
        </div>
      }
      invalid={errorCount?.[template] === false}
    >
      <div className={styles.container}>
        <div className={styles.step_header}>
          <div className={styles.label}>Features:</div>{' '}
          {selectedOptions.length > 0 && selectedOptions.length < 5 && (
                <p className={styles.error}>{dauMax === 5 ? 'Select 5 features' : 'A minimum of 5 is required'}</p>
              )}
        </div>
        {!isLoading && !isLoadingExisting && (
          <Select
            options={options}
            resetCondition={!!selectedOptions.length}
            multiple
            groupBy={option => option.name}
            dynamicDropdownPosition={false}
            listContainerProps={{
              className: classNames(styles.list, {
                [styles.list_disabled]: selectedOptions.length === dauMax
              })
            }}
            as="search"
            placeholder="Select features"
            labelProps={{
              className: classNames(styles.head, {
                [styles.head_invalid]: selectedOptions.length > 0 && selectedOptions.length < 5
              })
            }}
            wrapperProps={{ className: styles.wrapper }}
            inputProps={{
              className: styles['audience-input']
            }}
            renderTags={({ options, removeTag }) =>
              pipe(
                filter(prop('checked')),
                sort((a, b) => a.label.localeCompare(b.label)),
                map((option: any, i) => (
                  <div key={option.id} className={styles.feature_tag}>
                    <div className={styles.name}>{option.label}</div>
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedOptions(selectedOptions.filter(o => o.id !== option.id));
                        updateStepValues(
                          {
                            id: step.id,
                            new_features_ids: (
                              selectedOptions.filter(o => o.id !== option.id) ?? []
                            ).map(f => f.id)
                          },
                          template
                        );
                        removeTag(option);
                      }}
                    />
                  </div>
                ))
              )(options)
            }
            renderOption={option => (
              <div
                className={classNames(styles.option, {
                  [styles.option_selected]: selectedOptions.find(o => o.id === option.id)
                })}
                key={option.id}
              >
                <div>{option.name}</div>{' '}
                <div className={styles.category}>
                  <div className={styles.category_name}>
                    {
                      data?.data?.originalData.filter(
                        d =>
                          d.children.find(c => c.id === option?.parent_id) ||
                          d.id === option?.parent_id
                      )?.[0]?.name
                    }
                  </div>
                  <div className={styles.check}>
                    <CheckIcon width={14.5} />
                  </div>
                </div>
              </div>
            )}
            onSelect={option => {
              if (selectedOptions.find(o => o.id === option.id)) {
                setSelectedOptions(selectedOptions.filter(o => o.id !== option.id));
                updateStepValues(
                  {
                    id: step.id,
                    new_features_ids: (selectedOptions.filter(o => o.id !== option.id) ?? []).map(
                      f => f.id
                    )
                  },
                  template
                );
              } else {
                setSelectedOptions([...selectedOptions, option]);
                updateStepValues(
                  {
                    id: step.id,
                    new_features_ids: ([...selectedOptions, option] ?? []).map(f => f.id)
                  },
                  template
                );
              }
            }}
          />
        )}

        {selectedOptions.length >= 5 && selectedOptions.length <= dauMax && (
          <GlobalButton
            title="Next"
            className={styles.button}
            onClick={() =>
              onNext({
                new_features_ids: selectedOptions.map(o => o.id)
              })
            }
          />
        )}
      </div>
    </StepContainer>
  );
};

export default NewFeatureStep;
