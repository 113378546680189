/* eslint-disable react/no-unstable-nested-components */
import classNames from 'classnames';
import { useMemo } from 'react';


import ErrorIcon from 'ui-lib-12traits/src/Icons/svg/common/error.svg?component';
import { GlobalButton } from 'ui-lib-12traits/src/index';

import { useI18n } from '@/i18n';
import {
  StaticValues,
  Step,
  SubmissionValues,
  Template,
  templates
} from '@/pages/Navigator/AssessmentAutomationV2/types';

import StepContainer from '../StepContainer';

import SubmittingStepI18n from './SubmittingStep.i18n';
import styles from './SubmittingStep.module.scss';




type Props = {
  baseStepValues: StaticValues;
  isActive: boolean;
  onNext: (v: SubmissionValues, options: any) => void;
  errorCount: any;
  totalSteps: Step[];
  goTo: (t: Template, options: Step) => void;
  stepsValues: Step[];
};

function SubmittingStep({
  baseStepValues,
  onNext,
  goTo,
  errorCount,
  stepsValues,
  totalSteps
}: Props) {
  const i18n = useI18n(SubmittingStepI18n);
  const dangerouslySetInnerHTML = useMemo(
    () => ({
      __html: i18n.title(baseStepValues, stepsValues)
    }),
    [baseStepValues, i18n, stepsValues]
  );

  const invalidSteps = errorCount
    ? totalSteps.filter(step => errorCount[step.template] === false)
    : [];

  return (
    <StepContainer
      template={templates.submit}
      title=""
      isExpanded
      cardStyle={{
        background:
          'radial-gradient(206.51% 160.54% at 99.19% 126.23%, #005975 9.82%, #005173 42.71%, #00274D 100%)',
        overflow: 'hidden'
      }}
    >
      <div className={styles.container}>
        <p className={styles.content} dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        <GlobalButton
          className={classNames(styles.button, {
            [styles.button_disabled]: invalidSteps.length > 0
          })}
          title={
            <span>
              <ErrorIcon width={24} /> {i18n.actions.submit.title(invalidSteps)}
            </span>
          }
          onClick={() =>
            invalidSteps.length > 0
              ? goTo(invalidSteps[0].template, invalidSteps[0])
              : onNext(null, { template: templates.submit })
          }
        />
      </div>
    </StepContainer>
  );
}

export default SubmittingStep;
