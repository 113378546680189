import AssessmentSurveyApiService from '../AssessmentSurveyApiService';
import { ApiResponse } from '../types';

import { Assessment } from './types';

// export const createTeamAssessmentSurvey = (data: SurveyBody): Promise<ApiResponse<{ id: string }>> =>
//   new AssessmentSurveyApiService({ version: 'v2', shouldSendSnakeCase: true })
//     .setEndpoint('/surveys')
//     .setBody(data)
//     .post();

// export const updateTeamAssessmentSurvey = (id: string, data: SurveyBody): Promise<ApiResponse<any>> =>
//   new AssessmentSurveyApiService({ version: 'v2', shouldSendSnakeCase: true })
//     .setEndpoint(`/surveys/${id}`)
//     .setBody(data)
//     .put();

// export const cancelTeamAssessment = async (id: string): Promise<void> =>
//   new AssessmentSurveyApiService({ version: 'v2' }).setEndpoint(`/surveys/${id}`).delete();

export const fetchTeamAssessments = async (params: {
  limit?: number;
  offset?: number;
  sortField?: string;
  sortDirection?: 'asc' | 'desc';
  query?: string;
  id?: string;
}): Promise<ApiResponse<Assessment[]>> =>
  new AssessmentSurveyApiService({ version: 'v2', shouldSendSnakeCase: true })
    .setEndpoint(`/teamAssessments`)
    .setParams(params)
    .get();

export const fetchTeamAssessment = async (params: {
  limit?: number;
  offset?: number;
  sortField?: string;
  sortDirection?: 'asc' | 'desc';
  query?: string;
  id?: string;
}): Promise<ApiResponse<Assessment[]>> =>
  new AssessmentSurveyApiService({ version: 'v2', shouldSendSnakeCase: true })
    .setEndpoint(`/team-assessment/id/${params.id}`)
    .get();
