import { Step } from '@/pages/Navigator/AssessmentAutomationV2/types';

export default () => ({
  title: ({ projectName }, stepsValues) => {
    const name = stepsValues?.ASSESSMENT_STEP_STATIC?.projectName || projectName;

    return `Nice, we have all we need to<br />build an assessment for<br /><span>${name}!</span>`;
  },
  actions: {
    submit: {
      title: (invalidSteps: Step[]) => {
        const stepNumbers = invalidSteps.map(({ order }) => order + 1).join(', ');

        return !invalidSteps?.length 
          ? 'Submit assessment request'
          : `Complete question${invalidSteps.length > 1 ? 's' : ''} ${stepNumbers} to submit`;
      }
    }
  }
});
