import { call, put, takeLatest, all, select } from 'redux-saga/effects';

import historyService from '@/services/HistoryService';

import {
  setGameSlug,
  setDashboardConfig,
  unsetGameSlug,
  resetDashboardConfig
} from '@/redux/game/actions';
import { Game } from '@/redux/games/types';
import { Action } from '@/redux/types';

import { selectGames } from '../games/selectors';
import { setActivePulsePersonasPerSegmentSuccess as resetActivePulsePersonasPerSegmentSuccess } from '../pages/overviewPage/actions';

import { mapDataToDashboardConfig } from './mappers';
import { LOAD_GAME, SET_GAME, RESET_GAME } from './types';


const forwardToCatalogue = () => {
  historyService.getHistory().push('/');
};

const selectGame = (games: Game[], gameSlug: string): Game | undefined =>
  games.find((item: { slug: string }) => item.slug === gameSlug);

function* loadGame({ payload }: Action) {
  try {
    const games = yield select(selectGames);

    const game: Game = yield call(selectGame, games, payload.gameSlug);

    if (!game) {
      throw Error(`No Game with slug '${payload.gameSlug}'`);
    }

    if (game.status !== 0) {
      forwardToCatalogue();
    } else {
      const dashboardConfig = mapDataToDashboardConfig(game);

      yield all([put(setGameSlug(game.slug)), put(setDashboardConfig(dashboardConfig))]);
    }
  } catch (e) {
    yield call(forwardToCatalogue);
  }
}

function* setGame({ payload }: Action) {
  yield put(setGameSlug(payload.gameSlug));
  const games: Game[] = yield select(state => state.games.list);
  const game: Game = yield call(selectGame, games, payload.gameSlug);
  if (!game) {
    throw Error(`No Game with slug '${payload.gameSlug}'`);
  }

  const dashboardConfig = mapDataToDashboardConfig(game);

  yield put(setDashboardConfig(dashboardConfig));
}

function* resetGame() {
  yield put(unsetGameSlug());
  yield put(resetDashboardConfig());
  yield put(resetActivePulsePersonasPerSegmentSuccess(null));
}

function* Saga() {
  yield takeLatest(LOAD_GAME, loadGame);
  yield takeLatest(SET_GAME, setGame);
  yield takeLatest(RESET_GAME, resetGame);
}

export default Saga;
