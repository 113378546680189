import type { ComponentType } from 'react';

import { Route } from 'react-router-dom';

interface Props {
  page: ComponentType<any>;
  path: string | string[];
}

export function AliasRoute({ page: component, path }: Props) {
  return <Route component={component} path={path} exact />
}

export default AliasRoute;
