import {
  PossibleConfigurations,
  SocialListeningIntegrationSources,
  SocialListeningSources,
  SocialListeningState,
  SocialListeningStateBase
} from './types';

export const prepareSourceUpdate =
  (
    newIntegrationData: PossibleConfigurations[SocialListeningSources],
    sources: SocialListeningIntegrationSources
  ) =>
  (integrationToUpdate: SocialListeningSources) =>
    updateSingleSource(integrationToUpdate, newIntegrationData, sources);

export const updateSingleSource = (
  integrationToUpdate: SocialListeningSources,
  newIntegrationData: PossibleConfigurations[typeof integrationToUpdate],
  sources: SocialListeningIntegrationSources
): SocialListeningIntegrationSources => {
  if (!sources) {
    return [
      {
        socialListeningSource: integrationToUpdate,
        enabled: false,
        config: newIntegrationData as any
      }
    ];
  }
  if (!sources.find(source => source.socialListeningSource === integrationToUpdate)) {
    return [
      ...sources,
      {
        socialListeningSource: integrationToUpdate,
        enabled: false,
        config: newIntegrationData as any
      }
    ];
  }
  return sources.map(source => {
    if (source.socialListeningSource === integrationToUpdate) {
      return {
        socialListeningSource: source.socialListeningSource,
        enabled: source.enabled,
        config: { ...(newIntegrationData as any) }
      };
    }
    return source;
  });
};

export const enableNewSources = (state: SocialListeningStateBase): SocialListeningStateBase => ({
  ...state,
  sources: state.sources.map(source => ({
    ...source,
    enabled: true
  }))
});

export const removeIntegrationSource = (
  state: SocialListeningState,
  payload: SocialListeningSources
): SocialListeningState => ({
  ...state,
  sources: state.sources.filter(source => source.socialListeningSource !== payload)
});
