/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  deleteAssessmentSurvey,
  getAssessmentSurvey,
  submitAssessmentSurvey,
  updateAssessment
} from '@/services/Api/AssessmentSurveyService';

import { setError } from '@/redux/errors/actions';

import { ErrorEnum } from '@/components/Error/types';

import {
  statuses,
  type AssessmentID,
  type Step,
  type Template,
  type TemplateValues,
  type RewardValues,
  templates
} from './types';

type FormState = {
  fetching: boolean;
  submitted: boolean;
  error: { error?: Error; message: string };
};

type AssessmentMetaData = {
  id: AssessmentID;
  status: string;
  type: string;
  contentType: string;
};

export default function useMultistepForm() {
  const { assessmentID, h, l } = useRouter();
  const searchParams = new URLSearchParams(l.search);
  const dispatch = useDispatch();

  // * form state
  const [formState, setFormState] = useState<FormState>({
    fetching: true,
    submitted: false,
    error: { message: '' }
  });

  const [errorCount, setErrorCount] = useState({});

  // * steps state
  const [assessmentMetaData, setAssessmentMetaData] = useState<AssessmentMetaData>();
  const [totalSteps, setTotalSteps] = useState<Step[]>([]);
  const [prevSteps, setPrevSteps] = useState<Step[]>([]);
  const [currentStep, setCurrentStep] = useState<Step>({
    template: templates.static,
    order: 0,
    required: false,
    skipped: false,
    status: statuses.pristine,
    values: {} as TemplateValues[Template]
  });
  const [activeStepTemplate, setActiveStepTemplate] = useState<Template>(currentStep.template);

  // * steps values
  const [stepsValues, setStepsValues] = useState<any>({});

  function updateUrl(t: Template) {
    setActiveStepTemplate(t);

    searchParams.set('step', t);
    h.replace({ search: `${searchParams}` });
  }

  function updateStepValues(values: TemplateValues[Template], t: Template) {
    setStepsValues({ ...stepsValues, [t]: values });
  }

  async function saveAndClose() {
    const values = Object.entries(stepsValues).map(([t, v]: any) => {
      const { id, ...vals } = v;
      return {
        template: t,
        values: vals,
        id
      };
    });

    const promises = values.map((v: any) => updateAssessment(assessmentID, v));

    // * we don't handle error, don't ask me why, that is what is decided 💩
    await Promise.allSettled(promises);
  }

  useEffect(() => {
    (async function fetchAssessment() {
      setFormState({ ...formState, fetching: true });

      try {
        const res = await getAssessmentSurvey(assessmentID);
        const { steps, assessment } = res.data;

        setAssessmentMetaData(assessment);

        const t = searchParams.get('step') as Template;

        const isLastStep = steps.nbTotal === steps.history.length;

        if (isLastStep) {
          setCurrentStep({
            template: templates.submit,
            order: steps.nbTotal + 1,
            required: true,
            skipped: false,
            status: statuses.pristine,
            values: (steps?.previous?.values ?? {}) as RewardValues
          });
          setPrevSteps(steps.history);

          updateUrl(t || templates.submit);
        } else {
          setCurrentStep(steps.next ?? steps.history.at(-1));
          setPrevSteps(steps.history);

          updateUrl(t || steps.next.template);
        }

        setTotalSteps(steps.skeleton);
      } catch (error) {
        if ((error as Error).message === 'unable to find assessment request') {
          dispatch(setError({ type: ErrorEnum.viewedAssessmentNoLongerExistsOnOpen }));
        } else {
          setFormState({
            ...formState,
            error: { ...formState.error, message: 'Failed to fetch an assessment survey' }
          });
        }
      } finally {
        setFormState({ ...formState, fetching: false });
      }
    })();
  }, []);

  async function next(data: TemplateValues[Template], options: Partial<Step>) {
    if (options.template === templates.submit) {
      try {
        await saveAndClose();
        await submitAssessmentSurvey(assessmentID);

        setFormState({ ...formState, submitted: true });
      } catch (error) {
        if ((error as Error).message === 'unable to find assessment request') {
          dispatch(setError({ type: ErrorEnum.viewedAssessmentNoLongerExistsOnOpen }));
        } else {
          setFormState({
            ...formState,
            error: { error: error as Error, message: 'Failed to submit assessment' }
          });
        }
      }
    } else {
      const payload = {
        ...currentStep,
        ...options,
        values: data
      } as Step;

      // * order is 0 indexed and total is not
      // * last step for backend, but frontend have additional step to submit
      const isLastStep =
        prevSteps.length === totalSteps.length || options.order === totalSteps.length - 1;

      try {
        const res = await updateAssessment(assessmentID, payload);
        const currentStep = res.data.steps.next;
        if (isLastStep) {
          setCurrentStep({
            template: templates.submit,
            order: totalSteps.length,
            status: statuses.pristine,
            skipped: false,
            values: null,
            required: true
          });

          setPrevSteps((prevSteps: Step[]) => {
            // * an user can go to previous step and edit values followed with patching
            // * so update previously filled step
            const i = prevSteps.findIndex((ps: Step) => ps.template === options.template);
            if (i >= 0) {
              prevSteps[i] = payload;
            } else {
              prevSteps.push(payload);
            }

            return prevSteps;
          });
          updateUrl(
            options.order === totalSteps.length - 1 ? templates.submit : currentStep.template
          );
        } else {
          setCurrentStep(currentStep);

          setPrevSteps((prevSteps: Step[]) => {
            // * an user can go to previous step and edit values followed with patching
            // * so update previously filled step
            const i = prevSteps.findIndex((ps: Step) => ps.template === options.template);
            if (i >= 0) {
              prevSteps[i] = payload;
            } else {
              prevSteps.push(payload);
            }

            return prevSteps;
          });

          updateUrl(currentStep.template);
        }
      } catch (error) {
        if ((error as Error).message === 'unable to find assessment request') {
          dispatch(setError({ type: ErrorEnum.viewedAssessmentNoLongerExistsOnOpen }));
        } else {
          setFormState({
            ...formState,
            error: {
              ...formState.error,
              message: (error as Error).message
            }
          });
        }
      }
    }
  }

  async function deleteAssessment() {
    try {
      await deleteAssessmentSurvey(assessmentID);
    } catch (error) {
      setFormState({
        ...formState,
        error: {
          ...formState.error,
          message: 'Could not delete assessment survey. Please try again.'
        }
      });
    }
  }

  return {
    isFetchingAssessment: formState.fetching,
    isSurveySubmitted: formState.submitted,
    formState,

    assessmentMetaData,
    currentStep,
    prevSteps,
    totalSteps,
    setActiveStepTemplate,
    updateStepValues,
    stepsValues,
    errorCount,
    setErrorCount,

    updateUrl,

    isActiveAssessment: Boolean(assessmentID),
    next,
    deleteAssessment,
    activeStepTemplate,
    saveAndClose
  };
}

function useRouter() {
  const { id: assessmentID } = useParams<{ id: AssessmentID }>();
  const h = useHistory();
  const l = useLocation();
  return { assessmentID, h, l };
}
