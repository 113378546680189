import { call, put, select, takeEvery } from 'redux-saga/effects';

import { type Timeline } from 'ui-lib-12traits/src/index';

import {
  getSocialListeningSentimentAvailability,
  SocialListeningSentimentSourceAvailability
} from '@/services/Api/SocialListeningApi/index';
import { getSentimentChartTimeline } from '@/services/LocalStorageService';

import {
  setSentimentActiveChannel,
  setSentimentAvailableChannels,
  setSentimentActiveTimeline,
  setSentimentAvailableTimelines,
  loadSentimentRequest,
  loadSentimentError,
  setSentimentPrevGameJwt,
  setSentimentPrevActiveTimeline
} from '@/redux/pages/overviewPage/actions';
import {
  selectSentimentActiveChannel,
  sentimentPrevJwt
} from '@/redux/pages/overviewPage/selectors';

import { selectGameJwt } from '../game/selectors';

import { LOAD_TIMELINES } from './types';



type Timelines = Timeline[];

const castToChannels = (stack: string[], { source }: SocialListeningSentimentSourceAvailability) =>
  !source ? stack : stack.concat(source);

const toChannels = (sources: SocialListeningSentimentSourceAvailability[] | null) => {
  if (!sources) {
    return null;
  }

  return sources.reduce(castToChannels, []);
};

const ALL_CHANNELS = 'all';

const selectAvailableChannel = (availableChannels: string[]): string =>
  availableChannels.find(channel => channel === ALL_CHANNELS) || availableChannels[0];

const castToTimeline = (stack: Timeline[], [timeline, available]) =>
  !available ? stack : stack.concat(timeline);

const toTimelines = (availability: SocialListeningSentimentSourceAvailability | null) => {
  if (!availability) {
    return null;
  }
  const { source: _source, ...timelines } = availability;
  return Object.entries(timelines).reduce(castToTimeline, []);
};

function* loadTimelines() {
  const prevGameJWT = yield select(sentimentPrevJwt);
  const gameJWT = yield select(selectGameJwt);
  if (prevGameJWT !== gameJWT) {
    yield put(setSentimentPrevGameJwt(gameJWT));
    yield put(loadSentimentRequest());
  }
  try {
    const {
      data: { availability: availableChannelsAndTimelines }
    }: Awaited<ReturnType<typeof getSocialListeningSentimentAvailability>> = yield call(
      getSocialListeningSentimentAvailability
    );

    const availableChannels = toChannels(availableChannelsAndTimelines);

    if (availableChannels?.length) {
      const activeChannel = yield select(selectSentimentActiveChannel);

      const newActiveChannel = availableChannels.includes(activeChannel)
        ? activeChannel
        : selectAvailableChannel(availableChannels);

      const availableTimelines: Timelines = toTimelines(
        availableChannelsAndTimelines.find(({ source }) => source === newActiveChannel)!
      );
      const activeTimeline = getSentimentChartTimeline();

      yield put(setSentimentPrevActiveTimeline(activeTimeline));

      const newActiveTimeline = activeTimeline || availableTimelines[0];

      yield put(setSentimentAvailableChannels(availableChannels));
      yield put(setSentimentActiveChannel(newActiveChannel));
      yield put(setSentimentAvailableTimelines(availableTimelines));
      yield put(setSentimentActiveTimeline(newActiveTimeline));
    } else {
      yield put(setSentimentAvailableChannels([]));
      yield put(setSentimentActiveChannel(null));
      yield put(setSentimentAvailableTimelines([]));
      yield put(setSentimentActiveTimeline(null));
    }
  } catch (error) {
    yield put(loadSentimentError(error.message));
  }
}

function* Saga() {
  yield takeEvery(LOAD_TIMELINES, loadTimelines);
}

export default Saga;
