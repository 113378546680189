import { Action } from '@/redux/types';

import { SET_ERROR, ErrorsState } from './types';

const initialState: ErrorsState = {
  type: null
};

export default (state = initialState, action: Action): ErrorsState => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        type: action.payload.type
      };
    default:
      return state;
  }
};
