import {
  type FormEvent,
  type SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo
} from 'react';

import { useAppDispatch } from '@/hooks/useApp';

import { useAsync } from '@/hooks';

import { consent } from '@/services/Api/UserService';

import { setUserConsent } from '@/redux/user/actions';

import { serialize } from './helpers';

export default () => {
  const ref = useRef<HTMLInputElement>(null);
  const [error, warn] = useState('');
  const dispatch = useAppDispatch();
  const submitting = useAsync();
  const invalid = useMemo(() => !!error, [error]);
  const submit = useCallback(
    ({ email, ...data }) => {
      const marketingEmails = data.hasOwnProperty('marketingEmails');
      const persist = () => dispatch(setUserConsent({ consent: true, email, marketingEmails }));

      return submitting.watch(consent({ email, marketingEmails })).then(persist);
    },
    [dispatch, submitting]
  );
  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      const { target } = event;

      event.preventDefault();

      return target?.checkValidity?.() && submit(serialize(target));
    },
    [submit]
  );
  const onChange = useCallback(({ target: { form } }: FormEvent<HTMLInputElement>) => {
    const {
      elements: {
        email: {
          validity: { valid }
        },
        submit
      }
    } = form;
    const disabled = !valid;

    return Object.assign(submit, { disabled }) && valid && warn('');
  }, []);
  const onClick = useCallback(
    ({
      currentTarget: {
        children: {
          submit: {
            form: {
              elements: {
                email: {
                  validity: { valid }
                }
              }
            },
            disabled
          }
        }
      }
    }: SyntheticEvent) => disabled && !valid && warn('Valid email address required.'),
    []
  );

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return { error, invalid, onChange, onClick, onSubmit, ref, submitting };
};
