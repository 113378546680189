export const getValueWithUnits = (
  value: number,
  units: string,
  fractionDigits: number = 0
): string => {
  if (units === '$') {
    return units + value.toFixed(fractionDigits);
  }
  return value.toFixed(fractionDigits) + units;
};

export const capitalizeFirstLetter = (string: string): string =>
  string.charAt(0).toUpperCase() + string.substring(1);

export const highlightWordinDefinition = (word: string = '', text: string = '') => {
  let result = text;
  const array = word.split(' ');
  array.map(item => {
    const regex = new RegExp(`\\b${item}(?![\\w-])`, 'i');
    const match = result.match(regex);

    if (match) {
      const wrappedMatch = `<span style="font-weight:900">${match[0]}</span>`;
      result = result.replace(new RegExp(`\\b${match[0]}(?![\\w-])`, 'i'), wrappedMatch);
    }
    return item;
  });

  return result;
};
