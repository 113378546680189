import camelcaseKeys from 'camelcase-keys';
import { takeLatest, put, call, select, all } from 'redux-saga/effects';

import { fetchLeaderboardByKpi } from '@/services/Api/CompareService';
import { fetchMarketGenres, fetchMarketSize, fetchMarketSpend } from '@/services/Api/CoreService';
import { fetchGenreAverages } from '@/services/Api/GameService';
import { fetchEngagementChartData } from '@/services/Api/KpisService';
import {
  getSocialListeningSentimentData,
  getSocialListeningReleaseVersions
} from '@/services/Api/SocialListeningApi/index';
import {
  fetchClustersExperience,
  fetchUniqueAndSimilar,
  fetchScores,
  fetchClusterGames,
  fetchClusterFavorites
} from '@/services/Api/SurveyService';

import { selectSegments, selectGameId, selectGameJwt } from '@/redux/game/selectors';
import { selectIsNonGamingCompany } from '@/redux/games/selectors';
import { Action, Segment } from '@/redux/types';


import { SizeItemI, SpendItemI, GenreI } from '@/components/Trait/Overview/MarketSize/types';

import {
  loadTopGamesRequest,
  loadTopGamesError,
  loadTopGamesSuccess,
  loadPcsRequest,
  loadPcsError,
  loadPcsSuccess,
  loadKpisLeaderboardRequest,
  loadKpisLeaderboardSuccess,
  loadKpisLeaderboardError,
  loadExperienceRequest,
  loadExperienceError,
  loadExperienceSuccess,
  loadEngagementRateRequest,
  loadEngagementRateSuccess,
  loadEngagementRateError,
  loadSentimentRequest,
  loadSentimentSuccess,
  loadSentimentError,
  loadUniqueAndSimilarRequest,
  loadUniqueAndSimilarSuccess,
  loadUniqueAndSimilarError,
  loadGenreAveragesRequest,
  loadGenreAveragesSuccess,
  loadGenreAveragesError,
  setActivePulsePersonasPerSegmentSuccess,
  setMarketSizeData,
  setSentimentPrevGameJwt,
  setMarketGenresData,
  setMarketSpendData,
  setMarketPersonaNumber,
  loadMarketGenresError,
  loadMarketSpendError,
  loadMarketSizeError,
  resetMarketSpendData,
  resetMarketGenresData,
  resetMarketSizeData
} from './actions';
import { mapEngagementRate } from './mappers';
import { marketSizeInitial } from './reducers';
import {
  selectActivePulsePersonasPerSegment,
  selectSentimentActiveChannel,
  selectSentimentActiveTimeline,
  sentimentPrevActiveChannel,
  sentimentPrevActiveTimeline,
  sentimentPrevJwt
} from './selectors';
import {
  type SentimentDataPoint,
  LOAD_TOP_GAMES,
  LOAD_PCS,
  LOAD_KPIS_LEADERBOARD,
  LOAD_EXPERIENCE,
  LOAD_ENGAGEMENT_RATE,
  LOAD_UNIQUE_AND_SIMILAR,
  LOAD_GENRE_AVERAGES,
  SET_SHOWING_HEADERBAR,
  SET_SENTIMENT_ACTIVE_TIMELINE,
  SET_ACTIVE_PULSE_PERSONAS_PER_SEGMENT,
  LOAD_MARKET_SIZE_DATA,
  LOAD_MARKET_GENRES_DATA,
  LOAD_MARKET_SPEND_DATA
} from './types';


function* loadTopGames({ payload: { personaCode } }: Action) {
  const isNonGaming = yield select(selectIsNonGamingCompany);
  yield put(loadTopGamesRequest());

  try {
    if (isNonGaming) {
      const { data } = yield call(fetchClusterFavorites, 'app', undefined, personaCode);
      yield put(loadTopGamesSuccess(camelcaseKeys(data, { deep: true })));
      return;
    }
    const { games } = yield call(fetchClusterGames, personaCode);
    yield put(loadTopGamesSuccess(camelcaseKeys(games, { deep: true })));
  } catch (e) {
    yield put(loadTopGamesError(e.message));
  }
}

function* loadPcs({ payload: { segmentId } }: Action) {
  yield put(loadPcsRequest());

  try {
    const data = yield call(fetchScores, 'pcs', segmentId);
    yield put(loadPcsSuccess(data));
  } catch (e) {
    yield put(loadPcsError(e.message));
  }
}

function* loadKpisLeaderboard({ payload: { kpiCode, segmentId } }: Action) {
  yield put(loadKpisLeaderboardRequest());

  try {
    const result = yield call(fetchLeaderboardByKpi, kpiCode, segmentId);
    const { leaderboard } = result.data;
    yield put(loadKpisLeaderboardSuccess(camelcaseKeys(leaderboard, { deep: true })));
  } catch (e) {
    yield put(loadKpisLeaderboardError(e.message));
  }
}

function* loadExperience({ payload }: Action) {
  yield put(loadExperienceRequest());

  try {
    const { personaCode } = payload;
    const result = yield call(fetchClustersExperience, personaCode === 'all' ? null : personaCode);
    yield put(loadExperienceSuccess(camelcaseKeys(result)));
  } catch (e) {
    yield put(loadExperienceError(e.message));
  }
}

function* loadEngagementRate({ payload }: Action) {
  yield put(loadEngagementRateRequest());

  try {
    const { timeline } = payload;
    const dashboardId = yield select(selectGameId);
    const { data } = yield call(fetchEngagementChartData, dashboardId, timeline);
    yield put(loadEngagementRateSuccess(mapEngagementRate(data)));
  } catch (e) {
    yield put(loadEngagementRateError(e.message));
  }
}

function* loadSentiment() {
  const sortByTimeStart = (previous: SentimentDataPoint, next: SentimentDataPoint): number =>
    previous.time_start - next.time_start;

  try {
    const activeChannel = yield select(selectSentimentActiveChannel);
    const prevActiveChannel = yield select(sentimentPrevActiveChannel);
    const activeTimeline = yield select(selectSentimentActiveTimeline);
    const prevActiveTimeline = yield select(sentimentPrevActiveTimeline);

    const prevGameJWT = yield select(sentimentPrevJwt);
    const gameJWT = yield select(selectGameJwt);
    if (
      prevGameJWT !== gameJWT ||
      (activeChannel !== prevActiveChannel && prevActiveChannel !== null) ||
      activeTimeline !== prevActiveTimeline
    ) {
      yield put(setSentimentPrevGameJwt(gameJWT));
      yield put(loadSentimentRequest());
    }

    if (activeChannel && activeTimeline) {
      const {
        data: { data: sentiment },
        releases: { data: releases }
      } = yield all({
        data: call(getSocialListeningSentimentData, activeChannel, activeTimeline),
        releases: call(getSocialListeningReleaseVersions, activeTimeline)
      });

      yield put(loadSentimentSuccess({ data: sentiment.data.sort(sortByTimeStart), releases }));
    } else {
      yield put(loadSentimentSuccess({ data: [], releases: [] }));
    }
  } catch (e) {
    yield put(loadSentimentError(e.message));
    yield put(loadSentimentSuccess({ data: [], releases: [] }));
  }
}

function* loadUniqueAndSimilar({ payload: { personaCode } }: Action) {
  yield put(loadUniqueAndSimilarRequest());

  try {
    const result = yield call(
      fetchUniqueAndSimilar,
      personaCode === 'all' ? undefined : personaCode
    );
    yield put(loadUniqueAndSimilarSuccess(camelcaseKeys(result, { deep: true })));
  } catch (e) {
    yield put(loadUniqueAndSimilarError(e.message));
  }
}

function* loadGenreAverages({ payload: { traits, segmentId, personaCode, gameId } }: Action) {
  yield put(loadGenreAveragesRequest());

  const params = {
    trait_ids: traits,
    cluster: personaCode,
    dashboard_id: gameId
  };

  try {
    const result = yield call(
      fetchGenreAverages,
      segmentId ? { ...params, segment: segmentId } : { ...params }
    );
    yield put(loadGenreAveragesSuccess(result.data));
  } catch (e) {
    yield put(loadGenreAveragesError(e.message));
  }
}

const setShowingHeaderbar = ({ payload }: Action) => {
  if (payload) {
    localStorage.setItem('OverviewPage/headerbar', 'true');
  } else {
    localStorage.removeItem('OverviewPage/headerbar');
  }
};

function* setActivePulsePersonasPerSegment() {
  const currentData = yield select(selectActivePulsePersonasPerSegment);
  if (!currentData) {
    const segments: Segment[] = yield select(selectSegments);
    const initialData: { [key: string]: string[] } = {};
    segments.forEach(segment => {
      initialData[segment.id] = ['all'];
    });
    yield put(setActivePulsePersonasPerSegmentSuccess(initialData));
  }
}

function* loadMarketGenres({ payload: { cluster } }: Action) {
  try {
    const { data: genres }: { data: GenreI[] } = camelcaseKeys(
      yield call(fetchMarketGenres, cluster),
      { deep: true }
    );
    yield put(setMarketGenresData(genres || []));
  } catch (e) {
    yield put(loadMarketGenresError(e.message));
    yield put(resetMarketGenresData());
  }
}

function* loadMarketSpend({ payload: { cluster } }: Action) {
  try {
    const marketSpend = {
      ...marketSizeInitial.spend
    };
    const { data: spend }: { data: SpendItemI[] } = camelcaseKeys(
      yield call(fetchMarketSpend, cluster),
      { deep: true }
    );
    if (spend && spend.length > 0) {
      marketSpend.valuesSpend = spend.map(({ avgValue }) => avgValue);
      marketSpend.minSpendRangeValues = spend.map(({ minValue }) => minValue);
      marketSpend.maxSpendRangeValues = spend.map(({ maxValue }) => maxValue);

      const currentMaxSpendValue = Math.max(...spend.map(({ maxValue }) => maxValue));
      marketSpend.maxSpendValue = Math.ceil(currentMaxSpendValue / 5) * 5; // give gap

      marketSpend.meanValuesSpend = spend.map(({ meanValue }) => meanValue);
      marketSpend.minSpendCIRangeValues = spend.map(({ minCi }) => minCi);
      marketSpend.maxSpendCIRangeValues = spend.map(({ maxCi }) => maxCi);

      const currentMaxCISpendValue = Math.max(...spend.map(({ maxCi }) => maxCi));
      marketSpend.maxCIValue = Math.ceil(currentMaxCISpendValue / 5) * 5; // give gap
    }

    const personaNumbers = spend?.length || 0;
    yield put(setMarketPersonaNumber(personaNumbers));
    yield put(setMarketSpendData(marketSpend));
  } catch (e) {
    yield put(loadMarketSpendError(e.message));
    yield put(resetMarketSpendData());
  }
}

function* loadMarketSize({ payload: { cluster } }: Action) {
  try {
    const { data: size }: { data: SizeItemI[] } = camelcaseKeys(
      yield call(fetchMarketSize, cluster),
      { deep: true }
    );

    const marketSize = {
      ...marketSizeInitial.size
    };

    if (size) {
      marketSize.valuesSize = size.map(({ avgValue }) => Math.round(avgValue / 1000000));
      marketSize.minSizeRangeValues = size.map(({ minValue }) => Math.round(minValue / 1000000));
      marketSize.maxSizeRangeValues = size.map(({ maxValue }) => Math.round(maxValue / 1000000));

      const currentMaxSizeValue = Math.max(...size.map(({ maxValue }) => maxValue / 1000000));
      marketSize.maxSizeValue = Math.ceil(currentMaxSizeValue / 25) * 25; // give gap
    }

    const personaNumbers = size?.length || 0;
    yield put(setMarketPersonaNumber(personaNumbers));
    yield put(setMarketSizeData(marketSize));
  } catch (e) {
    yield put(loadMarketSizeError(e.message));
    yield put(resetMarketSizeData());
  }
}

function* Saga() {
  yield takeLatest(LOAD_TOP_GAMES, loadTopGames);
  yield takeLatest(LOAD_PCS, loadPcs);
  yield takeLatest(LOAD_KPIS_LEADERBOARD, loadKpisLeaderboard);
  yield takeLatest(LOAD_EXPERIENCE, loadExperience);
  yield takeLatest(LOAD_ENGAGEMENT_RATE, loadEngagementRate);
  yield takeLatest(SET_SENTIMENT_ACTIVE_TIMELINE, loadSentiment);
  yield takeLatest(LOAD_UNIQUE_AND_SIMILAR, loadUniqueAndSimilar);
  yield takeLatest(LOAD_GENRE_AVERAGES, loadGenreAverages);
  yield takeLatest(SET_SHOWING_HEADERBAR, setShowingHeaderbar);
  yield takeLatest(SET_ACTIVE_PULSE_PERSONAS_PER_SEGMENT, setActivePulsePersonasPerSegment);
  yield takeLatest(LOAD_MARKET_SIZE_DATA, loadMarketSize);
  yield takeLatest(LOAD_MARKET_GENRES_DATA, loadMarketGenres);
  yield takeLatest(LOAD_MARKET_SPEND_DATA, loadMarketSpend);
}

export default Saga;
