import CoreApiService from '@/services/Api/CoreApiService';

export default class CompareApiService extends CoreApiService {
  constructor(
    config: {
      version?: string;
      useGameJwt?: boolean;
      isCamelCaseResponse?: boolean;
      shouldSendSnakeCase?: boolean;
    } = {}
  ) {
    const { version, useGameJwt, isCamelCaseResponse = true, shouldSendSnakeCase } = config;
    super({ version, useGameJwt, isCamelCaseResponse, shouldSendSnakeCase });
    this.api = `${process.env.BASE_URL}/compare`;
  }
}
