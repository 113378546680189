import { FacebookAccount, FacebookTargetingCategories } from '@/services/Api/AdsService/types';
import { ClusterKpi } from '@/services/Api/GameService/types';

export const LOAD_TARGETING_SUMMARY = 'TargetPage / LOAD_TARGETING_SUMMARY';
export const LOAD_TARGETING_SUMMARY_REQUEST = 'TargetPage / LOAD_TARGETING_SUMMARY_REQUEST';
export const LOAD_TARGETING_SUMMARY_SUCCESS = 'TargetPage / LOAD_TARGETING_SUMMARY_SUCCESS';
export const LOAD_TARGETING_SUMMARY_ERROR = 'TargetPage / LOAD_TARGETING_SUMMARY_ERROR';
export const LOAD_TARGETING_CATEGORIES = 'TargetPage / LOAD_TARGETING_CATEGORIES';
export const LOAD_TARGETING_CATEGORIES_REQUEST = 'TargetPage / LOAD_TARGETING_CATEGORIES_REQUEST';
export const LOAD_TARGETING_CATEGORIES_SUCCESS = 'TargetPage / LOAD_TARGETING_CATEGORIES_SUCCESS';
export const LOAD_TARGETING_CATEGORIES_ERROR = 'TargetPage / LOAD_TARGETING_CATEGORIES_ERROR';
export const LOAD_TARGETING_GAMES = 'TargetPage / LOAD_TARGETING_GAMES';
export const LOAD_TARGETING_GAMES_REQUEST = 'TargetPage / LOAD_TARGETING_GAMES_REQUEST';
export const LOAD_TARGETING_GAMES_SUCCESS = 'TargetPage / LOAD_TARGETING_GAMES_SUCCESS';
export const LOAD_TARGETING_GAMES_ERROR = 'TargetPage / LOAD_TARGETING_GAMES_ERROR';

export const FB_AUDIENCE_SET_AUDIENCE = 'TargetPage / FB_AUDIENCE_SET_AUDIENCE';
export const FB_AUDIENCE_SET_SEGMENT = 'TargetPage / FB_AUDIENCE_SET_SEGMENT';
export const FB_AUDIENCE_SET_CLUSTER = 'TargetPage / FB_AUDIENCE_SET_CLUSTER';
export const FB_AUDIENCE_SET_STEP = 'TargetPage / FB_AUDIENCE_SET_STEP';
export const FB_AUDIENCE_SET_ACCOUNT = 'TargetPage / FB_AUDIENCE_SET_ACCOUNT';
export const FB_AUDIENCE_GET_ACCOUNTS = 'TargetPage / FB_AUDIENCE_GET_ACCOUNTS';
export const FB_AUDIENCE_GET_ACCOUNTS_REQUEST = 'TargetPage / FB_AUDIENCE_GET_ACCOUNTS_REQUEST';
export const FB_AUDIENCE_GET_ACCOUNTS_SUCCESS = 'TargetPage / FB_AUDIENCE_GET_ACCOUNTS_SUCCESS';
export const FB_AUDIENCE_GET_ACCOUNTS_ERROR = 'TargetPage / FB_AUDIENCE_GET_ACCOUNTS_ERROR';
export const FB_AUDIENCE_LOGOUT = 'TargetPage / FB_AUDIENCE_LOGOUT';
export const FB_AUDIENCE_SET_UPLOAD_ID = 'TargetPage / FB_AUDIENCE_SET_UPLOAD_ID';

export const FB_AUDIENCE_GET_CLUSTER_KPIS = 'TargetPage / FB_AUDIENCE_GET_CLUSTER_KPIS';
export const FB_AUDIENCE_GET_CLUSTER_KPIS_REQUEST =
  'TargetPage / FB_AUDIENCE_GET_CLUSTER_KPIS_REQUEST';
export const FB_AUDIENCE_GET_CLUSTER_KPIS_SUCCESS =
  'TargetPage / FB_AUDIENCE_GET_CLUSTER_KPIS_SUCCESS';
export const FB_AUDIENCE_GET_CLUSTER_KPIS_ERROR = 'TargetPage / FB_AUDIENCE_GET_CLUSTER_KPIS_ERROR';

export interface Game {
  cluster: number;
  value: string;
  count: number;
  percentage: number;
  url: string;
  rank: number;
}

interface FacebookAudience {
  clusterKpis: Array<ClusterKpi> | null;
  currentAudience: number;
  currentSegment: string | '';
  currentCluster: string | '';
  currentKpis: string | '';
  currentStep: number;
  currentAccount: string | null;
  isLogged: boolean;
  accounts: FacebookAccount[] | null;
  accountsLoading: boolean;
  accountsError: boolean;
  accountsErrorMsg: string;
  uploadId: string | null;
}

export interface Demographics {
  [key: string]: number | string | { traitId: string; name: string; percentage: number }[];
  population: number;
  endurability: number;
  communityScore: number;
  age: number;
  gender: string;
  edu: string;
  device: string;
  country: string;
  work: string;
  genderPercentage: { traitId: string; name: string; percentage: number }[];
  devicePercentage: { traitId: string; name: string; percentage: number }[];
  workPercentage: { traitId: string; name: string; percentage: number }[];
  eduPercentage: { traitId: string; name: string; percentage: number }[];
  countryPercentage: { traitId: string; name: string; percentage: number }[];
}

export interface TargetPageState {
  summary: {
    targetSummary: { [key: string]: any };
    demographics: Demographics | null;
  } | null;
  summaryLoading: boolean;
  summaryError: boolean;
  summaryErrorMsg: string;
  targetingCategories: FacebookTargetingCategories | null;
  targetingCategoriesLoading: boolean;
  targetingCategoriesError: boolean;
  targetingCategoriesErrorMsg: string;
  games: Array<Game> | null;
  gamesLoading: boolean;
  gamesError: boolean;
  gamesErrorMsg: string;
  fbAudience: FacebookAudience;
}
