import { call, put, takeEvery, select } from 'redux-saga/effects';

import {
  fetchUserInfo,
  fetchUserPreferences,
  updateUserInfo,
  uploadAvatar,
  uploadCompanyAvatar,
  updateUserPreferences
} from '@/services/Api/UserService';

import { Action } from '@/redux/types';

import {
  setIsLoading,
  loadUserInfoSuccess,
  updateUserAvatarUrl,
  updateCompanyAvatarUrl,
  loadUserPreferencesSuccess,
  updateUserPreferences as updateUserPreferencesAction,
  setIsLoadingPreferences
} from './actions';
import { selectUserPreferences } from './selectors';
import {
  LOAD_USER_INFO,
  LOAD_USER_PREFERENCES,
  UserFrequencyPreferences,
  UPDATE_FREQ_PREFERENCES,
  UPDATE_USER_DATA,
  UPDATE_USER_PREFERENCES,
  UPLOAD_AVATAR,
  UPLOAD_COMPANY_AVATAR,
  UserPreferences
} from './types';

function* loadUser() {
  yield put(setIsLoading(true));
  try {
    const { data } = yield call(fetchUserInfo);
    yield put(loadUserInfoSuccess(data));

    yield put(setIsLoading(false));
  } catch (err) {
    // if token is expired
    localStorage.removeItem('state');
    throw new Error(err);
  }
}

function* updateUser(action: Action) {
  yield put(setIsLoading(true));
  try {
    yield call(updateUserInfo, action.payload.data);

    yield put(setIsLoading(false));
  } catch (err) {
    throw new Error(err);
  }
}

function* uploadAvatarSaga(action: Action) {
  yield put(setIsLoading(true));
  try {
    const { data } = yield call(uploadAvatar, action.payload);

    yield put(updateUserAvatarUrl(data));
    yield put(setIsLoading(false));
  } catch (err) {
    throw new Error(err);
  }
}

function* uploadCompanyAvatarSaga(action: Action) {
  yield put(setIsLoading(true));
  try {
    const { data } = yield call(uploadCompanyAvatar, action.payload);

    yield put(updateCompanyAvatarUrl(data));
    yield put(setIsLoading(false));
  } catch (err) {
    throw new Error(err);
  }
}

const initialKpiIds = [0, 0, 0];

function* loadUserPreferences() {
  yield put(setIsLoadingPreferences(true));
  try {
    const { data } = yield call(fetchUserPreferences);
    const preferences: UserPreferences = (yield select(selectUserPreferences)) || {};

    yield put(
      loadUserPreferencesSuccess({
        ...data,
        navigator: {
          ...data.navigator,
          assessmentListSort: preferences.navigator?.assessmentListSort
        },
        frequency: {
          ...data.frequency,
          ...preferences.frequency,
          buildsLibrarySort: preferences.frequency?.buildsLibrarySort,
          creativesDetailsKpis: [...data.frequency.creativesDetailsKpis, ...initialKpiIds].slice(
            0,
            3
          )
        }
      })
    );
  } catch (err) {
    throw new Error(err);
  }
  yield put(setIsLoadingPreferences(false));
}

function* loadUpdateFreqPreferences(action: Action) {
  try {
    const preferences: UserPreferences = (yield select(selectUserPreferences)) || {};
    const { data }: { data: UserFrequencyPreferences } = action.payload;
    const newPreferences: UserPreferences = {
      ...preferences,
      frequency: {
        ...preferences?.frequency,
        ...data,
        creativesLibrarySort: {
          ...preferences?.frequency.creativesLibrarySort,
          ...data.creativesLibrarySort
        },
        creativesLibraryColumns: {
          ...preferences?.frequency.creativesLibraryColumns,
          ...data.creativesLibraryColumns
        },
        buildsLibrarySort: {
          ...preferences?.frequency.buildsLibrarySort,
          ...data.buildsLibrarySort
        }
      }
    };
    yield put(updateUserPreferencesAction(newPreferences));
  } catch (err) {
    throw new Error(err);
  }
}

function* loadUpdateUserPreferences(action: Action) {
  try {
    const { data } = action.payload;
    yield put(loadUserPreferencesSuccess(data));
    yield call(updateUserPreferences, data);
  } catch (err) {
    throw new Error(err);
  }
}

function* Saga() {
  yield takeEvery(LOAD_USER_INFO, loadUser);
  yield takeEvery(UPDATE_USER_DATA, updateUser);
  yield takeEvery(UPLOAD_AVATAR, uploadAvatarSaga);
  yield takeEvery(UPLOAD_COMPANY_AVATAR, uploadCompanyAvatarSaga);
  yield takeEvery(LOAD_USER_PREFERENCES, loadUserPreferences);
  yield takeEvery(UPDATE_USER_PREFERENCES, loadUpdateUserPreferences);
  yield takeEvery(UPDATE_FREQ_PREFERENCES, loadUpdateFreqPreferences);
}

export default Saga;
