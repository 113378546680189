// overview
export const LOAD_COMPARISON_OVERVIEW_REQUEST = 'ComparisonPage / LOAD_COMPARISON_OVERVIEW_REQUEST';
export const LOAD_COMPARISON_OVERVIEW_SUCCESS = 'ComparisonPage / LOAD_COMPARISON_OVERVIEW_SUCCESS';
export const LOAD_COMPARISON_OVERVIEW_ERROR = 'ComparisonPage / LOAD_COMPARISON_OVERVIEW_ERROR';
// graphs
export const UPDATE_GRAPHS = 'ComparisonPage / UPDATE_GRAPHS';

interface ComparisonKpi {
  id: number;
  name: string;
  label: string;
  value: number;
  comparisonMaxValue: number;
  measurement: string;
  description: string;
  objective: string;
}

interface ComparisonTrait {
  name: string;
  label: string;
  value: number;
  measurement: string;
  comparisonMaxValue: number;
}

export interface ComparisonPageState {
  loading: boolean;
  error: boolean;
  errorMsg: string;
  left: {
    clusterCode: string;
    kpis: ComparisonKpi[];
    traits: ComparisonTrait[];
  };
  right: {
    segmentId: string;
    clusterCode: string;
    genreId: string;
    kpis: ComparisonKpi[];
    traits: ComparisonTrait[];
    dashboardId: string;
  };
  comparisonGraphs: ComparisonGraph[];
  savedComparisons: ComparisonGraphSelection[];
  isInitialSettingsLoaded: boolean;
}

export interface ComparisonGraphSelection {
  type: 'kpi' | 'trait';
  key: string;
}

export interface ComparisonGraph extends ComparisonGraphSelection {
  leftValue: string;
  rightValue: string;
  measurement: string;
  maxValue: number;
  label: string;
}

export interface CategorizedTraits {
  name: string;
  items: TraitsCategory[];
}

export interface TraitsCategory {
  name: string;
  type: string;
  value: string;
  label: string;
  category: string;
  disabled?: boolean;
}

export interface ComparisonParams {
  left: { segment: string; cluster: string; dashboardId: string };
  right: { segment: string; cluster: string; dashboardId: string; genreId: number };
}
