import React from 'react';

import { Redirect, generatePath } from 'react-router-dom';

import { useAppSelector } from '@/hooks/useApp';

import { aliasesOverviewPath, homePagePath } from '@/route/paths';

import { selectAppJwt, selectIsAuthenticated } from '@/redux/auth/selectors';
import { getUserEmail, getUserId, selectIsPreviewMode } from '@/redux/user/selectors';

import useRedirect from './useRedirect';

export interface Props {
  previewable?: boolean;
}

function PrivateMainPageRoute({ previewable = false }: Props): React.ReactElement {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const jwt = useAppSelector(selectAppJwt);
  const id = useAppSelector(getUserId);
  const email = useAppSelector(getUserEmail);
  const isPrewiewMode = useAppSelector(selectIsPreviewMode);

  if (isAuthenticated && window.gtag) {
    if (!email.endsWith('12traits.com') && !email.endsWith('solsten.io')) {
      window.gtag('config', process.env.GOOGLE_TAG_KEY, {
        user_id: id
      });
    }
  }
  useRedirect(isAuthenticated, jwt);

  if (!isAuthenticated || !jwt) {
    return <Redirect to="/login" />;
  }

  if (isPrewiewMode && !previewable) {
    return <Redirect to={aliasesOverviewPath} />;
  }

  return <Redirect to={generatePath(homePagePath)} />;
}

export default PrivateMainPageRoute;
