import { Action } from '@/redux/types';

import { ConfigState, SET_NAVIGATOR_PULSE_IS_EXPANDED } from './types';

const initialState: ConfigState = {
  navigatorPulseIsExpanded: false
};

export default (state = initialState, action: Action): ConfigState => {
  switch (action.type) {
    case SET_NAVIGATOR_PULSE_IS_EXPANDED:
      return {
        ...state,
        navigatorPulseIsExpanded: action.payload.navigatorPulseIsExpanded
      };
    default:
      return state;
  }
};
