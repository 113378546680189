import React, { ReactElement } from 'react';

import { generatePath, Redirect } from 'react-router-dom';

import { useAppSelector } from '@/hooks/useApp';

import { selectAppJwt, selectIsAuthenticated } from '@/redux/auth/selectors';
import {
  selectIsUserCompanyOwner,
  selectIsGamingCompany,
  selectUserHaveGames,
  selectUserHaveAssessments,
  selectIsPreviewMode,
  selectIsUserLoading
} from '@/redux/user/selectors';

import Loader from '@/components/Frequency/Loader';

import { aliasesOverviewPath, newAssessmentAutomationPath } from './paths';
import PrivateRoute from './PrivateRoute';
import useRedirect from './useRedirect';


interface Props {
  path: string;
  page: React.ComponentType<any>;
  layout: React.ComponentType<any>;
  disabled?: boolean;
  redirectIfNonGaming?: boolean;
  redirectIfNotOwner?: boolean;
  fullWidth?: boolean;
  footerWithoutBg?: boolean;
  withoutCart?: boolean;
  previewable?: boolean;
}

function NavigatorRoute({
  redirectIfNotOwner,
  redirectIfNonGaming,
  disabled,
  previewable = false,
  ...props
}: Props): ReactElement | null {
  const isCompanyOwner = useAppSelector(selectIsUserCompanyOwner);
  const isGamingCompany = useAppSelector(selectIsGamingCompany);
  const isLoading = useAppSelector(selectIsUserLoading);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const jwt = useAppSelector(selectAppJwt);
  const haveDashboards = useAppSelector(selectUserHaveGames);
  const haveAssessments = useAppSelector(selectUserHaveAssessments);
  const isPrewiewMode = useAppSelector(selectIsPreviewMode);

  useRedirect(isAuthenticated, jwt);

  if (!isAuthenticated || !jwt) {
    return <Redirect to="/login" />;
  }

  if (isPrewiewMode && !previewable) {
    return <Redirect to={aliasesOverviewPath} />;
  }

  if (!haveAssessments && !haveDashboards) {
    return <Redirect to={generatePath(newAssessmentAutomationPath)} />;
  }

  if (isCompanyOwner === null) {
    return <Loader centerScreen />;
  }

  if (disabled) {
    return <Redirect to="/" />;
  }

  if (redirectIfNonGaming && !isGamingCompany && !isLoading) {
    return <Redirect to="/" />;
  }

  if (redirectIfNotOwner && !isCompanyOwner) {
    return <Redirect to="/" />;
  }

  return <PrivateRoute {...props} />;
}

export default NavigatorRoute;
