import classNames from 'classnames';
import { forwardRef, useMemo } from 'react';


import { useI18n } from '@/i18n';

import InputI18n from './Input.i18n';
import styles from './Input.module.scss';

import type { Props } from './Input.types';

export const Input = forwardRef(({ pristine, ...props }: Props, ref) => {
  const i18n = useI18n(InputI18n);
  const className = useMemo(() => classNames(styles.container, props.className), [props.className]);

  return (
    <label className={className} htmlFor="prompt">
      {pristine ? (
        <>
          <span>{i18n.empty.title}</span>
          <span>{i18n.empty.subtitle}</span>
        </>
      ) : (
        <span>{i18n.full.title}</span>
      )}
      <input {...props} id="prompt" ref={ref} />
    </label>
  );
});

export default Input;
