import React, { ReactElement, useEffect } from 'react';

import { Redirect, RouteProps } from 'react-router-dom';

import { useAppSelector } from '@/hooks/useApp';

import { DEFAULT_ID } from '@/constants';

import { updateOrRemoveSearchParam } from '@/services/UrlService';

import { selectAppJwt, selectIsAuthenticated } from '@/redux/auth/selectors';
import { selectFrequencyPurchased, selectSelectedDashboardId } from '@/redux/frequency/selectors';
import {
  selectFrequencyPermissionReadOnly,
  selectIsPreviewMode
} from '@/redux/user/selectors';

import Loader from '@/components/Frequency/Loader';

import { aliasesOverviewPath, frequencyOverviewPath, frequencyAdNetworksPath } from './paths';
import PrivateRoute from './PrivateRoute';
import useRedirect from './useRedirect';




interface Props extends RouteProps {
  path: string | string[];
  page: React.ComponentType<any>;
  layout: React.ComponentType<any>;
  disabled?: boolean;
  fullWidth?: boolean;
  previewable?: boolean;
}

function FrequencyRoute({
  disabled = false,
  path,
  previewable = false,
  ...props
}: Props): ReactElement | null {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const jwt = useAppSelector(selectAppJwt);
  const frequencyPurchased = useAppSelector(selectFrequencyPurchased);
  const frequencyPermissionReadOnly = useAppSelector(selectFrequencyPermissionReadOnly);
  const frequencyDashboardId = useAppSelector(selectSelectedDashboardId);
  const isPrewiewMode = useAppSelector(selectIsPreviewMode);

  useRedirect(isAuthenticated, jwt);

  useEffect(() => {
    updateOrRemoveSearchParam({
      dashboardId: frequencyDashboardId !== DEFAULT_ID ? frequencyDashboardId : null
    });
  }, [path]);

  if (!isAuthenticated || !jwt) {
    return <Redirect to="/login" />;
  }

  if (isPrewiewMode && !previewable) {
    return <Redirect to={aliasesOverviewPath} />;
  }

  if (frequencyPurchased === null) {
    return <Loader centerScreen />;
  }

  if (
    disabled ||
    (frequencyPurchased !== null && !frequencyPurchased)
  ) {
    return <Redirect to="/" />;
  }

  if (path === frequencyAdNetworksPath && frequencyPermissionReadOnly) {
    return <Redirect to={frequencyOverviewPath} />;
  }

  return <PrivateRoute path={path} {...props} />;
}

export default FrequencyRoute;
