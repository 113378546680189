/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import jwtDecode from 'jwt-decode';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import Cookies from 'js-cookie';
import Status404Error from '@/services/Api/Errors/Status404Error';
import { fetchForgotPass, fetchNewPass, fetchLogin, fetchLogout } from '@/services/Api/UserService';

import { Action } from '@/redux/types';

import { resetGamesState } from '../games/actions';
import { setShowingHeaderbar } from '../pages/overviewPage/actions';
import { resetUser } from '../user/actions';

import {
  authRequest,
  authError,
  authSuccess,
  saveJWT,
  saveUserEmail,
  eraseAuth,
  setIsLogOut
} from './actions';
import { FORGOT_PASS, NEW_PASS, LOGIN, LOGOUT } from './types';

function* forgotPass({ payload }: Action): Generator {
  try {
    yield put(authRequest());
    const data = yield call(fetchForgotPass, payload.email);
    yield put(authSuccess(data as { message: string }, false));
  } catch (error) {
    let { message } = error;
    if (error instanceof Status404Error) {
      message = 'Network connection error. Please, check your connection.';
    }
    yield put(authError(message));
  }
}

function* newPass({ payload }: Action): Generator {
  try {
    yield put(authRequest());
    const data = yield call(fetchNewPass, payload.pass, payload.token);
    yield put(authSuccess(data as { message: string }, false));
  } catch (error) {
    let { message } = error;
    if (error instanceof Status404Error) {
      message = 'Network connection error. Please, check your connection.';
    }
    yield put(authError(message));
  }
}
function* login({ payload }: Action) {
  try {
    yield put(authRequest());
    const data = yield call(fetchLogin, payload.email, payload.pass, { credentials: 'include' });

    // * storing JWTs in cookies
    Cookies.set('domain_jwts', JSON.stringify(data.data.jwts), {
      domain: '.solsten.io'
    });

    const firstLogin = localStorage.getItem('firstLogin');
    const { exp } = jwtDecode<{ exp: number }>(data.data.jwt);
    yield all([
      put(authSuccess(data, firstLogin === null)),
      put(saveJWT(data.data.jwt, exp)),
      put(saveUserEmail(payload.email)),
      localStorage.setItem('firstLogin', 'true'),
      put(setShowingHeaderbar(true))
    ]);
  } catch (error) {
    let { message } = error;
    if (error instanceof Status404Error) {
      message = 'Network connection error. Please, check your connection.';
    }
    yield put(authError(message));
  }
}

function* logout() {
  try {
    yield put(authRequest());
    const data = yield call(() => fetchLogout());
    yield put(authSuccess(data, false));
    yield put(setIsLogOut(true));
    yield put(eraseAuth());
    yield put(resetGamesState());
    yield put(resetUser());
  } catch (error) {
    yield put(authError(error.message));
  }
}

function* Saga() {
  yield takeLatest(FORGOT_PASS, forgotPass);
  yield takeLatest(NEW_PASS, newPass);
  yield takeLatest(LOGIN, login);
  yield takeLatest(LOGOUT, logout);
}

export default Saga;
