import { ContentfulClientApi, createClient, Entry, EntryCollection } from 'contentful';

import {
  ContentType,
  ContentTypeEnum,
  getNamespaceContentType,
  Namespace
} from '@/redux/game/types';
import { ContentfulEntry } from '@/redux/pages/traitsPage/types';

interface Configs {
  [key: string]: { space: string; accessToken: string; environment?: string };
}

export default class ContentfulApiService {
  protected contentType: ContentType;

  protected CONFIGS: Configs = {
    [ContentTypeEnum.Gaming]: {
      space: 'c4dp2qj39444',
      accessToken: 'a5988d3e21d67a3615728a6763c33da3581a07c2276418e0bf41c57e754d04bd',
      environment: process.env.REACT_APP_CONTENTFUL_API_ENV
    },

    [ContentTypeEnum.Product]: {
      space: 'l9yi848o5ruz',
      accessToken: 'i4cHPS8qYlt8HNYPPD4Redypl1fzTlkjB_FmEyDricc',
      environment: process.env.REACT_APP_CONTENTFUL_API_ENV
    }
  };

  protected client: ContentfulClientApi;

  /**
   * @param namespace
   */
  constructor(namespace: Namespace) {
    let contentType = getNamespaceContentType(namespace);
    if (contentType === ContentTypeEnum.Medical) {
      contentType = ContentTypeEnum.Product;
    }

    this.contentType = contentType;

    this.client = createClient(this.CONFIGS[this.contentType]);
  }

  protected mapEntries = (entries: Array<Entry<ContentfulEntry>>): ContentfulEntry[] =>
    entries.map(item => item.fields);

  async getEntries(contentType: string): Promise<any> {
    const entries: EntryCollection<ContentfulEntry> = await this.client.getEntries({
      content_type: contentType,
      limit: 200
    });
    return this.mapEntries(entries.items);
  }
}
