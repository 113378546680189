import { createSelector } from '@reduxjs/toolkit';

import { selectCart } from './api';
import { CartData } from './types';

export const selectCartId = createSelector(
  selectCart,
  cart => (cart.data as CartData | undefined)?.id || null
);

export const selectCartProducts = createSelector(
  selectCart,
  cart => (cart.data as CartData | undefined)?.products || null
);

export const selectCartPricing = createSelector(
  selectCart,
  cart => (cart.data as CartData | undefined)?.pricing || null
);

export const selectCartSuggestion = createSelector(
  selectCart,
  cart => (cart.data as CartData | undefined)?.suggestion || null
);
