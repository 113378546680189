import classNames from 'classnames';
import React, { memo, ReactElement } from 'react';


import { Spinner } from 'ui-lib-12traits/src/index';

import styles from './Loader.module.scss';

interface Props {
  centerScreen?: boolean;
}

function Loader({ centerScreen = false }: Props): ReactElement {
  return <div
    className={classNames(styles.loader, {
      [styles.loader_center]: centerScreen
    })}
  >
    <Spinner width={30} height={30} />
  </div>
}

export default memo(Loader);
