import { useEffect } from 'react';

import { Spinner } from 'ui-lib-12traits/src/index';

function DocumentTitle({
  children,
  condition = false,
  title
}: {
  children: React.ReactNode;
  condition?: boolean;
  title: string;
}) {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = title;

    return () => {
      document.title = previousTitle;
    };
  });

  return condition ? <Spinner width={30} height={30} /> : children;
}

export default DocumentTitle;
