import { useQuery } from '@tanstack/react-query';
import { getTaxonomy } from './api';

export default function useGetTaxonomy(queryOptions: any = {}) {
  return useQuery({
    queryKey: ['taxonomy'],
    queryFn: getTaxonomy,
    ...queryOptions
  });
}
