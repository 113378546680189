import { storeRegistry } from '@/services/StoreRegistryService';

import { selectCurrentClusterCode } from '@/redux/cluster/slice';
import { selectGameId } from '@/redux/game/selectors';
import { selectDefaultSegmentId, selectCurrentSegmentId } from '@/redux/segment/selectors';

import {
  selectRightClusterCode,
  selectRightDashboardId,
  selectRightSegmentId,
  selectRightGenreId
} from './selectors';
import { ComparisonParams } from './types';

export const getComparisonParams = (): ComparisonParams => {
  const state = storeRegistry.getState();

  // global settings
  const gameId = selectGameId(state);
  const defaultSegmentId = selectDefaultSegmentId(state);
  const currentClusterCode = selectCurrentClusterCode(state);

  const rightClusterCode = selectRightClusterCode(state);
  const rightDashboardId = selectRightDashboardId(state);
  const rightGenreId = selectRightGenreId(state);

  // select left segment id or empty if default selected
  let segmentId = selectCurrentSegmentId(state);
  segmentId = segmentId !== defaultSegmentId ? segmentId : '';

  // select right segment id or empty if default selected
  let rightSegmentId = selectRightSegmentId(state);
  rightSegmentId = rightSegmentId !== defaultSegmentId ? rightSegmentId : '';

  return {
    left: {
      segment: segmentId,
      cluster: currentClusterCode,
      dashboardId: gameId
    },
    right: {
      segment: rightSegmentId,
      cluster: rightClusterCode,
      dashboardId: rightDashboardId,
      genreId: Number(rightGenreId)
    }
  };
};
