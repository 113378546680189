import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { loginPath, mainPath } from '@/route/paths';

import { saveJWT } from '@/redux/auth/actions';
import { setShowingHeaderbar } from '@/redux/pages/overviewPage/actions';

import type { Location } from 'history';

export const decode = (token: string) => {
  try {
    return jwtDecode<{ exp: number }>(token);
  } catch {
    return false;
  }
};

export const parse = ({ search }: Location) => {
  const params = new URLSearchParams(search);
  const jwt = params.get('jwt') || '';
  const to = jwt ? params.get('redirect_to') || mainPath : loginPath;

  return { jwt, to };
};

export function PreviewRoute() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { jwt, to } = parse(location);

  useEffect(() => {
    const data = decode(jwt);

    if (data) {
      dispatch(saveJWT(jwt, data.exp));
      dispatch(setShowingHeaderbar(true));
    }
  }, [dispatch, jwt]);

  useEffect(() => {
    const firstLogin = ['true', 'null', null].includes(localStorage.getItem('firstLogin'));

    localStorage.setItem('firstLogin', firstLogin.toString());
  }, []);

  return <Redirect to={to} />;
}

export default PreviewRoute;
