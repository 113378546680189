import { type Timeline } from 'ui-lib-12traits/src/index';

import { type ChannelsEnum } from '@/pages/Traits/DataIntegrationPage/types';
import {
  KpiLeaderboardData,
  UserExperience,
  UniqueAndSimilarTraits,
  GenreAverages,
  PcsData
} from '@/pages/Traits/OverviewPage/types';

import { GenreI } from '@/components/Trait/Overview/MarketSize/types';

import { Demographics } from '../targetPage/types';

export const RESET_OVERVIEW_PAGE_STATE = 'OverviewPage / RESET_OVERVIEW_PAGE_STATE';
export const LOAD_TOP_GAMES = 'OverviewPage / LOAD_TOP_GAMES';
export const LOAD_TOP_GAMES_REQUEST = 'OverviewPage / LOAD_TOP_GAMES_REQUEST';
export const LOAD_TOP_GAMES_SUCCESS = 'OverviewPage / LOAD_TOP_GAMES_SUCCESS';
export const LOAD_TOP_GAMES_ERROR = 'OverviewPage / LOAD_TOP_GAMES_ERROR';
export const LOAD_PCS = 'OverviewPage / LOAD_PCS';
export const LOAD_PCS_REQUEST = 'OverviewPage / LOAD_PCS_REQUEST';
export const LOAD_PCS_SUCCESS = 'OverviewPage / LOAD_PCS_SUCCESS';
export const LOAD_PCS_ERROR = 'OverviewPage / LOAD_PCS_ERROR';
export const LOAD_KPIS_LEADERBOARD = 'OverviewPage / LOAD_KPIS_LEADERBOARD';
export const LOAD_KPIS_LEADERBOARD_REQUEST = 'OverviewPage / LOAD_KPIS_LEADERBOAR_REQUEST';
export const LOAD_KPIS_LEADERBOARD_SUCCESS = 'OverviewPage / LOAD_KPIS_LEADERBOARD_SUCCESS';
export const LOAD_KPIS_LEADERBOARD_ERROR = 'OverviewPage / LOAD_KPIS_LEADERBOARD_ERROR';
export const LOAD_EXPERIENCE = 'OverviewPage / LOAD_EXPERIENCE';
export const LOAD_EXPERIENCE_REQUEST = 'OverviewPage / LOAD_EXPERIENCE_REQUEST';
export const LOAD_EXPERIENCE_SUCCESS = 'OverviewPage / LOAD_EXPERIENCE_SUCCESS';
export const LOAD_EXPERIENCE_ERROR = 'OverviewPage / LOAD_EXPERIENCE_ERROR';
export const LOAD_UNIQUE_AND_SIMILAR = 'OverviewPage / LOAD_UNIQUE__AND_SIMILAR';
export const LOAD_UNIQUE_AND_SIMILAR_REQUEST = 'OverviewPage / LOAD_UNIQUE__AND_SIMILAR_REQUEST';
export const LOAD_UNIQUE_AND_SIMILAR_SUCCESS = 'OverviewPage / LOAD_UNIQUE__AND_SIMILAR_SUCCESS';
export const LOAD_UNIQUE_AND_SIMILAR_ERROR = 'OverviewPage / LOAD_UNIQUE__AND_SIMILAR_ERROR';
export const LOAD_GENRE_AVERAGES = 'OverviewPage / LOAD_GENRE_AVERAGES';
export const LOAD_GENRE_AVERAGES_REQUEST = 'OverviewPage / LOAD_GENRE_AVERAGES_REQUEST';
export const LOAD_GENRE_AVERAGES_SUCCESS = 'OverviewPage / LOAD_GENRE_AVERAGES_SUCCESS';
export const LOAD_GENRE_AVERAGES_ERROR = 'OverviewPage / LOAD_GENRE_AVERAGES_ERROR';
export const SET_SHOWING_HEADERBAR = 'OverviewPage / SET_SHOWING_HEADERBAR';
export const LOAD_DEMOGRAPHICS_SCORES_REQUEST = 'OverviewPage / LOAD_DEMOGRAPHICS_SCORES_REQUEST';
export const LOAD_DEMOGRAPHICS_SCORES_SUCCESS = 'OverviewPage / LOAD_DEMOGRAPHICS_SCORES_SUCCESS';
export const LOAD_DEMOGRAPHICS_SCORES_ERROR = 'OverviewPage / LOAD_DEMOGRAPHICS_SCORES_ERROR';

export const SET_HOVERED_PULSE_PERSONA = 'OverviewPage / SET_HOVERED_PULSE_PERSONA';
export const SET_ACTIVE_PULSE_PERSONA = 'OverviewPage / SET_ACTIVE_PULSE_PERSONA';
export const SET_SELECTED_PULSE_PERSONA = 'OverviewPage / SET_SELECTED_PULSE_PERSONA';

export const SET_PULSE_ACTIVE_TIMELINE = 'OverviewPage / SET_PULSE_ACTIVE_TIMELINE';
export const SET_PULSE_AVAILABLE_TIMELINES = 'OverviewPage / SET_PULSE_AVAILABLE_TIMELINES';
export const SET_PULSE_HOVERED_DATE = 'OverviewPage / SET_PULSE_HOVERED_DATE';
export const SET_SENTIMENT_ACTIVE_TIMELINE = 'OverviewPage / SET_SENTIMENT_ACTIVE_TIMELINE';
export const SET_SENTIMENT_AVAILABLE_TIMELINES = 'OverviewPage / SET_SENTIMENT_AVAILABLE_TIMELINES';
export const SET_SENTIMENT_ACTIVE_CHANNEL = 'OverviewPage / SET_SENTIMENT_ACTIVE_CHANNEL';
export const SET_SENTIMENT_AVAILABLE_CHANNELS = 'OverviewPage / SET_SENTIMENT_AVAILABLE_CHANNELS';
export const SET_SENTIMENT_HOVERED_DATE = 'OverviewPage / SET_SENTIMENT_HOVERED_DATE';
export const SET_SENTIMENT_HOVERED_FILTER = 'OverviewPage / SET_SENTIMENT_HOVERED_FILTER';
export const SET_SENTIMENT_ACTIVE_FILTERS = 'OverviewPage / SET_SENTIMENT_ACTIVE_FILTERS';
export const SET_SENTIMENT_PREV_GAME_JWT = 'OverviewPage / SET_SENTIMENT_PREV_GAME_JWT';
export const SET_SENTIMENT_PREV_ACTIVE_TIMELINE =
  'OverviewPage / SET_SENTIMENT_PREV_ACTIVE_TIMELINE';
export const SET_SENTIMENT_PREV_ACTIVE_CHANNEL = 'OverviewPage / SET_SENTIMENT_PREV_ACTIVE_CHANNEL';

export const LOAD_ENGAGEMENT_RATE = 'OverviewPage / LOAD_ENGAGEMENT_RATE';
export const LOAD_ENGAGEMENT_RATE_REQUEST = 'OverviewPage / LOAD_ENGAGEMENT_RATE_REQUEST';
export const LOAD_ENGAGEMENT_RATE_SUCCESS = 'OverviewPage / LOAD_ENGAGEMENT_RATE_SUCCESS';
export const LOAD_ENGAGEMENT_RATE_ERROR = 'OverviewPage / LOAD_ENGAGEMENT_RATE_ERROR';

export const LOAD_SENTIMENT_REQUEST = 'OverviewPage / LOAD_SENTIMENT_REQUEST';
export const LOAD_SENTIMENT_SUCCESS = 'OverviewPage / LOAD_SENTIMENT_SUCCESS';
export const LOAD_SENTIMENT_ERROR = 'OverviewPage / LOAD_SENTIMENT_ERROR';

export const LOAD_MARKET_SIZE_DATA = 'OverviewPage / LOAD_MARKET_SIZE_DATA';
export const LOAD_MARKET_GENRES_DATA = 'OverviewPage / LOAD_MARKET_GENRES_DATA';
export const SET_MARKET_GENRES_DATA = 'OverviewPage / SET_MARKET_GENRES_DATA';
export const LOAD_MARKET_SPEND_DATA = 'OverviewPage / LOAD_MARKET_SPEND_DATA';
export const SET_MARKET_SPEND_DATA = 'OverviewPage / SET_MARKET_SPEND_DATA';
export const SET_MARKET_SIZE_DATA = 'OverviewPage / SET_MARKET_SIZE_DATA';
export const RESET_MARKET_DATA = 'OverviewPage / RESET_MARKET_DATA';
export const RESET_MARKET_SIZE_DATA = 'OverviewPage / RESET_MARKET_SIZE_DATA';
export const RESET_MARKET_SPEND_DATA = 'OverviewPage / RESET_MARKET_SPEND_DATA';
export const RESET_MARKET_GENRES_DATA = 'OverviewPage / RESET_MARKET_GENRES_DATA';
export const SET_MARKET_PERSONA_NUMBER = 'OverviewPage / SET_MARKET_PERSONA_NUMBER';
export const LOAD_MARKET_SIZE_ERROR = 'OverviewPage / LOAD_MARKET_SIZE_ERROR';
export const LOAD_MARKET_SPEND_ERROR = 'OverviewPage / LOAD_MARKET_SPEND_ERROR';
export const LOAD_MARKET_GENRES_ERROR = 'OverviewPage / LOAD_MARKET_GENRES_ERROR';

export const SET_ACTIVE_PULSE_PERSONAS_PER_SEGMENT =
  'OverviewPage / SET_ACTIVE_PULSE_PERSONAS_PER_SEGMENT';
export const SET_ACTIVE_PULSE_PERSONAS_PER_SEGMENT_SUCCESS =
  'OverviewPage / SET_ACTIVE_PULSE_PERSONAS_PER_SEGMENT_SUCCESS';

export interface SentimentIndexes {
  first: number;
  last: number;
}

export interface EngagementRate {
  [personaValue: string]: {
    [timestamp: string]: number | null;
  };
}

export interface TopGame {
  cluster: number;
  value: string;
  count: number;
  percentage: number;
  url: string;
  rank: number;
}

interface DataState<TData> {
  loading: boolean;
  error: boolean;
  data: TData | null;
  errorMsg: string;
}

export type Channel = `${ChannelsEnum}`;

export interface SentimentDataPoint {
  time_start: number;
  time_interval: Timeline;
  total_volume: number;
  negative_volume: number;
  positive_volume: number;
  neutral_volume: number;
  score: number;
}

export type SentimentData = SentimentDataPoint[];

export type SentimentFilter = 'releases' | 'positive' | 'negative' | 'neutral';

export interface Game {
  release_date: number;
  store_application_id: string;
  store_id: number;
  url: string;
}

export interface Version {
  description: string;
  release_date: number;
  version: string;
  x: string;
}

export type Versions = Version[];

export interface Release {
  game: Game;
  versions: Versions;
}

export type Releases = Release[];

export interface MarketSizeI {
  // commmon
  personaNumbers: number;
  // size chart
  size: {
    maxSizeValue: number;
    valuesSize: number[];
    minSizeRangeValues: number[];
    maxSizeRangeValues: number[];
  };
  // spend chart
  spend: {
    maxSpendValue: number;
    valuesSpend: number[];
    minSpendRangeValues: number[];
    maxSpendRangeValues: number[];
    meanValuesSpend: number[];
    maxCIValue: number;
    minSpendCIRangeValues: number[];
    maxSpendCIRangeValues: number[];
  };
  // genres
  currentGenres: GenreI[];
  // errors
  errors: {
    sizeError: string | boolean;
    spendError: string | boolean;
    genresError: string | boolean;
  };
}

export interface OverviewPageState {
  error: boolean;
  pulse: {
    loading: boolean;
    error: boolean;
    errorMsg: string;
    hoveredPulsePersona: string | null;
    selectedPulsePersona: string | null;
    activeTimeline: Timeline | null;
    availableTimelines: Timeline[];
    hoveredDate: string | null;
    engagementRateData: EngagementRate | null;
    activePulsePersonasPerSegment: {
      [key: string]: string[];
    } | null;
  };
  sentiment: {
    loading: boolean;
    error: boolean;
    errorMsg: string;
    activeTimeline: Timeline | null;
    availableTimelines: Timeline[];
    activeChannel: Channel | null;
    availableChannels: Channel[];
    hoveredDate: string | null;
    data: SentimentData;
    releases: Releases;
    hoveredFilter: SentimentFilter | null;
    activeFilters: SentimentFilter[];
    isTooltipAnimating: boolean;
    prevGameJWT: string | null;
    prevActiveChannel: Channel | null;
    prevActiveTimeline: Timeline | null;
  };
  topGames: DataState<TopGame[]>;
  demographicsScores: DataState<Demographics>;
  pcs: DataState<PcsData>;
  kpisLeaderboard: DataState<KpiLeaderboardData>;
  experience: DataState<UserExperience>;
  uniqueAndSimilar: DataState<UniqueAndSimilarTraits>;
  genreAverages: DataState<GenreAverages>;
  isShowingHeaderbar: boolean;
  marketSize: MarketSizeI;
}
