
import useOutsideClick from '@12traits/useoutsideclick';
import classNames from 'classnames';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import SearchIcon from 'ui-lib-12traits/src/Icons/svg/common/search.svg?component';

import { updateOrRemoveSearchParam } from '@/services/UrlService';

import styles from './SearchDropdown.module.scss';

interface Props {
  availableHighlightedTerms: string[];
  searchQuery: string;
}

function SearchDropdown({ availableHighlightedTerms, searchQuery }: Props): ReactElement {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isDropdownExpanded, setIsDropdownExpanded] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const dropdownWrapperRef = useRef(null);
  const inputRef = useRef(null);
  useOutsideClick(dropdownWrapperRef, () => {
    setIsExpanded(false);
    setIsDropdownExpanded(false);
  });

  const filterTermsList = useCallback(() => {
    let filteredList = [];
    filteredList = value
      ? availableHighlightedTerms?.filter(
          term =>
            term
              .toLowerCase()
              .trim()
              .split(' ')
              .filter(word => word.indexOf(value.toLowerCase().trim()) === 0).length
        )
      : availableHighlightedTerms;
    return filteredList?.map(term => (
      <div
        key={term}
        onClick={(): void => addTerm(term)}
        className={classNames(styles.list__item, {
          [styles.list__item_selected]: value === term
        })}
        data-testid={`search-dropdown-list-item-${term}`}
      >
        <div>{term}</div>
      </div>
    ));
  }, [value, availableHighlightedTerms, searchQuery]);

  const addTerm = (value: string): void => {
    setValue(value);
    updateOrRemoveSearchParam({ searchQuery: value });
  };

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => setIsDropdownExpanded(true), 400);
    }
  }, [isExpanded]);
  return (
    <div ref={dropdownWrapperRef} className={styles.dropdown__wrapper}>
      <div
        className={classNames(styles.dropdown__head, {
          [styles.dropdown__head_collapsed]: !isExpanded,
          [styles.dropdown__head_expanded]: isExpanded,
          [styles.dropdown__head_active]: isExpanded && isDropdownExpanded
        })}
        onClick={(): void => {
          setIsExpanded(true);
          inputRef.current.focus();
        }}
      >
        <div
          className={classNames(styles.input__wrapper, {
            [styles.input__wrapper_expanded]: isDropdownExpanded
          })}
        >
          <div className={styles['search-button']}>
            <SearchIcon className={styles.icon} />
          </div>

          <input
            value={value}
            onChange={(e): void => addTerm(e.target.value)}
            ref={inputRef}
            className={classNames(styles.input, {
              [styles.input_expanded]: isExpanded,
              [styles.input_collapsed]: !isExpanded
            })}
            onKeyDown={(e): void => {
              if (e.code === 'Enter' && availableHighlightedTerms.includes(value)) {
                addTerm(value);
              }
            }}
            data-testid="top-section-search-input"
          />
        </div>
      </div>
      {isDropdownExpanded && <div className={styles.dropdown__list}>{filterTermsList()}</div>}
    </div>
  );
}

export default SearchDropdown;
