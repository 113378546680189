import { Filter } from '@/redux/pages/segmentsPage/types';
import SegmentsApiService from '../SegmentsApiService';
import { SegmentPayload } from './types';

export const addSegment = async (segment: SegmentPayload): Promise<any> =>
  new SegmentsApiService({ shouldSendSnakeCase: true , version: 'v1'})
    .setEndpoint('/segments')
    .setBody(segment)
    .post();

export const fetchSegmentsList = async (): Promise<any> =>
  new SegmentsApiService({ version: 'v1'}).setEndpoint('/segments').get();

export const updateSegment = async (
  title: string,
  description: string,
  segmentId: string,
  filters: Filter[]
): Promise<any> =>
  new SegmentsApiService({  version: 'v1'})
    .setEndpoint(`/segments/${segmentId}`)
    .setBody({ title, description, filters })
    .put();

export const deleteSegment = async (segmentId: string): Promise<any> =>
  new SegmentsApiService({  version: 'v1'}).setEndpoint(`/segments/${segmentId}`).delete();

export const simulateSegmentsSize = (segment: SegmentPayload): Promise<any> =>
  new SegmentsApiService({ shouldSendSnakeCase: true , version: 'v1'})
    .setEndpoint('/segments/simulate')
    .setBody(segment)
    .post();
