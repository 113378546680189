import { ContentTypeEnum } from '@/redux/game/types';

export default () => ({
  title: 'Assessment Launcher',
  actions: {
    close: {
      title: 'Save draft and close'
    }
  },
  disclaimer: {
    title: 'Congrats!',
    items: {
      first: meta => {
        const { [meta?.contentType as ContentTypeEnum]: audience } =
          {
            [ContentTypeEnum.Gaming]: 'players',
            [ContentTypeEnum.Medical]: 'patients',
            [ContentTypeEnum.Product]: 'users'
          } || 'audience';

        return `You’re one step closer<br />to better understanding your ${audience}.`;
      },
      second: meta => {
        const isGaming = meta?.contentType === ContentTypeEnum.Gaming;
        const subject = isGaming ? 'game' : 'product';

        return `Detailed next steps were sent to your email (be sure to check spam).<br />Or you can find the assessment link to add to your ${subject} on the home page.`;
      }
    }
  }
});
