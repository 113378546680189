import { ReactElement, useState } from 'react';

import { Dropdown } from 'ui-lib-12traits/src/index';

import { updateOrRemoveSearchParam } from '@/services/UrlService';

import { SortOption, SORT_OPTIONS } from '../../types';

import styles from './SortByScore.module.scss';

interface Props {
  sort: string;
  sortDirection: string;
}

const sortParamDictionary = {
  [SORT_OPTIONS[0]]: { sort: 'informativeness_score', sortDirection: 'desc' },
  [SORT_OPTIONS[1]]: { sort: 'sentiment_score', sortDirection: 'desc' },
  [SORT_OPTIONS[2]]: { sort: 'sentiment_score', sortDirection: 'asc' },
  [SORT_OPTIONS[3]]: { sort: '', sortDirection: '' }
};

const getSelectedSortOptionFromParams = (sort: string, sortDirection: string): SortOption => {
  const sortOptionEq: SortOption =
    Object.keys(sortParamDictionary).find(
      key =>
        sortParamDictionary[key].sort === sort &&
        sortParamDictionary[key].sortDirection === sortDirection
    ) || SORT_OPTIONS[0];

  return sortOptionEq;
};

function SortByScore({ sort, sortDirection }: Props): ReactElement {
  const [selectedSortOption, setSelectedSortOption] = useState<SortOption>(
    getSelectedSortOptionFromParams(sort, sortDirection)
  );

  const onSortChangeHandler = (value: SortOption): void => {
    setSelectedSortOption(value);
    updateOrRemoveSearchParam(sortParamDictionary[value]);
  };

  return (
    <Dropdown
      headClass={styles.select__head}
      arrowClass={styles['select__head-arrow']}
      selectedClass={styles['select__head-selected']}
      selectedExpandedClass={styles.select__head_expanded}
      onChange={(item: SortOption): void => onSortChangeHandler(item)}
      expandedHeadClass={styles.select__head_expanded}
      showSelectedInList
      selected={selectedSortOption}
      options={SORT_OPTIONS.map((item: SortOption) => ({ name: item, value: item }))}
      data-testid="sort-by-score-dropdown"
    />
  );
}

export default SortByScore;
