import GameApiService from '@/services/Api/GameApiService';

import { Action, ActionUpdate, ClusterSettings } from './types';

export const fetchActions = async (params: object): Promise<any> =>
  new GameApiService().setEndpoint('/actions').setParams(params).get();

export const fetchActionsProgress = async (
  cluster: string,
  timeFrame: string = 'week'
): Promise<any> =>
  new GameApiService()
    .setEndpoint('/actions/stats/progress')
    .setParams({
      selected_cluster: cluster,
      timeframe: timeFrame
    })
    .get();

export const fetchActionsConfigKpis = async (params?: object): Promise<any> =>
  new GameApiService()
    .setEndpoint('/actions/config/kpis')
    .setParams(params || {})
    .get();

export const fetchAction = async (id: string): Promise<any> =>
  new GameApiService().setEndpoint(`/actions/${id}`).get();

export const addAction = async (payload: Action): Promise<any> =>
  new GameApiService().setEndpoint(`/actions`).setBody(payload).post();

export const updateAction = async (actionId: string, payload: ActionUpdate): Promise<any> =>
  new GameApiService({ shouldSendSnakeCase: true })
    .setEndpoint(`/actions/${actionId}`)
    .setBody(payload)
    .put();

export const deleteAction = async (actionId: string): Promise<any> =>
  new GameApiService().setEndpoint(`/actions/${actionId}`).delete();

export const voteAction = async (actionId: string, value: -1 | 1): Promise<any> =>
  new GameApiService().setEndpoint(`/actions/${actionId}/vote`).setBody({ value }).put();

export const unvoteAction = async (actionId: string): Promise<any> =>
  new GameApiService().setEndpoint(`/actions/${actionId}/vote`).delete();

export const fetchComparisonValue = async (params: any): Promise<any> =>
  new GameApiService({ shouldSendSnakeCase: true })
    .setEndpoint('/comparison/value')
    .setBody({ ...params })
    .post();

export const fetchKpisUploadsHistory = async (): Promise<any> =>
  new GameApiService().setEndpoint('/uploads/history').get();

export const fetchDataSyncStatuses = async (): Promise<any> =>
  new GameApiService().setEndpoint('/uploads/statuses').get();

export const fetchArtDimension = async (clusterCode: string, segment?: string): Promise<any> => {
  const response = new GameApiService().setEndpoint('/cluster/art-dimension');
  response.setParams({ cluster: clusterCode });
  if (segment) {
    response.setParams({ segment });
  }
  return response.get();
};

export const uploadImage = async (type: string, formData: FormData): Promise<any> =>
  new GameApiService().setEndpoint(`/dashboard/pictures/${type}`).setBody(formData, true).post();

export const deleteImage = async (type: string): Promise<any> =>
  new GameApiService().setEndpoint(`/dashboard/pictures/${type}`).delete();

export const saveClusterSettings = async (settings: ClusterSettings): Promise<any> =>
  new GameApiService().setEndpoint('/cluster/settings').setBody(settings).post();

export const fetchSurveyStatus = async (): Promise<any> =>
  new GameApiService({ version: 'v1' }).setEndpoint('/custom-survey-data/status').get();

export const fetchSurveyQuestions = async (): Promise<any> =>
  new GameApiService().setEndpoint('/custom-survey-data/questions').get();

export const fetchGenreAverages = async (params: object): Promise<any> =>
  new GameApiService().setEndpoint('/comparison/genre-average').setParams(params).get();

export const unlockAction = async (actionId: string): Promise<any> =>
  new GameApiService().setEndpoint(`/actions/${actionId}/unlock`).put();
