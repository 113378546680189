import { FacebookAccount, FacebookTargetingCategories } from '@/services/Api/AdsService/types';

import { prepareAction } from '@/redux/helpers';
import { Action } from '@/redux/types';

import {
  LOAD_TARGETING_SUMMARY,
  LOAD_TARGETING_SUMMARY_REQUEST,
  LOAD_TARGETING_SUMMARY_SUCCESS,
  LOAD_TARGETING_SUMMARY_ERROR,
  LOAD_TARGETING_CATEGORIES,
  LOAD_TARGETING_CATEGORIES_REQUEST,
  LOAD_TARGETING_CATEGORIES_SUCCESS,
  LOAD_TARGETING_CATEGORIES_ERROR,
  LOAD_TARGETING_GAMES,
  LOAD_TARGETING_GAMES_REQUEST,
  LOAD_TARGETING_GAMES_SUCCESS,
  LOAD_TARGETING_GAMES_ERROR,
  FB_AUDIENCE_SET_AUDIENCE,
  FB_AUDIENCE_SET_SEGMENT,
  FB_AUDIENCE_SET_CLUSTER,
  FB_AUDIENCE_SET_STEP,
  FB_AUDIENCE_SET_ACCOUNT,
  FB_AUDIENCE_SET_UPLOAD_ID,
  FB_AUDIENCE_GET_ACCOUNTS,
  FB_AUDIENCE_GET_ACCOUNTS_REQUEST,
  FB_AUDIENCE_GET_ACCOUNTS_SUCCESS,
  FB_AUDIENCE_GET_ACCOUNTS_ERROR,
  FB_AUDIENCE_LOGOUT,
  FB_AUDIENCE_GET_CLUSTER_KPIS,
  FB_AUDIENCE_GET_CLUSTER_KPIS_REQUEST,
  FB_AUDIENCE_GET_CLUSTER_KPIS_SUCCESS,
  FB_AUDIENCE_GET_CLUSTER_KPIS_ERROR
} from './types';

export const loadTargetingSummary = (): Action => prepareAction(LOAD_TARGETING_SUMMARY);

export const loadTargetingSummaryRequest = (): Action =>
  prepareAction(LOAD_TARGETING_SUMMARY_REQUEST);

export const loadTargetingSummarySuccess = ({ targetSummary, demographics }: any): Action =>
  prepareAction(LOAD_TARGETING_SUMMARY_SUCCESS, { data: { targetSummary, demographics } });

export const loadTargetingSummaryError = (error: string): Action =>
  prepareAction(LOAD_TARGETING_SUMMARY_ERROR, { error });

export const loadTargetingCategories = (): Action => prepareAction(LOAD_TARGETING_CATEGORIES);

export const loadTargetingCategoriesRequest = (): Action =>
  prepareAction(LOAD_TARGETING_CATEGORIES_REQUEST);

export const loadTargetingCategoriesSuccess = (data: FacebookTargetingCategories): Action =>
  prepareAction(LOAD_TARGETING_CATEGORIES_SUCCESS, { data });

export const loadTargetingCategoriesError = (error: string): Action =>
  prepareAction(LOAD_TARGETING_CATEGORIES_ERROR, { error });

export const loadTargetingGames = (): Action => prepareAction(LOAD_TARGETING_GAMES);

export const loadTargetingGamesRequest = (): Action => prepareAction(LOAD_TARGETING_GAMES_REQUEST);

export const loadTargetingGamesSuccess = (data: any): Action =>
  prepareAction(LOAD_TARGETING_GAMES_SUCCESS, { data });

export const loadTargetingGamesError = (error: string): Action =>
  prepareAction(LOAD_TARGETING_GAMES_ERROR, { error });

export const setFacebookAudience = (data: number): Action =>
  prepareAction(FB_AUDIENCE_SET_AUDIENCE, { data });

export const setFacebookSegment = (segment: string, cluster?: string): Action =>
  prepareAction(FB_AUDIENCE_SET_SEGMENT, { segment, cluster });

export const setFacebookCluster = (data: string): Action =>
  prepareAction(FB_AUDIENCE_SET_CLUSTER, { data });

export const setFacebookStep = (data: number): Action =>
  prepareAction(FB_AUDIENCE_SET_STEP, { data });

export const setFacebookAccount = (data: string | null): Action =>
  prepareAction(FB_AUDIENCE_SET_ACCOUNT, { data });

export const setFacebookUploadId = (data: string | null): Action =>
  prepareAction(FB_AUDIENCE_SET_UPLOAD_ID, { data });

export const getFbAccounts = (): Action => prepareAction(FB_AUDIENCE_GET_ACCOUNTS);

export const getFbAccountsRequest = (): Action => prepareAction(FB_AUDIENCE_GET_ACCOUNTS_REQUEST);

export const getFbAccountsSuccess = (data: FacebookAccount[]): Action =>
  prepareAction(FB_AUDIENCE_GET_ACCOUNTS_SUCCESS, { data });

export const getFbAccountsError = (error: string): Action =>
  prepareAction(FB_AUDIENCE_GET_ACCOUNTS_ERROR, { error });

export const facebookLogout = (): Action => prepareAction(FB_AUDIENCE_LOGOUT);

export const getFbClusterKpis = (segment: string | undefined, clusterCode: string): Action =>
  prepareAction(FB_AUDIENCE_GET_CLUSTER_KPIS, { segment, clusterCode });

export const getFbClusterKpisRequest = (): Action =>
  prepareAction(FB_AUDIENCE_GET_CLUSTER_KPIS_REQUEST);

export const getFbClusterKpisSuccess = (data: any): Action =>
  prepareAction(FB_AUDIENCE_GET_CLUSTER_KPIS_SUCCESS, { data });

export const getFbClusterKpisError = (error: string): Action =>
  prepareAction(FB_AUDIENCE_GET_CLUSTER_KPIS_ERROR, { error });
