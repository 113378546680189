import classNames from 'classnames';
import React, { CSSProperties, ReactElement, ReactNode, memo, useRef } from 'react';

import { generatePath, useHistory } from 'react-router-dom';

import CrossIcon from 'ui-lib-12traits/src/Icons/svg/common/cross.svg?component';
import InfoIcon from 'ui-lib-12traits/src/Icons/svg/common/error.svg?component';
import ArrowIcon from 'ui-lib-12traits/src/Icons/svg/common/triangle_small.svg?component';
import LockIcon from 'ui-lib-12traits/src/Icons/svg/common/lock.svg?component';

import { Tooltip } from 'ui-lib-12traits/src/index';

import { useAppDispatch } from '@/hooks/useApp';

import { Template } from '@/pages/Navigator/AssessmentAutomationV2/types';

import { homePagePath } from '@/route/paths';

import { hoverAssessmentStep } from '@/redux/survey/actions';

import styles from './StepContainer.module.scss';

export type StepType = 'optional' | 'required' | '';

interface Props {
  children?: ReactElement;
  isExpanded?: boolean;
  titleExpanded?: ReactNode;
  id?: string;
  style?: CSSProperties;
  withNumber?: boolean;
  title: string;
  cardStyle?: CSSProperties;
  template: Template;
  onExpand: (v: Template) => void;
  testId?: string;
  collapsedElement: React.ReactNode;
  invalid: boolean;
  locked: boolean;
}

function StepContainer({
  children,
  isExpanded = false,
  titleExpanded,
  collapsedElement,
  onExpand,
  template,
  onClose,
  title,
  id,
  testId = '',
  withNumber = false,
  style,
  cardStyle,
  invalid,
  locked = false
}: Props): ReactElement {
  const dispatch = useAppDispatch();
  const refCollapsedDiv = useRef<HTMLDivElement>(null);
  const history = useHistory();

  return (
    <div
      data-testid={`${id}-step-container ${testId}`}
      className={classNames(styles.wrapper, {
        [styles.wrapper_collapsed]: !isExpanded
      })}
      style={style}
    >
      {onClose && (
        <Tooltip
          className={styles.tooltip}
          arrowClass={styles.arrow}
          content="Delete draft"
          placement="bottom"
        >
          <button
            type="button"
            onClick={() => history.push(generatePath(homePagePath))}
            className={styles.close}
          >
            <CrossIcon />
          </button>
        </Tooltip>
      )}
      <div
        className={classNames(styles.card, styles.card_expanded, {
          [styles.card_expanded_hiding]: !isExpanded
        })}
        style={
          !isExpanded
            ? { ...cardStyle, minHeight: refCollapsedDiv.current?.clientHeight }
            : { ...cardStyle }
        }
      >
        <div className={styles.card__title}>
          {withNumber && <span className={styles.card__number}>{id}</span>}
          {titleExpanded}
        </div>
        {children}
      </div>

      <div
        className={classNames(
          styles.card,
          !isExpanded && styles['card_collapsed-wider'],
          styles.card_collapsed
        )}
        role="button"
        tabIndex={0}
        onClick={(): void => onExpand(template)}
        onMouseEnter={() => dispatch(hoverAssessmentStep(template))}
        onMouseLeave={() => dispatch(hoverAssessmentStep())}
        ref={refCollapsedDiv}
      >
        <div>
          <div className={styles.card__inner}>
            <span className={styles.card__number}>{id}</span>
            <div className={styles.card__content}>
              <div className={styles.card__title}>{title}</div>
              {collapsedElement}
            </div>
          </div>
        </div>
        <div className={styles.card_button}>
          {locked && <LockIcon className={styles['card_lock-icon']} />}
          {invalid && <InfoIcon className={styles['card_info-icon']} />}
          {!isExpanded && <ArrowIcon className={styles.card__arrow} />}
        </div>
      </div>
    </div>
  );
}

export default memo(StepContainer);
