/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSelector, createSlice } from '@reduxjs/toolkit';

import { selectClusters } from '../game/selectors';
import { RootState } from '../rootStore';
import { Cluster } from '../types';

const slice = createSlice({
  name: 'CLUSTER',
  initialState: {
    disabledCluster: null,
    currentCode: null as string | null
  },
  reducers: {
    setClusterCode: (state, action) => {
      state.currentCode = typeof action.payload === 'string' ? action.payload : null;
    }
  }
});

export const { setClusterCode } = slice.actions;
export default slice.reducer;

const selectCurrentCluster = (state: RootState): Cluster | null =>
  selectClusters(state).find(({ value }) => value === state.cluster.currentCode) || null;

export const selectCluster = createSelector(
  selectClusters,
  selectCurrentCluster,
  (clusters: any, currentCluster: any) => {
    const defaultCluster = clusters.length ? clusters[0] : null;
    return currentCluster || defaultCluster;
  }
);

export const selectCurrentClusterCode = createSelector(
  selectClusters,
  selectCurrentCluster,
  (clusters: any, currentCluster: any) => {
    const defaultCluster = clusters.length ? clusters[0].value : '';
    return currentCluster && currentCluster.value ? currentCluster.value : defaultCluster;
  }
);

export const selectCurrentClusterName = createSelector(
  selectClusters,
  selectCurrentCluster,
  (clusters: any, currentCluster) => {
    const defaultCluster = clusters.length ? clusters[0].name : '';
    return currentCluster && currentCluster.name ? currentCluster.name : defaultCluster;
  }
);

export const selectDisabledCluster = (state: RootState): string | null =>
  state.cluster.disabledCluster || null;
