import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useAppSelector } from '@/hooks/useApp';

import { saveLastVisitedUrl, saveReferrerUrl } from '@/services/LocalStorageService';

import { selectIsLogOut } from '@/redux/auth/selectors';

const useRedirect = (isAuthenticated: boolean, jwt: string): void => {
  const location = useLocation();
  const isLogOut = useAppSelector(selectIsLogOut);

  useEffect(() => {
    if ((!isAuthenticated || !jwt) && !isLogOut) {
      saveReferrerUrl(location.pathname);
    }
    saveLastVisitedUrl(location.pathname);
  }, [isAuthenticated, jwt, isLogOut, location]);
};

export default useRedirect;
