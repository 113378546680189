import React, { createElement } from 'react';

import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAppSelector } from '@/hooks/useApp';

import { aliasesOverviewPath } from '@/route/paths';

import { selectIsPreviewMode } from '@/redux/user/selectors';

declare interface Props extends RouteProps {
  page: React.ComponentType<any>;
  layout: React.ComponentType<any>;
}

function Render({ page: Page, layout: Layout, ...rest }: Props): React.ReactElement {
  return <Route
    {...rest}
    render={(props): React.ReactElement => (
      <Layout>
        <Page {...props} />
      </Layout>
    )}
  />
}

function PublicRoute({ render = Render, previewable = false, ...props }) {
  const isPrewiewMode = useAppSelector(selectIsPreviewMode);

  return isPrewiewMode && !previewable ? (
    <Redirect to={aliasesOverviewPath} />
  ) : (
    createElement(render, props as Props)
  );
}

export default PublicRoute;
