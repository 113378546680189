import FeedbackApi from '../FeedbackApiService';

import type { Bucket, Feedback } from './types';
import type { ApiResponse } from '../types';

export const feedback = ({
  feature,
  params,
  screenshots,
  segment,
  ...data
}: Feedback): Promise<ApiResponse<any>> => {
  const state = JSON.parse(`${localStorage.getItem('state')}`);

  let token = null;
  if (state?.auth && state.auth?.jwt) {
    token = state.auth.jwt;
  }

  return new FeedbackApi({
    token,
    isCamelCaseResponse: true,
    shouldSendSnakeCase: true,
    useGameJwt: true,
    version: 'v1'
  })
    .setEndpoint('/notifications/feedback')
    .setBody({
      custom_params: params,
      feature_context: feature,
      screenshot_urls: screenshots,
      segment_id: segment,
      ...data
    })
    .post();
};

export const upload = (file: File): Promise<string> => {
  const process = ({ data: { publicUrl, uploadUrl } }: ApiResponse<Bucket>) => {
    const format = () => publicUrl;

    return window
      .fetch(uploadUrl, {
        headers: { 'Content-Type': '' },
        method: 'PUT',
        body: file
      })
      .then(format);
  };

  return new FeedbackApi({
    isCamelCaseResponse: true,
    shouldSendSnakeCase: true,
    useGameJwt: false,
    version: 'v1'
  })
    .setEndpoint('/notifications/feedback/uploadUrl')
    .setBody(file)
    .post()
    .then(process);
};
