/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */

import av from 'animate-value';
import cn from 'classnames';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import AssessmentLogoSpecificIcon from 'ui-lib-12traits/src/Icons/svg/common/12traits360-logo-specific.svg?component';
import PreferenceAssessment from 'ui-lib-12traits/src/Icons/svg/common/preferences-assessment.svg?component';

import CrossIcon from 'ui-lib-12traits/src/Icons/svg/common/cross.svg?component';
import { Tooltip } from 'ui-lib-12traits/src/index';

import { useAppSelector } from '@/hooks/useApp';

import { useI18n } from '@/i18n';

import ExistingFeaturesStep from '@/features/user-preferences/components/AssessmentSteps/ExistingFeaturesStep';
import NewFeatureStep from '@/features/user-preferences/components/AssessmentSteps/NewFeaturesStep';
import GameItemsStep from '@/features/user-preferences/components/AssessmentSteps/GameItemsStep';
import DocumentTitle from '@/hocs/DocumentTitle';
import { homePagePath } from '@/route/paths';

import { selectHoveredStep } from '@/redux/survey/selectors';

import { selectCompanyName } from '@/redux/user/selectors';
import AudienceStep from '@/components/AutomateAssessment/AudienceStep';
import BrandingStep from '@/components/AutomateAssessment/BrandingStep';
import RewardingStep from '@/components/AutomateAssessment/RewardingStep/RewardingStep';
import SubmittingStep from '@/components/AutomateAssessment/SubmittingStep/SubmittingStep';
import SurveyConfetti from '@/components/Frequency/FrequencyReviewPage/FrequencyReviewSurvey/SurveyConfetti';

import ActiveAssessmentPageI18n from './ActiveAssessmentPage.i18n';
import styles from './AssessmentAutomationPageV2.module.scss';
import Progress from './progress-bar';
import { titleMap, DELAY } from './types';
import useMultistepForm from './use-multistep-form';

import type { Template, Step, TemplateValues } from './types';

const map = {
  ASSESSMENT_STEP_STATIC: (props: any) => <AudienceStep {...props} />,
  ASSESSMENT_STEP_BRANDING: (props: any) => <BrandingStep {...props} />,
  ASSESSMENT_STEP_REWARD: (props: any) => <RewardingStep {...props} />,
  ASSESSMENT_STEP_SUBMIT: (props: any) => <SubmittingStep {...props} />,
  ASSESSMENT_STEP_EXISTING_FEATURES: (props: any) => <ExistingFeaturesStep {...props} />,
  ASSESSMENT_STEP_NEW_FEATURES: (props: any) => <NewFeatureStep {...props} />,
  ASSESSMENT_STEP_GAME_ITEMS: (props: any) => <GameItemsStep {...props} />
};

const MAX_PERCENTAGE = 99;

export default function ActiveAssessmentPage() {
  const {
    next,
    prevSteps,
    currentStep,
    totalSteps,
    isSurveySubmitted,
    formState,
    activeStepTemplate,
    updateUrl,
    isFetchingAssessment,
    assessmentMetaData,
    updateStepValues,
    saveAndClose,
    errorCount,
    setErrorCount,
    deleteAssessment,
    stepsValues
  } = useMultistepForm();

  let steps = currentStep?.template ? [...prevSteps, currentStep] : prevSteps;
  steps = steps.map((s: Step) => ({ ...s, title: titleMap[s.template] }));

  const ref = useRef<HTMLDivElement>(null);
  const listRef = useRef<any>();
  const history = useHistory();

  const [surveyHeight, setSurveyHeight] = useState<number>(0);

  const hoveredStep = useAppSelector(selectHoveredStep);
  const companyName = useAppSelector(selectCompanyName);
  const i18n = useI18n(ActiveAssessmentPageI18n);

  function goTo(template: Template, options = {} as { order: number }) {
    updateUrl(template);

    if (Number.isInteger(options.order)) {
      runAnimateScrollToStep(options.order ?? 0);
    }
  }

  function runAnimateScrollToStep(id: number) {
    const BASE_STEP_OFFSET_TOP = 52;
    const NEXT_STEP_OFFSET_TOP = 113;
    setTimeout(() => {
      av({
        from: window.scrollY,
        to: (id - 1) * NEXT_STEP_OFFSET_TOP + BASE_STEP_OFFSET_TOP,
        duration: 300,
        change: (value: number) => {
          window.scrollTo(0, value);
        }
      });
    }, 500);
  }

  const activeStep = steps.find(step => step.template === activeStepTemplate);
  useEffect(() => {
    if (activeStep?.order) runAnimateScrollToStep(activeStep.order);
  }, [activeStep?.order]);

  useEffect(() => {
    let t: ReturnType<typeof setTimeout>;

    if (isSurveySubmitted) {
      t = setTimeout(() => history.push(homePagePath), DELAY + 9300);
    }

    return () => {
      clearTimeout(t);
    };
  }, [isSurveySubmitted]);

  useLayoutEffect(() => {
    if (listRef.current) {
      const list = listRef?.current;
      const children: HTMLElement[] = Array.prototype.slice.call(list?.children);
      // eslint-disable-next-line
      children.map(child => {
        const next = child.getBoundingClientRect();
        setSurveyHeight(next.height);
      });
    }
  }, [listRef, steps.length, activeStep?.order]);

  const onClickOnStep = (t: Template) =>
    goTo(t, { order: steps.find((s: Step) => s.template === t)!.order });

  const STEP_PERCENTAGE = totalSteps ? Math.ceil(MAX_PERCENTAGE / (totalSteps.length - 1)) : 0;

  const currentProgress =
    STEP_PERCENTAGE * currentStep.order > 100 ? 100 : STEP_PERCENTAGE * currentStep.order;

  const dangerouslySetInnerHTML = useMemo(
    () => ({
      first: { __html: i18n.disclaimer.items.first(assessmentMetaData) },
      second: { __html: i18n.disclaimer.items.second(assessmentMetaData) }
    }),
    [assessmentMetaData, i18n]
  );

  const ASSESSMENT_ICONS = {
    '360': <AssessmentLogoSpecificIcon width={98} height={27} />,
    preference: <PreferenceAssessment width={82} height={28} />
  };

  return (
    <DocumentTitle
      title={`Assessment request | ${steps[0].values.projectName} | ${companyName} | Solsten`}
    >
      <div>
        <div
          className={cn(styles.header__wrapper, {
            [styles.header_animate]: isSurveySubmitted,
            [styles.header_visible]: steps.length > 1
          })}
        >
          <h1>{i18n.title}</h1>
          {ASSESSMENT_ICONS[assessmentMetaData?.type]}

          <div
            className={cn(styles.progress_wrapper, {
              [styles.progress_wrapper_360]: assessmentMetaData?.type === '360',
              [styles.progress_wrapper_preferences]: assessmentMetaData?.type === 'preference'
            })}
          >
            <Progress
              nodeRef={ref}
              currentProgress={currentProgress}
              className={styles['progress-bar']}
              showAnimation={isSurveySubmitted}
              totalSteps={totalSteps.length}
            />
            <div className={styles.steps}>
              {totalSteps.map(({ order, template }) => (
                <Tooltip
                  key={template}
                  className={styles['tooltip-step']}
                  content={titleMap[template]}
                  placement="bottom"
                  borderRadius={4}
                  offset={{ y: 15 }}
                >
                  <button
                    key={template}
                    type="button"
                    className={cn(styles.step, {
                      [styles.step_active]: activeStep?.order === order,
                      [styles.step_current]:
                        currentStep.order === order || errorCount?.[template] === false,
                      [styles.step_passed]:
                        order < currentStep.order && errorCount?.[template] !== false,
                      [styles.step_hovered]: hoveredStep === template
                    })}
                    style={{
                      left: `${STEP_PERCENTAGE * order}%`
                    }}
                    onClick={() => (order <= currentStep.order ? onClickOnStep(template) : null)}
                  >
                    {order + 1}
                  </button>
                </Tooltip>
              ))}
            </div>
          </div>

          <Tooltip
            className={styles.tooltip}
            arrowClass={styles.arrow}
            content={i18n.actions.close.title}
            placement="bottom"
          >
            <button
              onClick={() => {
                saveAndClose();
                history.push(generatePath(homePagePath));
              }}
              type="button"
              className={styles.close}
            >
              <CrossIcon />
            </button>
          </Tooltip>
          <span className={styles.complete_line} />
        </div>
        <div ref={listRef}>
          <TransitionGroup
            style={{ minHeight: surveyHeight || 'auto' }}
            className={cn(styles.survey, { [styles.survey_animate]: isSurveySubmitted })}
          >
            {steps.map((step: Step, i) => (
              <CSSTransition
                key={step.template}
                timeout={1000}
                classNames={{
                  enter: styles['survey-item-enter'],
                  enterActive: styles['survey-item-enter-active']
                }}
              >
                {map[step.template]({
                  onNext: (values: TemplateValues[Template], options: any = {}) => {
                    next(values, { ...step, ...options });
                    runAnimateScrollToStep(step.order);
                  },
                  key: i,
                  updateStepValues,
                  stepsValues,
                  setErrorCount,
                  deleteAssessment,
                  errorCount,
                  formState,
                  totalSteps,
                  saveAndClose,
                  goTo,
                  setActiveStep: (t: Template) => {
                    goTo(t, { order: step.order });
                  },
                  title: titleMap[step.template],
                  baseStepValues: i === 0 ? null : steps[0].values,
                  ...step,
                  isActive:
                    !isFetchingAssessment &&
                    activeStepTemplate === step.template &&
                    !isSurveySubmitted,
                  order: step.order + 1,
                  assessmentMetaData
                })}
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>

        {isSurveySubmitted && <SurveyConfetti delay={DELAY} />}

        <div
          className={cn(styles.badge, {
            [styles.badge_visible]: isSurveySubmitted
          })}
        >
          <div className={styles.icon} />
          <div className={styles.text}>
            <h1>{i18n.disclaimer.title}</h1>
            <h2 dangerouslySetInnerHTML={dangerouslySetInnerHTML.first} />
            <p dangerouslySetInnerHTML={dangerouslySetInnerHTML.second} />
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
}
