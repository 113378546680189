/* eslint-disable no-param-reassign */

import useOutsideClick from '@12traits/useoutsideclick';
import classNames from 'classnames';
import isFunction from 'lodash-es/isFunction';
import { createElement, useCallback, useEffect, useRef, useState } from 'react';

import { Accordion, GlobalButton } from 'ui-lib-12traits/src/index';

import { useI18n } from '@/i18n';
import {
  RewardValues,
  templates,
  StaticValues,
  Step
} from '@/pages/Navigator/AssessmentAutomationV2/types';

import styles from '../RewardingStep.module.scss';

import RewardDescriptionI18n from './RewardDescription.i18n';

interface Props {
  describedReward: string;
  setDescribedReward: (describedReward: string) => void;
  setShowDeliveryStep: (showDeliveryStep: boolean) => void;
  baseStepValues?: StaticValues | null;
  showDeliveryStep: boolean;
  setDeliveryDisabled: (deliveryDisabled: boolean) => void;
  deliveryDisabled: boolean;
  setFeedbackModal: (feedbackModal: boolean) => void;
  onSkip: <T extends { skipped: boolean }>(val: RewardValues, options: T) => void;
  setSkipCounter: (skipCounter: number) => void;
  skipCounter: number;
  setSkippedStep: (skipped: boolean) => void;
  setFilled: (filled: boolean) => void;
  filled: boolean;
  stepsValues: Step[];
}

function RewardDescription({
  describedReward,
  setDescribedReward,
  setShowDeliveryStep,
  showDeliveryStep,
  baseStepValues,
  setDeliveryDisabled,
  deliveryDisabled,
  setFeedbackModal,
  onSkip,
  skipCounter,
  setSkipCounter,
  setSkippedStep,
  setFilled,
  filled,
  stepsValues
}: Props) {
  const [focused, setFocused] = useState(true);

  const ref = useRef<any>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const i18n = useI18n(RewardDescriptionI18n);

  useOutsideClick(
    ref,
    () => {
      if (describedReward) {
        setFocused(false);
      }
    },
    [ref]
  );

  const scrollStep = () => {
    setTimeout(() => {
      if (scrollRef.current)
        scrollRef?.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
    }, 500);
  };

  useEffect(() => {
    if (!describedReward) {
      setShowDeliveryStep(false);
    } else {
      ref.current.innerText = describedReward;
      setFilled(true);
    }
  }, [describedReward]);

  useEffect(() => {
    if (!describedReward && !showDeliveryStep) {
      setFocused(true);
      setTimeout(() => {
        if (ref.current) ref.current.focus();
      }, 1000);
    }
  }, [ref.current, describedReward, showDeliveryStep]);

  const onClick = () => {
    setFocused(true);
    if (showDeliveryStep && !deliveryDisabled) {
      setDeliveryDisabled(true);
    }
  };

  const handleChange = (e: React.KeyboardEvent<HTMLSpanElement>): void => {
    if (e.key === 'Enter' && ref.current) {
      e.preventDefault();
      ref.current.blur();
    }
  };

  const handleBlur = (): void => {
    const newName = ref.current?.innerText.trim();

    if (!newName && ref.current) {
      setDescribedReward('');
    }

    if (describedReward !== newName) {
      setDescribedReward(newName || describedReward);
    }
    setFocused(false);
  };

  const renderPractice = useCallback(
    (message, key) => {
      const __html = isFunction(message)
        ? message(stepsValues?.[templates.static] || baseStepValues)
        : message;

      return createElement('li', { dangerouslySetInnerHTML: { __html }, key });
    },
    [baseStepValues, stepsValues?.[templates.static]?.contentType]
  );

  return (
    <div className={styles['reward-description']} data-testid="reward-description">
      <Accordion
        bodyClassNameExpanded={styles.accordion__content}
        titleClassName={styles.accordion__header}
        buttonClassName={styles.accordion__btn}
        className={styles.accordion}
        wholeTitleClickable
        title={i18n.title}
        testId="best-practices-accordion"
      >
        <p className={styles['accordion__content-title']}>{i18n.title} </p>
        <ul>{i18n.practices.map(renderPractice)}</ul>
      </Accordion>
      <div style={{ position: 'absolute', bottom: '465px' }} ref={scrollRef} />
      <p
        onClick={onClick}
        className={classNames(styles.reward_wrapper, {
          [styles.reward_wrapper_focused]: focused,
          [styles.reward_wrapper_skipped]: skipCounter !== 0
        })}
        role="presentation"
      >
        {i18n.subtitle.static.fragments.first}
        <span
          aria-label={i18n.subtitle.dynamic.aria}
          ref={ref}
          id="input"
          role="presentation"
          placeholder={i18n.subtitle.dynamic.placeholder}
          onFocus={() => {
            setFocused(true);
          }}
          onKeyDown={handleChange}
          onInput={e => (e.currentTarget.innerText ? setFilled(true) : setFilled(false))}
          onBlur={handleBlur}
          suppressContentEditableWarning
          className={styles.reward}
          style={describedReward.length > 20 ? { display: 'inline' } : {}}
          contentEditable
        >
          {describedReward}
        </span>
        {i18n.subtitle.static.fragments.second(stepsValues?.[templates.static] || baseStepValues)}
      </p>
      <div
        className={classNames(styles['no-reward-note'], {
          [styles['no-reward-note_hidden']]: skipCounter === 0
        })}
      >
        <h2>{i18n.disclaimer.title}</h2>
        {i18n.disclaimer.description.fragments.first}
        <br />
        {i18n.disclaimer.description.fragments.second}
        <button
          className={styles.feedback_btn}
          type="button"
          onClick={() => setFeedbackModal(true)}
        >
          {i18n.disclaimer.description.action}
        </button>
        {i18n.disclaimer.description.fragments.third}
      </div>
      <div
        className={classNames(styles.btns, {
          [styles.btns_hidden]: showDeliveryStep && !deliveryDisabled
        })}
      >
        {' '}
        <GlobalButton
          testid="next-btn"
          type={skipCounter !== 0 ? 'integration' : 'primary'}
          title={i18n.actions.proceed.title(skipCounter !== 0)}
          disabled={skipCounter === 0 && !filled}
          className={classNames(styles.next_btn, {
            [styles.continue_btn]: skipCounter !== 0
          })}
          onClick={() => {
            if (skipCounter !== 0) {
              setSkipCounter(0);
              setSkippedStep(false);
            } else {
              setShowDeliveryStep(true);
              setDeliveryDisabled(false);
              scrollStep();
            }
          }}
        />
        <GlobalButton
          type={skipCounter !== 0 ? 'primary' : 'neumorphic'}
          testid="skip-btn"
          title={i18n.actions.skip.title}
          className={styles.skip_btn}
          onClick={() => {
            if (skipCounter === 0) {
              setSkipCounter(prevCounter => {
                prevCounter += 1;
                return prevCounter;
              });
            } else if (skipCounter !== 0) {
              setSkipCounter(2);
              setDescribedReward('');
              setSkippedStep(true);
              onSkip(
                {
                  description: '',
                  mechanism: '',
                  link: ''
                },
                { skipped: true, template: templates.reward }
              );
            }
          }}
        />
      </div>
    </div>
  );
}

export default RewardDescription;
