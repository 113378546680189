import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import Status401Error from '@/services/Api/Errors/Status401Error';
import Status404Error from '@/services/Api/Errors/Status404Error';
import Status500Error from '@/services/Api/Errors/Status500Error';

import { eraseAuth } from '@/redux/auth/actions';
import { selectAuthExp } from '@/redux/auth/selectors';

import { storeRegistry } from '../StoreRegistryService';

export interface ResponseJson {
  message?: string;
  [key: string]: any;
}

export default class ApiAbstract {
  protected api: undefined | string;

  protected endpoint: undefined | string;

  protected params: object;

  protected body: object | FormData;

  protected headers: object;

  protected abortController: AbortController;

  protected responseJson: ResponseJson;

  protected isCamelCaseResponse: boolean;

  protected shouldSendSnakeCase: boolean;

  constructor(isCamelCaseResponse: boolean = false, shouldSendSnakeCase: boolean = false) {
    this.params = {};
    this.body = {};
    this.headers = {};
    this.responseJson = {};
    this.isCamelCaseResponse = isCamelCaseResponse;
    this.shouldSendSnakeCase = shouldSendSnakeCase;

    if (new.target === ApiAbstract) {
      throw new TypeError('Cannot construct Abstract instances directly');
    }
  }

  setEndpoint(endpoint: string): this {
    this.endpoint = endpoint;
    return this;
  }

  setParams(params: object): this {
    const paramsToSend = this.shouldSendSnakeCase ? snakecaseKeys(params, { deep: true }) : params;
    this.params = Object.assign({}, this.params, paramsToSend);
    return this;
  }

  setBody(body: object): this {
    const bodyToSend = this.shouldSendSnakeCase ? snakecaseKeys(body, { deep: true }) : body;
    this.body = bodyToSend;
    return this;
  }

  async get(): Promise<any> {
    return this.send({
      method: 'GET',
      headers: { ...this.getHeaders() }
    });
  }

  async post(): Promise<any> {
    return this.sendRequestWithBody('POST');
  }

  async put(): Promise<any> {
    return this.sendRequestWithBody('PUT');
  }

  async delete(): Promise<any> {
    return this.sendRequestWithBody('DELETE');
  }

  async patch(): Promise<any> {
    return this.sendRequestWithBody('PATCH');
  }

  abort(): this {
    if (this.abortController) this.abortController.abort();
    return this;
  }

  protected async sendRequestWithBody(method: string): Promise<any> {
    let headers = this.getHeaders();
    if (!(this.body instanceof FormData)) {
      headers = {
        'Content-Type': 'application/json',
        ...headers
      };
    }
    return this.send({
      method,
      headers: { ...headers },
      credentials: 'include',
      body: this.body instanceof FormData ? this.body : JSON.stringify(this.body)
    });
  }

  public getUri(): string | undefined {
    if (this.api && this.endpoint) {
      return this.api + this.endpoint;
    }
    return undefined;
  }

  public getQuery(): string | null {
    if (this.params) {
      return Object.entries(this.params)
        .map(pair => pair.map(encodeURIComponent).join('='))
        .join('&');
    }
    return null;
  }

  protected getHeaders(): object {
    return this.headers;
  }

  protected setHeaders(headers: object): this {
    this.headers = Object.assign({}, this.headers, headers);
    return this;
  }

  protected checkRequestData(): void {
    if (this.api === undefined) {
      throw new Error('api is not set');
    }
    if (!this.endpoint) {
      throw new Error('endpoint is not set');
    }
  }

  protected checkToken(): void {
    const exp = selectAuthExp(storeRegistry.getState());
    if (exp && exp * 1000 < new Date().getTime() - 5000) {
      throw new Error('token expired');
    }
  }

  protected async send(init: RequestInit): Promise<any> {
    this.abortController = new AbortController();

    this.checkRequestData();
    try {
      this.checkToken();
    } catch {
      storeRegistry.store?.dispatch(eraseAuth());
    }
    let uri = this.getUri();
    if (!uri) {
      return Promise.reject();
    }
    const query = this.getQuery();
    if (query) {
      uri += `?${query}`;
    }
    const response = await fetch(uri, { ...init, signal: this.abortController.signal });
    const raw = this.headers.hasOwnProperty('Accept');

    this.responseJson = await (raw ? response.text() : response.json());

    return this.handleResponse(response.status, response.url);
  }

  protected handleResponse(responseStatus: number, responseUrl: string): ResponseJson {
    const { responseJson } = this;
    switch (responseStatus) {
      case 200:
      case 204:
      case 201:
      case 207:
        // if it is errors free response - then just return the response json object

        return this.isCamelCaseResponse
          ? camelcaseKeys(responseJson, { deep: true, exclude: ['_id'] })
          : responseJson;
      case 400:
        console.error(responseJson.message);
        return this.isCamelCaseResponse
          ? camelcaseKeys(responseJson, { deep: true })
          : responseJson;
      case 401:
        responseJson.message = responseJson.message || 'Unauthorized';
        throw new Status401Error(responseJson);
      case 404:
        responseJson.message = responseJson.message || `${responseUrl} was not found.`;
        throw new Status404Error(responseJson);
      case 500:
        responseJson.message = responseJson.message || 'Internal error. Please try again later.';
        throw new Status500Error(responseJson);
      default:
        throw new Error(responseJson.message);
    }
  }
}
