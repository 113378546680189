import { ReactElement, useCallback, useState } from 'react';

import { TagsAutocomplete } from 'ui-lib-12traits/src/index';

import { updateOrRemoveSearchParam } from '@/services/UrlService';

import styles from './FilterByHighlights.module.scss';

interface Props {
  highlightedTerms: string[];
  availableHighlightedTerms: string[];
}

function FilterByHighlights({
  highlightedTerms,
  availableHighlightedTerms
}: Props): ReactElement {
  const [value, setValue] = useState<string>();

  const addHighlight = (value: string): void => {
    if (value && !highlightedTerms.includes(value)) {
      updateOrRemoveSearchParam({ highlightedTerms: [...highlightedTerms, value] });
      setValue('');
    }
  };

  const removeHighlights = (items: any[]): void => {
    const newHighlights = items.map(item => item.name);
    updateOrRemoveSearchParam({ highlightedTerms: newHighlights });
  };

  const filterTermsList = useCallback(() => {
    let filteredList = [];
    filteredList = value
      ? availableHighlightedTerms?.filter(term => term.includes(value))
      : availableHighlightedTerms;
    return filteredList?.map(item => ({ id: item, name: item }));
  }, [value, availableHighlightedTerms, highlightedTerms]);

  return (
    <div className={styles.filter__wrapper}>
      <div className={styles.filter__heading}>
        <div className={styles.filter__title}>Filter by highlights</div>
        {!!highlightedTerms.length && (
          <button
            type="button"
            onClick={(): void => {
              updateOrRemoveSearchParam({ highlightedTerms: [] });
            }}
            className={styles['filter__clear-btn']}
          >
            Clear filter
          </button>
        )}
      </div>
      <div>
        <div className={styles.filter}>
          <TagsAutocomplete
            placeholder="Search highlights"
            list={filterTermsList()}
            value={value}
            onInput={setValue}
            onChange={removeHighlights}
            onEnter={addHighlight}
            tags={highlightedTerms.map(item => ({ id: item, name: item }))}
            withIcon
          />
        </div>
      </div>
    </div>
  );
}

export default FilterByHighlights;
