import historyService from '@/services/HistoryService';

export const parseQuery = (): {
  [key: string]: string;
} => {
  const history = historyService.getHistory();
  const params: URLSearchParams = new URLSearchParams(history.location.search);
  return Object.fromEntries(params.entries());
};

const arrayIndicator = '[]';

export const updateOrRemoveSearchParam = (params: {
  [id: string]: string | string[] | null;
}): string => {
  const history = historyService.getHistory();

  const searchParams = new URLSearchParams(history.location.search);
  Object.keys(params).forEach(key => {
    if (typeof params[key] === 'string') {
      const value = params[key] as string;
      searchParams.set(key, value);
    }
    if (Array.isArray(params[key])) {
      searchParams.delete(`${key}${arrayIndicator}`);
      const values = params[key] as string[];
      values.forEach(item => {
        searchParams.append(`${key}${arrayIndicator}`, item);
      });
    }
    if (params[key] === null) {
      const name = searchParams.has(`${key}${arrayIndicator}`) ? `${key}${arrayIndicator}` : key;
      searchParams.delete(name);
    }
  });

  searchParams.sort();

  const paramsString = searchParams.toString();
  const search = paramsString ? `?${paramsString}` : '';
  history.push({ ...history.location, search });
  return search;
};

export const removeSearchParams = (params: Array<string>): void => {
  const history = historyService.getHistory();

  const searchParams = new URLSearchParams(history.location.search);

  if (params) {
    params.forEach(param => searchParams.delete(param));
  }
  searchParams.sort();

  const searchParamsString = searchParams.toString();

  const search = searchParamsString ? `?${searchParamsString}` : '';
  history.push({ ...history.location, search });
};
