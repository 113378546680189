/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { SettingsPageState } from './types';

const initialState: SettingsPageState = {
  data: {
    name: '',
    status: null,
    genres: {
      primary: null,
      additional: []
    },
    thumbnailUrl: '',
    coverUrl: ''
  },
  isActive: false
};

const slice = createSlice({
  name: 'SETTINGS_PAGE',
  initialState,
  reducers: {
    clearSettings: state => {
      state = initialState;
    },
    toggleGameSettings: state => {
      state.isActive = !state.isActive;
    }
  }
});

export const { clearSettings, toggleGameSettings } = slice.actions;
export default slice.reducer;

export const selectIsActive = (state: any) => state.settingsPage.isActive;
