import merge from 'lodash-es/merge';

import { ParsedGeocodeData } from 'ui-lib-12traits/src/index';

import UserService from '@/services/Api/UserApiService';

import { UserPreferences } from '@/redux/user/types';

import { ApiResponse } from '../types';

import { ConfiguredIDPData, SSOProviders, SSOURLs } from './types';

export const fetchLogin = async (email: string, pass: string): Promise<any> =>
  new UserService({ version: 'v2' }).setEndpoint('/auth/login').setBody({ email, pass }).post();

export const updateSSO = async (provider: SSOProviders, data: FormData | string): Promise<any> =>
  new UserService({ version: 'v1', shouldSendSnakeCase: provider === 'okta' })
    .setEndpoint('/sso/config')
    .setParams({ idp: provider, ...(provider === 'okta' ? { metadataUrl: data } : {}) })
    .setBody(data instanceof FormData ? data : {}, true)
    .post();

export const fetchConfiguredIDP = async (): Promise<ApiResponse<ConfiguredIDPData>> =>
  new UserService({ version: 'v1' }).setEndpoint('/sso/configuredIDP').get();

export const removeSSOConfig = async (): Promise<ApiResponse<null>> =>
  new UserService({ version: 'v1' }).setEndpoint('/sso/config').delete();

export const fetchLogout = async (): Promise<any> =>
  new UserService({ version: 'v2' }).setEndpoint('/auth/logout').post();

export const fetchForgotPass = async (email: string): Promise<any> =>
  new UserService({ version: 'v2' }).setEndpoint('/auth/forgot-pass').setBody({ email }).post();

export const fetchNewPass = async (pass: string, token: string): Promise<any> =>
  new UserService({ version: 'v2' })
    .setEndpoint('/auth/create-pass')
    .setBody({ pass, token })
    .post();

export const fetchUserInfo = async (): Promise<any> => new UserService().setEndpoint(`/me`).get();

export interface UpdateDataInfoI {
  firstName: string;
  lastName: string;
  jobTitle: string;
  timezone?: string;
  datetimeNumbersFormat?: string;
}
export const updateUserInfo = async (data: UpdateDataInfoI): Promise<any> =>
  new UserService().setEndpoint(`/me`).setBody(data).put();

export const uploadAvatar = async (formData: FormData): Promise<any> =>
  new UserService({ isCamelCaseResponse: false, shouldSendSnakeCase: false })
    .setEndpoint('/me/avatar')
    .setBody(formData, true)
    .post();

export const fetchUserPreferences = async (): Promise<any> =>
  new UserService().setEndpoint(`/me/preferences`).get();

export const updateUserPreferences = async (next: UserPreferences): Promise<any> => {
  const concat = ({ data: current }) => {
    const preferences = merge(current, next);

    return new UserService().setEndpoint(`/me/preferences`).setBody(preferences).post();
  };

  return fetchUserPreferences().then(concat);
};

interface UpdateCompanyDataInfoI {
  name?: string;
  billingAddress?: string;
  primaryOwnerId?: string;
  billingAddressDetails?: ParsedGeocodeData | null;
}

export const updateCompanyInfo = async (data: UpdateCompanyDataInfoI): Promise<any> =>
  new UserService().setEndpoint(`/company`).setBody(data).put();

export const uploadCompanyAvatar = async (formData: FormData): Promise<any> =>
  new UserService({ isCamelCaseResponse: false, shouldSendSnakeCase: false })
    .setEndpoint('/company/avatar')
    .setBody(formData, true)
    .post();

export const deleteCompanyAvatar = async (): Promise<any> =>
  new UserService({ isCamelCaseResponse: false, shouldSendSnakeCase: false })
    .setEndpoint('/company/avatar')
    .delete();

interface FetchTeamParams {
  owners?: boolean;
  dashboardId?: string;
  query?: string;
  excludeDashboardId?: string;
}
export const fetchTeam = async (params?: FetchTeamParams): Promise<any> => {
  const request = new UserService().setEndpoint('/team');

  if (params) {
    request.setParams(params);
  }

  return request.get();
};

export const fetchActiveTeam = async (params?: FetchTeamParams): Promise<any> => {
  const request = new UserService().setEndpoint('/team');

  if (params) {
    request.setParams(params);
  }

  return request.get();
};

interface PassChangeData {
  oldPass: string;
  newPass: string;
  confirmNewPass: string;
}
export const changePass = async (data: PassChangeData): Promise<any> =>
  new UserService().setEndpoint('/me/changePass').setBody(data).post();

export const consent = async (data: { email: string; marketingEmails: boolean }): Promise<any> =>
  new UserService().setEndpoint('/me/consent').setBody(data).post();

export interface User {
  role: string;
  email?: string;
  userId?: string;
  dashboardId?: string;
  subscriptionId?: string;
  changeCompany?: boolean;
}

// TODO: use withCompanyChange param properly
export const inviteNewUsers = async (users: User[]): Promise<void> =>
  new UserService().setEndpoint('/team/invite').setBody({ users }).post();

interface RevokeUser {
  userId: string;
  dashboardId: string;
}
export const revokeTeam = async (users: RevokeUser[]): Promise<any> =>
  new UserService().setEndpoint('/team/revoke').setBody({ users }).post();

export const changeRole = async (user: User): Promise<any> =>
  new UserService().setEndpoint('/team/roles').setBody(user).post();

export const resendInvite = async (email: string): Promise<void> =>
  new UserService().setEndpoint('/team/invite/resend').setBody({ email }).post();

export const deleteUser = async (userId: string): Promise<any> =>
  new UserService().setEndpoint(`/users/${userId}`).delete();

export const fetchCompany = async (): Promise<any> =>
  new UserService().setEndpoint('/company').get();

export const fetchSSOLogins = async (): Promise<any> =>
  new UserService().setEndpoint('/sso/loginURL').get();

export const fetchSSOURLs = (): Promise<ApiResponse<SSOURLs>> =>
  new UserService().setEndpoint('/sso/urls').get();
