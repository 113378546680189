import CoreApiService from '@/services/Api/CoreApiService';

import { Game } from '@/redux/games/types';

import { ApiResponse } from '../types';

import { GetDashboardsParams, KPI, NestedGameSettings } from './types';

export const nestedGameSettings = (): any => {
  const returnObj = new CoreApiService();
  return returnObj.setEndpoint('/config/settings/nested-options');
};

export const fetchNestedGameSettings = async (): Promise<ApiResponse<NestedGameSettings>> =>
  nestedGameSettings().get();

export const fetchKpis = async (): Promise<ApiResponse<{ kpis: KPI[] }>> =>
  new CoreApiService().setEndpoint('/config/kpis').get();

export const createNewGame = async (data: any): Promise<any> =>
  new CoreApiService().setEndpoint('/company/games').setBody(data).post();

export const getDashboards = async (
  params?: GetDashboardsParams
): Promise<ApiResponse<{ dashboards: Game[] }>> => {
  const request = new CoreApiService({ shouldSendSnakeCase: true }).setEndpoint(
    '/company/dashboards'
  );

  if (params) {
    request.setParams(params);
  }
  return request.get();
};

export const fetchComparisonOverview = async (body: any): Promise<any> =>
  new CoreApiService({ shouldSendSnakeCase: true })
    .setEndpoint('/comparison/overview')
    .setBody(body)
    .post();

export const saveToFavorites = async (id: string): Promise<any> =>
  new CoreApiService().setEndpoint(`/company/dashboards/${id}/fav`).put();

export const removeFromFavorites = async (id: string): Promise<any> =>
  new CoreApiService().setEndpoint(`/company/dashboards/${id}/fav`).delete();

export const fetchGenreDetails = async (id: number): Promise<any> =>
  new CoreApiService().setEndpoint(`/genres/${id}`).get();

export const genresRecommended = (): any => {
  const returnObj = new CoreApiService();
  returnObj.setEndpoint('/genres/recommended');
  return returnObj;
};

export const getGenresRecommended = async (): Promise<any> => genresRecommended().get();

export const fetchGenresHashMap = async (): Promise<any> =>
  new CoreApiService().setEndpoint('/genres').get();

export const simulateCraftedFilters = async (data: {
  games?: string[];
}): Promise<any> =>
  new CoreApiService({ shouldSendSnakeCase: true })
    .setEndpoint(`/crafted/filter/simulate`)
    .setBody(data)
    .post();

export const saveCraftedFilters = async (data: {
  id: string;
  games?: string[];
}): Promise<any> =>
  new CoreApiService({ shouldSendSnakeCase: true })
    .setEndpoint(`/crafted/filter/save`)
    .setBody(data)
    .post();

export const getMostVisitedGenres = async (): Promise<any> =>
  new CoreApiService().setEndpoint('/genres/most-accessed').get();

export const getKpisTemplate = async (ext: 'csv' | 'xlsx'): Promise<any> =>
  new CoreApiService({ version: 'v2', useGameJwt: true })
    .setEndpoint('/kpis/templates')
    .setParams({ ext })
    .get();

export const getSurveyTemplate = async (ext: 'csv' | 'xlsx'): Promise<any> =>
  new CoreApiService({ version: 'v1', useGameJwt: true })
    .setEndpoint('/custom-survey-data/templates')
    .setParams({ ext })
    .get();

export const fetchMarketSize = async (cluster: string): Promise<any> =>
  new CoreApiService({ version: 'v1', useGameJwt: true })
    .setEndpoint('/dashboard/market/size')
    .setParams({ cluster })
    .get();

export const fetchMarketSpend = async (cluster: string): Promise<any> =>
  new CoreApiService({ version: 'v1', useGameJwt: true })
    .setEndpoint('/dashboard/market/spend')
    .setParams({ cluster })
    .get();

export const fetchMarketGenres = async (cluster: string): Promise<any> =>
  new CoreApiService({ version: 'v1', useGameJwt: true })
    .setEndpoint('/dashboard/market/genres')
    .setParams({ cluster })
    .get();

export const setDashboardViewed = async (segment?: string | null): Promise<any> =>
  new CoreApiService({ version: 'v1', useGameJwt: true })
    .setEndpoint(`/dashboard/last-viewed`)
    .setParams(segment ? { segment } : {})
    .put();
