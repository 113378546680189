import { useRef, useEffect } from 'react';

export default function useDropdownPosition(
  expanded: boolean,
  { dynamic }: { dynamic: boolean } = { dynamic: true }
) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const headRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!dynamic) return;

    const listEl = listRef.current;
    const dropdownEl = dropdownRef.current;

    if (!listEl || !dropdownEl || !expanded) return;

    const dropdownHeight = listEl.clientHeight;
    const windowHeight = window.innerHeight;
    const dropdownTop = dropdownEl.getBoundingClientRect().top;

    if (windowHeight - dropdownTop - 20 < dropdownHeight) {
      listEl.style.bottom = `${headRef.current?.clientHeight}px`;
      listEl.style.top = 'auto';
    } else {
      listEl.style.bottom = 'auto';
      listEl.style.top = `${headRef.current?.clientHeight}px`;
    }
  }, [expanded]);

  return { dropdownRef, listRef, headRef };
}
