/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { TaxonomyNode } from '../types';

/**
 * Normalizes a tree of taxonomy nodes into a flat structure with references to parent IDs and children IDs.
 *
 * @param {Array<TaxonomyNode>} tree
 * The array of taxonomy nodes to be normalized. Each node is expected
 * to potentially have a `children` array containing other `TaxonomyNode` objects.
 *
 * @returns {Record<string, TaxonomyNode>}
 *
 * @example
 * Assuming TaxonomyNode is an object like { id: string, parent_id?: string, children?: Array<TaxonomyNode> }
 * const inputTree = [
 *   { id: "1", children: [
 *     { id: "2", children: [
 *       { id: "3" }
 *     ]}
 *   ]}
 * ];
 *
 * const normalized = normalizeTaxonomyTree(inputTree);
 * Output would be:
 * {
 *    "1": { id: "1", parent_id: null, children: ["2"] },
 *    "2": { id: "2", parent_id: "1", children: ["3"] },
 *    "3": { id: "3", parent_id: "2", children: [] }
 * }
 */
export function normalizeTaxonomyTree(tree: Array<TaxonomyNode>) {
  const res = {} as Record<string, TaxonomyNode>;
  for (const node of tree) {
    processNode(node);
  }
  return res;

  function processNode(node: TaxonomyNode, parentId = null) {
    const { children, ...newNode } = node;

    newNode.parent_id = parentId;
    newNode.children = (children || []).map(child => child.id);

    res[newNode.id] = newNode;

    for (const child of children || []) {
      processNode(child, newNode.id);
    }
  }
}

/**
 * Filters nodes from a normalized taxonomy tree, selecting only those nodes that have both a parent
 * and one or more children. Those are "features"
 *
 * @param {Record<string, TaxonomyNode>} normalizeTaxonomyTree
 * @returns {Array<TaxonomyNode>} An array of `TaxonomyNode` objects that have both a parent and one or more children.
 *
 * @example
 * Assuming TaxonomyNode is an object like { id: string, parent_id?: string, children: string[] }
 * const normalizedTree = {
 *   "1": { id: "1", parent_id: null, children: ["2"] },
 *   "2": { id: "2", parent_id: "1", children: ["3"] },
 *   "3": { id: "3", parent_id: "2", children: [] }
 * };
 * const filteredNodes = filterNodesWithParentAndChildren(normalizedTree);
 * console.log(filteredNodes);
 * Output would be:
 * [{ id: "2", parent_id: "1", children: ["3"] }]
 */
export function filterNodesWithParentAndChildren(
  normalizeTaxonomyTree: Record<string, TaxonomyNode>
) {
  const res = [];

  for (const k in normalizeTaxonomyTree) {
    const node = normalizeTaxonomyTree[k];
    if (node.parent_id !== null && node.children.length > 0) {
      res.push(node);
    }
  }

  return res;
}

export function createCategoriesOptions<
  T extends { label: string; name: string; value: string; checked: boolean }
>(tree: Array<TaxonomyNode>): Array<T> {
  const res = [];
  const copy = tree.slice();

  for (const node of copy) {
    if (node.parent_id === null) {
      const copy = Object.assign({}, node);
      delete copy.children;

      res.push({
        ...copy,
        label: node.name,
        value: node.name?.toLowerCase(),
        checked: true,
        name: node.type
      });
    }
  }

  return res;
}
