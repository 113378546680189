import { createSelector } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import camelcaseKeys from 'camelcase-keys';

import { useAppSelector } from '@/hooks/useApp';

import { prepareBaseQuery } from '@/redux/helpers';

import { selectCartProducts } from '../cart/selectors';
import { CartProductData } from '../cart/types';

import { WishlistItem } from './types';

export const wishlistApi = createApi({
  reducerPath: 'wishlistApi',
  tagTypes: ['Wishlist'],
  baseQuery: fetchBaseQuery(
    prepareBaseQuery({
      endpoint: 'core',
      useGameJwt: false
    })
  ),
  endpoints: build => ({
    addItemToUserWishlist: build.mutation<any, number>({
      query: genreId => ({
        url: `/genres/wishlist/${genreId}`,
        method: 'PUT'
      }),
      transformResponse: result => camelcaseKeys((result as { data: any }).data, { deep: true }),
      invalidatesTags: ['Wishlist']
    }),
    removeItemFromUserWishlist: build.mutation<any, number>({
      query: genreId => ({
        url: `/genres/wishlist/${genreId}`,
        method: 'DELETE'
      }),
      transformResponse: result => camelcaseKeys((result as { data: any }).data, { deep: true }),
      invalidatesTags: ['Wishlist'],
      onQueryStarted: (id, { dispatch, queryFulfilled }) => {
        const deleteResult = dispatch(
          wishlistApi.util.updateQueryData('getWishlist', undefined, (wishlist: WishlistItem[]) =>
            wishlist.filter(({ genreId }) => genreId !== id)
          )
        );
        queryFulfilled.catch(deleteResult.undo);
      }
    }),
    getWishlist: build.query<any, void>({
      queryFn: async () => {
        // These lines are commented out,
        // because these API calls are not needed for the aplication
        // and only spam the network
        // const [companyWishlistRequest, userWishlistRequest] = await Promise.all([
        //   baseQuery('/genres/wishlist/company'),
        //   baseQuery('/genres/wishlist/user')
        // ]);
        const companyWishlistRequest = {
          error: undefined,
          data: {
            data: []
          }
        };
        const userWishlistRequest = {
          error: undefined,
          data: {
            data: []
          }
        };
        if (companyWishlistRequest.error) {
          return {
            error: companyWishlistRequest.error
          };
        }
        if (userWishlistRequest.error) {
          return {
            error: userWishlistRequest.error
          };
        }

        const userWishlist = camelcaseKeys((userWishlistRequest.data as { data: any }).data, {
          deep: true
        });
        const companyWishlistT = camelcaseKeys(
          (companyWishlistRequest.data as { data: any }).data,
          { deep: true }
        );

        const companyWishlist = companyWishlistT?.map((item: WishlistItem) => ({
          ...item,
          isSavedByUser: !!userWishlist?.find(
            ({ genreId }: WishlistItem) => genreId === item.genreId
          )
        }));

        return {
          data: companyWishlist ?? null
        };
      },
      providesTags: ['Wishlist']
    })
  })
});
export const {
  useRemoveItemFromUserWishlistMutation,
  useAddItemToUserWishlistMutation,
  useGetWishlistQuery
} = wishlistApi;

export const selectWishlistResult = wishlistApi.endpoints.getWishlist.select();

export const selectWishlist = createSelector(
  selectWishlistResult,
  selectCartProducts,
  (wishlist, cartProducts) => {
    if (!cartProducts) return wishlist.data;

    return wishlist?.data?.filter(
      (item: WishlistItem) =>
        !cartProducts.find((product: CartProductData) => product.slug === item.genreDetails.slug)
    );
  }
);

export const selectGenreIds = createSelector(selectWishlist, (wishlist: WishlistItem[]) =>
  wishlist.map(item => item.genreId)
);

export const useGetWishlist = () => {
  const { isLoading, isError } = useGetWishlistQuery();
  const data = useAppSelector(selectWishlist) as WishlistItem[] | undefined;
  return { data, isError, isLoading };
};
