import { UpdateDataInfoI } from '@/services/Api/UserService';

import { prepareAction } from '@/redux/helpers';
import { Action } from '@/redux/types';

import { ViewEnum } from '@/components/Frequency/types';

import {
  type UserCompany,
  SET_USER_COMPANY,
  LOAD_USER_INFO,
  LOAD_USER_INFO_SUCCESS,
  SET_IS_LOADING,
  UPDATE_USER_DATA,
  UPDATE_USER_AVATAR_URL,
  UPLOAD_AVATAR,
  UPLOAD_COMPANY_AVATAR,
  UPDATE_COMPANY_AVATAR_URL,
  UserInfo,
  LOAD_USER_PREFERENCES,
  UPDATE_USER_PREFERENCES,
  LOAD_USER_PREFERENCES_SUCCESS,
  UserPreferences,
  UPDATE_FREQ_PREFERENCES,
  UserFrequencyPreferences,
  SET_IS_LOADING_PREFERENCES,
  UPDATE_FREQ_PREFERENCES_BUILDS_LIBRARY_SORT,
  UserFrequencyPreferencesBuildsLibrarySort,
  UPDATE_FREQ_PREFERENCES_BUILDS_LIBRARY_VIEW,
  SET_ASSESSMENT_FTUE_STEP,
  UserPreferencesAssessmentList,
  UPDATE_NAV_PREFERENCES_ASSESSMENT_LIST_SORT,
  UPDATE_USER_HAVE_ASSESSMENTS,
  UPDATE_USER_HAVE_GAMES,
  SET_CONSENT,
  RESET_USER,
  Consent,
  SET_EXPLORER_STATE,
  SET_TRAIT_DATA_CONFIG_STATE
} from './types';

export const setUserCompany = (company: UserCompany): Action =>
  prepareAction(SET_USER_COMPANY, { company });

export const loadUserInfo = (): Action => prepareAction(LOAD_USER_INFO);

export const loadUserInfoSuccess = (data: UserInfo): Action =>
  prepareAction(LOAD_USER_INFO_SUCCESS, { data });

export const setIsLoading = (isLoading: boolean): Action =>
  prepareAction(SET_IS_LOADING, isLoading);

export const setIsLoadingPreferences = (isLoading: boolean): Action =>
  prepareAction(SET_IS_LOADING_PREFERENCES, isLoading);

export const updateUserData = (data: UpdateDataInfoI): Action =>
  prepareAction(UPDATE_USER_DATA, { data });

export const updateUserAvatarUrl = (url: string): Action =>
  prepareAction(UPDATE_USER_AVATAR_URL, url);

export const uploadAvatar = (formData: FormData): Action => prepareAction(UPLOAD_AVATAR, formData);

export const updateCompanyAvatarUrl = (url: string): Action =>
  prepareAction(UPDATE_COMPANY_AVATAR_URL, url);

export const uploadCompanyAvatar = (formData: FormData): Action =>
  prepareAction(UPLOAD_COMPANY_AVATAR, formData);

export const loadUserPreferences = (): Action => prepareAction(LOAD_USER_PREFERENCES);

export const updateUserPreferences = (data: UserPreferences): Action =>
  prepareAction(UPDATE_USER_PREFERENCES, { data });

export const updateFrequencyPreferences = (data: UserFrequencyPreferences): Action =>
  prepareAction(UPDATE_FREQ_PREFERENCES, { data });

export const loadUserPreferencesSuccess = (data: UserPreferences): Action =>
  prepareAction(LOAD_USER_PREFERENCES_SUCCESS, { data });

export const updateFrequencyPreferencesBuildsLibrarySort = (
  data: UserFrequencyPreferencesBuildsLibrarySort
): Action => prepareAction(UPDATE_FREQ_PREFERENCES_BUILDS_LIBRARY_SORT, { data });

export const updateFrequencyPreferencesBuildsLibraryView = (view: ViewEnum): Action =>
  prepareAction(UPDATE_FREQ_PREFERENCES_BUILDS_LIBRARY_VIEW, { view });

export const setUserAssessmentFTUEStep = (step: number): Action =>
  prepareAction(SET_ASSESSMENT_FTUE_STEP, step);

export const updateNavigatorPreferencesAssessmentListSort = (
  data: UserPreferencesAssessmentList
): Action => prepareAction(UPDATE_NAV_PREFERENCES_ASSESSMENT_LIST_SORT, { data });

export const setUserHaveAssessments = (haveAssessments: boolean): Action =>
  prepareAction(UPDATE_USER_HAVE_ASSESSMENTS, haveAssessments);

export const setUserHaveGames = (haveDashboards: boolean): Action =>
  prepareAction(UPDATE_USER_HAVE_GAMES, haveDashboards);

export const setExplorerState = (state: string): Action =>
  prepareAction(SET_EXPLORER_STATE, state);

  export const setTraitsDataConfig = (state: string): Action =>
  prepareAction(SET_TRAIT_DATA_CONFIG_STATE, state);

export const setUserConsent = (consent: Consent): Action => prepareAction(SET_CONSENT, consent);

export const resetUser = (): Action => prepareAction(RESET_USER);
