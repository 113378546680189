export const enum SocialListeningSources {
  Twitter = 'twitter',
  AppleAppStore = 'apple_app_store',
  GooglePlayStore = 'google_play_store',
  Youtube = 'youtube'
}

export const enum SocialListeningActionTypes {
  SetSocialListeningLoaded = 'SocialListening / SET_SOCIAL_LISTENING_LOADED',
  UpdateSocialListening = 'SocialListening / UPDATE_SOCIAL_LISTENING',
  UpdateTwitterIntegration = 'SocialListening / UPDATE_TWITTER_INTEGRATION',
  UpdateYoutubeIntegration = 'SocialListening / UPDATE_YOUTUBE_INTEGRATION',
  UpdateGooglePlayStoreIntegration = 'SocialListening / UPDATE_GOOGLE_PLAY_STORE_INTEGRATION',
  UpdateAppleAppStoreIntegration = 'SocialListening / UPDATE_APPLE_APP_STORE_INTEGRATION',
  RemoveIntegrationSource = 'SocialListening / REMOVE_INTEGRATION_SOURCE'
}

export interface IntegrationBase<
  Source extends SocialListeningSources[keyof SocialListeningSources],
  Config extends PossibleConfigurations[keyof PossibleConfigurations]
> {
  socialListeningSource: Source;
  enabled: boolean;
  config?: Config;
}

export interface TwitterConfig {
  accounts: string[];
  hashtags: string[];
}

export type TwitterIntegration = IntegrationBase<SocialListeningSources.Twitter, TwitterConfig>;

export interface AppleAppStoreConfig {
  storeId: string;
}

export type AppleAppStoreIntegration = IntegrationBase<
  SocialListeningSources.AppleAppStore,
  AppleAppStoreConfig
>;

export interface GooglePlayStoreConfig {
  storeId: string;
}

export type GooglePlayStoreIntegration = IntegrationBase<
  SocialListeningSources.GooglePlayStore,
  GooglePlayStoreConfig
>;

export interface YoutubeChannelConfig {
  channelId: string;
  searchTerms: string[];
}

export interface YoutubeConfig {
  channels: YoutubeChannelConfig[];
}

export type YoutubeIntegration = IntegrationBase<SocialListeningSources.Youtube, YoutubeConfig>;

export interface PossibleConfigurations {
  [SocialListeningSources.Youtube]: YoutubeConfig;
  [SocialListeningSources.Twitter]: TwitterConfig;
  [SocialListeningSources.AppleAppStore]: AppleAppStoreConfig;
  [SocialListeningSources.GooglePlayStore]: GooglePlayStoreConfig;
}

export type SocialListeningSource =
  | TwitterIntegration
  | AppleAppStoreIntegration
  | GooglePlayStoreIntegration
  | YoutubeIntegration;

export type SocialListeningIntegrationSources = SocialListeningSource[];

export interface SocialListeningState {
  gameId: string;
  enabled: boolean;
  sources: SocialListeningIntegrationSources;
  isLoaded: boolean;
}

export type SocialListeningStateBase = Omit<SocialListeningState, 'isLoaded'>;
