import CoreApiService from '@/services/Api/CoreApiService';

export default class AdsApiService extends CoreApiService {
  constructor(
    config: {
      version?: string;
      useGameJwt?: boolean;
      withoutLoginRedirect?: boolean;
      isCamelCaseResponse?: boolean;
      shouldSendSnakeCase?: boolean;
    } = {}
  ) {
    const {
      version = 'v1',
      useGameJwt = true,
      withoutLoginRedirect = false,
      isCamelCaseResponse = true,
      shouldSendSnakeCase = false
    } = config;
    super({ version, useGameJwt, withoutLoginRedirect, isCamelCaseResponse, shouldSendSnakeCase });
    this.api = `${process.env.BASE_URL}/freq`;
  }
}
