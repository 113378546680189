
import { today, getLocalTimeZone } from '@internationalized/date';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { useDateFormatter, VisuallyHidden } from 'react-aria';

import ArrowIcon from 'ui-lib-12traits/src/Icons/svg/common/rounded-arrow.svg?component';

import { Button } from './Button';
import styles from './styles.module.scss';



export function CalendarHeader({
  state,
  calendarProps,
  prevButtonProps,
  nextButtonProps
}): ReactElement {
  const monthDateFormatter = useDateFormatter({
    month: 'long',
    year: 'numeric',
    timeZone: state.timeZone
  });

  return (
    <div className={styles.header__wrapper}>
      <VisuallyHidden>
        <h2>{calendarProps['aria-label']}</h2>
      </VisuallyHidden>
      <Button {...prevButtonProps} testId="left-arrow-button">
        <ArrowIcon className={styles.arrow_left} />
      </Button>
      <h2 aria-hidden className={styles.header__range} data-testid="left-month-header">
        {monthDateFormatter.format(state.visibleRange.start.toDate(state.timeZone))}
      </h2>
      <h2 aria-hidden className={styles.header__range} data-testid="right-month-header">
        {monthDateFormatter.format(
          state.visibleRange.start.add({ months: 1 }).toDate(state.timeZone)
        )}
      </h2>
      <Button {...nextButtonProps} testId="right-arrow-button">
        <ArrowIcon
          className={classNames(styles.arrow_right, {
            [styles.arrow_hidden]:
              monthDateFormatter.format(today(getLocalTimeZone()).toDate(state.timeZone)) ===
              monthDateFormatter.format(
                state.visibleRange.start.add({ months: 1 }).toDate(state.timeZone)
              )
          })}
        />
      </Button>
    </div>
  );
}
