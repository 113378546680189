import useTermsAndConditions from './hooks';
import styles from './TermsAndConditions.module.scss';

export default function () {
  const { error, invalid, onChange, onClick, onSubmit, ref, submitting } = useTermsAndConditions();

  return (
    <div className={styles.container}>
      <h1>
        <span>Welcome to </span>
        <a href="http://solsten.io/" rel="noopener noreferrer" target="_blank" title="Solsten">
          Solsten
        </a>
        <span>, the human insights engine.</span>
      </h1>
      <form onChange={onChange} onSubmit={onSubmit} noValidate>
        <fieldset>
          <legend>
            Maximize your experience and empower your audience. Please share your email address with
            us to access your free dashboard.
          </legend>
          <div>
            <label htmlFor="email">Email</label>
            <input
              aria-invalid={invalid}
              aria-errormessage="error"
              defaultValue=""
              id="email"
              name="email"
              pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
              placeholder="Your_email@address.com"
              ref={ref}
              type="email"
              required
            />
            {invalid && <p id="error">{error}</p>}
          </div>
          <div>
            <input
              id="marketingEmails"
              name="marketingEmails"
              type="checkbox"
              value="true"
              defaultChecked
            />
            <label htmlFor="marketingEmails">
              I want to receive emails highlighting new features on the dashboard, our other
              products and services and special events.
            </label>
          </div>
          <p>
            <span>By clicking &quot;Unlock Insights&quot; you agree to our </span>
            <a
              href="https://solsten.io/terms-of-service"
              rel="noopener noreferrer"
              target="_blank"
              title="Terms of Service"
            >
              [Terms of Service]
            </a>
            <span> and acknowledge the </span>
            <a
              href="https://solsten.io/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
              title="Privacy Policy"
            >
              [Privacy Policy]
            </a>
            <span>.</span>
          </p>
          <div onClick={onClick}>
            <button id="submit" name="submit" type="submit" disabled>
              {submitting.pending ? 'Unlocking...' : 'Unlock Insights'}
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
