import { BrowserHistory, createBrowserHistory } from 'history';

class HistoryService {
  history: BrowserHistory;

  constructor() {
    this.history = createBrowserHistory();
  }

  setHistory(history: BrowserHistory): void {
    this.history = history;
  }

  getHistory(): BrowserHistory {
    return this.history;
  }
}

const historyService = new HistoryService();

export default historyService;
