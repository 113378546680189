import { selectCurrentSegmentId } from '@/redux/segment/selectors';

import { Game } from '../games/types';
import { RootState } from '../rootStore';
import { Cluster, Segment } from '../types';

import {
  DashboardConfig,
  Namespace,
  GameState,
  NamespaceEnum,
  getNamespaceContentType,
  getNamespaceDataType,
  ContentType,
  DataType,
  ContentTypeEnum
} from './types';

// selects game from /redux/game
const selectCurrentGame = (state: RootState): GameState => state.game;

// select game from /redux/games
export const selectGame = (state: RootState): Game | null | undefined => {
  if (state.games.list && state.game.slug) {
    return state.games.list.find((item: Game) => item.slug === state.game.slug);
  }
  return null;
};

export const selectGameId = (state: RootState): string => {
  const currentGame = selectGame(state);
  return currentGame ? currentGame.id : '';
};

export const selectGameName = (state: RootState): string => {
  const currentGame = selectGame(state);
  return currentGame ? currentGame.name : '';
};

export const selectGameSlug = (state: RootState): string => {
  const currentGame = selectGame(state);
  return currentGame ? currentGame.slug : '';
};

export const selectGameJwt = (state: RootState): string => {
  const currentGame = selectGame(state);
  return currentGame ? currentGame.jwt : '';
};

export const selectGameIngestionApiKey = (state: RootState): string => {
  const currentGame = selectGame(state);
  return currentGame ? currentGame.ingestion_api_key : '';
};

export const selectSegments = (state: RootState): Segment[] => {
  const currentGame = selectGame(state);
  return currentGame ? currentGame.segments : [];
};

export const selectClusters = (state: RootState): Cluster[] => {
  const currentSegmentId = selectCurrentSegmentId(state);
  const segment = selectSegments(state).find(({ id }) => id === currentSegmentId);
  return segment && segment.clusters ? segment.clusters : [];
};

export const selectDashboardConfig = (state: RootState): DashboardConfig | null => {
  const currentGame = selectCurrentGame(state);
  return currentGame ? currentGame.dashboardConfig : null;
};

export const selectNewFbTargeting = (state: RootState): boolean => {
  const dashboardConfig = selectDashboardConfig(state);
  return dashboardConfig ? dashboardConfig.hasFbTargeting : false;
};

export const selectNamespace = (state: RootState): Namespace => {
  const dashboardConfig = selectDashboardConfig(state);
  return dashboardConfig ? dashboardConfig.namespace : NamespaceEnum.TraitGaming;
};

export const selectNamespaceContentType = (state: RootState): ContentType => {
  const namespace = selectNamespace(state);
  return getNamespaceContentType(namespace);
};

export const selectNamespaceDataType = (state: RootState): DataType => {
  const namespace = selectNamespace(state);
  return getNamespaceDataType(namespace);
};

export const selectHasPlayerIds = (state: RootState): boolean => {
  const dashboardConfig = selectDashboardConfig(state);
  return dashboardConfig ? dashboardConfig.hasPlayerIds : false;
};

export const selectHasKpis = (state: RootState): boolean => {
  const dashboardConfig = selectDashboardConfig(state);
  return dashboardConfig ? dashboardConfig.hasKpis : false;
};

export const selectHasOnlyPersonas = (state: RootState): boolean => {
  const dashboardConfig = selectDashboardConfig(state);
  return dashboardConfig ? dashboardConfig.hasOnlyPersonas : false;
};

export const selectHasLookalike = (state: RootState): boolean => {
  const dashboardConfig = selectDashboardConfig(state);
  return dashboardConfig ? dashboardConfig.hasLookalike : false;
};

export const selectIsKpisUploaded = (state: RootState): boolean => {
  const dashboardConfig = selectDashboardConfig(state);
  return dashboardConfig ? dashboardConfig.isKpisUploaded : false;
};

export const selectIsMedicalDashboard = (state: RootState): boolean => {
  const contentType = selectNamespaceContentType(state);
  return contentType === ContentTypeEnum.Medical;
};
