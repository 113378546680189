import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareBaseQuery } from '@/redux/helpers';

export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: fetchBaseQuery(
    prepareBaseQuery({
      endpoint: 'core',
      useGameJwt: false
    })
  ),
  endpoints: build => ({
    geKpisList: build.query<any, void>({
      query: () => ({
        url: '/config/kpis'
      }),
      transformResponse: data => (data as any).data
    })
  })
});

export const { useGeKpisListQuery } = coreApi;
