import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';


import styles from './SurveyList.module.scss';

interface Props {
  items: { id: string; content: ReactNode }[];
  itemClassName?: string;
  listTitle?: string;
  titleClassName?: string;
  listClassName?: string;
}

function SurveyList({
  items,
  itemClassName,
  listTitle,
  titleClassName,
  listClassName
}: Props): ReactElement {
  return <ul className={classNames(styles.list, listClassName)}>
    {listTitle && <div className={classNames(styles.title, titleClassName)}>{listTitle}</div>}
    {items.map(item => (
      <li className={classNames(styles.list__item, itemClassName)} key={item.id}>
        {item.content}
      </li>
    ))}
  </ul>
}

export default SurveyList;
