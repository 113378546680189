import { useAppSelector } from '@/hooks/useApp';

import { useFetchCartQuery } from './cart/api';
import { selectIsGamingCompany, selectIsUserCompanyOwner } from './user/selectors';

export const useInitialRequest = () => {
  const isCompanyOwner = useAppSelector(selectIsUserCompanyOwner);
  const isGamingCompany = useAppSelector(selectIsGamingCompany);
  useFetchCartQuery(undefined, {
    skip: !(isCompanyOwner && isGamingCompany)
  });
};
