import React, { useState } from 'react';

import { Route, Redirect } from 'react-router-dom';

import { useAppSelector } from '@/hooks/useApp';

import { aliasesOverviewPath } from '@/route/paths';

import { selectAppJwt, selectIsAuthenticated } from '@/redux/auth/selectors';
import {
  getUserEmail,
  getUserId,
  selectIsPreviewMode,
  selectUserInfo
} from '@/redux/user/selectors';

import useRedirect from './useRedirect';

interface Props {
  page: React.ComponentType<any>;
  layout: React.ComponentType<any>;
  fullWidth?: boolean;
  customFooter?: boolean;
  path: string | string[];
  footerWithoutBg?: boolean;
  withoutCart?: boolean;
  previewable?: boolean;
  isFooterFixed?: boolean;
  withoutGameMenu?: boolean;
}

function PrivateRoute({
  fullWidth,
  customFooter,
  footerWithoutBg,
  withoutCart,
  page: Page,
  layout: Layout,
  isFooterFixed,
  withoutBottomPadding,
  withoutGameMenu,
  previewable = false,
  ...rest
}: Props): React.ReactElement {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const jwt = useAppSelector(selectAppJwt);
  const id = useAppSelector(getUserId);
  const email = useAppSelector(getUserEmail);
  const userInfo = useAppSelector(selectUserInfo) as any;
  const isPrewiewMode = useAppSelector(selectIsPreviewMode);
  const [previousAnalyticsPageView, setPreviousAnalyticsPageView] = useState('');
  if (isAuthenticated && window.gtag) {
    if (
      (!email.endsWith('12traits.com') && !email.endsWith('solsten.io')) ||
      !(userInfo.companyDetails?.subdomain ?? '').includes('e2e') ||
      !(userInfo.companyDetails?.subdomain ?? '').includes('staging')
    ) {
      window.gtag('config', process.env.GOOGLE_TAG_KEY, {
        user_id: id
      });
      // prevent duplicate entires in GA
      if (previousAnalyticsPageView !== window.location.pathname) {
        window.gtag('event', 'page_view', {
          page_title: document.title,
          page_location: window.location.pathname
        });
        setPreviousAnalyticsPageView(window.location.pathname);
      }
    }
  }

  useRedirect(isAuthenticated, jwt);

  return isPrewiewMode && !previewable ? (
    <Redirect to={aliasesOverviewPath} />
  ) : (
    <Route
      {...rest}
      render={(props): React.ReactElement =>
        isAuthenticated && jwt ? (
          <Layout
            withoutCart={withoutCart}
            footerWithoutBg={footerWithoutBg}
            fullWidth={fullWidth}
            customFooter={customFooter}
            isFooterFixed={isFooterFixed}
            withoutBottomPadding={withoutBottomPadding}
            withoutGameMenu={withoutGameMenu}
          >
            <Page {...props} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default PrivateRoute;
