import { Action } from '@/redux/types';

import {
  LOAD_TARGETING_SUMMARY_REQUEST,
  LOAD_TARGETING_SUMMARY_SUCCESS,
  LOAD_TARGETING_SUMMARY_ERROR,
  LOAD_TARGETING_CATEGORIES_REQUEST,
  LOAD_TARGETING_CATEGORIES_SUCCESS,
  LOAD_TARGETING_CATEGORIES_ERROR,
  LOAD_TARGETING_GAMES_REQUEST,
  LOAD_TARGETING_GAMES_SUCCESS,
  LOAD_TARGETING_GAMES_ERROR,
  FB_AUDIENCE_SET_AUDIENCE,
  FB_AUDIENCE_SET_SEGMENT,
  FB_AUDIENCE_SET_CLUSTER,
  FB_AUDIENCE_SET_STEP,
  FB_AUDIENCE_SET_ACCOUNT,
  FB_AUDIENCE_SET_UPLOAD_ID,
  FB_AUDIENCE_GET_ACCOUNTS_REQUEST,
  FB_AUDIENCE_GET_ACCOUNTS_SUCCESS,
  FB_AUDIENCE_GET_ACCOUNTS_ERROR,
  FB_AUDIENCE_GET_CLUSTER_KPIS_REQUEST,
  FB_AUDIENCE_GET_CLUSTER_KPIS_SUCCESS,
  FB_AUDIENCE_GET_CLUSTER_KPIS_ERROR,
  FB_AUDIENCE_LOGOUT,
  TargetPageState
} from './types';

const initialState: TargetPageState = {
  summary: null,
  summaryLoading: true,
  summaryError: false,
  summaryErrorMsg: '',
  targetingCategories: null,
  targetingCategoriesLoading: true,
  targetingCategoriesError: false,
  targetingCategoriesErrorMsg: '',
  games: null,
  gamesLoading: true,
  gamesError: false,
  gamesErrorMsg: '',
  fbAudience: {
    clusterKpis: null,
    currentAudience: 0,
    currentSegment: '',
    currentCluster: '',
    currentKpis: '',
    currentStep: 0,
    currentAccount: null,
    isLogged: false,
    accounts: null,
    accountsLoading: false,
    accountsError: true,
    accountsErrorMsg: '',
    uploadId: null
  }
};

export default (state = initialState, action: Action): TargetPageState => {
  switch (action.type) {
    case LOAD_TARGETING_SUMMARY_REQUEST:
      return {
        ...state,
        summaryLoading: true,
        summaryError: false,
        summaryErrorMsg: ''
      };
    case LOAD_TARGETING_SUMMARY_SUCCESS:
      return {
        ...state,
        summaryLoading: false,
        summaryError: false,
        summaryErrorMsg: '',
        summary: action.payload.data
      };
    case LOAD_TARGETING_SUMMARY_ERROR:
      return {
        ...state,
        summaryLoading: false,
        summaryError: true,
        summaryErrorMsg: action.payload.error
      };
    case LOAD_TARGETING_CATEGORIES_REQUEST:
      return {
        ...state,
        targetingCategoriesLoading: true,
        targetingCategoriesError: false,
        targetingCategoriesErrorMsg: ''
      };
    case LOAD_TARGETING_CATEGORIES_SUCCESS:
      return {
        ...state,
        targetingCategoriesLoading: false,
        targetingCategoriesError: false,
        targetingCategoriesErrorMsg: '',
        targetingCategories: action.payload.data
      };
    case LOAD_TARGETING_CATEGORIES_ERROR:
      return {
        ...state,
        targetingCategoriesLoading: false,
        targetingCategoriesError: true,
        targetingCategoriesErrorMsg: action.payload.error
      };
    case LOAD_TARGETING_GAMES_REQUEST:
      return {
        ...state,
        gamesLoading: true,
        gamesError: false,
        gamesErrorMsg: ''
      };
    case LOAD_TARGETING_GAMES_SUCCESS:
      return {
        ...state,
        gamesLoading: false,
        gamesError: false,
        gamesErrorMsg: '',
        games: action.payload.data
      };
    case LOAD_TARGETING_GAMES_ERROR:
      return {
        ...state,
        gamesLoading: false,
        gamesError: true,
        gamesErrorMsg: action.payload.error
      };
    case FB_AUDIENCE_SET_AUDIENCE:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          currentAudience: action.payload.data,
          currentKpis: '',
          currentStep: 0,
          currentAccount: null,
          uploadId: null
        }
      };
    case FB_AUDIENCE_SET_SEGMENT:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          currentSegment: action.payload.segment,
          currentCluster: action.payload.cluster ? action.payload.cluster : ''
        }
      };
    case FB_AUDIENCE_SET_CLUSTER:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          currentCluster: action.payload.data
        }
      };
    case FB_AUDIENCE_SET_STEP:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          currentStep: action.payload.data
        }
      };
    case FB_AUDIENCE_SET_ACCOUNT:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          currentAccount: action.payload.data
        }
      };
    case FB_AUDIENCE_SET_UPLOAD_ID:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          uploadId: action.payload.data
        }
      };
    case FB_AUDIENCE_GET_ACCOUNTS_REQUEST:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          accountsLoading: true,
          accountsError: false,
          accountsErrorMsg: ''
        }
      };
    case FB_AUDIENCE_GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          accountsLoading: false,
          accountsError: false,
          isLogged: true,
          accounts: action.payload.data
        }
      };
    case FB_AUDIENCE_GET_ACCOUNTS_ERROR:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          accountsLoading: false,
          accountsError: true,
          accountsErrorMsg: action.payload.error
        }
      };

    case FB_AUDIENCE_GET_CLUSTER_KPIS_REQUEST:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          clusterKpis: null
        }
      };
    case FB_AUDIENCE_GET_CLUSTER_KPIS_SUCCESS:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          clusterKpis: action.payload.data.kpis || null
        }
      };
    case FB_AUDIENCE_GET_CLUSTER_KPIS_ERROR:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          clusterKpis: null
        }
      };

    case FB_AUDIENCE_LOGOUT:
      return {
        ...state,
        fbAudience: {
          ...state.fbAudience,
          currentAccount: null,
          isLogged: false,
          accounts: null
        }
      };
    default:
      return state;
  }
};
