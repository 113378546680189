import { CalendarDate } from '@internationalized/date';

export enum ChannelsEnum {
  AllChannels = 'all',
  AppleAppStore = 'apple_app_store',
  GooglePlayStore = 'google_play_store',
  Twitter = 'twitter',
  YouTube = 'youtube'
}

export const getScoreColorClassName = (score: number): string | undefined => {
  if (score >= 0 && score <= 33) {
    return 'negative';
  }
  if (score >= 34 && score <= 65) {
    return 'neutral';
  }
  if (score >= 66 && score <= 100) {
    return 'positive';
  }
  return 'default';
};

export const getInputValue = (date: CalendarDate): string =>
  `${date.month < 10 ? `0${date.month}` : date.month}/${
    date.day < 10 ? `0${date.day}` : date.day
  }/${date.year}`;

export const getUrlValue = (date: CalendarDate): string =>
  `${date.year}-${date.month < 10 ? `0${date.month}` : date.month}-${
    date.day < 10 ? `0${date.day}` : date.day
  }`;

export const getCalendarValueFromUrl = (date: string): CalendarDate => {
  const dateValues = date.split('-');
  const localDate = new CalendarDate(+dateValues[0], +dateValues[1], +dateValues[2]);
  return localDate;
};
