/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../rootStore';

const initialState = {
  contentfulEntries: {
    engagement: null,
    acquisition: null
  }
};

const slice = createSlice({
  name: 'MIGRATING_TRAITS_PAGE',
  initialState,
  reducers: {
    setContentfulEntries: (
      state,
      action: PayloadAction<Record<'data', { acquisition: unknown; engagement: unknown }>>
    ) => {
      const entries = action.payload.data;
      state.contentfulEntries = { ...state.contentfulEntries, ...entries };
    }
  }
});

export default slice.reducer;

export const { setContentfulEntries } = slice.actions;

export const selectEngagementCategories = (state: RootState) =>
  state.migratingTraitsPage.contentfulEntries.engagement;
