import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import camelcaseKeys from 'camelcase-keys';

import { prepareBaseQuery } from '@/redux/helpers';
import { selectIsDemoCustomer } from '@/redux/user/selectors';

import { RootState } from '../rootStore';

import { CartData } from './types';

export const cartApi = createApi({
  reducerPath: 'cartApi',
  tagTypes: ['Cart'],
  baseQuery: fetchBaseQuery(
    prepareBaseQuery({
      endpoint: 'payment',
      useGameJwt: false,
      headersEnhance: (headers, { getState }) => {
        const isDemoCustomer = selectIsDemoCustomer()(getState() as RootState);

        if (isDemoCustomer) {
          headers.set('Payment-Gateway', 'offline');
        }

        return headers;
      }
    })
  ),
  endpoints: build => ({
    fetchCart: build.query<any, void>({
      query: () => '/cart',
      keepUnusedDataFor: Infinity,
      transformResponse: result =>
        camelcaseKeys((result as { data: any }).data, { deep: true }).cart as { data: CartData },
      providesTags: ['Cart']
    }),
    addItem: build.mutation<any, { cartId: string; slug: string; data?: {} }>({
      query: ({ cartId, slug, data }) => ({
        url: `/carts/${cartId}/products/${slug}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Cart']
    }),
    removeItem: build.mutation<any, { cartId: string; slug: string; data?: { filterId?: string } }>(
      {
        query: ({ cartId, slug, data }) => ({
          url: `/carts/${cartId}/products/${slug}`,
          method: 'DELETE',
          body: data
        }),
        invalidatesTags: ['Cart']
      }
    )
  })
});

export const {
  useFetchCartQuery,
  useAddItemMutation,
  useRemoveItemMutation,
  useLazyFetchCartQuery
} = cartApi;

export const selectCart = cartApi.endpoints.fetchCart.select();
