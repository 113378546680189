import { ClusterEmotion } from '@/services/Api/GameService/types';

export const SET_CLUSTER_CODE = 'Cluster / SET_CLUSTER_CODE';

export interface ClusterState {
  currentCode: null | string;
  disabledCluster: null;
}

export interface ClusterEmotionsState {
  loading: boolean;
  saving: boolean;
  data: ClusterEmotion[];
}

export interface ClusterFeeling {
  name: string;
  value: string;
  isNegative: boolean;
}
