export const DELAY = 1300;

export const templates = {
  static: 'ASSESSMENT_STEP_STATIC',
  branding: 'ASSESSMENT_STEP_BRANDING',
  reward: 'ASSESSMENT_STEP_REWARD',
  submit: 'ASSESSMENT_STEP_SUBMIT',
  existingFeatures: 'ASSESSMENT_STEP_EXISTING_FEATURES'
} as const;

export const statuses = {
  pristine: 'pristine',
  completed: 'completed',
  draft: 'draft'
};

export const titleMap = {
  ASSESSMENT_STEP_STATIC: 'Audience',
  ASSESSMENT_STEP_BRANDING: 'Branding',
  ASSESSMENT_STEP_REWARD: 'Reward',
  ASSESSMENT_STEP_SUBMIT: 'Submission',
  ASSESSMENT_STEP_EXISTING_FEATURES: 'Existing Features',
  ASSESSMENT_STEP_NEW_FEATURES: 'New Features',
  ASSESSMENT_STEP_GAME_ITEMS: 'Game items'
};

export type AssessmentID = string;
export type Template = (typeof templates)[keyof typeof templates];

export type StaticValues = {
  assessmentType: string;
  contentType: string;
  projectName: string;
  audienceName: string;
  isNewProject?: boolean;
};

export type BrandingValues = {
  logoUrl: string;
  type?: 'custom';
};

export type RewardValues = {
  description: string;
  mechanism: string;
  link: string;
};

export type SubmissionValues = null;

export type TemplateValues = {
  ASSESSMENT_STEP_STATIC: StaticValues;
  ASSESSMENT_STEP_BRANDING: BrandingValues;
  ASSESSMENT_STEP_REWARD: RewardValues;
  ASSESSMENT_STEP_SUBMIT: SubmissionValues;
};

export type Status = (typeof statuses)[keyof typeof statuses];

export type Step = {
  id?: string;
  template: Template;
  required: boolean;
  skipped: boolean;
  order: number;
  status: Status;
  values: TemplateValues[Template];
  title?: string;
};

export type Steps = {
  next: Step;
  previous: Step;
  nbTotal: number;
  nbLeft: number;
};

export type ActiveAssessmentResponse = {
  data: {
    steps: Steps & { history: Step[]; skeleton: Step[] };
    assessment: {
      id: string;
      status: Status;
      type: string;
      contentType: string;
    };
  };
};

export type UpdatedAssessmentResponse = {
  data: {
    steps: Steps;
    assessment: {
      id: string;
      status: Status;
      type: string;
      contentType: string;
    };
  };
};
