
export interface ClusterEmotion {
  stage: number;
  value: number;
  details: ClusterEmotionDetails;
}

interface ClusterEmotionDetails {
  do: string;
  feel: string;
  think: string;
}

export interface ClusterKpi {
  id: number;
  name: string;
  measurement: string;
  label: string;
  value: number;
  description: string;
  objective: string;
  increase?: number;
}

export interface Action {
  id: string;
  summary: string;
  clusters: number[];
  kpis: ActionKpi[];
  status: number;
  isUserAction: boolean;
  createdBy: {
    email: string;
    id: string;
    name: string;
    active: boolean;
    role: string;
    createdAt: number;
    company: string;
  };
  levelOfEffort: number;
  notes: string;
  completedAt: number;
  added?: boolean;
  quickAdd?: boolean;
  deleted?: boolean;
  rating: number;
  category?: string;
  votesSummary?: ActionVotes;
  unlocked: boolean;
  type: 'original' | 'user' | 'custom';
  unlockRequestBy: UnlockRequestAuthor | null;
  unlockRequestById: string;
}

export interface UnlockRequestAuthor {
  active: boolean;
  company: string;
  createdAt: number;
  email: string;
  id: string;
  name: string;
  role: string;
}

export interface ActionVotes {
  totalValue: number;
  votedByCurrentUser: boolean;
  currentUserValue: 1 | -1;
}

export interface ActionUpdate {
  summary?: string;
  clusters?: Array<number>;
  kpis?: ActionUpdateKpi[];
  status?: number;
  levelOfEffort?: number;
  notes?: string;
  statusChanged?: boolean;
  launchDetails?: {
    releaseDate: string;
    releaseTime: string;
    clustersImpacted: number[];
    levelOfEffort: number;
    typeOfAction: string;
  };
  rating?: number;
}

export interface ActionUpdateKpi {
  dateRange: string;
  valueAfter: number;
  valueBefore: number;
  slug: string;
}

export interface ActionKpi {
  title: string;
  measurement: string;
  objective: string;
  valueAfter: number;
  valueBefore: number;
  description?: string;
  dateRange?: string;
  estimatedImpact: string;
  slug: string;
}

export const Statuses = {
  NEVER_UPLOADED: 0,
  PROCESSING: 1,
  UPDATING: 2,
  AVAILABLE: 3
};

export interface ClusterSettings {
  segment?: string;
  cluster: string;
  name: string;
}
