export const mainPath = '/';

export const teamAssessmentPath = '/team-assessment/:id?';

export const activeAssessmentAutomationPath = '/assessment-automation/:id';

export const newAssessmentAutomationPath = '/assessment-automation';

export const homePagePath = '/home/:app?';

export const navigatorPortfolioPath = '/portfolio';

export const navigatorGenresPath = '/genres';

export const navigatorGenresCategoriesPath = '/genres/categories/:slug?';

export const navigatorCraftPath = '/craft-audiences';

export const navigatorMarketPath = '/market-insights';

export const navigatorComparisonsPath = '/:gameSlug/compare';

export const navigatorComparisonTablePath = '/:gameSlug/comparisons/:id';

export const navigatorGameProfile = '/:gameSlug/profile';

export const navigatorCheckoutPath = '/checkout';

export const navigatorAccountSettingsPath = '/account-settings';

export const navigatorManageUsersPath = '/manage-users';

export const navigatorTeamMembersPath = '/team-members';

export const navigatorWishlistPath = '/wishlist/:type?';

export const navigatorMyGenresPath = '/my-genres';

export const previewPath = '/auth/preview';

export const forbiddenPath = '/forbidden';

export const loginPath = '/login';

export const forgotPassPath = '/forgot-pass';

export const newPassPath = '/create-pass/:token';

export const aliasesOverviewPath = '/summary';

export const traitPath = '/:gameSlug';

export const traitOverviewPath = `${traitPath}/summary`;

export const traitClustersPath = '/:gameSlug/clusters';

export const traitClustersProfilePath = `${traitClustersPath}/profile`;

export const traitClustersTraitsPath = `${traitClustersPath}/traits`;

export const traitClustersExperiencePath = `${traitClustersPath}/experience`;

export const traitSegmentsPath = '/:gameSlug/segments';

export const traitSegmentPath = '/:gameSlug/segments/:segmentId';

export const traitActionsPath = '/:gameSlug/actions/:actionId?';

export const traitTargetPath = '/:gameSlug/target/:reviewId?';

export const traitJourneyPath = '/:gameSlug/journey';

export const traitPulsePath = '/:gameSlug/pulse';

export const traitTraitsPath =
  '/:gameSlug/trait-explorer/:categoryCode?/:traitCode?/:subtraitCode?';

export const traitExplorerPath = '/:gameSlug/trait-explorer/:categoryCode?';

export const traitResearchPath = '/:gameSlug/research';

export const traitDataIntegrationPath = '/:gameSlug/data-integration';

export const traitConversationThreadsPath = '/:gameSlug/conversation-threads';

export const chartsPagePath = '/:gameSlug/charts/:groupId?';

export const frequencyCampaignsPath = '/frequency/campaigns';

export const frequencyStacksPath = '/frequency/stacks';

export const frequencyCreativesPath = '/frequency/creatives';

export const frequencyCreativePath = '/frequency/creatives/:creativeId';

export const frequencyOverviewPath = '/frequency/overview';

export const frequencyAdNetworksPath = '/frequency/ad-networks';

export const frequencyCreativeCollectionsPath = '/frequency/creative-collections';

export const frequencyCollectionDetailsPath = '/frequency/collection-details/:collectionId';

export const frequencyReviewsPath = '/frequency/reviews';

export const frequencyBuilderPath = '/frequency/builder';

export const frequencyBuildPath = '/frequency/builder/:id';

export const frequencyBuilderSavedPath = '/frequency/builder/saved';

export const benchmarkingReportsListPath = '/charts';

export const benchmarkingReportsDetailsPath = '/charts/:slug';

export const benchmarkingReportsPath = [
  benchmarkingReportsListPath,
  benchmarkingReportsDetailsPath
];
