import { createSelector } from 'reselect';

import { DataTypeEnum, getNamespaceDataType } from '../game/types';
import { RootState } from '../rootStore';

import { Game } from './types';

export const selectGames = (state: RootState): Game[] => state.games.list;
export const selectUniqueGameTypes = createSelector(selectGames, games => [
  ...new Set(
    games.map((d: Game) => {
      const dataType = getNamespaceDataType(d.dashboard_config.namespace);
      return dataType;
    })
  )
]);

export const selectDashboardsByDataType =
  (type: DataTypeEnum) =>
  (state: RootState): Game[] =>
    state.games.list.filter((item: Game) => {
      const dataType = getNamespaceDataType(item.dashboard_config.namespace);
      return dataType === type;
    });

export const selectIsNonGamingCompany = (state: RootState): boolean | null =>
  state.games.isNonGamingCompany;

export const selectIsNavigatorApp =
  (slug: string) =>
  (state: RootState): boolean =>
    state.games.list.some((item: Game) => {
      const dataType = getNamespaceDataType(item.dashboard_config.namespace);
      return item.slug === slug && [DataTypeEnum.Crafted, DataTypeEnum.Genre].includes(dataType);
    });
