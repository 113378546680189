import classNames from 'classnames';
import { useMemo } from 'react';


import Icon from 'ui-lib-12traits/src/Icons/svg/common/info.svg?component';
import { Tooltip } from 'ui-lib-12traits/src/index';

import styles from './Info.module.scss';

import type { Props } from './Info.types';

export function Info({ children, ...props }: Props) {
  const className = useMemo(() => classNames(styles.container, props.className), [props.className]);

  return (
    <Tooltip className={styles.tooltip} placement="top" content={children} interactive>
      <span className={className}>
        <Icon />
      </span>
    </Tooltip>
  );
}

export default Info;
