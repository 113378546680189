import { MotivationsViewEnum } from './types';

export const CULTURE_CATEGORY_CODE = 'culture';

export const VALUES_CATEGORY_CODE = 'values';

export const COMMUNICATION_CATEGORY_CODE = 'communication';

export const PERSONALITY_CATEGORY_CODE = 'personality';
const EXTRAVERSION_CATEGORY_CODE = 'extraversion';
const NEUROTICISM_CATEGORY_CODE = 'neuroticism';
const OPENNESS_CATEGORY_CODE = 'openness';
const CONSCIENTIOUSNESS_CATEGORY_CODE = 'conscientiousness';
const AGREEABLENESS_CATEGORY_CODE = 'agreeableness';

export const MOTIVATIONS_CATEGORY_CODE = 'motivations';

export const COMPETITIVENESS_CATEGORY_CODE = 'competitiveness';

export const ACTIVITIES_CATEGORY_CODE = 'activities';

export const HOPES_ASPIRATIONS_CATEGORY_CODE = 'ha';

export const PAIN_POINTS_CATEGORY_CODE = 'pp';

export const TECH_USAGE_CATEGORY_CODE = 'tech-usage';

export const EMOTIONS_CATEGORY_CODE = 'emotions';

export const WELL_BEING_CATEGORY_CODE = 'wellBeing';

export const UX_USEX_EXPERIENCE_CATEGORY_CODE = 'uxExperience';

export const CONTENTFUL_KEYS: { [key: string]: string } = {
  [CULTURE_CATEGORY_CODE]: '01 Culture',
  [VALUES_CATEGORY_CODE]: '02 Core Values',
  [COMMUNICATION_CATEGORY_CODE]: '03 Communication',
  [PERSONALITY_CATEGORY_CODE]: '04 Personality',
  [EXTRAVERSION_CATEGORY_CODE]: '4.1 Extraversion',
  [NEUROTICISM_CATEGORY_CODE]: '4.2 Neuroticism',
  [OPENNESS_CATEGORY_CODE]: '4.3 Openness',
  [CONSCIENTIOUSNESS_CATEGORY_CODE]: '4.4 Conscientiousness',
  [AGREEABLENESS_CATEGORY_CODE]: '4.5 Agreeableness',
  [MotivationsViewEnum.AllMotivators]: '05 Motivators',
  [MotivationsViewEnum.CoreMotivators]: '5.1 Core Motivators',
  [COMPETITIVENESS_CATEGORY_CODE]: '06 Competitiveness',
  [ACTIVITIES_CATEGORY_CODE]: '07 Activities',
  [HOPES_ASPIRATIONS_CATEGORY_CODE]: '08 Hopes & Aspirations',
  [PAIN_POINTS_CATEGORY_CODE]: '09 Pain Points',
  [TECH_USAGE_CATEGORY_CODE]: '10 Tech Usage',
  [EMOTIONS_CATEGORY_CODE]: '11 Pre-game Emotions',
  [WELL_BEING_CATEGORY_CODE]: '12 Well-Being',
  [UX_USEX_EXPERIENCE_CATEGORY_CODE]: 'UX User Experience'
};

export const CATEGORIES = [
  {
    name: 'Culture',
    actionCategory: 'Culture',
    code: CULTURE_CATEGORY_CODE,
    definition: 'Collective traits that distinguish one group from another.',
    hasSubcategories: false
  },
  {
    name: 'Values',
    actionCategory: 'Values',
    code: VALUES_CATEGORY_CODE,
    definition:
      'The core cognitive and emotional principles, standards, or judgements of what is important in life.',
    allTraitsCaption: 'All values',
    withDifferentChartViews: true,
    hasSubcategories: true
  },
  {
    name: 'Communication',
    actionCategory: 'Communication',
    code: COMMUNICATION_CATEGORY_CODE,
    definition: 'The style used to impart information or convey a message.',
    hasSubcategories: false
  },
  {
    name: 'Personality',
    actionCategory: 'Personality',
    code: PERSONALITY_CATEGORY_CODE,
    definition: 'The major dimensions of habitual behaviors, cognitions and emotional patterns.',
    allTraitsCaption: 'OCEAN',
    hasSubcategories: true
  },
  {
    name: 'Motivation',
    altName: 'Core Motivators',
    actionCategory: 'Motivators',
    allTraitsCaption: 'All motivators',
    code: MOTIVATIONS_CATEGORY_CODE,
    definition: 'Driving forces that compel behavior.',
    xAxisLabelAngle: 45,
    hasSubcategories: true
  },
  {
    name: 'Competitiveness',
    actionCategory: 'Competitiveness',
    code: COMPETITIVENESS_CATEGORY_CODE,
    definition: 'Desire to be better than oneself, another person, or group.',
    hasSubcategories: false
  },
  {
    name: 'Activities',
    actionCategory: 'Activities',
    code: ACTIVITIES_CATEGORY_CODE,
    definition: 'Those things a person has done, does, or intends to do.',
    xAxisLabelAngle: 45,
    hasSubcategories: true
  },
  {
    name: 'Hopes & Aspirations',
    altName: 'Hopes and Aspirations',
    actionCategory: 'Hopes & Aspirations',
    code: HOPES_ASPIRATIONS_CATEGORY_CODE,
    definition: 'Desire to achieve or acquire a goal.',
    xAxisLabelAngle: 25,
    withDifferentChartViews: true,
    hasSubcategories: true
  },
  {
    name: 'Pain Points',
    actionCategory: 'Pain Points',
    code: PAIN_POINTS_CATEGORY_CODE,
    definition: 'Real or perceived problems that cause pain or distress.',
    xAxisLabelAngle: 25,
    withDifferentChartViews: true,
    hasSubcategories: true
  },
  {
    name: 'Tech Usage',
    actionCategory: 'Tech Usage',
    code: TECH_USAGE_CATEGORY_CODE,
    definition: 'When technology gets used.',
    hasSubcategories: false
  },
  {
    name: 'Emotions',
    actionCategory: 'Emotions',
    code: EMOTIONS_CATEGORY_CODE,
    definition: 'Typical feelings before [playing] your [game].',
    xAxisLabelAngle: 45,
    allTraitsCaption: 'All emotions',
    withDifferentChartViews: true,
    hasSubcategories: true
  },
  {
    name: 'Well-Being',
    altName: 'Well Being',
    actionCategory: 'Well-Being',
    code: WELL_BEING_CATEGORY_CODE,
    definition: 'Physical, mental, and social well-being.',
    xAxisLabelAngle: 25,
    hasSubcategories: false
  }
];
