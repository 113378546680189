import { Action } from '@/redux/types';

import { SegmentState, SET_SEGMENT_CODE } from './types';

const initialState: SegmentState = {
  currentId: null
};

export default (state = initialState, action: Action): SegmentState => {
  switch (action.type) {
    case SET_SEGMENT_CODE:
      return {
        ...state,
        currentId: action.payload || null
      };
    default:
      return state;
  }
};
