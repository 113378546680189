import { Action } from '@/redux/types';

import {
  OverviewPageState,
  RESET_OVERVIEW_PAGE_STATE,
  SET_HOVERED_PULSE_PERSONA,
  SET_ACTIVE_PULSE_PERSONA,
  SET_PULSE_ACTIVE_TIMELINE,
  SET_PULSE_AVAILABLE_TIMELINES,
  SET_PULSE_HOVERED_DATE,
  SET_SENTIMENT_ACTIVE_CHANNEL,
  SET_SENTIMENT_AVAILABLE_CHANNELS,
  SET_SENTIMENT_ACTIVE_TIMELINE,
  SET_SENTIMENT_AVAILABLE_TIMELINES,
  SET_SENTIMENT_HOVERED_DATE,
  SET_SENTIMENT_HOVERED_FILTER,
  SET_SENTIMENT_ACTIVE_FILTERS,
  LOAD_TOP_GAMES_REQUEST,
  LOAD_TOP_GAMES_SUCCESS,
  LOAD_TOP_GAMES_ERROR,
  LOAD_DEMOGRAPHICS_SCORES_REQUEST,
  LOAD_DEMOGRAPHICS_SCORES_SUCCESS,
  LOAD_DEMOGRAPHICS_SCORES_ERROR,
  LOAD_PCS_REQUEST,
  LOAD_PCS_SUCCESS,
  LOAD_PCS_ERROR,
  LOAD_KPIS_LEADERBOARD_REQUEST,
  LOAD_KPIS_LEADERBOARD_SUCCESS,
  LOAD_KPIS_LEADERBOARD_ERROR,
  LOAD_EXPERIENCE_REQUEST,
  LOAD_EXPERIENCE_SUCCESS,
  LOAD_EXPERIENCE_ERROR,
  LOAD_UNIQUE_AND_SIMILAR_REQUEST,
  LOAD_UNIQUE_AND_SIMILAR_SUCCESS,
  LOAD_UNIQUE_AND_SIMILAR_ERROR,
  LOAD_GENRE_AVERAGES_REQUEST,
  LOAD_GENRE_AVERAGES_SUCCESS,
  LOAD_GENRE_AVERAGES_ERROR,
  LOAD_ENGAGEMENT_RATE_REQUEST,
  LOAD_ENGAGEMENT_RATE_SUCCESS,
  LOAD_ENGAGEMENT_RATE_ERROR,
  LOAD_SENTIMENT_REQUEST,
  LOAD_SENTIMENT_SUCCESS,
  LOAD_SENTIMENT_ERROR,
  SET_SHOWING_HEADERBAR,
  SET_SELECTED_PULSE_PERSONA,
  SET_ACTIVE_PULSE_PERSONAS_PER_SEGMENT_SUCCESS,
  SET_MARKET_SIZE_DATA,
  RESET_MARKET_SIZE_DATA,
  MarketSizeI,
  SentimentFilter,
  SET_SENTIMENT_PREV_GAME_JWT,
  SET_SENTIMENT_PREV_ACTIVE_TIMELINE,
  SET_SENTIMENT_PREV_ACTIVE_CHANNEL,
  SET_MARKET_GENRES_DATA,
  SET_MARKET_SPEND_DATA,
  SET_MARKET_PERSONA_NUMBER,
  LOAD_MARKET_GENRES_ERROR,
  LOAD_MARKET_SIZE_ERROR,
  LOAD_MARKET_SPEND_ERROR,
  RESET_MARKET_DATA,
  RESET_MARKET_SPEND_DATA,
  RESET_MARKET_GENRES_DATA
} from './types';

export const SENTIMENT_FILTERS = ['releases', 'positive', 'negative', 'neutral'];

export const marketSizeInitial: MarketSizeI = {
  // commmon
  personaNumbers: 0,
  // size chart
  size: { maxSizeValue: 0, valuesSize: [], minSizeRangeValues: [], maxSizeRangeValues: [] },
  // spend chart
  spend: {
    maxSpendValue: 0,
    valuesSpend: [],
    minSpendRangeValues: [],
    maxSpendRangeValues: [],
    meanValuesSpend: [],
    maxCIValue: 0,
    minSpendCIRangeValues: [],
    maxSpendCIRangeValues: []
  },
  // genres
  currentGenres: [],
  errors: {
    sizeError: '',
    spendError: '',
    genresError: ''
  }
};

const initialState: OverviewPageState = {
  error: false,
  pulse: {
    hoveredPulsePersona: null,
    activeTimeline: null,
    availableTimelines: [],
    hoveredDate: null,
    loading: false,
    error: false,
    errorMsg: '',
    engagementRateData: null,
    selectedPulsePersona: null,
    activePulsePersonasPerSegment: null
  },
  sentiment: {
    activeChannel: null,
    availableChannels: [],
    activeTimeline: null,
    availableTimelines: [],
    hoveredDate: null,
    loading: false,
    error: false,
    errorMsg: '',
    data: [],
    releases: [],
    hoveredFilter: null,
    activeFilters: ['releases', 'positive', 'negative', 'neutral'],
    isTooltipAnimating: true,
    prevGameJWT: null,
    prevActiveChannel: null,
    prevActiveTimeline: null
  },
  topGames: {
    loading: false,
    error: false,
    errorMsg: '',
    data: null
  },
  demographicsScores: {
    loading: false,
    error: false,
    errorMsg: '',
    data: null
  },
  pcs: {
    loading: false,
    error: false,
    errorMsg: '',
    data: null
  },
  kpisLeaderboard: {
    loading: false,
    error: false,
    errorMsg: '',
    data: null
  },
  experience: {
    loading: false,
    error: false,
    errorMsg: '',
    data: null
  },
  uniqueAndSimilar: {
    loading: false,
    error: false,
    errorMsg: '',
    data: null
  },
  genreAverages: {
    loading: false,
    error: false,
    errorMsg: '',
    data: null
  },
  isShowingHeaderbar: true,
  marketSize: {
    ...marketSizeInitial
  }
};

export default (state = initialState, action: Action): OverviewPageState => {
  const getActiveFilters = (): SentimentFilter[] => {
    const {
      sentiment: { activeFilters }
    } = state;
    const { payload } = action;
    const eject = (filter: SentimentFilter): boolean => filter !== payload.filter;

    return activeFilters.filter(eject).concat(payload.status ? payload.filter : []);
  };

  switch (action.type) {
    case RESET_OVERVIEW_PAGE_STATE:
      return {
        ...initialState,
        isShowingHeaderbar: !!localStorage.getItem('OverviewPage/headerbar'),
        pulse: {
          ...initialState.pulse,
          activePulsePersonasPerSegment: state.pulse.activePulsePersonasPerSegment
        }
      };
    case SET_ACTIVE_PULSE_PERSONAS_PER_SEGMENT_SUCCESS:
      return {
        ...state,
        pulse: {
          ...state.pulse,
          activePulsePersonasPerSegment: action.payload.data
        }
      };
    case SET_HOVERED_PULSE_PERSONA:
      return {
        ...state,
        pulse: {
          ...state.pulse,
          hoveredPulsePersona: action.payload.value
        }
      };
    case SET_SELECTED_PULSE_PERSONA:
      return {
        ...state,
        pulse: {
          ...state.pulse,
          selectedPulsePersona: action.payload.value
        }
      };
    case SET_ACTIVE_PULSE_PERSONA: {
      const { value, segmentId } = action.payload;

      let newActivePersonas = [
        ...(state.pulse.activePulsePersonasPerSegment
          ? state.pulse.activePulsePersonasPerSegment[segmentId] || ['all']
          : ['all'])
      ];

      const isActiveNow = newActivePersonas.includes(value);

      if (isActiveNow) {
        newActivePersonas = newActivePersonas.filter(personaValue => personaValue !== value);
      } else {
        newActivePersonas.push(value);
      }

      newActivePersonas = newActivePersonas.length ? newActivePersonas : ['all'];

      return {
        ...state,
        pulse: {
          ...state.pulse,
          selectedPulsePersona:
            newActivePersonas.length > 1 &&
            newActivePersonas.includes(state.pulse.selectedPulsePersona || '')
              ? state.pulse.selectedPulsePersona
              : null,
          hoveredPulsePersona: newActivePersonas.includes(state.pulse.hoveredPulsePersona || '')
            ? null
            : state.pulse.hoveredPulsePersona,
          activePulsePersonasPerSegment: {
            ...state.pulse.activePulsePersonasPerSegment,
            [segmentId]: newActivePersonas
          }
        }
      };
    }
    case SET_PULSE_ACTIVE_TIMELINE:
      return {
        ...state,
        pulse: {
          ...state.pulse,
          activeTimeline: action.payload.activeTimeline
        }
      };
    case SET_PULSE_AVAILABLE_TIMELINES:
      return {
        ...state,
        pulse: {
          ...state.pulse,
          availableTimelines: action.payload.availableTimelines
        }
      };
    case SET_PULSE_HOVERED_DATE:
      return {
        ...state,
        pulse: {
          ...state.pulse,
          hoveredDate: action.payload.hoveredDate
        }
      };
    case SET_SENTIMENT_ACTIVE_TIMELINE:
      return {
        ...state,
        sentiment: {
          ...state.sentiment,
          activeTimeline: action.payload.activeTimeline
        }
      };
    case SET_SENTIMENT_AVAILABLE_TIMELINES:
      return {
        ...state,
        sentiment: {
          ...state.sentiment,
          availableTimelines: action.payload.availableTimelines
        }
      };
    case SET_SENTIMENT_PREV_GAME_JWT:
      return {
        ...state,
        sentiment: {
          ...state.sentiment,
          prevGameJWT: action.payload
        }
      };
    case SET_SENTIMENT_ACTIVE_CHANNEL:
      return {
        ...state,
        sentiment: {
          ...state.sentiment,
          activeChannel: action.payload.activeChannel
        }
      };
    case SET_SENTIMENT_PREV_ACTIVE_TIMELINE:
      return {
        ...state,
        sentiment: {
          ...state.sentiment,
          prevActiveTimeline: action.payload
        }
      };
    case SET_SENTIMENT_PREV_ACTIVE_CHANNEL:
      return {
        ...state,
        sentiment: {
          ...state.sentiment,
          prevActiveChannel: action.payload
        }
      };
    case SET_SENTIMENT_AVAILABLE_CHANNELS:
      return {
        ...state,
        sentiment: {
          ...state.sentiment,
          availableChannels: action.payload.availableChannels
        }
      };
    case SET_SENTIMENT_HOVERED_DATE:
      return {
        ...state,
        sentiment: {
          ...state.sentiment,
          hoveredDate: action.payload.hoveredDate,
          isTooltipAnimating: !action.payload.hoveredDate || !state.sentiment.hoveredDate
        }
      };
    case SET_SENTIMENT_HOVERED_FILTER:
      return {
        ...state,
        sentiment: {
          ...state.sentiment,
          hoveredFilter: action.payload.hoveredFilter
        }
      };
    case SET_SENTIMENT_ACTIVE_FILTERS:
      return {
        ...state,
        sentiment: { ...state.sentiment, activeFilters: getActiveFilters() }
      };
    case LOAD_TOP_GAMES_REQUEST:
      return {
        ...state,
        topGames: { ...state.topGames, error: false, loading: true, errorMsg: '' }
      };
    case LOAD_TOP_GAMES_SUCCESS:
      return {
        ...state,
        topGames: { ...state.topGames, loading: false, data: action.payload.data }
      };
    case LOAD_TOP_GAMES_ERROR: {
      return {
        ...state,
        topGames: { ...state.topGames, loading: false, error: true, errorMsg: action.payload.error }
      };
    }
    case LOAD_DEMOGRAPHICS_SCORES_REQUEST:
      return {
        ...state,
        demographicsScores: {
          ...state.demographicsScores,
          error: false,
          errorMsg: '',
          loading: true
        }
      };
    case LOAD_DEMOGRAPHICS_SCORES_SUCCESS:
      return {
        ...state,
        demographicsScores: {
          ...state.demographicsScores,
          loading: false,
          data: action.payload.data
        }
      };
    case LOAD_DEMOGRAPHICS_SCORES_ERROR:
      return {
        ...state,
        demographicsScores: {
          ...state.demographicsScores,
          errorMsg: action.payload.error,
          error: true,
          loading: false
        }
      };
    case LOAD_PCS_REQUEST:
      return {
        ...state,
        pcs: {
          ...state.pcs,
          error: false,
          errorMsg: '',
          loading: true
        }
      };
    case LOAD_PCS_SUCCESS:
      return {
        ...state,
        pcs: {
          ...state.pcs,
          loading: false,
          data: action.payload.data
        }
      };
    case LOAD_PCS_ERROR:
      return {
        ...state,
        pcs: {
          ...state.pcs,
          errorMsg: action.payload.error,
          error: true,
          loading: false
        }
      };
    case LOAD_KPIS_LEADERBOARD_REQUEST:
      return {
        ...state,
        kpisLeaderboard: {
          ...state.kpisLeaderboard,
          error: false,
          errorMsg: '',
          loading: true
        }
      };
    case LOAD_KPIS_LEADERBOARD_SUCCESS:
      return {
        ...state,
        kpisLeaderboard: {
          ...state.kpisLeaderboard,
          loading: false,
          data: action.payload.data
        }
      };
    case LOAD_KPIS_LEADERBOARD_ERROR:
      return {
        ...state,
        kpisLeaderboard: {
          ...state.kpisLeaderboard,
          errorMsg: action.payload.error,
          error: true,
          loading: false
        }
      };
    case LOAD_EXPERIENCE_REQUEST:
      return {
        ...state,
        experience: { ...state.experience, error: false, loading: true, errorMsg: '' }
      };
    case LOAD_EXPERIENCE_SUCCESS:
      return {
        ...state,
        experience: { ...state.experience, loading: false, data: action.payload.data }
      };
    case LOAD_EXPERIENCE_ERROR:
      return {
        ...state,
        experience: {
          ...state.experience,
          loading: false,
          error: true,
          errorMsg: action.payload.error
        }
      };
    case LOAD_UNIQUE_AND_SIMILAR_REQUEST:
      return {
        ...state,
        uniqueAndSimilar: { ...state.uniqueAndSimilar, error: false, loading: true, errorMsg: '' }
      };
    case LOAD_UNIQUE_AND_SIMILAR_SUCCESS:
      return {
        ...state,
        uniqueAndSimilar: { ...state.uniqueAndSimilar, loading: false, data: action.payload.data }
      };
    case LOAD_UNIQUE_AND_SIMILAR_ERROR:
      return {
        ...state,
        uniqueAndSimilar: {
          ...state.uniqueAndSimilar,
          loading: false,
          error: true,
          errorMsg: action.payload.error
        }
      };
    case LOAD_GENRE_AVERAGES_REQUEST:
      return {
        ...state,
        genreAverages: { ...state.genreAverages, error: false, loading: true, errorMsg: '' }
      };
    case LOAD_GENRE_AVERAGES_SUCCESS:
      return {
        ...state,
        genreAverages: { ...state.genreAverages, loading: false, data: action.payload.data }
      };
    case LOAD_GENRE_AVERAGES_ERROR:
      return {
        ...state,
        genreAverages: {
          ...state.genreAverages,
          loading: false,
          error: true,
          errorMsg: action.payload.error
        }
      };
    case LOAD_ENGAGEMENT_RATE_REQUEST:
      return {
        ...state,
        pulse: { ...state.pulse, error: false, loading: true, errorMsg: '' }
      };
    case LOAD_ENGAGEMENT_RATE_SUCCESS:
      return {
        ...state,
        pulse: { ...state.pulse, loading: false, engagementRateData: action.payload.data }
      };
    case LOAD_ENGAGEMENT_RATE_ERROR:
      return {
        ...state,
        pulse: {
          ...state.pulse,
          loading: false,
          error: true,
          errorMsg: action.payload.error,
          engagementRateData: null
        }
      };
    case LOAD_SENTIMENT_REQUEST:
      return {
        ...state,
        sentiment: { ...state.sentiment, error: false, loading: true, errorMsg: '' }
      };
    case LOAD_SENTIMENT_SUCCESS:
      return {
        ...state,
        sentiment: {
          ...state.sentiment,
          loading: false,
          data: action.payload.data || [],
          releases: action.payload.releases || []
        }
      };
    case LOAD_SENTIMENT_ERROR:
      return {
        ...state,
        sentiment: {
          ...state.sentiment,
          loading: false,
          error: true,
          errorMsg: action.payload.error
        }
      };
    case SET_SHOWING_HEADERBAR:
      return {
        ...state,
        isShowingHeaderbar: action.payload
      };
    case SET_MARKET_SIZE_DATA:
      return {
        ...state,
        marketSize: {
          ...state.marketSize,
          size: action.payload.data,
          errors: {
            ...state.marketSize.errors,
            sizeError: ''
          }
        }
      };
    case RESET_MARKET_DATA:
      return {
        ...state,
        marketSize: {
          ...marketSizeInitial
        }
      };
    case RESET_MARKET_SIZE_DATA:
      return {
        ...state,
        marketSize: {
          ...state.marketSize,
          size: {
            ...marketSizeInitial.size
          }
        }
      };
    case RESET_MARKET_SPEND_DATA:
      return {
        ...state,
        marketSize: {
          ...state.marketSize,
          spend: {
            ...marketSizeInitial.spend
          }
        }
      };
    case RESET_MARKET_GENRES_DATA:
      return {
        ...state,
        marketSize: {
          ...state.marketSize,
          currentGenres: {
            ...marketSizeInitial.currentGenres
          }
        }
      };
    case SET_MARKET_GENRES_DATA:
      return {
        ...state,
        marketSize: {
          ...state.marketSize,
          currentGenres: action.payload.genres,
          errors: {
            ...state.marketSize.errors,
            genresError: ''
          }
        }
      };
    case SET_MARKET_SPEND_DATA:
      return {
        ...state,
        marketSize: {
          ...state.marketSize,
          spend: action.payload.spend,
          errors: {
            ...state.marketSize.errors,
            spendError: ''
          }
        }
      };
    case SET_MARKET_PERSONA_NUMBER:
      return {
        ...state,
        marketSize: {
          ...state.marketSize,
          personaNumbers: action.payload.personas
        }
      };
    case LOAD_MARKET_GENRES_ERROR:
      return {
        ...state,
        marketSize: {
          ...state.marketSize,
          errors: {
            ...state.marketSize.errors,
            genresError: action.payload.error
          }
        }
      };
    case LOAD_MARKET_SIZE_ERROR:
      return {
        ...state,
        marketSize: {
          ...state.marketSize,
          errors: {
            ...state.marketSize.errors,
            sizeError: action.payload.error
          }
        }
      };
    case LOAD_MARKET_SPEND_ERROR:
      return {
        ...state,
        marketSize: {
          ...state.marketSize,
          errors: {
            ...state.marketSize.errors,
            spendError: action.payload.error
          }
        }
      };
    default:
      return state;
  }
};
