export const LOAD_GAME = 'Game / LOAD_GAME';
export const SET_GAME = 'Game / SET_GAME';
export const RESET_GAME = 'Game / RESET_GAME';
export const SET_GAME_SLUG = 'Game / SET_GAME_SLUG';
export const RESET_GAME_SLUG = 'Game / RESET_GAME_SLUG';
export const SET_DASHBOARD_CONFIG = 'Game / SET_DASHBOARD_CONFIG';
export const RESET_DASHBOARD_CONFIG = 'Game / RESET_DASHBOARD_CONFIG';

export enum NamespaceEnum {
  TraitGaming = 'trait.gaming',
  TraitProduct = 'trait.product',
  TraitMedical = 'trait.medical',
  CraftedGaming = 'crafted.gaming',
  GenreGaming = 'genre.gaming'
}

export type Namespace =
  | NamespaceEnum.TraitGaming
  | NamespaceEnum.TraitProduct
  | NamespaceEnum.TraitMedical
  | NamespaceEnum.CraftedGaming
  | NamespaceEnum.GenreGaming;

export enum DataTypeEnum {
  Trait = 'trait',
  Crafted = 'crafted',
  Genre = 'genre'
}

export type DataType = DataTypeEnum.Trait | DataTypeEnum.Crafted | DataTypeEnum.Genre;

export enum ContentTypeEnum {
  Gaming = 'gaming',
  Product = 'product',
  Medical = 'medical'
}

export type ContentType =
  | ContentTypeEnum.Gaming
  | ContentTypeEnum.Product
  | ContentTypeEnum.Medical;

export function getNamespaceDataType(namespace: Namespace): DataType {
  const parts = namespace.split('.');
  return parts[0] as DataType;
}

export function getNamespaceContentType(namespace: Namespace): ContentType {
  const parts = namespace.split('.');
  return parts[1];
}

export interface DashboardConfig {
  hasKpis: boolean;
  hasPlayerIds: boolean;
  hasFbTargeting: boolean;
  hasActions: boolean;
  hasOnlyPersonas: boolean;
  assessmentVersion: string;
  hasLookalike: boolean;
  hasHighValueBlade: boolean;
  hasPerformanceMetrics: boolean;
  isKpisUploaded: boolean;
  namespace: Namespace;
}
export interface GameState {
  slug: string | null;
  dashboardConfig: DashboardConfig | null;
}
