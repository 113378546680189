import type { Timeline } from 'ui-lib-12traits/src/index';

import { SocialListeningSources, SocialListeningState } from '@/redux/social-listening/types';

import SocialListeningApi from '../SocialListeningApi';

import type { ApiResponse } from '../types';

type SocialListeningResponse = SocialListeningState & { id: string; created_at: number };

export interface SocialListeningSentimentSourceAvailability {
  source: SocialListeningSources;
  day: boolean;
  week: boolean;
  month: boolean;
  quarter: boolean;
  year: boolean;
  all: boolean;
}

interface LastSyncRes {
  lastSync: number;
}

type SocialListeningSentimentAvailabilityResponse = {
  availability: SocialListeningSentimentSourceAvailability[];
};

export interface SourcesErrors {
  source: string;
  errorMessage: string;
}

export const createSocialListening = (data: any): Promise<ApiResponse<SocialListeningResponse>> =>
  new SocialListeningApi({ version: 'v1', isCamelCaseResponse: true, shouldSendSnakeCase: true })
    .setEndpoint('/dashboard/social-listening')
    .setBody(data)
    .post();

export const validateSocialListeningSource = (
  data: any
): Promise<ApiResponse<SocialListeningResponse | null, SourcesErrors[]>> =>
  new SocialListeningApi({ version: 'v1', isCamelCaseResponse: true, shouldSendSnakeCase: true })
    .setEndpoint('/dashboard/social-listening/validate')
    .setBody(data)
    .post();

export const getSocialListening = (): Promise<ApiResponse<SocialListeningResponse>> =>
  new SocialListeningApi({ version: 'v1', useGameJwt: true, isCamelCaseResponse: true })
    .setEndpoint('/dashboard/social-listening')
    .get();

export const getLastTimeFetched = (): Promise<ApiResponse<LastSyncRes>> =>
  new SocialListeningApi({ version: 'v1', useGameJwt: true, isCamelCaseResponse: true })
    .setEndpoint('/dashboard/social-listening/last-sync')
    .get();

export const getSocialListeningSentimentAvailability = (): Promise<
  ApiResponse<SocialListeningSentimentAvailabilityResponse>
> =>
  new SocialListeningApi({ version: 'v1', useGameJwt: true, isCamelCaseResponse: false })
    .setEndpoint('/dashboard/sentiment/availability')
    .get();

export const getSocialListeningSentimentData = (
  source: String,
  timeline: Timeline
): Promise<ApiResponse<{}>> =>
  new SocialListeningApi({ version: 'v1', useGameJwt: true, isCamelCaseResponse: false })
    .setEndpoint('/dashboard/sentiment/data')
    .setParams({ date_range: timeline, source })
    .get();

export const getConversationThreads = (params: {
  limit?: number;
  offset?: number;
  dateFrom: string;
  dateTo: string;
  highlightedTerms?: string[];
  sort?: string;
  sortDirection?: string;
  sources?: string[];
  searchQuery?: string;
  clusters?: string[];
}): Promise<ApiResponse<any>> =>
  new SocialListeningApi({ version: 'v1', useGameJwt: true, shouldSendSnakeCase: true })
    .setEndpoint('/dashboard/social-listening/conversation-threads')
    .setParams(params || {})
    .get();

export const getSocialListeningReleaseVersions = (timeline: Timeline): Promise<ApiResponse<{}>> =>
  new SocialListeningApi({ version: 'v1', useGameJwt: true, isCamelCaseResponse: false })
    .setEndpoint('/dashboard/release-versions')
    .setParams({ date_range: timeline })
    .get();
