declare global {
  interface Window {
    hj: Function;
  }
}

export const hotjar = (userInfo: any): void => {
  const { id: userId, email, companyDetails } = userInfo;
  const { id: companyId, name: companyDomain, subdomain: companySubdomain } = companyDetails;

  const attributes = {
    companyId,
    companyDomain
  };

  const isInternalUser = /@solsten.io\s*$/.test(email) || /@12traits.com\s*$/.test(email);
  const isInternalCompany = ['internal', 'demo', 'e2e'].includes(companySubdomain);

  if (window.hasOwnProperty('hj') && !isInternalUser && !isInternalCompany) {
    window.hj('identify', userId, attributes);
  } else {
    const hotjarScript = document.getElementById('hotjar');
    hotjarScript?.remove();

    const hotjarIframe = document.querySelectorAll('[id^="_hjSafeContext"], #_hjRemoteVarsFrame');
    hotjarIframe?.forEach(iframe => iframe.remove());
  }
};
