import { prepareAction } from '@/redux/helpers';
import { Action } from '@/redux/types';

import {
  LOAD_SEGMENTS,
  LOAD_SEGMENTS_REQUEST,
  LOAD_SEGMENTS_SUCCESS,
  LOAD_SEGMENTS_ERROR,
  ADD_NEW_SEGMENT,
  ADD_NEW_SEGMENT_REQUEST,
  ADD_NEW_SEGMENT_SUCCESS,
  ADD_NEW_SEGMENT_ERROR,
  Filter,
  SET_SHOW_CREATE_SEGMENT,
  SET_SHOW_CREATED_CONFIRMATION
} from './types';

export const loadSegments = (): Action => prepareAction(LOAD_SEGMENTS);

export const loadSegmentsRequest = (): Action => prepareAction(LOAD_SEGMENTS_REQUEST);

export const loadSegmentsSuccess = (data: any): Action =>
  prepareAction(LOAD_SEGMENTS_SUCCESS, { data });

export const loadSegmentsError = (error: string): Action =>
  prepareAction(LOAD_SEGMENTS_ERROR, { error });

export const addNewSegment = (
  title: string,
  description: string,
  filters: Filter[],
  audienceId: string
): Action => prepareAction(ADD_NEW_SEGMENT, { title, description, filters, audienceId });

export const addNewSegmentRequest = (): Action => prepareAction(ADD_NEW_SEGMENT_REQUEST);

export const addNewSegmentSuccess = (): Action => prepareAction(ADD_NEW_SEGMENT_SUCCESS);

export const addNewSegmentError = (): Action => prepareAction(ADD_NEW_SEGMENT_ERROR);

export const setShowCreateSegment = (showCreateSegment: boolean): Action =>
  prepareAction(SET_SHOW_CREATE_SEGMENT, { showCreateSegment });

export const setShowCreatedConfirmation = (showCreatedConfirmation: boolean): Action =>
  prepareAction(SET_SHOW_CREATED_CONFIRMATION, { showCreatedConfirmation });
