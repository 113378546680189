/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */

import useOutsideClick from '@12traits/useoutsideclick';
import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import ArrowIcon from 'ui-lib-12traits/src/Icons/svg/common/triangle_small.svg?component';
import CheckIcon from 'ui-lib-12traits/src/Icons/svg/frequency/check.svg?component';
import AsteriskIcon from 'ui-lib-12traits/src/Icons/svg/common/asterisk.svg?component';

import { GlobalButton } from 'ui-lib-12traits/src/index';

import { GameOption, ifElse, map, pipe } from './AudienceStep';
import styles from './SearchInput.module.scss';

type Props = {
  option: GameOption;
  options: Array<GameOption>;
  splitBy: string; // TODO: type it so it's connected to GameOption
  placeholder: string;
  contentType: string;
  onSelect: (option: GameOption) => void;
  onChange: (label: string) => void;
  onClick?: () => void;
  refs?: any; // exceptions for the outside click hook
  disabled?: boolean;
};

export default function SearchInput({
  option,
  splitBy,
  placeholder,
  options,
  contentType,
  onChange,
  onSelect,
  onClick,
  refs,
  disabled
}: Props) {
  const optionRef = useRef<HTMLLIElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const searchInputContainerRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState(option.label);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const isValueInOptions = useMemo(
    () => options.some(opt => opt.label.toLowerCase().trim() === value.trim().toLowerCase()),
    [value]
  );

  useOutsideClick(
    searchInputContainerRef,
    () => {
      setIsOptionsVisible(false);
      handleEnterPress();
    },
    [...refs]
  );

  // TODO: there must be a better way to handle this case
  useEffect(() => {
    setValue(option.label);
  }, [option.label]);

  useEffect(() => {
    if (highlightedIndex >= 0 && ulRef.current) {
      const listItems = ulRef.current.children;
      const highlightedItem = listItems[highlightedIndex] as HTMLElement;

      highlightedItem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [highlightedIndex]);

  function handleInputChange(val: string) {
    setIsOptionsVisible(true);
    setValue(val);
    setIsAdded(false);
    onChange(val);
  }

  function handleOptionSelect(option: GameOption) {
    setValue(option.label);
    setIsOptionsVisible(false);
    setIsAdded(false);

    onSelect(option);
  }

  function handleFocus() {
    setIsOptionsVisible(true);
    setTimeout(() => inputRef.current?.focus(), 0);

    if (optionRef.current) {
      optionRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'nearest'
      });
    }
  }

  function handleKeyDown(e: any) {
    let newIndex = -1;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      newIndex = highlightedIndex < options.length - 1 ? highlightedIndex + 1 : 0;
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      newIndex = highlightedIndex > 0 ? highlightedIndex - 1 : options.length - 1;
    } else if (e.key === 'Enter') {
      e.preventDefault();
      handleEnterPress();
      return;
    }

    setHighlightedIndex(newIndex);
  }

  function handleEnterPress() {
    if (value === '') return;
    if (highlightedIndex >= 0) {
      handleOptionSelect(options[highlightedIndex]);
      setHighlightedIndex(-1);
    } else if (!isValueInOptions) {
      handleAddNewGame();
    } else {
      pipe(
        map((opt: GameOption) => ({ ...opt, _l: opt.label.toLowerCase() })),
        find((opt: GameOption & { _l: string }) => opt._l === value.toLocaleLowerCase().trim()),
        ifElse(
          (opt: GameOption) => typeof opt !== 'undefined',
          handleOptionSelect,
          () => {}
        )
      )(options);
    }
  }

  function handleAddNewGame() {
    onSelect({ id: '', label: value });

    setIsOptionsVisible(false);
    setIsAdded(true);
  }
  function handleBlur() {
    setIsOptionsVisible(false);
    inputRef.current?.blur();
  }
  return (
    <div
      onClick={onClick}
      ref={searchInputContainerRef}
      className={cn(styles.search_input_container, {
        [styles.search_input_container_empty]: !options.length
      })}
    >
      <div
        className={cn(styles.input__wrapper, {
          [styles.input__wrapper_focused]: isOptionsVisible,
          [styles.input_wrapper_filled]: isAdded
        })}
        onClick={() => (disabled ? null : isOptionsVisible ? null : handleFocus())}
      >
        {isOptionsVisible ? (
          <input
            ref={inputRef}
            value={value}
            placeholder={placeholder}
            onChange={e => handleInputChange(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <div
            className={cn(styles.value, {
              [styles.placeholder]: !value
            })}
          >
            {value || placeholder}
          </div>
        )}
        <div className={styles.input_extension}>
          {/* tightly coupled with game options only, must be done on parent level */}
          {/* TODO: render input and its extensions to upper level for more flexibility */}
          <div>
            {isAdded ? (
              <div className={styles.added_game}>
                <CheckIcon width={12} height={10} />
                <span>{contentType === 'gaming' ? 'Game' : 'Product'} added!</span>
              </div>
            ) : !isValueInOptions && value !== '' ? (
              <GlobalButton
                className="fade-in-animation"
                type="secondary-outlined"
                size="sm"
                title={`Add ${contentType === 'gaming' ? 'game' : 'product'}`}
                onClick={handleAddNewGame}
              />
            ) : null}
          </div>

          <ArrowIcon
            width={18}
            height={11}
            color="#133D66"
            className={cn(styles.arrow, { [styles.arrow_focused]: !isOptionsVisible })}
            onClick={() => (disabled ? null : isOptionsVisible ? handleBlur() : handleFocus())}
          />
        </div>{' '}
      </div>
      <ul
        id="input"
        ref={ulRef}
        className={cn(styles.options, {
          [styles.options_hidden]: !isOptionsVisible,
          [styles.options_gaming]: contentType === 'gaming'
        })}
      >
        {options.map((opt, i) => (
          <li
            key={`${opt.label}${i}`}
            ref={opt.label === option.label ? optionRef : null}
            className={cn(styles.option, {
              [styles.option_with_divider]:
                splitBy in opt &&
                options[i + 1] &&
                Object.keys(options[i + 1]).length > 0 &&
                !options[i + 1]?.[splitBy],
              [styles.option_with_title_all]:
                !opt?.[splitBy] &&
                ((options[i - 1] &&
                  Object.keys(options[i - 1]).length > 0 &&
                  options[i - 1]?.[splitBy]) ||
                  i === 0),
              [styles.option_with_title_assessed]: opt?.[splitBy] && i === 0,
              [styles.highlighted]: i === highlightedIndex || option.label === opt.label
            })}
          >
            <button tabIndex={0} type="button" onClick={() => handleOptionSelect(opt)}>
              <span>{opt.label}</span>

              {splitBy in opt ? (
                <p>
                  {!opt?.hasPreferences && <AsteriskIcon className={styles.asteriks} color='#9891FC' width={14} />}
                  <span className={styles.tag}>{(opt as any)[splitBy]}</span>
                </p>
              ) : null}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

function find(fn: any) {
  return (list: any[]) => list.find(fn);
}
