import { call, put, takeLatest } from 'redux-saga/effects';

import { updateSegment, deleteSegment } from '@/services/Api/SegmentsService';
import historyService from '@/services/HistoryService';

import { Action } from '@/redux/types';

import { loadSegments } from '../pages/segmentsPage/actions';

import {
  updateSegmentRequest,
  updateSegmentError,
  deleteSegmentRequest,
  deleteSegmentSuccess,
  deleteSegmentError
} from './actions';
import { UPDATE_SEGMENT, DELETE_SEGMENT } from './types';

function* updateSegmentSaga(action: Action) {
  const {
    payload: { title, description, segmentId, filters }
  } = action;
  yield put(updateSegmentRequest());

  try {
    yield call(() => updateSegment(title, description, segmentId, filters));
    yield put(loadSegments());

    historyService.getHistory().goBack();
  } catch {
    yield put(updateSegmentError());
  }
}

function* deleteSegmentSaga(action: Action) {
  yield put(deleteSegmentRequest());

  try {
    const {
      payload: { segmentId }
    } = action;

    yield call(() => deleteSegment(segmentId));
    yield put(deleteSegmentSuccess());
    yield put(loadSegments());
    historyService.getHistory().goBack();
  } catch {
    yield put(deleteSegmentError());
  }
}

function* Saga() {
  yield takeLatest(UPDATE_SEGMENT, updateSegmentSaga);
  yield takeLatest(DELETE_SEGMENT, deleteSegmentSaga);
}

export default Saga;
