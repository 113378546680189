/* eslint-disable no-param-reassign */

import classNames from 'classnames';
import isFunction from 'lodash-es/isFunction';
import isEqual from 'lodash.isequal';
import { createElement, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import Dropzone, { DropzoneRef, ErrorCode, FileRejection } from 'react-dropzone';
import { useParams } from 'react-router-dom';

import ErrorIcon from 'ui-lib-12traits/src/Icons/svg/common/error.svg?component';
import LogoIcon from 'ui-lib-12traits/src/Icons/svg/common/logo.svg?component';
import { GlobalButton } from 'ui-lib-12traits/src/index';

import { useAppSelector } from '@/hooks/useApp';


import { useI18n } from '@/i18n';
import { statuses, templates } from '@/pages/Navigator/AssessmentAutomationV2/types';

import { fetchAssessments } from '@/services/Api/AssessmentSurveyService';
import { upload } from '@/services/Api/MediaService';
import { BUCKETS } from '@/services/Api/MediaService/constants';

import { selectCompanyAvatarUrl } from '@/redux/user/selectors';


import StepContainer from '../StepContainer';

import BrandingStepI18n from './BrandingStep.i18n';
import styles from './BrandingStep.module.scss';
import { MAX_FILE_SIZE } from './constants';





const solstenLogo = 'https://cdn.solsten.io/logos/solsten-logo-colored.png';
const errorOnUpload = {
  [ErrorCode.FileTooLarge]: (
    <>
      File size is too large. Please upload a file
      <span className={styles['drop-rejected-error__highlight']}> less than 2MB</span>.
    </>
  ),
  [ErrorCode.FileInvalidType]: (
    <>
      File type not supported. Please upload
      <span className={styles['drop-rejected-error__highlight']}> PNG, JPG, or JPEG</span>.
    </>
  )
} as const;

const branding = {
  solsten: 'solsten',
  custom: 'custom'
} as const;
type Branding = (typeof branding)[keyof typeof branding];

function BrandingStep({
  onNext,
  isActive,
  values = { logoUrl: '' },
  setActiveStep,
  template,
  title,
  order,
  status,
  baseStepValues,
  updateStepValues,
  setErrorCount,
  errorCount,
  stepsValues,
  ...step
}: any) {
  const dropzoneRef = useRef<DropzoneRef>(null);
  const { id: assessmentID } = useParams<{ id: string }>();

  const companyLogo = useAppSelector(selectCompanyAvatarUrl);

  const [dropRejectedType, setDropRejectedType] = useState<
    'file-too-large' | 'file-invalid-type' | ''
  >('');
  const [brandingType, setBrandingType] = useState<Branding>(branding.custom);
  const [selectedBrandFile, setSelectedBrandFile] = useState<File | null>(null);
  const [assessments, setAssessments] = useState([]);
  const i18n = useI18n(BrandingStepI18n);

  const assessment = assessments
    .filter(item => item.id !== assessmentID && item?.projectName === baseStepValues.projectName)
    .sort((a, b) => (+a.updatedAt > +b.updatedAt ? 1 : -1));

  const defaultLogo =
    assessment.length > 0
      ? assessment[assessment.length - 1]?.logoUrl === solstenLogo
        ? ''
        : assessment[assessment.length - 1]?.logoUrl
      : companyLogo;

  const [logoUrl, setLogoUrl] = useState();
  const errorCountRef = useRef(errorCount);

  if (!isEqual(errorCountRef.current, errorCount)) {
    errorCount.current = errorCount;
  }

  useEffect(() => {
    if (status === statuses.pristine) {
      setLogoUrl(defaultLogo);
    }
  }, [defaultLogo]);

  useEffect(() => {
    const fetchAllAssessments = async () => {
      try {
        const { data } = await fetchAssessments({});
        setAssessments(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAllAssessments();
  }, [baseStepValues.projectName]);

  useEffect(() => {
    if (values.type.length > 0 && status !== statuses.pristine) {
      setBrandingType(values.type);
      setLogoUrl(
        brandingType === branding.solsten
          ? ''
          : values.type === branding.solsten
            ? ''
            : values.logoUrl
      );
    }
  }, [values.type, status]);

  useEffect(() => {
    const uploadLogo = async () => {
      if (selectedBrandFile) {
        const data = await upload({
          bucket: BUCKETS.AssessmentRequestBrandingLogo,
          file: selectedBrandFile
        });

        setLogoUrl(data);
        setBrandingType('custom');
        updateStepValues(
          {
            id: step.id,
            logo_url: data,
            type: branding.custom
          },
          template
        );
      }
    };
    uploadLogo();
  }, [selectedBrandFile, brandingType]);

  useEffect(() => {
    setErrorCount({
      ...errorCount,
      [template]: !(brandingType === branding.custom && !logoUrl)
    });
  }, [brandingType, logoUrl, errorCountRef.current]);

  const handleDrop = (acceptedFiles: File[]): void => {
    const [file] = acceptedFiles;
    setSelectedBrandFile(file);
    setDropRejectedType('');
  };

  const handleDropRejected = (event: FileRejection[]): void => {
    const [fileRejection] = event;
    const [error] = fileRejection.errors;
    setDropRejectedType(error.code as ErrorCode.FileInvalidType | ErrorCode.FileTooLarge);
  };

  const renderDisclaimer = useCallback(
    (message, key) => {
      const __html = isFunction(message)
        ? message(stepsValues?.[templates.static] || baseStepValues)
        : message;
      return createElement('p', { dangerouslySetInnerHTML: { __html }, key });
    },
    [baseStepValues, stepsValues?.[templates.static]?.contentType]
  );

  return (
    <StepContainer
      id={order}
      titleExpanded={i18n.title}
      title={title}
      withNumber
      isExpanded={isActive}
      template={template}
      onExpand={setActiveStep}
      invalid={errorCount?.[template] === false}
      testId="branding-step"
      collapsedElement={
        <>
          <div className={styles.card__value}>
            {i18n.branding.type[brandingType === branding.solsten ? 'solsten' : 'other']}
          </div>
          <div className={styles.card__img}>
            {(brandingType === branding.solsten ||
              (brandingType === branding.custom && logoUrl)) && (
              <img
                src={brandingType === branding.solsten ? solstenLogo : logoUrl}
                alt={i18n.branding.status.uploaded}
              />
            )}
          </div>
        </>
      }
    >
      <div className={styles.container}>
        <section>
          <Dropzone
            ref={dropzoneRef}
            accept={{ 'image/jpeg': [], 'image/png': [], 'image/jpg': [] }}
            onDropAccepted={handleDrop}
            onDropRejected={handleDropRejected}
            multiple={false}
            maxSize={MAX_FILE_SIZE}
          >
            {({ getRootProps, getInputProps, isDragActive }): ReactElement => (
              <div
                {...getRootProps()}
                className={classNames(styles['upload-area'], {
                  [styles['upload-area_dragging']]: isDragActive
                })}
              >
                <div
                  data-testid="upload-area"
                  className={classNames(styles['upload-logo'], {
                    [styles['upload-logo_dragging']]: isDragActive
                  })}
                >
                  <input {...getInputProps()} />
                  {dropRejectedType && (
                    <div data-testid="upload-error" className={styles['drop-rejected-error']}>
                      <ErrorIcon className={styles['drop-rejected-error__icon']} />

                      <div className={styles['drop-rejected-error__text']}>
                        {errorOnUpload[dropRejectedType]}
                      </div>
                    </div>
                  )}

                  <div
                    className={classNames(styles.logo_container, {
                      [styles.logo_container_filled]: !!logoUrl
                    })}
                  >
                    {brandingType !== 'solsten' && (
                      <>
                        <div className={styles.logo}>
                          <img alt={i18n.branding.logo.other.subtitle} src={logoUrl} />
                          <div>{i18n.branding.logo.other.title}</div>
                        </div>
                        <div className={styles.line} />
                      </>
                    )}
                    <LogoIcon data-testid="solsten-logo" width={120} />
                  </div>

                  {i18n.disclaimer.map(renderDisclaimer)}
                </div>
              </div>
            )}
          </Dropzone>

          <div className={styles.section}>
            <span className={styles.browse_text}>
              {i18n.options.upload.title}{' '}
              <button type="button" onClick={() => dropzoneRef?.current.open()}>
                {i18n.options.upload.action}
              </button>
            </span>

            {brandingType !== branding.solsten ? (
              <div className={styles.use_solsten_text}>
                {logoUrl ? (
                  <>
                    <span>{i18n.options.remove.title}</span>{' '}
                    <button
                      type="button"
                      onClick={() => {
                        setDropRejectedType('');
                        setLogoUrl('');
                        setSelectedBrandFile(null);
                      }}
                    >
                      {i18n.options.remove.action}
                    </button>
                  </>
                ) : (
                  <>
                    <span>{i18n.options.skip.title}</span>{' '}
                    <button
                      type="button"
                      onClick={() => {
                        setBrandingType(branding.solsten);
                        updateStepValues(
                          {
                            id: step.id,
                            logo_url: solstenLogo,
                            type: branding.solsten
                          },
                          template
                        );
                      }}
                    >
                      {i18n.options.skip.action}
                    </button>
                  </>
                )}
              </div>
            ) : null}
          </div>
        </section>
        {(brandingType === branding.solsten || logoUrl) && (
          <GlobalButton
            className={styles['btn-next']}
            data-testid="next-btn"
            title="Next"
            onClick={() =>
              onNext({
                logo_url: brandingType === branding.solsten ? solstenLogo : logoUrl,
                type: brandingType
              })
            }
          />
        )}
      </div>
    </StepContainer>
  );
}

export default BrandingStep;
