export const SET_NAVIGATOR_PULSE_IS_EXPANDED = 'Config / SET_NAVIGATOR_PULSE_IS_EXPANDED';
export interface ConfigState {
  navigatorPulseIsExpanded: boolean;
}

export interface Genre {
  id: number;
  name: string;
  slug: string;
  is_purchased: boolean;
}

interface DemographicsItem {
  name: string;
  traitId: string;
}
export interface DemographicsItems {
  [key: string]: DemographicsItem | DemographicsItem[];
}

export interface DemographicsOption {
  category: string;
  key: string;
  label: string;
  measurement: string;
}
export interface DemographicsCategory {
  category: string;
  items: DemographicsOption[];
}

export interface FlatDemographicsOptions {
  [key: string]: DemographicsCategory | DemographicsOption;
}

export interface ComparisonOptions {
  kpis: ComparisonCategory;
  traits: ComparisonCategory;
  demographics: DemographicsItems;
}

export interface ComparisonCategory {
  [code: string]: ComparisonOption;
}

export interface CategorizedItems {
  [code: string]: ComparisonOption[];
}

export interface ComparisonOption {
  key?: string;
  label: string;
  measurement: string;
  game_average_value: string;
  category: string;
  code?: string;
  is_binary?: boolean;
  subtraits?: {
    [key: string]: ComparisonOption;
  };
}
