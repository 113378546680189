import { prepareAction } from '@/redux/helpers';
import { Action } from '@/redux/types';

import {
  LOAD_GAMES_ERROR,
  LOAD_GAMES_REQUEST,
  LOAD_GAMES_SUCCESS,
  RESET_GAMES_STATE,
  SET_CURRENT_CLUSTER_NAME
} from './types';

export const loadGamesRequest = (): Action => prepareAction(LOAD_GAMES_REQUEST);

export const loadGamesSuccess = (
  games: any,
  companyName: string,
  isNonGamingCompany: boolean
): Action =>
  prepareAction(LOAD_GAMES_SUCCESS, {
    list: games,
    companyName,
    isNonGamingCompany
  });

export const loadGamesError = (error: string): Action => prepareAction(LOAD_GAMES_ERROR, { error });

export const setCurrentClusterName = (
  gameSlug: string,
  segmentId: string,
  clusterCode: string,
  clusterName: string
): Action =>
  prepareAction(SET_CURRENT_CLUSTER_NAME, { gameSlug, segmentId, clusterCode, clusterName });

export const resetGamesState = (): Action => prepareAction(RESET_GAMES_STATE);
