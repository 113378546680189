import { Template } from '@/pages/Navigator/AssessmentAutomationV2/types';

import { RootState } from '../rootStore';

import { type TeamSurvey } from './types';

export const selectTeamSurvey = (state: RootState): TeamSurvey => state.survey.team;

export const selectSurveyIntegrationPercentage = (state: RootState): number =>
  state.survey.surveyIntegrationPercentage;

export const selectSurveyIntegrationStatus = (state: RootState): string | null =>
  state.survey.surveyIntegrationStatus;

export const selectApproximateSurveyIntegrationTime = (state: RootState): number =>
  state.survey.approximateSurveyIntegrationTime;

export const selectHoveredStep = (state: RootState): Template => state.survey.hoveredStep;
