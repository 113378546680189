import { Action } from '@/redux/types';

import {
  LOAD_KPIS_SUCCESS,
  LOAD_KPIS_STATUS_SUCCESS,
  LOAD_KPIS_STATUS_ERROR,
  LOAD_KPIS_ERROR,
  SET_KPIS_INTEGRATION_PERCENTAGE,
  SET_KPIS_INTEGRATION_STATUS,
  SET_APPROXIMATE_KPIS_INTEGRATION_TIME,
  KpiState,
  SET_KPIS_IS_UPLOADING,
  SET_KPIS_UPLOAD_ERROR,
  LOAD_DASHBOARD_KPIS_SUCCESS
} from './types';

const initialState: KpiState = {
  isDataLoading: false,
  dataLoadingError: false,
  dataLoadingErrorMsg: ''
};

export default (state = initialState, action: Action): KpiState => {
  const { dashboardId } = action.payload || {};
  switch (action.type) {
    case LOAD_KPIS_STATUS_SUCCESS:
      return {
        ...state,
        [dashboardId]: { ...state[dashboardId], status: action.payload.status }
      };
    case LOAD_KPIS_SUCCESS:
      return {
        ...state,
        [dashboardId]: { ...state[dashboardId], data: action.payload.data }
      };
    case LOAD_KPIS_STATUS_ERROR:
      return {
        ...state,
        [dashboardId]: {
          ...state[dashboardId],
          statusError: true,
          statusErrorMsg: action.payload.errorMsg
        }
      };
    case LOAD_KPIS_ERROR:
      return {
        ...state,
        [dashboardId]: {
          ...state[dashboardId],
          kpisError: true,
          kpisErrorMsg: action.payload.errorMsg
        }
      };
    case SET_KPIS_INTEGRATION_PERCENTAGE:
      return {
        ...state,
        [dashboardId]: {
          ...state[dashboardId],
          kpisIntegrationPercentage: action.payload.percentage
        }
      };
    case SET_KPIS_INTEGRATION_STATUS:
      return {
        ...state,
        [dashboardId]: { ...state[dashboardId], kpisIntegrationStatus: action.payload.status }
      };
    case SET_APPROXIMATE_KPIS_INTEGRATION_TIME:
      return {
        ...state,
        [dashboardId]: {
          ...state[dashboardId],
          approximateKpisIntegrationTime: action.payload.time
        }
      };
    case SET_KPIS_UPLOAD_ERROR:
      return {
        ...state,
        [dashboardId]: {
          ...state[dashboardId],
          kpisUploadError: action.payload.errorData
        }
      };
    case SET_KPIS_IS_UPLOADING:
      return {
        ...state,
        [dashboardId]: { ...state[dashboardId], kpisIsUploading: action.payload.isUploading }
      };
    case LOAD_DASHBOARD_KPIS_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        isDataLoading: false
      };
    default:
      return state;
  }
};
