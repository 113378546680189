// @ts-nocheck
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { selectGameSlug, selectIsMedicalDashboard, selectNamespace } from '@/redux/game/selectors';
import { selectIsNavigatorApp, selectIsNonGamingCompany } from '@/redux/games/selectors';
import { selectCompanyDetails, selectUserInfo } from '@/redux/user/selectors';
import { CompanyTypes } from '@/redux/user/types';

export const Glossary = createContext();

export const Locale = createContext();

export const Settings = createContext();

export const Testing = createContext();

export const noop = () => {};

export function Provider({ testing = false, children, ...settings }) {
  const [locale, persist] = useState(getLocale());
  const check = useCallback(selector => (testing ? noop : selector), [testing]);
  const company = useSelector(check(selectCompanyDetails));
  const user = useSelector(check(selectUserInfo));
  const namespace = useSelector(check(selectNamespace));
  const glossary = useMemo(() => {
    const dashboard = { namespace };

    return getGlossary({
      company: settings.company || company,
      user: settings.user || user,
      dashboard: settings.dashboard || dashboard
    });
  }, [company, namespace, settings, user]);

  useEffect(() => {
    const listen = () => persist(getLocale());

    window.addEventListener('languagechange', listen, false);

    return () => window.removeEventListener('languagechange', listen, false);
  }, []);

  return (
    <Testing.Provider value={testing}>
      <Settings.Provider value={settings}>
        <Locale.Provider value={locale}>
          <Glossary.Provider value={glossary}>{children}</Glossary.Provider>
        </Locale.Provider>
      </Settings.Provider>
    </Testing.Provider>
  );
}

export function Render({ component: Component, settings, props }) {
  const i18n = useI18n(settings);

  return <Component i18n={i18n} {...props} />;
}

export const connect = settings => component => function(props) {
  return <Render component={component} props={props} settings={settings} />
};

export const getGlossary = ({ company }) => ({
  actor:
    { [CompanyTypes.Gaming]: 'player', [CompanyTypes.Medical]: 'patient' }[company.companyType] ||
    'user',
  actors:
    { [CompanyTypes.Gaming]: 'players', [CompanyTypes.Medical]: 'patients' }[company.companyType] ||
    'users',
  verb: { [CompanyTypes.Gaming]: 'playing' }[company.companyType] || 'using',
  app: { [CompanyTypes.Gaming]: 'game' }[company.companyType] || 'product',
  gameToExperience: { [CompanyTypes.Gaming]: 'game' }[company.companyType] || 'experience',
  gameToProduct: { [CompanyTypes.Gaming]: 'game' }[company.companyType] || 'product',
  experienceTab: { [CompanyTypes.Gaming]: 'Experience' }[company.companyType] || 'UX Metrics',
  youHave:
    {
      [CompanyTypes.Gaming]: 'Your game has'
    }[company.companyType] || 'You have',
  iGame: { [CompanyTypes.Gaming]: 'I game' }[company.companyType] || 'I experience products',
  play:
    {
      [CompanyTypes.Gaming]: 'play'
    }[company.companyType] || 'use',
  inGameFlow:
    {
      [CompanyTypes.Gaming]: 'My In-Game Flow'
    }[company.companyType] || 'Flow State Disposition',
  myAbility:
    {
      [CompanyTypes.Gaming]: 'My Ability'
    }[company.companyType] || 'Usage Ability Check',
  gamer:
    {
      [CompanyTypes.Gaming]: 'gamer'
    }[company.companyType] || 'user',
  pcsTitle:
    {
      [CompanyTypes.Gaming]: 'Player-centered Score (PCS)'
    }[company.companyType] || 'Human-centered Score (HCS)',
  pcsDefinition:
    {
      [CompanyTypes.Gaming]:
        'PCS is a measure of shared value including how much value (LTV) the player is capable of giving to your game as well as how much value (biopsychosocial benefit) the user is getting from playing your game.'
    }[company.companyType] ||
    'HCS is a measure of shared value including how much value (LTV) the user is capable of giving to your experience as well as how much value (biopsychosocial benefit) the user is getting from engaging with your experience.',
  engagement:
    {
      [CompanyTypes.Gaming]: 'Product'
    }[company.companyType] || 'Product',
  acquisition:
    {
      [CompanyTypes.Gaming]: 'Acquisition'
    }[company.companyType] || 'Marketing',
  pcsOrHcs:
    {
      [CompanyTypes.Gaming]: 'pcs'
    }[company.companyType] || 'hcs',
  playersToPeople:
    {
      [CompanyTypes.Gaming]: 'players'
    }[company.companyType] || 'people'
});

export const getLocale = () => {
  const { language, languages = [], userLanguage } = window.navigator;

  return language || userLanguage || languages?.at(0) || 'en-US';
};

export const useSettings = () => {
  const testing = useContext(Testing);
  const settings = useContext(Settings);
  const check = useCallback(selector => (testing ? noop : selector), [testing]);
  const glossary = useContext(Glossary);
  const company = useSelector(check(selectCompanyDetails));
  const user = useSelector(check(selectUserInfo));
  const namespace = useSelector(check(selectNamespace));
  const gameSlug = useSelector(check(selectGameSlug));
  const isNavigatorApp = useSelector(check(selectIsNavigatorApp(gameSlug)));
  const isNonGamingCompany = useSelector(check(selectIsNonGamingCompany));
  const isMedicalDashboard = useSelector(check(selectIsMedicalDashboard));
  const state = useMemo(
    () => ({
      dashboard: { namespace },
      company,
      glossary,
      isMedicalDashboard,
      isNavigatorApp,
      isNonGamingCompany,
      user
    }),
    [company, glossary, isMedicalDashboard, isNavigatorApp, isNonGamingCompany, namespace, user]
  );

  return testing ? settings : state;
};

export const useI18n = (getTranslation: T) => {
  const settings = useSettings();
  const i18n = useMemo(() => getTranslation(settings), [settings]);

  return i18n;
};
