export const SORT_OPTIONS = [
  'Most informative',
  'Highest sentiment',
  'Lowest sentiment',
  'Most recent'
];

export type SortOption = (typeof SORT_OPTIONS)[number];

export enum Panels {
  Date,
  Channels,
  Highlights,
  Personas
}
