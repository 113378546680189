import classNames from 'classnames';
import { ReactElement } from 'react';


import { SingleSelect } from 'ui-lib-12traits/src/index';

import { useAppSelector } from '@/hooks/useApp';

import { updateOrRemoveSearchParam } from '@/services/UrlService';

import { selectClusters } from '@/redux/game/selectors';


import { ClusterSelectItem } from './ClusterSelectItem';
import styles from './FilterByPersona.module.scss';


interface Props {
  clusters: string[];
}

function FilterByPersona({ clusters }: Props): ReactElement {
  const availableClusters = useAppSelector(selectClusters).filter(
    cluster => cluster.value !== 'all'
  );

  const isClusterSelected = (clusterValue: string): boolean =>
    Boolean(clusters.includes(clusterValue));

  const updateClusters = (clusterValue: string): void => {
    if (isClusterSelected(clusterValue)) {
      updateOrRemoveSearchParam({ clusters: clusters.filter(cluster => cluster !== clusterValue) });
    } else {
      updateOrRemoveSearchParam({
        clusters: [...clusters, clusterValue]
      });
    }
  };

  const clearClusters = (): void => {
    updateOrRemoveSearchParam({
      clusters: null
    });
  };

  return (
    <div className={styles['select-cluster--wrapper']}>
      <div className={styles['select-cluster--wrapper__heading']}>
        <div className={styles['select-cluster--wrapper__title']} data-testid="filter-title">
          Filter Threads by Audience
        </div>
        {Boolean(clusters.length) && (
          <div className={styles['select-cluster--wrapper__clear']} onClick={clearClusters}>
            Clear filter
          </div>
        )}
      </div>
      <div className={styles['segment-select']}>
        <span className={styles['segment-select--text']} data-testid="filter-segment-text">
          Segment: Conversations can only be filtered by the personas identified in your overall
          assessed audience.
        </span>
        <SingleSelect
          disabled
          options={[{ name: 'My Audience', value: 'all' }]}
          selected="all"
          headClassName={classNames({
            [styles['segment-select--dropdown--disabled']]: true,
            [styles['segment-select--dropdown']]: false
          })}
          width={312}
          // TODO: single-select-open by default value / else add to ui-lib. Need to add values probably
        />
      </div>
      <div className={styles['select-cluster--options']}>
        <span className={styles['select-cluster--options--text']}>Select one or more personas</span>
        <div className={styles['select-cluster--items']}>
          {availableClusters.map(cluster => (
            <ClusterSelectItem
              cluster={cluster}
              key={cluster.value}
              onClick={updateClusters}
              isSelected={isClusterSelected(cluster.value)}
              testid={`filter-by-persona-${cluster.value}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export { FilterByPersona };
