import { prepareAction } from '@/redux/helpers';
import { Action } from '@/redux/types';

import {
  LOAD_KPIS,
  LOAD_KPIS_SUCCESS,
  LOAD_KPIS_ERROR,
  LOAD_KPIS_STATUS,
  LOAD_KPIS_STATUS_SUCCESS,
  LOAD_KPIS_STATUS_ERROR,
  SET_KPIS_INTEGRATION_PERCENTAGE,
  SET_KPIS_INTEGRATION_STATUS,
  SET_APPROXIMATE_KPIS_INTEGRATION_TIME,
  CALCULATE_KPIS_INTEGRATION,
  SET_KPIS_UPLOAD_ERROR,
  KpisUploadError,
  SET_KPIS_IS_UPLOADING,
  LOAD_DASHBOARD_KPIS_SUCCESS,
  LOAD_TIMELINES
} from './types';

export const loadKpis = (dashboardId: string, segment?: string, cluster?: string): Action =>
  prepareAction(LOAD_KPIS, { segment, cluster: cluster || 'all', dashboardId });

export const loadKpisSuccess = (data: any, dashboardId: string): Action =>
  prepareAction(LOAD_KPIS_SUCCESS, { data, dashboardId });

export const loadKpisError = (data: any, dashboardId: string): Action =>
  prepareAction(LOAD_KPIS_ERROR, { data, dashboardId });

export const loadKpisStatus = (dashboardId: string): Action =>
  prepareAction(LOAD_KPIS_STATUS, { dashboardId });

export const loadKpisStatusSuccess = (status: number, dashboardId: string): Action =>
  prepareAction(LOAD_KPIS_STATUS_SUCCESS, { status, dashboardId });

export const loadKpisStatusError = (error: string, dashboardId: string): Action =>
  prepareAction(LOAD_KPIS_STATUS_ERROR, { errorMsg: error, dashboardId });

export const setKpisIntegrationPercentage = (percentage: number, dashboardId: string): Action =>
  prepareAction(SET_KPIS_INTEGRATION_PERCENTAGE, { percentage, dashboardId });

export const setKpisIntegrationStatus = (status: string, dashboardId: string): Action =>
  prepareAction(SET_KPIS_INTEGRATION_STATUS, { status, dashboardId });

export const setApproximateKpisIntegrationTime = (time: number, dashboardId: string): Action =>
  prepareAction(SET_APPROXIMATE_KPIS_INTEGRATION_TIME, { time, dashboardId });

export const calculateKpisIntegration = (dashboardId: string): Action =>
  prepareAction(CALCULATE_KPIS_INTEGRATION, { dashboardId });

export const setKpisUploadError = (
  errorData: KpisUploadError | null,
  dashboardId: string
): Action => prepareAction(SET_KPIS_UPLOAD_ERROR, { errorData, dashboardId });

export const setKpisIsUploading = (isUploading: boolean, dashboardId: string): Action =>
  prepareAction(SET_KPIS_IS_UPLOADING, { isUploading, dashboardId });

export const loadDashboardsKpisSuccess = (data: any): Action =>
  prepareAction(LOAD_DASHBOARD_KPIS_SUCCESS, { data });

export const loadTimelines = (): Action => prepareAction(LOAD_TIMELINES);
