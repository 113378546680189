import CoreApiService from '@/services/Api/CoreApiService';

import { selectGameJwt } from '@/redux/game/selectors';

import { storeRegistry } from '../StoreRegistryService';

export default class KpisApiService extends CoreApiService {
  constructor(
    config: {
      token?: string;
      version?: 'v1' | 'v2' | 'v0';
      isCamelCaseResponse?: boolean;
      shouldSendSnakeCase?: boolean;
      useGameJwt?: boolean;
      acceptsCsv?: boolean;
      authHeader?: boolean;
    } = {}
  ) {
    const {
      version,
      token,
      isCamelCaseResponse = true,
      shouldSendSnakeCase = true,
      useGameJwt = false,
      acceptsCsv = false,
      authHeader = false
    } = config;
    super({ isCamelCaseResponse, shouldSendSnakeCase, useGameJwt, version, token });

    this.api = `${process.env.BASE_URL}/kpis`;
    const jwt = token || selectGameJwt(storeRegistry.getState());

    this.setHeaders(
      authHeader ? { Authorization: `Bearer ${jwt}` } : acceptsCsv ? { Accept: 'text/csv' } : {}
    );
  }
}
