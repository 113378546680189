import { storeRegistry } from '@/services/StoreRegistryService';
import { parseQuery, updateOrRemoveSearchParam } from '@/services/UrlService';

import { selectSegments } from '@/redux/game/selectors';
import { Segment } from '@/redux/types';


export const segmentUrlParam = 'segment';

/**
 * Updates segment param in url
 */
export const updateSegmentInUrl = (
  segment: string,
  segments: Segment[],
  urlParam: string = segmentUrlParam
): void => {
  const selected = segments.find(one => one.id === segment) || segments[0];

  updateOrRemoveSearchParam({ [urlParam]: selected.isDefault ? null : selected.id });
};

/**
 * Parses segment parameter from query
 */
export const parseSegmentInUrl = (urlParam: string = segmentUrlParam): string => {
  const parsedQuery = parseQuery();
  return parsedQuery[urlParam];
};

/**
 * Validates segment
 */
export const isSegmentValid = (segment?: string): boolean => {
  if (segment === undefined) {
    return true;
  }
  const segments = selectSegments(storeRegistry.getState());
  return segments.map(one => one.id).includes(segment);
};
