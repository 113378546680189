import { ChartColumn } from 'ui-lib-12traits/src/index';

import { avg } from '@/services/ArrayService';

interface DataItem {
  [columnName: string]: number | null;
}

const getArrayOfValuesByColumnName = (columnName: string, personas: Array<DataItem>) =>
  personas.map(columns => columns[columnName]);

const getAverageByEachColumn = (columnsNames: Array<string>, columnsData: Array<DataItem>) => {
  const average: DataItem = {};
  columnsNames.forEach((columnName: string) => {
    const columnValues = getArrayOfValuesByColumnName(columnName, columnsData);
    const notNullColumnValues = columnValues.filter(value => value !== null);
    if (!notNullColumnValues.length) {
      average[columnName] = null;
    } else {
      average[columnName] = avg(notNullColumnValues as number[]) || 0;
    }
  });
  return average;
};

export const mapEngagementRate = (
  data: { cluster: number; timeSeries: { [timestamp: string]: number | null } }[]
) => {
  const mappedData: { [personaName: string]: { [timestamp: string]: number | null } } = data.reduce(
    (res, clusterData) => ({ ...res, [clusterData.cluster]: clusterData.timeSeries }),
    {}
  );

  const mappedDataArray: { [timestamp: string]: number | null }[] = Object.values(mappedData);
  if (!mappedDataArray.length) {
    return null;
  }
  const columnsNames = Object.keys(mappedDataArray[0]);
  mappedData.all = getAverageByEachColumn(columnsNames, mappedDataArray);
  return mappedData;
};

/* eslint-disable no-restricted-syntax */
export const groupMissingChartDataRanges = (columns: Array<ChartColumn>) => {
  const res: Array<ChartColumn[]> = [];
  let lastGroup: Array<ChartColumn> = [];
  for (const column of columns) {
    if (column.y === null) {
      lastGroup.push(column);
    } else if (lastGroup.length) {
      res.push(lastGroup);
      lastGroup = [];
    }
  }
  if (lastGroup.length) {
    res.push(lastGroup);
  }
  return res;
};
