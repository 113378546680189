import CompareApiService from '@/services/Api/CompareApiService';

import { AudienceI } from './types';

export const createComparison = async (title: string, audiences: AudienceI[]): Promise<any> =>
  new CompareApiService({ shouldSendSnakeCase: true })
    .setEndpoint(`/pages`)
    .setBody({
      title,
      audiences
    })
    .post();

export const getComparisons = async (): Promise<any> =>
  new CompareApiService().setEndpoint(`/pages`).get();

export const getComparison = async (id: string): Promise<any> =>
  new CompareApiService({ isCamelCaseResponse: false }).setEndpoint(`/pages/${id}`).get();

export const removeComparison = (id: string): Promise<any> =>
  new CompareApiService().setEndpoint(`/pages/${id}`).delete();

export const updateTitle = (
  id: string,
  fields: { title?: string; description?: string }
): Promise<any> => new CompareApiService().setEndpoint(`/pages/${id}`).setBody(fields).put();

export const addSheet = (
  pageId: string,
  fields: { title: string; filters?: { key: string; type: string }[] }
): Promise<any> =>
  new CompareApiService()
    .setEndpoint(`/pages/${pageId}/sheets`)
    .setBody({ ...fields, hidden: false })
    .post();

export const updateSheet = (
  pageId: string,
  sheetId: string,
  fields: { title?: string; hidden?: boolean; filters?: any }
): Promise<any> =>
  new CompareApiService().setEndpoint(`/pages/${pageId}/sheets/${sheetId}`).setBody(fields).put();

export const removeSheet = (pageId: string, sheetId: string): Promise<any> =>
  new CompareApiService().setEndpoint(`/pages/${pageId}/sheets/${sheetId}`).delete();

export const getComparisonOptions = (pageId: string): Promise<any> =>
  new CompareApiService({ isCamelCaseResponse: false })
    .setEndpoint(`/pages/${pageId}/options`)
    .get();

export const addAudience = (
  pageId: string,
  dashboardId: string,
  cluster: string,
  segmentId?: string
): Promise<any> =>
  new CompareApiService({ shouldSendSnakeCase: true })
    .setEndpoint(`/pages/${pageId}/audiences`)
    .setBody({
      dashboardId,
      cluster,
      ...(segmentId ? { segmentId } : {})
    })
    .post();

export const removeAudience = (pageId: string, audienceId: string): Promise<any> =>
  new CompareApiService().setEndpoint(`/pages/${pageId}/audiences/${audienceId}`).delete();

export const fetchComparisonOptionsConfig = async (gameId: string): Promise<any> =>
  new CompareApiService({ isCamelCaseResponse: false })
    .setParams({ dashboard_id: gameId })
    .setEndpoint('/config/comparison/options')
    .get();

export const fetchLeaderboardByTrait = async (traitId: string, segment?: string): Promise<any> => {
  const request = new CompareApiService({ useGameJwt: true, isCamelCaseResponse: false })
    .setEndpoint('/cluster/traits/leaderboard')
    .setParams({
      trait_id: traitId
    });
  if (segment) {
    request.setParams({
      segment
    });
  }
  return request.get();
};

export const fetchLeaderboardByKpi = async (kpiId: string, segment?: string): Promise<any> => {
  const request = new CompareApiService({
    version: 'v2',
    useGameJwt: true,
    isCamelCaseResponse: false
  })
    .setEndpoint('/kpis/leaderboard')
    .setParams({
      kpi_id: kpiId
    });
  if (segment) {
    request.setParams({
      segment
    });
  }
  return request.get();
};
