import { createSelector } from 'reselect';

import { ActionsCategories } from '@/pages/Traits/ActionsPage/constants';

import { RootState } from '@/redux/rootStore';

import { ActionsData, Filters, Progress } from './types';

export const selectIsActionsLoading = (state: RootState): boolean => state.actionsPage.loading;

export const selectActionsCurrentCategoryIndex = (state: RootState): number =>
  state.actionsPage.currentCategoryIndex || 0;

export const selectActionsCurrentCategoryName = createSelector(
  selectActionsCurrentCategoryIndex,
  categoryIndex => {
    const currentCategory = ActionsCategories.find(({ value }) => Number(value) === categoryIndex);
    return currentCategory ? currentCategory.name : '';
  }
);

export const selectActionsCurrentStatusId = (state: RootState): number =>
  state.actionsPage.currentStatusId || 0;

export const selectActionsData = (state: RootState): ActionsData => state.actionsPage.actionsData;

export const selectActionsPerPage = (state: RootState): number => state.actionsPage.perPage;

export const selectCurrentActionId = (state: RootState): string =>
  state.actionsPage.currentActionId;

export const selectShowLaunchDetails = (state: RootState): boolean =>
  state.actionsPage.showLaunchDetails;

export const selectProgress = (state: RootState): Progress => state.actionsPage.progress;

export const selectTimeFrame = (state: RootState): string => state.actionsPage.timeFrame;

export const selectShowFilters = (state: RootState): boolean => state.actionsPage.showFilters;

export const selectFilters = (state: RootState): Filters => state.actionsPage.filters;

export const selectFiltersByTags = ({
  actionsPage
}: RootState): {
  category: string;
  label: string;
  value: string;
}[] => [...actionsPage.filters.kpis.map(filterKpi => ({ ...filterKpi, category: 'kpis' }))];

export const selectFiltersApplied = ({ actionsPage }: RootState): boolean =>
  Boolean(actionsPage.filters.kpis.length);
