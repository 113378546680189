import { ContentTypeEnum } from '@/redux/game/types';

export default () => ({
  title: 'Best practices',
  subtitle: {
    static: {
      fragments: {
        first: "You'll receive ",
        second: ({ contentType, projectName }) => {
          const isGaming = contentType === ContentTypeEnum.Gaming;
          const subject = isGaming ? 'game' : 'product';

          return `in ${projectName || `[${subject}]`} for completing the assessment...`;
        }
      }
    },
    dynamic: {
      aria: 'reward description',
      placeholder: 'a reward'
    }
  },
  practices: [
    `<strong>Use what you know</strong> about your audience already and what makes them tick.`,
    `<strong>Talk with your customer support team</strong>. Learn how they give out rewards when something goes wrong. (i.e., wrong authorization, payment method, bug, etc).`,
    `<strong>Calculate the expected value</strong> of an incentive to understand any impact on your KPIs when determining what to offer.`,
    ({ contentType }) => {
      const isGaming = contentType === ContentTypeEnum.Gaming;
      const subject = isGaming ? 'game' : 'product';

      return `<strong>See this as an immediate upsell opportunity</strong>. For other clients, this was an opportunity to showcase premium features in their ${subject}. Customers saw an uptick in sales after the reward &quot;ran out&quot; to break even on what they gave out in free premium subscriptions.`;
    }
  ],
  disclaimer: {
    title: 'See more participant engagement with a rewarded assessment.',
    description: {
      fragments: {
        first:
          'Companies like yours see increased participation and reduced time to statistically significant results ',
        second: 'when their assessment is rewarded. ',
        third: ' to discuss reward ideas and benefits.'
      },
      action: 'Contact us'
    }
  },
  actions: {
    proceed: {
      title: status => (status ? 'Continue editing reward' : 'Next')
    },
    skip: {
      title: 'Skip giving reward'
    }
  }
});
