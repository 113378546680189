export enum AssessmentStatus {
  Draft = 0,
  InReview = 1,
  Cancelled = 2,
  Live = 3,
  Calculating = 4,
  Completed = 5
}

export enum DeliveryMethod {
  DeepLink = 'deeplink',
  Api = 'api',
  Manual = 'manual'
}

export interface SurveyBody {
  dashboardId?: string;
  gameName?: string;
  genres?: number[];
  ipBrands?: number[];
  audienceReach?: string;
  dau?: string;
  availableOnPlatforms?: number[];
  reward?: {
    description?: string;
    mechanism?: string;
    link?: string;
  };
  logoUrl?: string;
  customerNote?: string;
  status?: AssessmentStatus;
  assessmentType: '360';
}
export interface Assessment {
  id: string;
  createdAt: number;
  updatedAt: number;
  assessmentType: string;
  companyId: string;
  companyDetails?: {
    id: string;
    name: string;
  };
  dashboardDetails: {
    clustersCount: number;
    slug: string;
  };
  surveyId: string | null;
  dashboardId: string;
  gameName?: string;
  projectName?: string;
  genres: number[];
  ipBrands: number[];
  status: number;
  audienceReach: string;
  dau: string;
  availableOnPlatforms: number[];
  reward: {
    description: string;
    mechanism: DeliveryMethod;
    link: string;
  };
  customerNote: string;
  logoUrl: string;
  configs: {
    env: string;
    link: string;
    surveyId: number;
    ingestionApiKey: string;
    deeplink: {
      surveyId: number;
      active: boolean;
      isStatic: boolean;
      aesSecretKey: string;
      link: string;
      customTemplate: string;
    };
  }[];
  surveyLinks: {
    liveDeeplink?: DeeplinkBody;
    testDeeplink?: DeeplinkBody;
    liveIngestionApiKey: string;
    liveLink: string;
    liveSurveyId: number;
    testIngestionApiKey: string;
    testLink: string;
    testSurveyId: number;
  };
}
export interface DeeplinkBody {
  active: boolean;
  aesSecretKey: string;
  customTemplate: string;
  gameName: string;
  isStatic: boolean;
  link: string;
  surveyId: number;
}
