import classNames from 'classnames';
import { useCallback, useMemo } from 'react';


import { Overlay } from 'ui-lib-12traits/src/index';

import Attachment from './Attachment';
import useFeedback from './Feedback.hooks';
import styles from './Feedback.module.scss';
import Info from './Info';
import Input from './Input';
import Target from './Target';

import type { Props } from './Feedback.types';

export function Feedback(props: Props & { i18n: any }) {
  const { i18n } = props;

  const {
    attachments,
    cancel,
    enabled,
    onChange,
    onSubmit,
    open,
    pristine,
    prompt,
    ref,
    remove,
    submitting
  } = useFeedback(props);
  const className = useMemo(() => classNames(styles.container, props.className), [props.className]);
  const renderAttachment = useCallback(
    (attachment: File, index: number) => {
      const onRemove = () => remove(index);

      return <Attachment attachment={attachment} key={index} onRemove={onRemove} />;
    },
    [remove]
  );

  return (
    <Overlay contentClassName={styles.overlay} testId="feedback" onClose={cancel} centered>
      <form className={className} onChange={onChange} onSubmit={onSubmit}>
        <fieldset>
          <legend>{i18n.title}</legend>
          <div aria-roledescription="message">
            <label htmlFor="message">
              <span>
                <span>{i18n.input.title}</span>
                <Info>{i18n.disclaimer}</Info>
              </span>
              <textarea id="message" placeholder={i18n.input.placeholder} ref={ref} />
            </label>
          </div>
          <div aria-roledescription="prompt">
            <Input pristine={pristine} {...prompt.input} />
            <ul>{attachments.map(renderAttachment)}</ul>
            <Target enabled={enabled} onClick={open} pristine={pristine} {...prompt.target} />
          </div>
          <div>
            <button id="submit" type="submit" disabled>
              {submitting.pending ? i18n.actions.wait : i18n.actions.submit}
            </button>
          </div>
        </fieldset>
      </form>
    </Overlay>
  );
}

export default Feedback;
