/* eslint-disable no-restricted-syntax */
import './App.scss';
import { ReactElement, useEffect } from 'react';

import { Switch, BrowserRouter, Redirect } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/hooks/useApp';

import { useTeamAssessments } from '@/hooks';
import {
  AliasRoute,
  PreviewRoute,
  PrivateRoute,
  PrivateMainPageRoute,
  PublicRoute,
  NavigatorRoute,
  FrequencyRoute,
  KPIChartsRoute
} from '@/route';
import { hotjar } from '@/utils/hotjar';
import loadableWithRetry from '@/utils/lodableWithRetry';

import {
  aliasesOverviewPath,
  previewPath,
  forbiddenPath,
  loginPath,
  mainPath,
  traitPath,
  traitOverviewPath,
  traitTargetPath,
  traitClustersPath,
  traitActionsPath,
  traitSegmentsPath,
  traitTraitsPath,
  forgotPassPath,
  newPassPath,
  traitDataIntegrationPath,
  navigatorGenresPath,
  traitJourneyPath,
  traitSegmentPath,
  homePagePath,
  navigatorPortfolioPath,
  navigatorGenresCategoriesPath,
  navigatorCraftPath,
  navigatorMarketPath,
  navigatorComparisonsPath,
  navigatorComparisonTablePath,
  navigatorCheckoutPath,
  navigatorAccountSettingsPath,
  navigatorWishlistPath,
  navigatorMyGenresPath,
  traitResearchPath,
  frequencyCampaignsPath,
  frequencyOverviewPath,
  frequencyStacksPath,
  frequencyCreativePath,
  frequencyAdNetworksPath,
  frequencyCreativesPath,
  frequencyCreativeCollectionsPath,
  frequencyCollectionDetailsPath,
  frequencyReviewsPath,
  frequencyBuilderPath,
  frequencyBuilderSavedPath,
  frequencyBuildPath,
  teamAssessmentPath,
  traitConversationThreadsPath,
  chartsPagePath,
  newAssessmentAutomationPath,
  activeAssessmentAutomationPath,
  benchmarkingReportsPath,
  navigatorTeamMembersPath,
  navigatorGameProfile
} from '@/route/paths';

import { selectAppJwt, selectIsAuthenticated } from '@/redux/auth/selectors';
import {
  selectIsUserLoading,
  selectUserFeaturePermissions,
  selectUserInfo
} from '@/redux/user/selectors';

import TermsAndConditions from '@/components/TermsAndConditions';

import { ActiveAssessmentPage } from './pages/Navigator/AssessmentAutomationV2';
import AssessmentAutomationPageV2 from './pages/Navigator/AssessmentAutomationV2/AssessmentAutomationPageV2';
import ConversationThreadsPage from './pages/Traits/ConversationThreadsPage';
import { selectIsMedicalDashboard } from './redux/game/selectors';
import { useInitialRequest } from './redux/hooks';
import { initialRequest } from './redux/initial-request/actions';
import historyService from './services/HistoryService';

const Layout = loadableWithRetry(() => import('@/layouts/Layout'));
const TraitLayout = loadableWithRetry(() => import('@/layouts/TraitLayout'));
const NavigatorLayout = loadableWithRetry(() => import('@/layouts/NavigatorLayout'));
const CheckoutLayout = loadableWithRetry(() => import('@/layouts/CheckoutLayout'));
const FrequencyLayout = loadableWithRetry(() => import('@/layouts/FrequencyLayout'));
const AssessmentLayout = loadableWithRetry(() => import('@/layouts/AssessmentLayout'));

const ForbiddenPage = loadableWithRetry(() => import('./pages/ForbiddenPage'));
const TeamAssessmentPage = loadableWithRetry(() => import('./pages/TeamAssessmentPage'));
const LoginPage = loadableWithRetry(() => import('./pages/LoginPage'));
const RedirectOverviewPage = loadableWithRetry(() => import('./pages/AliasPage/OverviewPage'));
const OverviewPage = loadableWithRetry(() => import('./pages/Traits/OverviewPage'));
const ActionsPage = loadableWithRetry(() => import('./pages/Traits/ActionsPage'));
const TargetPage = loadableWithRetry(() => import('./pages/Traits/TargetPage'));
const ClustersPage = loadableWithRetry(() => import('./pages/Traits/ClustersPage'));
const SegmentsPage = loadableWithRetry(() => import('./pages/Traits/SegmentsPage'));
const ExplorerPage = loadableWithRetry(() => import('./pages/Traits/ExplorerPage'));
const DataIntegrationPage = loadableWithRetry(() => import('./pages/Traits/DataIntegrationPage'));
const JourneyPage = loadableWithRetry(() => import('./pages/Traits/JourneyPage'));
const ResearchPage = loadableWithRetry(() => import('./pages/Traits/ResearchPage'));
const ChartsPage = loadableWithRetry(() => import('./pages/Traits/ChartsPage'));
const ForgotPassPage = loadableWithRetry(() => import('./pages/ForgotPassPage'));
const NewPassPage = loadableWithRetry(() => import('./pages/NewPassPage'));
const HomePage = loadableWithRetry(() => import('./pages/HomePage'));
const PortfolioPage = loadableWithRetry(() => import('./pages/Navigator/PortfolioPage'));
const GenresCategoriesPage = loadableWithRetry(
  () => import('./pages/Navigator/GenresCategoriesPage')
);
const GenresPage = loadableWithRetry(() => import('./pages/Navigator/GenresPage'));
const CraftPage = loadableWithRetry(() => import('./pages/Navigator/CraftPage'));
const MarketPage = loadableWithRetry(() => import('./pages/Navigator/MarketPage'));
const ComparisonsPage = loadableWithRetry(() => import('./pages/Navigator/ComparisonsPage'));
const ComparisonTablePage = loadableWithRetry(
  () => import('./pages/Navigator/ComparisonTablePage')
);
const CheckoutPage = loadableWithRetry(() => import('./pages/Navigator/CheckoutPage'));
const AccountSettingsPage = loadableWithRetry(() => import('./pages/AccountSettingsPage'));
const WishlistPage = loadableWithRetry(() => import('./pages/Navigator/WishlistPage'));
const MyGenresPage = loadableWithRetry(() => import('./pages/Navigator/MyGenresPage'));
const TeamMembersPage = loadableWithRetry(() => import('./pages/TeamMembersPage/index'));
const GameProfilePage = loadableWithRetry(() => import('./pages/Navigator/GameProfilePage/index'));

const FrequencyStacksPage = loadableWithRetry(
  () => import('./pages/Frequency/FrequencyStacksPage')
);
const FrequencyStackPage = loadableWithRetry(() => import('./pages/Frequency/FrequencyStackPage'));
const FrequencyCampaignsPage = loadableWithRetry(
  () => import('./pages/Frequency/FrequencyCampaignsPage')
);
const FrequencyOverviewPage = loadableWithRetry(
  () => import('./pages/Frequency/FrequencyOverviewPage')
);
const FrequencyAdNetworksPage = loadableWithRetry(
  () => import('./pages/Frequency/FrequencyAdNetworksPage')
);
const FrequencyCreativeCollectionsPage = loadableWithRetry(
  () => import('./pages/Frequency/FrequencyCreativeCollectionsPage')
);
const FrequencyCollectionDetailsPage = loadableWithRetry(
  () => import('./pages/Frequency/FrequencyCollectionDetailsPage')
);
const FrequencyReviewPage = loadableWithRetry(
  () => import('./pages/Frequency/FrequencyReviewPage')
);
const FrequencyBuilderPage = loadableWithRetry(
  () => import('./pages/Frequency/FrequencyBuilderPage')
);
const FrequencyBuilderSavedPage = loadableWithRetry(
  () => import('./pages/Frequency/FrequencyBuilderSavedPage')
);

const BenchmarkingReportsPage = loadableWithRetry(() => import('./pages/BenchmarkingReportsPage'));

function App(): ReactElement {
  const dispatch = useAppDispatch();

  const companyJwt = useAppSelector(selectAppJwt);
  const isMedicalCompany = useAppSelector(selectIsMedicalDashboard);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isUserLoading = useAppSelector(selectIsUserLoading);
  const userInfo = useAppSelector(selectUserInfo);
  const { dashboardsEdit } = useAppSelector(selectUserFeaturePermissions);

  const teamAssessments = useTeamAssessments();

  const consent = userInfo?.consent?.consent ?? false;

  useInitialRequest();

  useEffect(() => {
    if (companyJwt) {
      dispatch(initialRequest());
    }
  }, [companyJwt, dispatch]);

  useEffect(() => {
    const { id } = userInfo;
    if (id) hotjar(userInfo);
  }, [userInfo]);

  if (isAuthenticated && !isUserLoading && !consent) {
    return <TermsAndConditions />;
  }

  return (
    <BrowserRouter
      ref={(ref: any): void => {
        if (ref) {
          historyService.setHistory(ref.history);
        }
      }}
    >
      {isAuthenticated && !isUserLoading && teamAssessments.redirect && (
        <Redirect to={teamAssessments.redirect} />
      )}
      <Switch>
        {/* Navigator ⬇️ */}
        <NavigatorRoute
          path={homePagePath}
          page={HomePage}
          layout={NavigatorLayout}
          footerWithoutBg={false}
          withoutCart
        />
        <NavigatorRoute
          path={navigatorPortfolioPath}
          page={PortfolioPage}
          layout={NavigatorLayout}
          disabled
        />
        <NavigatorRoute
          path={navigatorGenresCategoriesPath}
          page={GenresCategoriesPage}
          layout={NavigatorLayout}
          redirectIfNonGaming
        />
        <NavigatorRoute
          path={navigatorGenresPath}
          page={GenresPage}
          layout={NavigatorLayout}
          redirectIfNonGaming
        />
        <NavigatorRoute
          path={navigatorCraftPath}
          page={CraftPage}
          layout={NavigatorLayout}
          disabled
        />
        <NavigatorRoute
          path={navigatorMarketPath}
          page={MarketPage}
          layout={NavigatorLayout}
          disabled
        />
        <NavigatorRoute
          path={navigatorCheckoutPath}
          page={CheckoutPage}
          layout={CheckoutLayout}
          redirectIfNonGaming
          redirectIfNotOwner
        />
        <NavigatorRoute
          path={navigatorAccountSettingsPath}
          page={AccountSettingsPage}
          layout={NavigatorLayout}
          withoutCart
        />
        {process.env.SHOW_USER_MANAGEMENT === 'true' && (
          <NavigatorRoute
            path={navigatorTeamMembersPath}
            page={TeamMembersPage}
            layout={NavigatorLayout}
            withoutCart
          />
        )}
        <NavigatorRoute
          path={navigatorWishlistPath}
          page={WishlistPage}
          layout={NavigatorLayout}
          redirectIfNonGaming
        />
        <NavigatorRoute
          path={navigatorMyGenresPath}
          page={MyGenresPage}
          layout={NavigatorLayout}
          redirectIfNonGaming
        />

        {/* Navigator ⬆️ */}

        {/* Frequency ⬇️ */}
        <FrequencyRoute
          path={[frequencyStacksPath, frequencyCreativesPath]}
          page={FrequencyStacksPage}
          layout={FrequencyLayout}
          exact
          fullWidth
        />
        <FrequencyRoute
          exact
          path={frequencyCreativePath}
          page={FrequencyStackPage}
          layout={FrequencyLayout}
          fullWidth
        />
        <FrequencyRoute
          path={frequencyOverviewPath}
          page={FrequencyOverviewPage}
          layout={FrequencyLayout}
          fullWidth
        />
        <FrequencyRoute
          path={frequencyReviewsPath}
          page={FrequencyReviewPage}
          layout={FrequencyLayout}
          fullWidth
        />
        <FrequencyRoute
          path={frequencyCampaignsPath}
          page={FrequencyCampaignsPage}
          layout={FrequencyLayout}
        />
        <FrequencyRoute
          path={frequencyAdNetworksPath}
          page={FrequencyAdNetworksPage}
          layout={FrequencyLayout}
          fullWidth
        />
        <FrequencyRoute
          path={frequencyCreativeCollectionsPath}
          page={FrequencyCreativeCollectionsPage}
          layout={FrequencyLayout}
          fullWidth
        />
        <FrequencyRoute
          path={frequencyCollectionDetailsPath}
          page={FrequencyCollectionDetailsPage}
          layout={FrequencyLayout}
          fullWidth
        />
        <FrequencyRoute
          path={frequencyBuilderSavedPath}
          page={FrequencyBuilderSavedPage}
          layout={FrequencyLayout}
          fullWidth
        />
        <FrequencyRoute
          path={[frequencyBuildPath, frequencyBuilderPath]}
          page={FrequencyBuilderPage}
          layout={FrequencyLayout}
          fullWidth
        />
        {/* Frequency ⬆️ */}

        {/* Game ⬇️ */}
        {process.env.SHOW_USER_PREFERENCES === 'true' && dashboardsEdit && (
          <PrivateRoute
            path={navigatorGameProfile}
            page={GameProfilePage}
            layout={TraitLayout}
            withoutGameMenu
          />
        )}
        <AliasRoute path={aliasesOverviewPath} page={RedirectOverviewPage} />
        <PrivateRoute
          path={traitOverviewPath}
          page={OverviewPage}
          layout={TraitLayout}
          previewable
        />
        {process.env.SHOW_ACTIONS_PAGE === 'true' && (
          <PrivateRoute path={traitActionsPath} page={ActionsPage} layout={TraitLayout} />
        )}
        <PrivateRoute path={traitTargetPath} page={TargetPage} layout={TraitLayout} previewable />
        {process.env.SHOW_JOURNEY_PAGE === 'true' && (
          <PrivateRoute path={traitJourneyPath} page={JourneyPage} layout={TraitLayout} />)}
        <PrivateRoute
          path={traitClustersPath}
          page={ClustersPage}
          layout={TraitLayout}
          previewable
        />
        <PrivateRoute path={traitSegmentPath} page={SegmentsPage} layout={TraitLayout} />
        <PrivateRoute path={traitSegmentsPath} page={SegmentsPage} layout={TraitLayout} />
        <PrivateRoute
          path={traitTraitsPath}
          page={ExplorerPage}
          layout={TraitLayout}
          withoutBottomPadding
        />
        {process.env.SHOW_RESEARCH_PAGE === 'true' && (
          <PrivateRoute
            path={traitResearchPath}
            page={ResearchPage}
            layout={TraitLayout}
            fullWidth
            previewable
          />)}
        <PrivateRoute
          path={traitDataIntegrationPath}
          page={DataIntegrationPage}
          layout={TraitLayout}
          fullWidth
          previewable
        />
        {process.env.SHOW_COMPARE_PAGE === 'true' && (
          <PrivateRoute path={navigatorComparisonsPath} page={ComparisonsPage} layout={TraitLayout} />)}
        <PrivateRoute
          path={navigatorComparisonTablePath}
          page={ComparisonTablePage}
          layout={TraitLayout}
        />
        {!isMedicalCompany && (
          <PrivateRoute
            path={traitConversationThreadsPath}
            page={ConversationThreadsPage}
            layout={TraitLayout}
          />
        )}
        <KPIChartsRoute path={chartsPagePath} page={ChartsPage} layout={TraitLayout} />

        {/* Game ⬆️ */}
        <PrivateRoute path={teamAssessmentPath} page={TeamAssessmentPage} layout={Layout} />

        {/* Auth ⬇️ */}
        <PublicRoute path={forbiddenPath} page={ForbiddenPage} layout={NavigatorLayout} />
        <PublicRoute path={previewPath} render={PreviewRoute} />
        <PublicRoute path={loginPath} page={LoginPage} layout={Layout} />
        <PublicRoute path={forgotPassPath} page={ForgotPassPage} layout={Layout} />
        <PublicRoute path={newPassPath} page={NewPassPage} layout={Layout} />
        {/* Auth ⬆️ */}

        <PrivateRoute
          path={activeAssessmentAutomationPath}
          page={ActiveAssessmentPage}
          layout={AssessmentLayout}
          isFooterFixed
        />
        <PrivateRoute
          path={newAssessmentAutomationPath}
          page={AssessmentAutomationPageV2}
          layout={AssessmentLayout}
        />
        <PrivateRoute
          path={benchmarkingReportsPath}
          page={BenchmarkingReportsPage}
          layout={NavigatorLayout}
          previewable
          withoutCart
        />
        <PrivateRoute path={traitPath} page={OverviewPage} layout={TraitLayout} />
        <PrivateMainPageRoute exact path={mainPath} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
