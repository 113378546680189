import { type Timeline } from 'ui-lib-12traits/src/index';

import KpisApiService from '@/services/Api/KpisApiService';

import { KPI } from '../CoreService/types';
import { ApiResponse } from '../types';

import { KpiChartsRangeData, KpiChartsValuesData, KpiChartsCohortData } from './types';

export const fetchAvailableTimelines = async (dashboardIds: string[]): Promise<any> =>
  new KpisApiService().setEndpoint(`/company/kpis/availability`).setParams({ dashboardIds }).get();

export const fetchKpisList = async (
  dashboardIds: string[]
): Promise<ApiResponse<{ kpis: KPI[] }>> =>
  new KpisApiService().setEndpoint(`/company/kpis/options`).setParams({ dashboardIds }).get();

export const fetchKpisChartData = async (
  dashboardIds: string[],
  dateRange: Timeline
): Promise<any> =>
  new KpisApiService()
    .setEndpoint(`/company/kpis/data`)
    .setParams({ dashboardIds, dateRange })
    .get();

export const fetchEngagementAvailableTimelines = async (dashboardIds: string): Promise<any> =>
  new KpisApiService({ isCamelCaseResponse: false })
    .setEndpoint(`/company/engagement-rate/availability`)
    .setParams({ dashboardIds })
    .get();

export const fetchEngagementChartData = async (
  dashboardIds: string,
  dateRange: Timeline
): Promise<any> =>
  new KpisApiService()
    .setEndpoint(`/company/engagement-rate/data`)
    .setParams({ dashboardIds, dateRange })
    .get();

export const fetchGenresMarketChartData = async (
  genreIds: number[],
  dateRange: string
): Promise<any> =>
  new KpisApiService({ isCamelCaseResponse: false })
    .setEndpoint(`/market/kpis/data`)
    .setParams({ genreIds, dateRange })
    .get();

export const fetchAdNetworkAvailableTimelines = (adNetworks: string[]): Promise<any> =>
  new KpisApiService()
    .setEndpoint('/company/kpis/adNetworks/availability')
    .setParams({ adNetworks })
    .get();

export const fetchAdNetworkChartData = (
  dateRange: Timeline,
  adNetworks: string[],
  aggFunc: string = 'avg'
): Promise<any> =>
  new KpisApiService()
    .setEndpoint('/company/kpis/adNetworks/data')
    .setParams({ dateRange, aggFunc, adNetworks })
    .get();

export const fetchQueryRangeData = ({
  kpis,
  dateFrom,
  dateTo,
  segment,
  clusters,
  step,
  acceptsCsv = false
}: {
  kpis: string;
  dateFrom: string;
  dateTo: string;
  segment?: string;
  clusters?: string[];
  step?: string;
  acceptsCsv?: boolean;
}): Promise<ApiResponse<KpiChartsRangeData>> =>
  new KpisApiService({
    useGameJwt: true,
    shouldSendSnakeCase: false,
    isCamelCaseResponse: false,
    acceptsCsv
  })
    .setEndpoint('/dashboard/query-range')
    .setParams({
      ...(!!clusters && { clusters }),
      ...(!!segment && { segment }),
      ...(!!step && { step }),
      kpis,
      dateFrom,
      dateTo
    })
    .get();

export const fetchQueryValuesData = ({
  kpis,
  dateFrom,
  dateTo,
  segment,
  clusters
}: {
  kpis: string;
  dateFrom: string;
  dateTo: string;
  segment?: string;
  clusters?: string[];
}): Promise<ApiResponse<KpiChartsValuesData>> =>
  new KpisApiService({
    useGameJwt: true,
    shouldSendSnakeCase: false,
    isCamelCaseResponse: false
  })
    .setEndpoint('/dashboard/query-values')
    .setParams({
      ...(!!clusters && { clusters }),
      ...(!!segment && { segment }),
      kpis,
      dateFrom,
      dateTo
    })
    .get();

export const fetchQueryCohortData = ({
  kpis,
  dateFrom,
  dateTo,
  segment,
  clusters,
  step,
  days,
  acceptsCsv = false
}: {
  kpis: string;
  dateFrom: string;
  dateTo: string;
  segment?: string;
  clusters?: string[];
  step?: string;
  days?: number;
  acceptsCsv?: boolean;
}): Promise<ApiResponse<KpiChartsCohortData>> =>
  new KpisApiService({
    useGameJwt: true,
    shouldSendSnakeCase: false,
    isCamelCaseResponse: false,
    acceptsCsv
  })
    .setEndpoint('/dashboard/query-cohort')
    .setParams({
      ...(!!clusters && { clusters }),
      ...(!!segment && { segment }),
      ...(!!days && { days }),
      ...(!!step && { step }),
      kpis,
      dateFrom,
      dateTo
    })
    .get();

export const fetchKpis = <T extends string[]>(): Promise<ApiResponse<T>> =>
  new KpisApiService({
    useGameJwt: true,
    shouldSendSnakeCase: false,
    isCamelCaseResponse: false
  })
    .setEndpoint('/dashboard/kpis')
    .get();

export const fetchDates = (): Promise<any> =>
  new KpisApiService({ useGameJwt: true, shouldSendSnakeCase: false, isCamelCaseResponse: false })
    .setEndpoint('/dashboard/dates')
    .get();

export const fetchKpi = async (
  token?: string,
  segment?: string,
  clusterCode?: string
): Promise<any> => {
  const request = new KpisApiService({ token, version: 'v0', authHeader: true })
    .setParams({ all: true })
    .setEndpoint('/dashboard/kpis');
  if (segment) {
    request.setParams({ segment });
  }
  if (clusterCode) {
    request.setParams({ cluster: clusterCode });
  }
  return request.get();
};

export const fetchKpiStatus = async (token?: string): Promise<any> => {
  const request = new KpisApiService({ version: 'v0', token, authHeader: true });
  return request.setEndpoint('/dashboard/kpis/status').get();
};

export const fetchKpisOptions = async (): Promise<any> =>
  new KpisApiService({ version: 'v0', authHeader: true })
    .setEndpoint('/dashboard/kpis/options')
    .get();
