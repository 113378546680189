import CoreApiService from '@/services/Api/CoreApiService';

export default class UserApiService extends CoreApiService {
  constructor(
    config: { isCamelCaseResponse?: boolean; shouldSendSnakeCase?: boolean; version?: string } = {}
  ) {
    const { isCamelCaseResponse = true, shouldSendSnakeCase = true, version } = config;
    super({ isCamelCaseResponse, shouldSendSnakeCase, version });
    this.api = `${process.env.BASE_URL}/users`;
  }
}
