import { HomePageTabType } from '@/components/Navigator/ItemsView/types';

export enum ViewEnum {
  List = 'list',
  Carousel = 'carousel'
}

export type ViewType = ViewEnum.List | ViewEnum.Carousel;

export interface Tab {
  name: string;
  activeName: string;
  type?: HomePageTabType;
  hasDashboards: boolean;
}

export interface HomePageTab {
  name: string;
  activeName: string;
  type: 'traits' | 'navigator' | 'frequency' | 'recent';
  hasDashboards: boolean;
}

export enum CardBrands {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  AMEX = 'amex',
  DISCOVER = 'discover',
  CARTES_BANCAIRES = 'cartes_bancaires',
  DINERS_CLUB = 'diners',
  JCB = 'jcb',
  UNIONPAY = 'unionpay'
}

export const CardBrandsLabels: { [key: string]: string } = {
  [CardBrands.VISA]: 'Visa',
  [CardBrands.MASTERCARD]: 'Mastercard',
  [CardBrands.AMEX]: 'American Express',
  [CardBrands.DISCOVER]: 'Discover',
  [CardBrands.CARTES_BANCAIRES]: 'Cartes Bancaires',
  [CardBrands.DINERS_CLUB]: 'Diners Club',
  [CardBrands.JCB]: 'JCB',
  [CardBrands.UNIONPAY]: 'China UnionPay'
};

interface BillingDetails {
  email: string;
  name: string;
  phone: string;
}

interface CardPaymentMethod {
  brand: CardBrands;
  lastFour: string;
  expiration: {
    month: number;
    year: number;
  };
}

export enum PaymentMethodTypeEnum {
  card = 'card',
  bankAccount = 'bank_account'
}

export interface PaymentMethodsI {
  id: string;
  card: CardPaymentMethod;
  billingDetails: BillingDetails;
  default: boolean;
  type: PaymentMethodTypeEnum;
}

export enum SortAssessmentFieldEnum {
  Status = 'status',
  Type = 'assessment_type',
  Name = 'game_name'
}
