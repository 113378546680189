import CoreApiService from '@/services/Api/CoreApiService';
import { storeRegistry } from '@/services/StoreRegistryService';

import { selectGameJwt } from '@/redux/game/selectors';

export default class GameApiService extends CoreApiService {
  constructor(
    config: {
      version?: string;
      jwt?: string;
      isCamelCaseResponse?: boolean;
      shouldSendSnakeCase?: boolean;
    } = {}
  ) {
    const { version, jwt, isCamelCaseResponse = false, shouldSendSnakeCase } = config;
    super({ version, isCamelCaseResponse, shouldSendSnakeCase });
    const token = jwt || selectGameJwt(storeRegistry.getState());
    this.setHeaders({ Authorization: `Bearer ${token}` });
  }
}
