import { Action } from '@/redux/types';

import {
  LOAD_SEGMENTS_REQUEST,
  LOAD_SEGMENTS_SUCCESS,
  LOAD_SEGMENTS_ERROR,
  SegmentsPageState,
  SET_SHOW_CREATE_SEGMENT,
  SET_SHOW_CREATED_CONFIRMATION
} from './types';

const initialState: SegmentsPageState = {
  list: [],
  loading: false,
  error: false,
  errorMsg: '',
  showCreateSegment: false,
  showCreatedConfirmation: false
};

export default (state = initialState, action: Action): SegmentsPageState => {
  switch (action.type) {
    case LOAD_SEGMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: ''
      };
    case LOAD_SEGMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: '',
        list: action.payload.data
      };
    case LOAD_SEGMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload.error
      };
    case SET_SHOW_CREATE_SEGMENT: {
      return {
        ...state,
        showCreateSegment: action.payload.showCreateSegment
      };
    }
    case SET_SHOW_CREATED_CONFIRMATION: {
      return {
        ...state,
        showCreatedConfirmation: action.payload.showCreatedConfirmation
      };
    }
    default:
      return state;
  }
};
