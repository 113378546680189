/* eslint-disable no-restricted-syntax */
import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { useEffect } from 'react';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as I18nProvider } from '@/i18n';

import { addScript, ScriptNameEnum } from '@/services/ScriptsService';
import { storeRegistry } from '@/services/StoreRegistryService';

import App from './App';
import store from './redux/rootStore';
import historyService from './services/HistoryService';
import { useAppDispatch } from './hooks/useApp';
import { saveJWT } from './redux/auth/actions';

storeRegistry.register(store);

if (process.env.NODE_ENV === 'production') {
  addScript(ScriptNameEnum.Analytics);
  addScript(ScriptNameEnum.AnalyticsLoad);
}

const history = historyService.getHistory();
if (process.env.SEND_SENTRY_ERRORS === 'true' && process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENV,
    integrations: [
      new BrowserTracing({
        // @ts-expect-error no right types from sentry lib
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],
    ignoreErrors: [
      'Unauthorized',
      'invalid or expired jwt',
      'Unauthorized',
      'missing or malformed jwt',
      'session is not valid or expired',
      'ResizeObserver loop limit exceeded'
    ]
  });
}

const ConnectedApp = Sentry.withProfiler(App);
const queryClient = new QueryClient();
const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);
root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <I18nProvider>
          <CheckCookiesWrapper>
            <ConnectedApp />
          </CheckCookiesWrapper>
        </I18nProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    <div id="notifications-wrapper" />
  </>
);

function CheckCookiesWrapper({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { hostname } = new URL(window.location.href);

    if (window.localStorage.getItem(`${hostname.replace('.solsten.io', '')}_jwt`)) return;

    const normalizedDomain = new URL(window.location.href).hostname.replace(/-|\./g, '');

    const domainJwtsString = Cookies.get('domain_jwts') || '{}';
    const jwts = JSON.parse(domainJwtsString);

    for (const domainKey in jwts) {
      if (domainKey.toLowerCase().includes(normalizedDomain)) {
        window.localStorage.setItem(`${hostname.replace('.solsten.io', '')}_jwt`, jwts[domainKey]);

        const { exp } = jwtDecode(jwts[domainKey]);
        dispatch(saveJWT(jwts[domainKey], exp));
      }
    }
  }, []);

  return children;
}
