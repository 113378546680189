import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import frequency from './frequency/sagas';
import game from './game/sagas';
import initialRequest from './initial-request/sagas';
import kpis from './kpis/sagas';
import actions from './pages/actionsPage/sagas';
import comparison from './pages/comparisonPage/sagas';
import overview from './pages/overviewPage/sagas';
import segments from './pages/segmentsPage/sagas';
import target from './pages/targetPage/sagas';
import segment from './segment/sagas';
import sentiment from './sentiment/sagas';
import survey from './survey/sagas';
import user from './user/sagas';

/**
 * saga to yield all others
 */
export default function* rootSaga() {
  yield all([
    game(),
    actions(),
    user(),
    kpis(),
    sentiment(),
    comparison(),
    segments(),
    auth(),
    target(),
    segment(),
    survey(),
    overview(),
    initialRequest(),
    frequency()
  ]);
}
