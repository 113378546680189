import { Template } from '@/pages/Navigator/AssessmentAutomationV2/types';

import { prepareAction } from '@/redux/helpers';
import { Action } from '@/redux/types';

import {
  type TeamSurvey,
  LOAD_TEAM_SURVEY,
  LOAD_SURVEY,
  LOAD_SURVEY_SUCCESS,
  LOAD_SURVEY_ERROR,
  LOAD_SURVEY_STATUS,
  LOAD_SURVEY_STATUS_SUCCESS,
  LOAD_SURVEY_STATUS_ERROR,
  SET_SURVEY_INTEGRATION_PERCENTAGE,
  SET_SURVEY_INTEGRATION_STATUS,
  SET_APPROXIMATE_SURVEY_INTEGRATION_TIME,
  CALCULATE_SURVEY_INTEGRATION
} from './types';


export const loadTeamSurvey = (data: TeamSurvey): Action =>
  prepareAction(LOAD_TEAM_SURVEY, { data });

export const loadSurvey = (segment?: string, cluster?: string): Action =>
  prepareAction(LOAD_SURVEY, { segment, cluster: cluster || 'all' });

export const loadSurveySuccess = (data: any): Action =>
  prepareAction(LOAD_SURVEY_SUCCESS, { data });

export const loadSurveyError = (data: any): Action => prepareAction(LOAD_SURVEY_ERROR, { data });

export const loadSurveyStatus = (): Action => prepareAction(LOAD_SURVEY_STATUS);

export const loadSurveyStatusSuccess = (status: number): Action =>
  prepareAction(LOAD_SURVEY_STATUS_SUCCESS, { status });

export const loadSurveyStatusError = (error: string): Action =>
  prepareAction(LOAD_SURVEY_STATUS_ERROR, { errorMsg: error });

export const setSurveyIntegrationPercentage = (percentage: number): Action =>
  prepareAction(SET_SURVEY_INTEGRATION_PERCENTAGE, { percentage });

export const setSurveyIntegrationStatus = (status: string): Action =>
  prepareAction(SET_SURVEY_INTEGRATION_STATUS, { status });

export const setApproximateSurveyIntegrationTime = (time: number): Action =>
  prepareAction(SET_APPROXIMATE_SURVEY_INTEGRATION_TIME, { time });

export const calculateSurveyIntegration = (): Action => prepareAction(CALCULATE_SURVEY_INTEGRATION);

export const hoverAssessmentStep = (step?: Template) =>
  prepareAction('Survey / HOVERED_STEP', { step });
