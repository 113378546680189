import classNames from 'classnames';
import daysjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import { ReactElement, ReactNode, useCallback, useState, Fragment } from 'react';

import AppleStoreIcon from 'ui-lib-12traits/src/Icons/svg/channels/apple-store.svg?component';
import GoogleAppStore from 'ui-lib-12traits/src/Icons/svg/channels/google-play-store.svg?component';
import TwitterIcon from 'ui-lib-12traits/src/Icons/svg/channels/twitter.svg?component';
import YouTubeIcon from 'ui-lib-12traits/src/Icons/svg/channels/youtube.svg?component';
import CommentIcon from 'ui-lib-12traits/src/Icons/svg/common/comment.svg?component';
import BackIcon from 'ui-lib-12traits/src/Icons/svg/frequency/arrow-back.svg?component';

import KeyGuyIcon from 'ui-lib-12traits/src/Icons/svg/keyguy/keyguy-rounded-with-stroke.svg?component';
import { SelectorButton } from 'ui-lib-12traits/src/index';
import { useAppSelector } from '@/hooks/useApp';
import { selectClusters } from '@/redux/game/selectors';
import { ChannelsEnum } from '../helpers';
import { Panels } from '../types';

import FilterByChannel from './FilterByChannel';

import FilterByDate from './FilterByDate/FilterByDate';
import FilterByHighlights from './FilterByHighlights';

import { FilterByPersona } from './FilterByPersona/FilterByPersona';
import personasStyles from './Personas.module.scss';
import SearchDropdown from './SearchDropdown';
import SortByScore from './SortByScore';
import styles from './TopSection.module.scss';

export const dayjs = daysjs.extend(UTC);

interface Props {
  highlightedTerms: string[];
  channels: string[];
  searchQuery: string;
  dateFrom: string;
  dateTo: string;
  sort: string;
  sortDirection: string;
  availableHighlightedTerms: string[];
  clusters: string[];
}
const ChannelTypeIconConfig = {
  [ChannelsEnum.Twitter]: TwitterIcon,
  [ChannelsEnum.YouTube]: YouTubeIcon,
  [ChannelsEnum.AppleAppStore]: AppleStoreIcon,
  [ChannelsEnum.GooglePlayStore]: GoogleAppStore
};

function TopSection({
  channels,
  dateFrom,
  dateTo,
  sort,
  sortDirection,
  availableHighlightedTerms,
  highlightedTerms,
  searchQuery,
  clusters
}: Props): ReactElement {
  const [activePanel, setActivePanel] = useState<Panels | null>(null);
  const availablePersonas = useAppSelector(selectClusters).filter(
    cluster => cluster.value !== 'all'
  );

  const onChangePanel = (type: Panels): void => {
    setActivePanel(type !== activePanel ? type : null);
  };

  const getChannelTypeIcon = (): ReactElement => {
    let Icon = CommentIcon;
    if (channels?.length === 1) {
      Icon = ChannelTypeIconConfig[channels[0]];
    }
    return (
      <Icon
        className={channels?.length !== 1 ? styles.filter__icon : styles.filter__icon_channel}
      />
    );
  };

  const getPersonasTitle = (): ReactNode => {
    if (!clusters.length) {
      return (
        <span className={classNames(styles['filter__personas--text'])}>Entire Population</span>
      );
    }

    const isMoreThenThreeClustersSelected = clusters.length > 3;

    const clustersToRenderIcons = clusters.slice(0, 3);

    return (
      <div
        className={classNames(styles['filter__personas--wrapper'], {
          [styles['filter__personas--wrapper--active']]: activePanel === Panels.Personas
        })}
      >
        <span className={styles['filter__personas--text']}>
          {clusters
            .map(cluster => `${availablePersonas.find(persona => persona.value === cluster)?.name}`)
            .join(', ')}
        </span>
        <div
          className={styles['filter__personas--icons']}
          style={{
            minWidth: `${36 + 10 * (isMoreThenThreeClustersSelected ? 3 : clusters.length)}px`
          }}
        >
          {clustersToRenderIcons.map((cluster, i, { length: count }) => {
            const isLastClusterToDisplay = i === 2 && isMoreThenThreeClustersSelected;
            return (
              <Fragment key={cluster}>
                <KeyGuyIcon
                  className={classNames(personasStyles.personas__icon, {
                    [personasStyles[`persona-${cluster}-fill-c`]]: !isLastClusterToDisplay,
                    [personasStyles[`persona-stacked-fill`]]: isLastClusterToDisplay
                  })}
                  style={{ right: `${10 * (count - (i + 1))}px`, zIndex: `${1 + i}` }}
                />
                {isLastClusterToDisplay && (
                  <span className={styles['filter__personas--icons-text']}>
                    + {clusters.length - 3}
                  </span>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  const getLabelDate = useCallback((): string | ReactNode => {
    let label;
    const dateStart = new Date(dateFrom);
    const dateEnd = new Date(dateTo);

    if (dateFrom === dateTo) {
      label = (
        <div
          className={classNames(styles.date__label, {
            [styles.date__label_single]: dateFrom === dateTo,
            [styles['date__label_with-icon']]: false
          })}
        >
          {daysjs(dateStart).utc().format('MMM DD, YYYY')}
        </div>
      );
    } else {
      label = (
        <div
          className={classNames(styles.date__label, {
            [styles['date__label_with-icon']]: false
          })}
        >
          <div className={styles.date__value}>
            {daysjs(dateStart).utc().format('MMM DD, YYYY')}
            <BackIcon className={styles['back-icon']} />
            {daysjs(dateEnd).utc().format('MMM DD, YYYY')}
          </div>
        </div>
      );
    }

    return label;
  }, [dateFrom, dateTo]);

  return (
    <div>
      <div className={styles.filter__wrapper}>
        <div className={styles.filters__wrapper}>
          <div
            className={styles.title}
            style={{ marginBottom: '6px' }}
            data-testid="top-section-filters-title"
          >
            Filters:
          </div>
          <div className={styles.filters}>
            <SelectorButton
              tooltipPlacement="bottom"
              arrowClassName={styles.filter__personas__arrow}
              isActive={activePanel === Panels.Personas}
              onClick={(): void => {
                onChangePanel(Panels.Personas);
              }}
              className={classNames(styles.filter__button, styles.filter__personas, {
                [styles.filter__button_active]: activePanel === Panels.Personas
              })}
              title={getPersonasTitle()}
              data-testid="top-section-personas-selector-button"
            />
            <SelectorButton
              tooltipPlacement="bottom"
              arrowClassName={styles.filter__arrow}
              isActive={activePanel === Panels.Date}
              onClick={(): void => onChangePanel(Panels.Date)}
              className={classNames(styles.filter__button, styles.filter__button_wide, {
                [styles.filter__button_active]: activePanel === Panels.Date,
                [styles.filter__button_date]: true
              })}
              title={getLabelDate()}
              data-testid="top-section-date-range-selector-button"
            />
          </div>
        </div>
        <div className={styles.filter__channels}>
          <SelectorButton
            tooltipContent="Channels"
            tooltipPlacement="bottom"
            tooltipClassname={styles.filter__tooltip}
            arrowClassName={styles.filter__arrow}
            isActive={activePanel === Panels.Channels}
            onClick={(): void => onChangePanel(Panels.Channels)}
            className={classNames(styles.filter__button, {
              [styles.filter__button_active]: activePanel === Panels.Channels,
              [styles.filter__button_applied]: !!channels.length
            })}
            title={getChannelTypeIcon()}
            data-testid="top-section-channels-selector-button"
          />
        </div>
        <div className={styles.search__wrapper}>
          <SearchDropdown
            availableHighlightedTerms={availableHighlightedTerms}
            searchQuery={searchQuery}
          />
        </div>
        <div className={styles.sort}>
          <div
            className={styles.title}
            style={{ marginBottom: '6px' }}
            data-testid="top-section-sort-title"
          >
            Sort by:
          </div>
          <SortByScore sort={sort} sortDirection={sortDirection} />
        </div>
      </div>
      {activePanel === Panels.Channels && <FilterByChannel channels={channels} />}
      {activePanel === Panels.Highlights && (
        <FilterByHighlights
          highlightedTerms={highlightedTerms}
          availableHighlightedTerms={availableHighlightedTerms}
        />
      )}
      {activePanel === Panels.Date && <FilterByDate dateFrom={dateFrom} dateTo={dateTo} />}
      {activePanel === Panels.Personas && <FilterByPersona clusters={clusters} />}
    </div>
  );
}

export default TopSection;
