import { createSelector } from 'reselect';

import {
  isUserCanUpdateSegment,
  isUserCanUploadKpi,
  isUserCanEditPersonaDescription,
  isUserCanDownloadPlayersIds,
  isUserCanEditJourney,
  isUserCanAddAction
} from '@/utils/dashboardPermissions';

import {
  SortBuildsFieldEnum,
  SortDirectionEnum,
  SortFieldEnum,
  ViewEnum
} from '@/components/Frequency/types';
import { SortAssessmentFieldEnum } from '@/components/Navigator/types';

import { selectGameId } from '../game/selectors';
import { RootState } from '../rootStore';

import {
  type UserCompany,
  UserInfo,
  CompanyTypes,
  DashboardPermission,
  DashboarUserRoles,
  UserPreferences,
  creativesLibraryColumns,
  CompanyDetails
} from './types';

const defaultColumns: creativesLibraryColumns = {
  grid: [],
  list: []
};

const getCreativeColumns = (
  creativeColumns: creativesLibraryColumns | null
): creativesLibraryColumns => {
  if (!creativeColumns) {
    return defaultColumns;
  }

  if (!creativeColumns?.grid) {
    return {
      list: creativeColumns.list,
      grid: []
    };
  }
  return creativeColumns;
};

const defaultKpis: { kpiId: string; enabled: boolean }[] = [
  { kpiId: '', enabled: false },
  { kpiId: '', enabled: false },
  { kpiId: '', enabled: false }
];

export const selectUserCompany = (state: RootState): UserCompany => state.user.company;

export const selectUserInfo = (state: RootState): UserInfo => state.user.info;

export const selectUserFirstName = (state: RootState): string => state.user.info.firstName;

export const selectCompanyDetails = (state: RootState): CompanyDetails =>
  state.user.info.companyDetails;

export const selectIsUserCompanyOwner = (state: RootState): boolean | null =>
  state.user.info.permissions.isAdmin;

export const selectIsGamingCompany = (state: RootState): boolean =>
  state.user.info.companyDetails.companyType === CompanyTypes.Gaming;

export const selectIsUserLoading = (state: RootState): boolean => state.user.isLoading;
export const selectIsLoadingPreferences = (state: RootState): boolean =>
  state.user.isLoadingPreferences;

export const getUserName = (state: RootState): string => state.user.info.name;

export const getUserFirstName = (state: RootState): string => state.user.info.firstName;

export const getUserAvatarUrl = (state: RootState): string => state.user.info.avatarUrl;

export const getUserEmail = (state: RootState): string => state.user.info.email;

export const getUserId = (state: RootState): string => state.user.info.id;

export const selectCompanyName = (state: RootState): string => state.user.info.companyDetails.name;

export const selectCompanySocialListeningSuperAdminOnly = (state: RootState): boolean =>
  state.user.info.companyDetails.socialListeningSuperadminOnly;

export const selectCompanySocialListeningEnabled = (state: RootState): boolean =>
  state.user.info.companyDetails.socialListeningEnabled;

export const selectCompanyAvatarUrl = (state: RootState): string =>
  state.user.info.companyDetails.avatarUrl;

export const selectDashbordPermissions = (state: RootState): DashboardPermission[] =>
  state.user.info.permissions.dashboardPermissions;

const getUserRoleForCurrentDashboard = (state: RootState): DashboarUserRoles | undefined => {
  const currentDashboardId = selectGameId(state);
  const dashboardPermissions = selectDashbordPermissions(state);
  const dashboardPermission = dashboardPermissions.find(
    ({ dashboardId }) => dashboardId === currentDashboardId
  );

  return dashboardPermission?.role;
};

export const getIsSegmentUpdateAvailable = (state: RootState): boolean => {
  const userRoleForDashboard = getUserRoleForCurrentDashboard(state) || '';

  return isUserCanUpdateSegment(userRoleForDashboard);
};

export const getIsUploadKPIAvailable = (state: RootState): boolean => {
  const userRoleForDashboard = getUserRoleForCurrentDashboard(state);

  return isUserCanUploadKpi(userRoleForDashboard);
};

export const getIsUserCanEditPersonaDescription = (state: RootState): boolean => {
  const userRoleForDashboard = getUserRoleForCurrentDashboard(state);

  return isUserCanEditPersonaDescription(userRoleForDashboard);
};

export const getIsUserCanDownloadPlayersIds = (state: RootState): boolean => {
  const userRoleForDashboard = getUserRoleForCurrentDashboard(state);

  return isUserCanDownloadPlayersIds(userRoleForDashboard);
};

export const getIsUserCanEditJourney = (state: RootState): boolean => {
  const userRoleForDashboard = getUserRoleForCurrentDashboard(state);

  return isUserCanEditJourney(userRoleForDashboard);
};

export const getIsUserCanAddAction = (state: RootState): boolean => {
  const userRoleForDashboard = getUserRoleForCurrentDashboard(state);

  return isUserCanAddAction(userRoleForDashboard);
};

export const selectUserPreferences = (state: RootState): UserPreferences | null =>
  state.user.preferences;

export const selectLibraryFTUE = (state: RootState): boolean =>
  !state.user.preferences?.frequency.ftueCreativesLibrary || false;

export const selectLibrarySTUE = (state: RootState): boolean =>
  !state.user.preferences?.frequency.stueCreativesLibrary || false;

export const selectDetailsFTUE = (state: RootState): boolean =>
  !state.user.preferences?.frequency.ftueCreativeDetails || false;

export const selectDetailsSTUE = (state: RootState): boolean =>
  !state.user.preferences?.frequency.stueCreativeDetails || false;

export const selectFTUECreativeAddedTags = (state: RootState): boolean =>
  !state.user.preferences?.frequency.ftueCreativeAddedTags || false;

export const selectCreativesView = (state: RootState): ViewEnum =>
  Object.values(ViewEnum).includes(state.user.preferences?.frequency.creativesView)
    ? state.user.preferences?.frequency.creativesView
    : ViewEnum.Tile;

export const selectBuildsView = (state: RootState): ViewEnum =>
  Object.values(ViewEnum).includes(state.user.preferences?.frequency.buildsView)
    ? state.user.preferences?.frequency.buildsView
    : ViewEnum.Grid;

export const selectCreativesColumns = (state: RootState): creativesLibraryColumns =>
  getCreativeColumns(state.user.preferences?.frequency.creativesLibraryColumns);

export const selectCreativesKpis = (state: RootState): { kpiId: string; enabled: boolean }[] =>
  state.user.preferences?.frequency.creativesLibraryKpis || defaultKpis;

export const selectSortField = (state: RootState): SortFieldEnum | string =>
  state.user.preferences?.frequency.creativesLibrarySort?.sortField || '';

export const selectCreativesLibrarySortPersona = (state: RootState): string =>
  state.user.preferences?.frequency.creativesLibrarySort?.sortCluster || '';

export const selectCreativesLibrarySortAudience = (state: RootState): string =>
  state.user.preferences?.frequency.creativesLibrarySort?.sortDashboardId || '';

export const selectCreativesLibrarySortSegment = (state: RootState): string =>
  state.user.preferences?.frequency.creativesLibrarySort?.sortSegmentId || '';

export const selectCreativesLibrarySortSortDirection = (
  state: RootState
): SortDirectionEnum | string =>
  state.user.preferences?.frequency.creativesLibrarySort?.sortDirection || SortDirectionEnum.Desc;

export const selectCreativesLibrarySortKpi = (state: RootState): string =>
  state.user.preferences?.frequency.creativesLibrarySort?.sortKpiId || '';

export const selectCreativesLibraryStacksEnabled = (state: RootState): boolean | null =>
  state.user.preferences
    ? state.user.preferences.frequency.creativesLibraryStacksEnabled || false
    : null;

export const selectCreativesLDetailsKpis = (state: RootState): number[] =>
  state.user.preferences ? state.user.preferences.frequency.creativesDetailsKpis : [];

export const selectTraitsAlphabeticallyFTUE = (state: RootState): boolean =>
  state.user.preferences?.traits.ftueTraitsAlphabetically || false;

export const selectAssessmentManagerConfig = (state: RootState): boolean =>
  state.user.preferences?.traits?.assessmentManagerConfig || '';

export const selectBenchmarkingReportsManagerConfig = (state: RootState): boolean =>
  state.user.preferences?.traits?.benchmarkingConfig || '';

export const selectFrequencyPermissionReadOnly = (state: RootState): boolean =>
  state.user.info.role === 'superadmin'
    ? false
    : state.user.info.companyDetails.frequencyPermissions.readOnly;

export const selectIsUserRoleSuperAdmin = (state: RootState): boolean =>
  state.user.info.role === 'superadmin';

export const selectFrequencyPermissionTagBuilderSuperadminOnly = (state: RootState): boolean =>
  state.user.info.companyDetails.frequencyPermissions.tagBuilderSuperadminOnly;

export const selectFrequencyPermissionReviewsSuperadminOnly = (state: RootState): boolean =>
  state.user.info.companyDetails.frequencyPermissions.reviewsSuperadminOnly;

export const selectBuildSortField = (state: RootState): SortBuildsFieldEnum | string =>
  state.user.preferences?.frequency.buildsLibrarySort?.sortField || '';

export const selectAssessmentSortField = (state: RootState): SortAssessmentFieldEnum | string =>
  state.user.preferences?.navigator.assessmentListSort?.sortField || SortAssessmentFieldEnum.Status;

export const selectBuildsLibrarySortSortDirection = (state: RootState): SortDirectionEnum =>
  state.user.preferences?.frequency.buildsLibrarySort?.sortDirection || SortDirectionEnum.Asc;

export const selectAssessmentListSortDirection = (state: RootState): SortDirectionEnum =>
  state.user.preferences?.navigator.assessmentListSort?.sortDirection || SortDirectionEnum.Asc;

export const selectIsDemoCustomer =
  (withSuperAdmin: boolean = true) =>
  (state: RootState): boolean =>
    state.user.info.companyDetails.isDemo ||
    (withSuperAdmin && state.user.info.role === 'superadmin');

export const selectIsPreviewMode = (state: RootState): boolean =>
  state.user.info.companyDetails.isPreview;

export const selectAssessmentFTUEStep = (state: RootState): number =>
  state.user.assessmentFTUEStep || 0;

export const selectUserHaveAssessments = (state: RootState): boolean => state.user.haveAssessments;

export const selectUserHaveGames = (state: RootState): boolean => state.user.haveDashboards;

export const selectTraitsExplorerConfig = (state: RootState) =>
  state.user.preferences?.traits?.explorerConfig || '';

export const selectTraitsDataConfig = (state: RootState) =>
  state.user.preferences?.traits?.traitsDataConfig || '';

export const selectUserFeaturePermissions = (state: RootState) =>
  state.user.info.permissions.featurePermissions;
