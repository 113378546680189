import { Action } from '@/redux/types';

import {
  RESET_GAME_SLUG,
  SET_GAME_SLUG,
  SET_DASHBOARD_CONFIG,
  RESET_DASHBOARD_CONFIG,
  GameState
} from './types';

const initialState: GameState = {
  slug: null,
  dashboardConfig: null
};

export default (state = initialState, { type, payload }: Action): GameState => {
  switch (type) {
    case SET_GAME_SLUG: {
      return { ...state, slug: payload.gameSlug };
    }
    case RESET_GAME_SLUG: {
      return {
        ...state,
        slug: null
      };
    }

    case SET_DASHBOARD_CONFIG: {
      return {
        ...state,
        dashboardConfig: payload.dashboardConfig
      };
    }
    case RESET_DASHBOARD_CONFIG: {
      return {
        ...state,
        dashboardConfig: null
      };
    }
    default:
      return state;
  }
};
