import { useCallback, useMemo } from 'react';

import { generatePath, useHistory } from 'react-router-dom';

import { useAppSelector } from '@/hooks/useApp';

import { teamAssessmentPath } from '@/route/paths';

import { selectGames } from '@/redux/games/selectors';
import { selectTeamSurvey } from '@/redux/survey/selectors';
import type { AssessementTeam, TeamSurvey } from '@/redux/survey/types';

import type { DropdownOption } from '@/components/Header/Dropdown/types';

import type { Settings } from './types';

export const situate = ({ id }: AssessementTeam) => generatePath(teamAssessmentPath, { id });

export default function useTeamAssessments() {
  const history = useHistory();
  const games = useAppSelector(selectGames);
  const teamSurvey = useAppSelector(selectTeamSurvey);
  const link = useCallback(
    ({ name }: Settings) => {
      const extract = ([survey]: TeamSurvey) => {
        const path = situate(survey);
        const onClick = () => history.push(path);

        return [{ name, onClick }] as Array<DropdownOption>;
      };

      return !teamSurvey?.length ? [] : extract(teamSurvey);
    },
    [history, teamSurvey]
  );
  const redirect = useMemo(
    () => !games.length && !!teamSurvey?.length && situate(teamSurvey.at(0)),
    [games.length, teamSurvey]
  );

  return { link, redirect };
}
