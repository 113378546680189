export const SET_SEGMENT_CODE = 'Segment / SET_SEGMENT_CODE';
export const UPDATE_SEGMENT = 'Segment / UPDATE_SEGMENT';
export const UPDATE_SEGMENT_REQUEST = 'Segment / UPDATE_SEGMENT_REQUEST';
export const UPDATE_SEGMENT_ERROR = 'Segment / UPDATE_SEGMENT_ERROR';
export const DELETE_SEGMENT = 'Segment / DELETE_SEGMENT';
export const DELETE_SEGMENT_REQUEST = 'Segment / DELETE_SEGMENT_REQUEST';
export const DELETE_SEGMENT_SUCCESS = 'Segment / DELETE_SEGMENT_SUCCESS';
export const DELETE_SEGMENT_ERROR = 'Segment / DELETE_SEGMENT_ERROR';

export interface SegmentState {
  currentId: null | string;
}
