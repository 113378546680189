import CoreApiService from '@/services/Api/CoreApiService';

export default class FeedbackApi extends CoreApiService {
  constructor(config: {
    version: 'v1';
    isCamelCaseResponse?: boolean;
    shouldSendSnakeCase?: boolean;
    useGameJwt?: boolean;
    token?: string;
  }) {
    const { isCamelCaseResponse = true, shouldSendSnakeCase, version, useGameJwt, token } = config;
    super({ isCamelCaseResponse, shouldSendSnakeCase, version, useGameJwt, token });
    this.api = `${process.env.BASE_URL}/core`;
  }
}
