import { TagBuilderTag } from '@/components/Frequency/FrequencyBuilderPage/TagBuilder/types';
import { Creative } from '@/components/Frequency/types';

export const LOAD_FREQUENCY_PERMISSIONS = 'Frequency / LOAD_FREQUENCY_PERMISSIONS';
export const SET_CREATIVES_LIMIT = 'Frequency / SET_CREATIVES_LIMIT';
export const SET_CREATIVES_COUNT = 'Frequency / SET_CREATIVES_COUNT';
export const SET_UNDO_DELETE = 'Frequency / SET_UNDO_DELETE';
export const SET_FREQUENCY_PURCHASED = 'Frequency / SET_FREQUENCY_PURCHASED';
export const SET_FREQUENCY_ENABLE_RESONANCE = 'Frequency / SET_FREQUENCY_ENABLE_RESONANCE';
export const SET_FREQUENCY_IS_UPLOADING = 'Frequency / SET_FREQUENCY_IS_UPLOADING';

export const CHECK_SYNC_STATUS = 'Frequency / CHECK_SYNC_STATUS';
export const START_CHECK_SYNC_STATUS = 'Frequency / START_CHECK_SYNC_STATUS';
export const SET_FREQUENCY_IS_SYNCING = 'Frequency / SET_FREQUENCY_IS_SYNCING';
export const SET_FREQUENCY_SYNC_DATA = 'Frequency / SET_FREQUENCY_SYNC_DATA';
export const SET_FREQUENCY_SELECTED_DASHBOARD_ID =
  'Frequency / SET_FREQUENCY_SELECTED_DASHBOARD_ID';

export const SET_FREQUENCY_IS_REVIEW_SURVEY_FLOW_OPENED =
  'Frequency / SET_FREQUENCY_IS_REVIEW_SURVEY_FLOW_OPENED';

export const SET_FREQUENCY_BUILD_ID = 'Frequency / SET_FREQUENCY_BUILD_ID';

export const SET_FREQUENCY_BUILD_NAME = 'Frequency / SET_FREQUENCY_BUILD_NAME';

export const SET_FREQUENCY_BUILD_COVER_CREATIVE = 'Frequency / SET_FREQUENCY_BUILD_COVER_CREATIVE';

export const SET_FREQUENCY_BUILD_PINNED_CREATIVE_IDS =
  'Frequency / SET_FREQUENCY_BUILD_PINNED_CREATIVE_IDS';

export const SET_FREQUENCY_BUILD_UPLOADED_CREATIVES =
  'Frequency / SET_FREQUENCY_BUILD_UPLOADED_CREATIVES';

export const SET_FREQUENCY_BUILD_SELECTED_TAGS = 'Frequency / SET_FREQUENCY_BUILD_SELECTED_TAGS';

export const SET_FREQUENCY_BUILD_SELECTED_AUDIENCE_ID =
  'Frequency / SET_FREQUENCY_BUILD_SELECTED_AUDIENCE_ID';

export const SET_FREQUENCY_BUILD_SELECTED_SEGMENT_ID =
  'Frequency / SET_FREQUENCY_BUILD_SELECTED_SEGMENT_ID';

export const SET_FREQUENCY_BUILD_SELECTED_PERSONA =
  'Frequency / SET_FREQUENCY_BUILD_SELECTED_PERSONA';

export const SET_FREQUENCY_IS_BUILDER_PAGE_VISITED =
  'Frequency / SET_FREQUENCY_IS_BUILDER_PAGE_VISITED';

export const SET_FREQUENCY_BUILD_RESONANCE_SCORE =
  'Frequency / SET_FREQUENCY_BUILD_RESONANCE_SCORE';
export const SET_FREQUENCY_NEED_FETCH_BUILD_DATA =
  'Frequency / SET_FREQUENCY_NEED_FETCH_BUILD_DATA';

export interface FrequencyState {
  creativesLimit: number;
  creativesCount: number;
  frequencyPurchased: boolean | null;
  enableResonanceScore: boolean | null;
  isUploading: boolean;
  isDeleted: string;
  isSyncing: boolean;
  syncData: AdAccountSync[];
  selectedDashboardId: string;
  isReviewSurveyFlowOpened: boolean;
  build: BuildState;
  isBuilderPageVisited: boolean;
  needFetchBuildData: boolean;
}

export interface AdAccountSync {
  adNetwork: string;
  adNetworkAccountId: string;
  adNetworkAccountName: string;
  syncedCreativesCount: number;
  totalCreativesCount: number;
}

export interface BuildState {
  id: string;
  name: string;
  pinnedCreativeIds: string[];
  coverCreative: null | Creative;
  selectedAudienceId: string;
  selectedPersona: string;
  selectedSegmentId: string;
  selectedTags: TagBuilderTag[];
  uploadedCreatives: Creative[];
  resonanceScore: string;
}
