import { RESET_GAME_SLUG } from '@/redux/game/types';
import { Action } from '@/redux/types';

import {
  ComparisonPageState,
  LOAD_COMPARISON_OVERVIEW_REQUEST,
  LOAD_COMPARISON_OVERVIEW_SUCCESS,
  LOAD_COMPARISON_OVERVIEW_ERROR,
  UPDATE_GRAPHS
} from './types';

const initialState: ComparisonPageState = {
  loading: false,
  error: false,
  errorMsg: '',
  left: {
    clusterCode: '',
    kpis: [],
    traits: []
  },
  right: {
    segmentId: '',
    clusterCode: '',
    genreId: '0',
    kpis: [],
    traits: [],
    dashboardId: ''
  },
  comparisonGraphs: [],
  savedComparisons: [],
  isInitialSettingsLoaded: false
};

export default (state = initialState, action: Action): ComparisonPageState => {
  switch (action.type) {
    case LOAD_COMPARISON_OVERVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: ''
      };
    case LOAD_COMPARISON_OVERVIEW_SUCCESS:
      return {
        ...state,
        left: {
          ...state.left,
          kpis: action.payload.data.left.kpis,
          traits: action.payload.data.left.traits
        },
        right: {
          ...state.right,
          kpis: action.payload.data.right.kpis,
          traits: action.payload.data.right.traits
        },
        loading: false,
        error: false,
        errorMsg: ''
      };
    case LOAD_COMPARISON_OVERVIEW_ERROR:
      return {
        ...state,
        left: { ...state.left, kpis: [], traits: [] },
        right: { ...state.right, kpis: [], traits: [] },
        loading: false,
        error: true,
        errorMsg: action.payload.error
      };
    case UPDATE_GRAPHS:
      return { ...state, comparisonGraphs: action.payload };
    case RESET_GAME_SLUG:
      return initialState;
    default:
      return state;
  }
};
