import { combineReducers } from '@reduxjs/toolkit';

import auth from './auth/reducers';
import { benchmarkApi } from './benchmark';
import { cartApi } from './cart/api';
import cluster from './cluster/slice';
import { configApi } from './config/api';
import config from './config/reducers';
import { coreApi } from './CoreServiceApi/api';
import errors from './errors/reducers';
import frequency from './frequency/reducers';
import game from './game/reducers';
import games from './games/reducers';
import kpis from './kpis/reducers';
import { kpisApi } from './KpisServiceApi/api';
import navigator from './navigator/reducers';
import actionsPage from './pages/actionsPage/reducers';
import comparisonPage from './pages/comparisonPage/reducers';
import overviewPage from './pages/overviewPage/reducers';
import segmentsPage from './pages/segmentsPage/reducers';
import targetPage from './pages/targetPage/reducers';
import traitsPage from './pages/traitsPage/reducers';
import segment from './segment/reducers';
import { settingReducer } from './settings-page';
import socialListening from './social-listening/reducers';
import survey from './survey/reducers';
import traits from './traits/reducers';
import { traitsContentfulApi, traitsReducer } from './traits-page';
import user from './user/reducers';
import { wishlistApi } from './wishlist/api';

const rootReducer = combineReducers({
  game,
  navigator,
  traits,
  games,
  auth,
  cluster,
  segment,
  config,
  user,
  kpis,
  survey,
  frequency,
  errors,
  // pages goes below
  overviewPage,
  actionsPage,
  comparisonPage,
  segmentsPage,
  settingsPage: settingReducer,
  targetPage,
  traitsPage,
  migratingTraitsPage: traitsReducer,
  socialListening,
  [wishlistApi.reducerPath]: wishlistApi.reducer,
  [kpisApi.reducerPath]: kpisApi.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [coreApi.reducerPath]: coreApi.reducer,
  [cartApi.reducerPath]: cartApi.reducer,
  [benchmarkApi.reducerPath]: benchmarkApi.reducer,
  [traitsContentfulApi.reducerPath]: traitsContentfulApi.reducer
});

export default rootReducer;
