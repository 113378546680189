import classNames from 'classnames';
import { forwardRef, useMemo } from 'react';


import Icon from 'ui-lib-12traits/src/Icons/svg/common/plus.svg?component';
import { ButtonIcon } from 'ui-lib-12traits/src/index';

import { useI18n } from '@/i18n';

import TargetI18n from './Target.i18n';
import styles from './Target.module.scss';

import type { Props } from './Target.types';

export const Target = forwardRef(({ enabled, onClick, pristine, ...props }: Props, ref) => {
  const i18n = useI18n(TargetI18n);
  const className = useMemo(() => classNames(styles.container, props.className), [props.className]);

  return enabled ? (
    <p {...props} className={className} ref={ref}>
      {pristine ? (
        <>
          <span>{i18n.actions.drag}</span>
          <button onClick={onClick} type="button">
            {i18n.actions.browse}
          </button>
        </>
      ) : (
        <ButtonIcon onClick={onClick}>
          <Icon />
        </ButtonIcon>
      )}
    </p>
  ) : null;
});

export default Target;
