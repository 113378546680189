import { Filters } from '@/redux/pages/actionsPage/types';

export const getResultlabel = (totalCount: number): string => {
  if (totalCount > 100) {
    return 'Show 100+ actions';
  }
  if (totalCount > 1 && totalCount <= 100) {
    return `Show ${totalCount} actions`;
  }
  if (totalCount === 1) {
    return 'Show 1 action';
  }
  return 'No actions found';
};

const getFiltersValues = (filters: Array<{ label: string; value: string }>): string[] =>
  filters.map(f => f.value);

export const mapFiltersToParams = (
  categories: Filters
): {
  [category: string]: string[];
} =>
  Object.entries(categories).reduce(
    (res, [category, categoryFilters]) => ({
      ...res,
      [category]: getFiltersValues(categoryFilters)
    }),
    {}
  );
