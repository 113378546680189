import { call, put, takeLatest, delay, select } from 'redux-saga/effects';

import { fetchSurveyQuestions, fetchSurveyStatus } from '@/services/Api/GameService';
import { Statuses } from '@/services/Api/GameService/types';
import { parseClusterInUrl } from '@/services/ClustersService';
import { parseSegmentInUrl } from '@/services/SegmentsService';

import {
  loadSurvey,
  loadSurveyStatus,
  loadSurveySuccess,
  loadSurveyError,
  loadSurveyStatusSuccess,
  loadSurveyStatusError,
  setSurveyIntegrationPercentage,
  setSurveyIntegrationStatus,
  calculateSurveyIntegration
} from './actions';
import {
  LOAD_SURVEY,
  LOAD_SURVEY_STATUS,
  CALCULATE_SURVEY_INTEGRATION,
  Statuses as surveyIntegrationStatuses
} from './types';

function* getSurvey() {
  try {
    const {
      data: { questions }
    } = yield call(fetchSurveyQuestions);
    yield put(loadSurveySuccess(questions));
    yield put(setSurveyIntegrationPercentage(100));
    yield put(setSurveyIntegrationStatus(surveyIntegrationStatuses.INTEGRATED));
  } catch (e) {
    yield put(loadSurveyError(e.message));
  }
}

function* getStatus() {
  try {
    const result = yield call(fetchSurveyStatus);
    const { status } = result.data;
    const surveyIntegrationPercentage = yield select(
      state => state.survey.surveyIntegrationPercentage
    );

    const cluster = parseClusterInUrl() || undefined;
    const segment = parseSegmentInUrl() || undefined;

    yield put(loadSurveyStatusSuccess(status));

    switch (status) {
      case Statuses.NEVER_UPLOADED:
        yield put(setSurveyIntegrationStatus(surveyIntegrationStatuses.NOT_UPLOADED));
        break;
      case Statuses.PROCESSING:
        yield put(setSurveyIntegrationStatus(surveyIntegrationStatuses.INTEGRATING));
        if (surveyIntegrationPercentage === 0) {
          yield put(setSurveyIntegrationPercentage(99));
        }
        yield delay(5000);
        yield put(loadSurveyStatus());
        break;
      case Statuses.UPDATING:
        yield put(setSurveyIntegrationStatus(surveyIntegrationStatuses.INTEGRATING));
        if (surveyIntegrationPercentage === 0) {
          yield put(setSurveyIntegrationPercentage(99));
        }
        yield delay(5000);
        yield put(loadSurveyStatus());
        break;
      case Statuses.AVAILABLE:
        yield put(loadSurvey(segment, cluster));
        break;
      default:
        yield delay(5000);
        yield put(loadSurveyStatus());
        break;
    }
  } catch (e) {
    yield put(setSurveyIntegrationStatus(surveyIntegrationStatuses.ERROR));
    yield put(loadSurveyStatusError(e.message));
  }
}

function* integrateSurvey() {
  const initialIntegrationStop = 99;
  const approximateSurveyIntegrationTime = yield select(
    state => state.survey.approximateSurveyIntegrationTime
  );
  const surveyIntegrationPercentage = yield select(
    state => state.survey.surveyIntegrationPercentage
  );
  const quarter = (approximateSurveyIntegrationTime / initialIntegrationStop) * 25;
  if (surveyIntegrationPercentage >= initialIntegrationStop) {
    yield put(setSurveyIntegrationPercentage(initialIntegrationStop));
    yield put(loadSurveyStatus());
  } else {
    yield delay(quarter * 1000);
    yield put(setSurveyIntegrationPercentage(surveyIntegrationPercentage + 25));
    yield put(calculateSurveyIntegration());
  }
}

function* Saga() {
  yield takeLatest(LOAD_SURVEY, getSurvey);
  yield takeLatest(LOAD_SURVEY_STATUS, getStatus);
  yield takeLatest(CALCULATE_SURVEY_INTEGRATION, integrateSurvey);
}

export default Saga;
