import { RootState } from '@/redux/rootStore';

import { ComparisonGraph } from './types';

export const selectRightSegmentId = (state: RootState): string =>
  state.comparisonPage.right.segmentId;

export const selectRightClusterCode = (state: RootState): string =>
  state.comparisonPage.right.clusterCode;

export const selectRightDashboardId = (state: RootState): string =>
  state.comparisonPage.right.dashboardId;

export const selectRightGenreId = (state: RootState): string => state.comparisonPage.right.genreId;

export const selectComparisonGraphs = (state: RootState): ComparisonGraph[] =>
  state.comparisonPage.comparisonGraphs;

export const selectIsInitialSettingsLoaded = (state: RootState): boolean =>
  state.comparisonPage.isInitialSettingsLoaded;
