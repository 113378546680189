import CoreApiService from '@/services/Api/CoreApiService';
import { TaxonomyNode } from '../types';

export async function getTaxonomy(): Promise<{ items: Array<TaxonomyNode> }> {
  return new CoreApiService().setEndpoint('/config/taxonomy').get();
}

export async function getGameProfile({token}): Promise<any> {
  return new CoreApiService({ useGameJwt: true, token }).setEndpoint('/project/profile').get();
}

export async function updateGameProfile(payload: any): Promise<any> {
  return new CoreApiService({ useGameJwt: true })
    .setEndpoint('/project/profile')
    .setBody(payload)
    .post();
}
