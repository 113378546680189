import { Creative } from '@/services/Api/AdsService/types';

import { CollectionModalViewEnum } from './CollectionModal/types';

export enum ViewEnum {
  Tile = 'Tile',
  Grid = 'Grid',
  List = 'List'
}

export enum Orientation {
  portrait = 'portrait',
  landscape = 'landscape',
  square = 'square'
}

export enum SortFieldEnum {
  UploadedAt = 'uploaded_at',
  Nickname = 'nickname',
  LastViewedAt = 'last_viewed_at',
  ResonanceScore = 'resonance_score',
  AudienceName = 'audienceName',
  Metrics = 'sort_kpi_id'
}

export enum SortBuildsFieldEnum {
  AudienceName = 'target_audience',
  ResonanceScore = 'resonance_score',
  Name = 'name',
  LastUpdatedAt = 'last_updated_at'
}

export enum SortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export type Tag = {
  name: string;
  slug: string;
  type: string;
  source: string;
  visionId: string;
  children?: Tag[];
};

export interface TagItem extends Tag {
  id: string | number;
  isLowerCase?: boolean;
}

export enum CreativeType {
  Image = 'image',
  Video = 'video',
  Audio = 'audio'
}

export interface MediaInfo {
  height: number;
  length: number;
  type: CreativeType;
  width: number;
}

export interface KPI {
  abbreviation: string;
  description: string;
  facebookKey: string;
  googleKey: string;
  id: number;
  measurement: string;
  objective: string;
  slug: string;
  supported: boolean;
  title: string;
  type: number;
}

export interface AdNetworkKpi {
  kpiInfo: KPI;
  value: number;
  valueDailyChange: number;
}

export interface AdNetworkInfo {
  [k: string]: {
    adAccountId: string;
    adAccountName: string;
    adCreativeId: string;
    syncedAt: number;
    syncedBy: string;
  };
}

export type QueryCreativeDetail = {
  offset: string;
  colors: string[];
  searchValue: string;
  creativeTypes: CreativeType[];
  tags: string[];
  kpi: string;
  assessment?: boolean;
};

export enum SlideDirection {
  next,
  prev
}

export enum ObjectiveEnum {
  Increase = 'Increase',
  Decrease = 'Decrease'
}

export interface AdNetwork {
  name: string;
  connected: boolean;
  hasKpis: boolean;
}

export interface OverviewCardListItem {
  id: string;
  name: string;
  value: number | string;
  imageURL?: string;
  details: {
    id?: string;
    name: string;
    value: string | number;
    imageURL?: string;
  }[];
}

export interface OverviewCreative extends Creative {
  filterKPIID: number;
}

export interface Campaign {
  adaccount: string;
  createdTime: number;
  id: string;
  kpis: AdNetworkKpi[];
  name: string;
  objective: string;
  startTime: number;
  status: string;
  stopTime: number;
  creatives?: Creative[];
}

export interface OverviewCampaign extends Campaign {
  filterKPIID: number;
}

export interface Pagination {
  prev: string | null;
  next: string | null;
}

export interface Stack {
  stackId: string;
  creatives: Creative[];
}

export interface UploadModalConfig {
  subtitle?: string;
  withUploadButtons?: boolean;
  withSyncButton?: boolean;
  stackID?: string;
  onFinishUpload?: (data: Creative[]) => void;
  multiple?: boolean;
}

export interface FrequencyCreativeParams {
  creativeId: string;
}

interface CreativeAudienceKpi {
  id: number;
  measurement: string;
  name: string;
  value: number;
  label: string;
}

export interface CreativeAudience {
  cluster: number | 'all';
  clusterName: string;
  gameId: string;
  gameName: string;
  gameSlug: string;
  kpis: CreativeAudienceKpi[];
  pcs: number;
  score: number;
  segmentId: string | null;
  segmentName: string;
  status: string;
}

export interface CreativeAudienceColumn {
  id: string;
  name: string;
  abbreviation?: string;
  isActive?: boolean;
}

export enum Column {
  Name = 'Name',
  ViewedDate = 'Viewed date',
  UploadDate = 'Upload date',
  Resonance = 'Resonance'
}

export interface Columns {
  id: Column | null;
  isActive: boolean;
}

export const PROCESSING_NOTIFICATION_ID = '[Upload] processing';

export interface CollectionAudience {
  dashboardId: string;
  dashboardName: string;
  segmentId: string;
  segmentName: string;
  cluster: number;
  clusterName: string;
  averageResonanceScore: number;
}

export interface Collection {
  id: string;
  description: string;
  name: string;
  companyId: string;
  createdAt: number;
  lastUpdatedAt: number;
  createdBy: {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    active: boolean;
    role: string;
    createdAt: number;
    company: string;
  };
  audiences: CollectionAudience[];
  creativesCount: number;
  covers: {
    coverCreativeId: string;
    thumbnailUrl: string | undefined;
  }[];
  coverCreativeId: string;
}

export interface CollectionModalConfig {
  collectionModalView?: CollectionModalViewEnum;
  creative?: Creative;
  collectionId?: string;
  onUpdate?: (data: any) => void;
}

export enum DotUpdatesEnum {
  profile = 'profile',
  collections = 'collections',
  none = 'none'
}

export enum ResonanceScoreTextType {
  One,
  Two,
  Three
}
