
import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import { ReactElement, useEffect, useRef, useState } from 'react';

import { GlobalButton } from 'ui-lib-12traits/src/index';

import { useI18n } from '@/i18n';
import { Step, Template, templates } from '@/pages/Navigator/AssessmentAutomationV2/types';

import { DeliveryMethod } from '@/services/Api/TeamAssessmentSurveyService/types';

import { Feedback } from '@/components/Ui/Beta/Feedback/Feedback';

import StepContainer from '../StepContainer';

import RewardingStepI18n from './RewardingStep.i18n';
import styles from './RewardingStep.module.scss';
import RewardDeliveryMethod from './Steps/RewardDeliveryMethod';
import RewardDescription from './Steps/RewardDescription';




interface Props {
  isActive: boolean;
  setActiveStep: (isActive: boolean) => void;
  setHoveredStep: (isActive: string) => void;
  template: Template;
  onNext: any;
  title: string;
  order: number | string;
  values: any;
  status: string;
  baseStepValues: any;
  assessmentMetaData: { id: string; status: string; type: string; contentType: string } | null;
  updateStepValues: (values: any, t: Template) => void;
  setErrorCount: any;
  errorCount: any;
  skipped: boolean;
  stepsValues: Step[];
}

function RewardingStep(props: Props): ReactElement {
  const {
    isActive,
    setActiveStep,
    onNext,
    title,
    order,
    baseStepValues,
    assessmentMetaData,
    updateStepValues,
    setErrorCount,
    errorCount,
    skipped,
    stepsValues,
    ...step
  } = props;
  const { values, template, status } = step;
  const [deepLink, setDeepLink] = useState('');
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState('');
  const [describedReward, setDescribedReward] = useState('');
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [showDeliveryStep, setShowDeliveryStep] = useState(false);
  const [deliveryDisabled, setDeliveryDisabled] = useState(false);
  const [skipCounter, setSkipCounter] = useState<number>(0);
  const [skippedStep, setSkippedStep] = useState(false);
  const [filled, setFilled] = useState(false);
  const deepLinkIsValid = !!deepLink.match(/\w+:\/\/\??\w+/);
  const errorCountRef = useRef(errorCount);

  if (!isEqual(errorCountRef.current, errorCount)) {
    errorCount.current = errorCount;
  }

  const i18n = useI18n(RewardingStepI18n);
  useEffect(() => {
    if (skippedStep) {
      setSelectedDeliveryMethod('');
    }
    if (!skippedStep && selectedDeliveryMethod === 'deeplink') {
      setErrorCount({ ...errorCount, [template]: deepLinkIsValid && deepLink });
    } else if (skipCounter === 0 && (!filled || !describedReward)) {
      setErrorCount({ ...errorCount, [template]: false });
    } else {
      setErrorCount({ ...errorCount, [template]: true });
    }
  }, [
    deepLinkIsValid,
    deepLink,
    filled,
    skippedStep,
    describedReward,
    selectedDeliveryMethod,
    errorCountRef.current
  ]);

  useEffect(() => {
    if (status !== 'pristine') {
      if (skipped) {
        setSkipCounter(1);
        setSkippedStep(true);
      } else {
        if (values.link) setDeepLink(values.link);
        if (values.description) {
          setDescribedReward(values.description);
          setShowDeliveryStep(true);

          if (values.mechanism) setSelectedDeliveryMethod(values.mechanism);
        }
      }
    }
  }, [values.link, values.description, skipped, values.mechanism, status]);

  useEffect(() => {
    updateStepValues(
      {
        id: step.id,
        description: describedReward,
        mechanism: selectedDeliveryMethod,
        skipped: skippedStep,
        link: deepLink
      },
      template
    );
  }, [selectedDeliveryMethod, deepLink, describedReward, skippedStep]);

  return (
    <>
      <StepContainer
        style={{ width: '804px' }}
        title={title}
        titleExpanded={i18n.title}
        id={order}
        isExpanded={isActive}
        invalid={errorCount?.[template] === false}
        withNumber
        template={template}
        onExpand={setActiveStep}
        testId="rewarding-step"
        collapsedElement={
          <div className={styles.card__value}>
            {!describedReward ? i18n.scenarios.none : describedReward}
          </div>
        }
      >
        <form className={styles.step}>
          <RewardDescription
            describedReward={describedReward}
            setDescribedReward={setDescribedReward}
            setShowDeliveryStep={setShowDeliveryStep}
            showDeliveryStep={showDeliveryStep}
            baseStepValues={baseStepValues}
            setDeliveryDisabled={setDeliveryDisabled}
            deliveryDisabled={deliveryDisabled}
            setFeedbackModal={setFeedbackModal}
            setSkipCounter={setSkipCounter}
            skippedStep={skippedStep}
            skipCounter={skipCounter}
            setSkippedStep={setSkippedStep}
            onSkip={onNext}
            setFilled={setFilled}
            filled={filled}
            stepsValues={stepsValues}
          />
          {showDeliveryStep && (
            <div
              className={classNames({
                [styles.delivery_disabled]: deliveryDisabled
              })}
            >
              <RewardDeliveryMethod
                baseStepValues={baseStepValues}
                showDeliveryStep={showDeliveryStep}
                deepLink={deepLink}
                setDeepLink={setDeepLink}
                selectedDeliveryMethod={selectedDeliveryMethod}
                setSelectedDeliveryMethod={setSelectedDeliveryMethod}
                stepsValues={stepsValues}
              />
            </div>
          )}
          {((errorCount[template] &&
            showDeliveryStep &&
            selectedDeliveryMethod === DeliveryMethod.DeepLink &&
            deepLink.length > 0 &&
            deepLinkIsValid) ||
            (selectedDeliveryMethod.length > 0 &&
              selectedDeliveryMethod !== DeliveryMethod.DeepLink)) &&
            !deliveryDisabled && (
              <GlobalButton
                testid="submit-btn"
                title={i18n.actions.proceed.title}
                className={styles.submit_btn}
                onClick={() =>
                  onNext(
                    {
                      description: describedReward,
                      mechanism: selectedDeliveryMethod,
                      link: deepLink
                    },
                    {
                      skipped: false,
                      template: templates.reward
                    }
                  )
                }
              />
            )}
        </form>
      </StepContainer>

      {feedbackModal && (
        <Feedback
          i18n={i18n.contact}
          cancel={() => setFeedbackModal(false)}
          conclude={() => setFeedbackModal(false)}
          feature="assessment_launcher"
          params={{
            assessment: assessmentMetaData,
            steps: {
              next: {
                ...step,
                order: +order - 1
              }
            }
          }}
        />
      )}
    </>
  );
}

export default RewardingStep;
