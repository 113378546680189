import { type SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import { useAsync } from '@/hooks';

import { feedback, upload } from '@/services/Api/Feedback';

import { selectCurrentSegment } from '@/redux/segment/selectors';

import * as reducers from './Feedback.reducers';

import type { Form, Props, State } from './Feedback.types';

export const OPTIONS = {
  accept: {
    'image/gif': ['.gif'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'image/png': ['.png'],
    'image/webp': ['.webp']
  },
  limit: 3
};

export const onChange = ({
  currentTarget: {
    elements: {
      message: { value },
      submit: button
    }
  }
}: SyntheticEvent<Form>) => {
  const disabled = !value.trim().length;

  return Object.assign(button, { disabled });
};

export default ({ feature, cancel, conclude, params }: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [{ attachments }, persist] = useState<State>(reducers.getInitialState());
  const { id: segment } = useSelector(selectCurrentSegment) ?? {};
  const submitting = useAsync();
  const onSubmit = useCallback(
    (event: SyntheticEvent<Form>) => {
      const { currentTarget: form } = event;
      const {
        elements: {
          message: { value: message }
        },
        submit: button
      } = form;
      const flush = () => form.reset();
      const send = (screenshots: string[]) =>
        feedback({
          browser: window.navigator.userAgent,
          feature,
          message,
          params,
          screenshots,
          segment
        }).then(flush);
      const promise = Promise.all(attachments.map(upload)).then(send);

      event.preventDefault();
      submitting.watch(promise.then(conclude));

      return Object.assign(button, { disabled: true });
    },
    [attachments, conclude, feature, params, segment, submitting]
  );
  const focus = useCallback(() => target?.current?.setAttribute('aria-busy', 'true'), []);
  const blur = useCallback(() => target?.current?.removeAttribute('aria-busy'), []);
  const onDropAccepted = useCallback(
    (files: File[]) => persist(reducers.drop({ limit: OPTIONS.limit, files })),
    []
  );
  const {
    rootRef: target,
    getInputProps,
    getRootProps,
    open
  } = useDropzone({
    accept: OPTIONS.accept,
    onDragEnter: focus,
    onDragLeave: blur,
    onDrop: blur,
    onDropAccepted
  });
  const prompt = useMemo(
    () => ({ input: getInputProps(), target: getRootProps() }),
    [getInputProps, getRootProps]
  );
  const remove = useCallback((index: number) => persist(reducers.remove(index)), []);
  const stats = useMemo(
    () => ({
      enabled: attachments.length + 1 <= OPTIONS.limit,
      pristine: !attachments.length
    }),
    [attachments]
  );

  useEffect(() => ref?.current?.focus(), []);

  return {
    attachments,
    cancel,
    onChange,
    onSubmit,
    open,
    prompt,
    ref,
    remove,
    submitting,
    ...stats
  };
};
