import classNames from 'classnames';
import { useCallback } from 'react';

import { Step } from 'ui-lib-12traits/src';

import { useI18n } from '@/i18n';
import { templates } from '@/pages/Navigator/AssessmentAutomationV2/types';

import { DeliveryMethod } from '@/services/Api/AssessmentSurveyService/types';

import SurveyInput from '@/components/Ui/Survey/SurveyInput';
import SurveyList from '@/components/Ui/Survey/SurveyList';

import styles from '../RewardingStep.module.scss';

import RewardDeliveryMethodI18n from './RewardDeliveryMethod.i18n';

interface Props {
  baseStepValues?: StaticValues | null;
  selectedDeliveryMethod: string;
  setDeepLink: (deepLink: string) => void;
  deepLink: string;
  setSelectedDeliveryMethod: (selectedDeliveryMethod: 'key' | 'api' | 'manual') => void;
  showDeliveryStep: boolean;
  stepsValues: Step[];
}

function RewardDeliveryMethod({
  baseStepValues,
  selectedDeliveryMethod,
  setDeepLink,
  deepLink,
  setSelectedDeliveryMethod,
  stepsValues
}: Props) {
  const i18n = useI18n(RewardDeliveryMethodI18n);
  const deepLinkIsValid = deepLink.length === 0 ? true : !!deepLink.match(/\w+:\/\/\??\w+/);
  const renderBtn = ({ key, preffered }: any) => (
    <label htmlFor={key} key={key}>
      <input
        type="radio"
        name="audience"
        checked={key === selectedDeliveryMethod}
        id={key}
        onChange={() => setSelectedDeliveryMethod(key)}
      />
      <span>
        <strong>{i18n.options(key, preffered)}</strong>
      </span>
    </label>
  );
  const renderFormat = useCallback(
    ({ label, pattern }, key) => (
      <tr key={key}>
        <td>{label}:</td>
        <td>{pattern}</td>
      </tr>
    ),
    []
  );

  return (
    <div data-testid="reward-delivery" className={classNames(styles.step, styles.delivery)}>
      <div data-testid="title" className={styles.step__title}>
        {i18n.title}
      </div>
      <div
        data-testid="toggler"
        className={classNames(styles.toggle, {
          [styles['toggle--checked']]: selectedDeliveryMethod
        })}
      >
        {[{ key: 'deeplink', preffered: true }, { key: 'api' }, { key: 'manual' }].map(item =>
          renderBtn(item)
        )}
      </div>
      {selectedDeliveryMethod === DeliveryMethod.DeepLink && (
        <>
          <div className={styles.text}>
            {i18n.deeplink.description(stepsValues?.[templates.static] || baseStepValues)}
          </div>
          <div className={styles.input__container}>
            <SurveyInput
              autoFocus
              zIndex={3}
              withBorder={!deepLink}
              errorTooltipClassname={styles.tooltip}
              className={classNames({ [styles.deeplink_invalid]: !deepLinkIsValid })}
              tooltipPlacement="right-start"
              placeholder={i18n.deeplink.placeholder}
              onClear={(): void => {
                setDeepLink('');
              }}
              errorTooltipContent={
                deepLinkIsValid ? (
                  ''
                ) : (
                  <div className={styles.tooltip__content}>{i18n.deeplink.reminder}</div>
                )
              }
              name="deep-link"
              value={deepLink}
              onInputChange={setDeepLink}
            />
            <div className={styles.delivery_note}>
              <p>{i18n.deeplink.disclaimer.title}</p>
              <table>
                <tbody>{i18n.deeplink.disclaimer.formats.map(renderFormat)}</tbody>
              </table>
              <p>{i18n.deeplink.disclaimer.observation}</p>
            </div>
          </div>
        </>
      )}
      {selectedDeliveryMethod === DeliveryMethod.Api && (
        <div className={styles['desc-api']}>
          <div className={styles['desc-api__title']}>{i18n.api.disclaimer.title}</div>
          <SurveyList
            itemClassName={styles.list__item}
            items={[
              {
                id: '1',
                content: (
                  <>
                    {i18n.api.disclaimer.items.first.fragments.first}
                    <a
                      className={styles.list__link}
                      target="_blank"
                      rel="noreferrer"
                      href={process.env.DOCS_URL}
                    >
                      {i18n.api.disclaimer.items.first.fragments.second}
                    </a>
                  </>
                )
              },
              {
                id: '2',
                content: i18n.api.disclaimer.items.second
              }
            ]}
          />
          <div className={styles['desc-api__bottom']}>{i18n.api.disclaimer.observation}</div>
        </div>
      )}
      {selectedDeliveryMethod === DeliveryMethod.Manual && (
        <div className={styles['desc-api']}>
          <div className={styles['desc-api__title']}>
            {i18n.manual.disclaimer.title(stepsValues?.[templates.static] || baseStepValues)}
          </div>
          <SurveyList
            itemClassName={styles.list__item}
            items={[
              {
                id: '1',
                content:
                  baseStepValues.assessmentType === '360'
                    ? i18n.manual.disclaimer.items.first
                    : i18n.manual.disclaimer.itemsPreferences.first
              },
              {
                id: '2',
                content:
                  baseStepValues.assessmentType === '360'
                    ? i18n.manual.disclaimer.items.second
                    : i18n.manual.disclaimer.itemsPreferences.second
              }
            ]}
          />
        </div>
      )}
    </div>
  );
}

export default RewardDeliveryMethod;
