export function fuzzyFilter(list: Array<any>, val: string) {
  const valLower = val.toLowerCase();

  return list.filter(item => {
    const labelLower = item.label.toLowerCase();
    let valIdx = 0;
    for (let i = 0; i < labelLower.length && valIdx < valLower.length; i += 1) {
      if (labelLower[i] === valLower[valIdx]) {
        valIdx += 1;
      }
    }
    return valIdx === valLower.length;
  });
}
