import GamesService from '@/services/Api/GamesApiService';

import { ApiResponse } from '../types';

import { KnownGame } from './types';

export const fetchKnownGames = (
  query: string,
  datatype: 'known-game' | 'game' = 'known-game'
): Promise<ApiResponse<KnownGame[]>> =>
  new GamesService()
    .setEndpoint('/search')
    .setParams({ query, datatype, apiKey: process.env.GAMES_API_KEY })
    .get();
