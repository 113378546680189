import { UserRole } from '@/features/user-management/types';
import {
  SortBuildsFieldEnum,
  SortDirectionEnum,
  SortFieldEnum,
  ViewEnum
} from '@/components/Frequency/types';
import { SortAssessmentFieldEnum } from '@/components/Navigator/types';

export const SET_USER_COMPANY = 'User / SET_USER_COMPANY';
export const LOAD_USER_INFO = 'User / LOAD_USER_INFO';
export const LOAD_USER_INFO_SUCCESS = 'User / LOAD_USER_INFO_SUCCESS';
export const SET_IS_LOADING = 'User / SET_IS_LOADING';
export const SET_IS_LOADING_PREFERENCES = 'User / SET_IS_LOADING_PREFERENCES';
export const UPDATE_USER_DATA = 'User / UPDATE_USER_DATA';
export const UPDATE_USER_AVATAR_URL = 'User / UPDATE_USER_AVATAR_URL';
export const UPLOAD_AVATAR = 'User / UPLOAD_AVATAR';
export const UPDATE_COMPANY_AVATAR_URL = 'User / UPDATE_COMPANY_AVATAR_URL';
export const UPLOAD_COMPANY_AVATAR = 'User / UPLOAD_COMPANY_AVATAR';

export const LOAD_USER_PREFERENCES = 'User / LOAD_USER_PREFERENCES';
export const LOAD_USER_PREFERENCES_SUCCESS = 'User / LOAD_USER_PREFERENCES_SUCCESS';
export const UPDATE_USER_PREFERENCES = 'User / UPDATE_USER_PREFERENCES';
export const UPDATE_FREQ_PREFERENCES = 'User / UPDATE_FREQ_PREFERENCES';
export const UPDATE_NAV_PREFERENCES_ASSESSMENT_LIST_SORT =
  'User / UPDATE_NAV_PREFERENCES_ASSESSMENT_LIST_SORT';
export const UPDATE_FREQ_PREFERENCES_BUILDS_LIBRARY_SORT =
  'User / UPDATE_FREQ_PREFERENCES_BUILDS_LIBRARY_SORT';

export const UPDATE_FREQ_PREFERENCES_BUILDS_LIBRARY_VIEW =
  'User / UPDATE_FREQ_PREFERENCES_BUILDS_LIBRARY_VIEW';
export const SET_ASSESSMENT_FTUE_STEP = 'User / SET_ASSESSMENT_FTUE_STEP';
export const UPDATE_USER_HAVE_ASSESSMENTS = 'User / UPDATE_USER_HAVE_ASSESSMENTS';
export const UPDATE_USER_HAVE_GAMES = 'User / UPDATE_USER_HAVE_GAMES';
export const SET_CONSENT = 'User / SET_CONSENT';
export const RESET_USER = 'User / RESET_USER';
export const SET_EXPLORER_STATE = 'User / SET_EXPLORER_PAGE';
export const SET_TRAIT_DATA_CONFIG_STATE = 'User / SET_TRAIT_DATA_CONFIG_STATE';

export enum JobTitles {
  GAME_DESIGNER = 'Game Designer',
  MARKETER = 'Marketer',
  UX_DESIGNER = 'UX Designer',
  PRODUCT_MANAGER = 'Product Manager',
  PRODUCER = 'Producer',
  RESEARCHER = 'Researcher',
  DESIGNER = 'Designer'
}

export enum CompanyTypes {
  Gaming = 0,
  NonGaming = 1,
  Medical = 2
}

export enum DashboarUserRoles {
  MANAGER = 'manager',
  ADMIN = 'admin',
  VIEWER = 'viewer',
  CONTRIBUTOR = 'contributor'
}

export enum CompanyUserRoles {
  USER = 'user',
  ADMIN = 'admin'
}

export interface User {
  avatarUrl: string;
  socialListeningEnabled: boolean;
  email: string;
  name: string;
  id: string;
  active: boolean;
  permissions: {
    isAdmin: boolean | null;
    dashboardPermissions: DashboardPermission[];
  };
}

export interface DashboardPermission {
  dashboardId: string;
  role?:
    | DashboarUserRoles.MANAGER
    | DashboarUserRoles.VIEWER
    | DashboarUserRoles.ADMIN
    | DashboarUserRoles.CONTRIBUTOR;
}

export interface CompanyDetails {
  name: string;
  companyType: CompanyTypes.Gaming | CompanyTypes.NonGaming | CompanyTypes.Medical;
  avatarUrl: string;
  socialListeningEnabled: boolean;
  socialListeningSuperadminOnly: boolean;
  isDemo: boolean;
  isPreview: boolean;
  frequencyPermissions: {
    campaignsLimit: number;
    creativesLimit: number;
    readOnly: boolean;
    reviewsSuperadminOnly: boolean;
    superAdminOnly: boolean;
    tagBuilderSuperadminOnly: boolean;
  };
}

export interface Consent {
  consent?: boolean;
  consentDate?: number | null;
  email?: string;
  marketingEmails?: boolean;
  partnerLoggedInUserId?: string;
}

export interface UserCompany {}

export interface UserInfo {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  email: string;
  name: string;
  role: UserRole;
  datetimeNumbersFormat: string;
  jobTitle:
    | JobTitles.GAME_DESIGNER
    | JobTitles.MARKETER
    | JobTitles.UX_DESIGNER
    | JobTitles.PRODUCT_MANAGER
    | JobTitles.PRODUCER
    | JobTitles.RESEARCHER
    | JobTitles.DESIGNER
    | '';
  companyDetails: CompanyDetails;
  consent: Consent;
  permissions: {
    isCompanyOwner: boolean | null;
    dashboardPermissions: DashboardPermission[];
  };
  timezone: string;
}

export interface creativesLibraryColumns {
  grid?: string[];
  list?: string[];
}

export interface UserFrequencyPreferencesBuildsLibrarySort {
  sortDirection?: SortDirectionEnum;
  sortField?: SortBuildsFieldEnum;
}

export interface UserPreferencesAssessmentList {
  sortDirection?: SortDirectionEnum;
  sortField?: SortAssessmentFieldEnum;
}
export interface UserFrequencyPreferences {
  creativesView?: ViewEnum;
  buildsView?: ViewEnum;
  ftueCreativesLibrary?: boolean;
  ftueCreativeDetails?: boolean;
  stueCreativesLibrary?: boolean;
  stueCreativeDetails?: boolean;
  ftueCreativeAddedTags?: boolean;
  creativesLibraryStacksEnabled?: boolean;
  creativesLibraryColumns?: null | creativesLibraryColumns;
  creativesActiveGridViewOption?: string;
  creativesLibraryKpis?: null | { kpiId: string; enabled: boolean };
  creativesLibrarySort?: {
    sortCluster?: string;
    sortDashboardId?: string;
    sortDirection?: SortDirectionEnum;
    sortField?: SortFieldEnum;
    sortSegmentId?: string;
    sortKpiId?: string;
  };
  buildsLibrarySort?: UserFrequencyPreferencesBuildsLibrarySort;
  creativesDetailsKpis?: number[];
}

export interface UserNavigatorPreferences {
  genresView: string;
  assessmentListSort?: UserPreferencesAssessmentList;
}

export interface UserPreferences {
  navigator: UserNavigatorPreferences;
  frequency: UserFrequencyPreferences;
  traits: {
    assessmentManagerConfig: string;
    benchmarkingConfig: string;
    ftueTraitsAlphabetically: boolean;
    explorerConfig: string;
    traitsDataConfig: string;
  };
}

export interface UserState {
  haveAssessments: boolean;
  haveDashboards: boolean;
  assessmentFTUEStep?: number;
  isLoading: boolean;
  isLoadingPreferences: boolean;
  company: UserCompany;
  info: UserInfo;
  preferences: UserPreferences | null;
}
