import { createSelector } from 'reselect';

import { type Timeline } from 'ui-lib-12traits/src/index';

import {
  KpiLeaderboardData,
  UserExperience,
  UniqueAndSimilarTraits,
  GenreAverages,
  PcsData
} from '@/pages/Traits/OverviewPage/types';

import { avg } from '@/services/ArrayService';


import { RootState } from '@/redux/rootStore';
import { selectCurrentSegmentId } from '@/redux/segment/selectors';

import {
  type SentimentIndexes,
  type SentimentFilter,
  Channel,
  SentimentData,
  SentimentDataPoint,
  EngagementRate,
  MarketSizeI,
  TopGame,
  Releases
} from './types';

export const selectError = (state: RootState): boolean => state.overviewPage.error;

export const selectHoveredPulsePersona = (state: RootState): string | null =>
  state.overviewPage.pulse.hoveredPulsePersona;

export const selectSelectedPulsePersona = (state: RootState): string | null =>
  state.overviewPage.pulse.selectedPulsePersona;

export const selectActivePulsePersonasPerSegment = (
  state: RootState
): {
  [key: string]: string[];
} | null => state.overviewPage.pulse.activePulsePersonasPerSegment;

export const selectActivePulsePersonas = createSelector(
  selectCurrentSegmentId,
  selectActivePulsePersonasPerSegment,
  (currentSegmentId: string, activePulsePersonasPerSegment) =>
    currentSegmentId &&
    activePulsePersonasPerSegment &&
    activePulsePersonasPerSegment[currentSegmentId]
      ? activePulsePersonasPerSegment[currentSegmentId]
      : ['all']
);

export const selectPersonaValue = createSelector(
  selectSelectedPulsePersona,
  selectActivePulsePersonas,
  (pulsePersona, activePulsePersonas) =>
    pulsePersona || (activePulsePersonas?.length === 1 ? activePulsePersonas[0] : null)
);

export const selectPulseActiveTimeline = (state: RootState): Timeline | null =>
  state.overviewPage.pulse.activeTimeline;

export const selectPulseAvailableTimelines = (state: RootState): Timeline[] =>
  state.overviewPage.pulse.availableTimelines;

export const selectSentimentActiveTimeline = (state: RootState): Timeline | null =>
  state.overviewPage.sentiment.activeTimeline;

export const selectSentimentAvailableTimelines = (state: RootState): Timeline[] =>
  state.overviewPage.sentiment.availableTimelines;

export const selectSentimentActiveChannel = (state: RootState): Channel | null =>
  state.overviewPage.sentiment.activeChannel;

export const selectSentimentAvailableChannels = (state: RootState): Channel[] =>
  state.overviewPage.sentiment.availableChannels;

export const selectPulseHoveredDate = (state: RootState): string | null =>
  state.overviewPage.pulse.hoveredDate;

export const selectPulseDataLoading = (state: RootState): boolean =>
  state.overviewPage.pulse.loading;

export const selectEngagementRateData = (state: RootState): EngagementRate | null =>
  state.overviewPage.pulse.engagementRateData;

export const selectSentimentDataLoading = (state: RootState): boolean =>
  state.overviewPage.sentiment.loading;

export const selectSentimentHoveredDate = (state: RootState): string | null =>
  state.overviewPage.sentiment.hoveredDate;

export const selectSentimentHoveredFilter = (state: RootState): string | null =>
  state.overviewPage.sentiment.hoveredFilter;

export const selectSentimentActiveFilters = (state: RootState): SentimentFilter[] =>
  state.overviewPage.sentiment.activeFilters;

export const selectIsTooltipAnimating = (state: RootState): boolean =>
  state.overviewPage.sentiment.isTooltipAnimating;

export const selectSentimentData = (state: RootState): SentimentData =>
  state.overviewPage.sentiment.data;

export const sentimentPrevJwt = (state: RootState): string | null =>
  state.overviewPage.sentiment.prevGameJWT;

export const sentimentPrevActiveTimeline = (state: RootState): string | null =>
  state.overviewPage.sentiment.prevActiveTimeline;

export const sentimentPrevActiveChannel = (state: RootState): string | null =>
  state.overviewPage.sentiment.prevActiveChannel;

export const selectSentimentReleases = (state: RootState): Releases =>
  state.overviewPage.sentiment.releases;

export const selectNormalizedSentimentScore = createSelector(
  selectSentimentData,
  selectSentimentHoveredDate,
  (data, hoveredDate) => {
    const dataWithoutZeroes = data.filter((point: SentimentDataPoint) => point.score !== 0);

    const avgScore = dataWithoutZeroes.length
      ? dataWithoutZeroes.reduce((total, { score }) => total + score, 0) / dataWithoutZeroes.length
      : 0;

    const isHovered = ({ time_start: time }: SentimentDataPoint): boolean =>
      String(hoveredDate).startsWith(String(time));
    const normalize = (dataPoint: SentimentDataPoint): number =>
      Number((Number(dataPoint?.score) * 100).toFixed(2));

    switch (true) {
      case !!data?.length && !hoveredDate:
        return normalize({ score: avgScore } as unknown as SentimentDataPoint);
      case !!data?.length && !!hoveredDate:
        return normalize(data.find(isHovered)!);
      default:
        return null;
    }
  }
);

export const selectSentimentDifference = createSelector(
  selectSentimentData,
  selectSentimentHoveredDate,
  (data, hoveredDate) => {
    const getSentimentScore = ({ score }: SentimentDataPoint): number => score;
    const getDefaultIndexes = (): SentimentIndexes => ({ first: -1, last: 0 });
    const getPercentage = (previous: number, next: number): number => {
      const difference = previous - next;

      return !next ? next : (difference * 100) / next;
    };

    if (data) {
      const values = data.map(getSentimentScore);
      const findByTimeStart = (
        stack: SentimentIndexes,
        { time_start: time }: SentimentDataPoint,
        index: number
      ): SentimentIndexes => {
        const isHovered = String(hoveredDate).startsWith(String(time));

        return !isHovered ? stack : { first: index, last: Math.max(index - 1, 0) };
      };
      const indexes = hoveredDate
        ? data.reduce(findByTimeStart, getDefaultIndexes())
        : getDefaultIndexes();
      const firstValue = values.at(indexes.first) || 0;
      const lastValue = values.at(indexes.last) || 0;

      return getPercentage(firstValue, lastValue);
    }
    return 0;
  }
);

export const selectAverageEngagementRate = createSelector(
  selectEngagementRateData,
  selectActivePulsePersonas,
  selectSelectedPulsePersona,
  selectHoveredPulsePersona,
  selectPulseHoveredDate,
  (
    engagementRateData,
    activePulsePersonas,
    selectedPulsePersona,
    hoveredPulsePersona,
    hoveredDate
  ) => {
    if (engagementRateData) {
      let values = [];
      const allPersonas = hoveredPulsePersona
        ? [...activePulsePersonas, hoveredPulsePersona]
        : activePulsePersonas;
      const personas = selectedPulsePersona ? [selectedPulsePersona] : allPersonas;
      if (hoveredDate) {
        values = personas
          .map(personaValue => engagementRateData[personaValue][hoveredDate])
          .filter(value => value !== null);
      } else {
        values = personas
          .map(personaValue => Object.values(engagementRateData[personaValue]))
          .flat()
          .filter(value => value !== null);
      }
      return avg(values as number[]) || 0;
    }
    return 0;
  }
);

export const selectIfSingleDataPoint = createSelector(
  selectEngagementRateData,
  engagementRateData => {
    if (engagementRateData) {
      const datesData = Object.values(engagementRateData)[0];
      return Object.values(datesData).filter(date => date !== null).length === 1;
    }
    return false;
  }
);

export const selectDifferenceEngagementRate = createSelector(
  selectEngagementRateData,
  selectActivePulsePersonas,
  selectSelectedPulsePersona,
  selectHoveredPulsePersona,
  selectPulseHoveredDate,
  (
    engagementRateData,
    activePulsePersonas,
    selectedPulsePersona,
    hoveredPulsePersona,
    hoveredDate
  ) => {
    if (engagementRateData) {
      const allPersonas = hoveredPulsePersona
        ? [...activePulsePersonas, hoveredPulsePersona]
        : activePulsePersonas;
      const personas = selectedPulsePersona ? [selectedPulsePersona] : allPersonas;
      if (hoveredDate) {
        const datesData = Object.values(engagementRateData)[0];
        const dates = Object.keys(datesData).filter(date => datesData[date] !== null);

        if (dates.length === 1 || dates.indexOf(hoveredDate) === 0) {
          return 0;
        }
        const previousDate = dates[dates.indexOf(hoveredDate) - 1];

        const hoveredValueByPersonas =
          avg(
            personas.map(personaValue => engagementRateData[personaValue][hoveredDate]) as number[]
          ) || 0;

        const previousValueByPersonas =
          avg(
            personas.map(personaValue => engagementRateData[personaValue][previousDate]) as number[]
          ) || 0;
        return hoveredValueByPersonas - previousValueByPersonas;
      }
      const valuesByPersonas = personas.map(personaValue =>
        Object.values(engagementRateData[personaValue]).filter(value => value !== null)
      );
      const firstValuesAvg = avg(valuesByPersonas.map(values => values[0]).flat() as number[]) || 0;
      const lastValuesAvg =
        avg(valuesByPersonas.map(values => values[values.length - 1]).flat() as number[]) || 0;
      return lastValuesAvg - firstValuesAvg;
    }
    return 0;
  }
);

export const selectTopGames = (state: RootState): TopGame[] | null =>
  state.overviewPage.topGames.data;

export const selectLeaderboardData = (state: RootState): KpiLeaderboardData | null =>
  state.overviewPage.kpisLeaderboard.data;

export const selectExperience = (state: RootState): UserExperience | null =>
  state.overviewPage.experience.data;

export const selectExperienceIsLoading = (state: RootState): boolean =>
  state.overviewPage.experience.loading;

export const selectUniqueAndSimilar = (state: RootState): UniqueAndSimilarTraits | null =>
  state.overviewPage.uniqueAndSimilar.data;

export const selectGenreAverages = (state: RootState): GenreAverages | null =>
  state.overviewPage.genreAverages.data;

export const selectPcsData = (state: RootState): PcsData | null => state.overviewPage.pcs.data;

export const selectShowingHeaderbar = (state: RootState): boolean =>
  state.overviewPage.isShowingHeaderbar;

export const selectMarketSize = (state: RootState): MarketSizeI => state.overviewPage.marketSize;

export const selectIsMarketSizeShown = (state: RootState): boolean =>
  state.overviewPage.marketSize.size.valuesSize.length !== 0 && !state.games.isNonGamingCompany;

export const selectExperienceFlowStateSkill = (state: RootState): number =>
  state.overviewPage.experience.data?.flowState.Skill || 0;

export const selectExperienceFlowStateChallenge = (state: RootState): number =>
  state.overviewPage.experience.data?.flowState.Challenge || 0;
