import { call, put, takeEvery, delay, takeLatest, select } from 'redux-saga/effects';

import { getAdNetworksStatus } from '@/services/Api/AdsService';

import {
  setCreativesLimit,
  setFrequencyPurchased,
  setResonanceEnabled,
  setFrequencySyncData,
  checkSyncStatus,
  setFrequencyIsSyncing
} from './actions';
import { selectFrequencySyncData } from './selectors';
import {
  LOAD_FREQUENCY_PERMISSIONS,
  CHECK_SYNC_STATUS,
  AdAccountSync,
  START_CHECK_SYNC_STATUS
} from './types';

function* loadFreqPermissions({
  payload: { frequencyPurchased, enableResonanceScore, frequencyPermissions }
}) {
  try {
    yield put(setFrequencyPurchased(frequencyPurchased));
    yield put(setResonanceEnabled(enableResonanceScore));

    if (frequencyPurchased) {
      yield put(setCreativesLimit(frequencyPermissions.creativesLimit));
    }
  } catch (err) {
    throw new Error(err);
  }
}

function* checkAdNetworksSyncStatus() {
  try {
    const { data } = yield call(getAdNetworksStatus);
    const mappedData: AdAccountSync[] = Object.values(data);
    if (mappedData.length) {
      yield put(setFrequencyIsSyncing(true));
      yield put(setFrequencySyncData(mappedData));
      yield delay(5000);
      yield put(checkSyncStatus());
    } else {
      yield put(setFrequencyIsSyncing(false));
      yield put(setFrequencySyncData([]));
    }
  } catch (err) {
    throw new Error(err);
  }
}

function* startCheckAdNetworksSyncStatus() {
  yield put(setFrequencyIsSyncing(true));
  yield delay(10000);
  const syncData = yield select(selectFrequencySyncData);
  if (!syncData.length) {
    yield put(checkSyncStatus());
  }
}

function* Saga() {
  yield takeEvery(LOAD_FREQUENCY_PERMISSIONS, loadFreqPermissions);
  yield takeLatest(CHECK_SYNC_STATUS, checkAdNetworksSyncStatus);
  yield takeLatest(START_CHECK_SYNC_STATUS, startCheckAdNetworksSyncStatus);
}

export default Saga;
