import camelcaseKeys from 'camelcase-keys';
import { call, put, takeLatest, all } from 'redux-saga/effects';

import { fetchSegmentsList, addSegment as addNewSegment } from '@/services/Api/SegmentsService';

import { eraseAuth } from '@/redux/auth/actions';
import { loadCompany } from '@/redux/initial-request/actions';
import {
  loadSegmentsRequest,
  loadSegmentsSuccess,
  loadSegmentsError,
  addNewSegmentRequest,
  addNewSegmentError,
  addNewSegmentSuccess
} from '@/redux/pages/segmentsPage/actions';
import { Action } from '@/redux/types';

import { LOAD_SEGMENTS, ADD_NEW_SEGMENT } from './types';

function* getSegments() {
  yield put(loadSegmentsRequest());
  try {
    const result = yield call(fetchSegmentsList);

    yield put(loadSegmentsSuccess(camelcaseKeys(result.data.segments)));
    yield put(loadCompany(false));
  } catch (e) {
    yield all([put(loadSegmentsError(e.message)), put(eraseAuth())]);
  }
}

function* addSegment({ payload }: Action) {
  yield put(addNewSegmentRequest());

  try {
    const { audienceId: parentId, ...rest } = payload;

    yield call(addNewSegment, { ...rest, parentId: parentId || null });

    yield put(addNewSegmentSuccess());

    yield all([put(loadCompany(false)), getSegments()]);
  } catch (e) {
    yield put(addNewSegmentError());
  }
}

function* Saga() {
  yield takeLatest(LOAD_SEGMENTS, getSegments);
  yield takeLatest(ADD_NEW_SEGMENT, addSegment);
}

export default Saga;
