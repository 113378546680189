import { ACTION_STATUS_NEW, ACTION_STATUS_SKIPPED } from '@/pages/Traits/ActionsPage/constants';

import { Action } from '@/services/Api/GameService/types';
import { parseQuery } from '@/services/UrlService';

import { Action as ReduxAction } from '@/redux/types';

import {
  LOAD_ACTIONS_REQUEST,
  LOAD_ACTIONS_SUCCESS,
  ADD_ACTION_SUCCESS,
  SET_ACTIONS_CATEGORY_INDEX,
  SET_ACTIONS_STATUS_ID,
  UPDATE_ACTION_SUCCESS,
  DELETE_ACTION_SUCCESS,
  LOAD_PROGRESS_REQUEST,
  LOAD_PROGRESS_SUCCESS,
  SET_PROGRESS_TIMEFRAME,
  SHOW_ACTION_DETAILS,
  ActionsPageState,
  SHOW_FILTERS,
  SET_FILTERS,
  VOTE_ACTION_REQUEST,
  VOTE_ACTION_SUCCESS
} from './types';

const initialState: ActionsPageState = {
  loading: false,
  actionsData: {
    actions: [] as Array<Action>,
    page: 1,
    totalActions: 0,
    hasMore: false
  },
  perPage: 4,
  currentCategoryIndex: parseInt(parseQuery().category, 10) || 0,
  currentStatusId: parseInt(parseQuery().status, 10) || ACTION_STATUS_NEW,
  currentActionId: '',
  showLaunchDetails: false,
  bestAction: null,
  bestActionError: false,
  bestActionErrorMessage: '',
  progress: {
    started: 0,
    completed: 0
  },
  timeFrame: 'week',
  showFilters: false,
  filters: {
    kpis: []
  }
};

export default (state = initialState, { type, payload }: ReduxAction): ActionsPageState => {
  switch (type) {
    case LOAD_ACTIONS_SUCCESS: {
      const currentCategoryIndex = parseInt(parseQuery().category, 10) || 0;
      const isSamePage = payload.page === state.actionsData.page;

      return {
        ...state,
        loading: false,
        currentCategoryIndex,
        actionsData: {
          ...state.actionsData,
          actions:
            payload.page === 1 || isSamePage
              ? payload.actions
              : state.actionsData.actions.concat(payload.actions),
          page: payload.page,
          totalActions: payload.totalActions,
          hasMore: payload.hasMore
        }
      };
    }
    case LOAD_ACTIONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_ACTION_SUCCESS: {
      const newActions = [...state.actionsData.actions];
      newActions.unshift(payload);
      return {
        ...state,
        actionsData: {
          ...state.actionsData,
          actions: newActions
        }
      };
    }
    case UPDATE_ACTION_SUCCESS: {
      const newActions = [...state.actionsData.actions];
      let position = -1;
      const newAction = state.actionsData.actions.find((action, index) => {
        if (action.id === payload.id) {
          position = index;
        }
        return action.id === payload.id;
      });

      if (
        newAction &&
        position >= 0 &&
        newAction.status === ACTION_STATUS_NEW &&
        payload.status === ACTION_STATUS_SKIPPED &&
        !state.actionsData.hasMore
      ) {
        newActions.splice(position, 1);
        newActions.push(payload);
      } else {
        newActions[position] = payload;
      }
      return {
        ...state,
        actionsData: {
          ...state.actionsData,
          actions: newActions
        }
      };
    }
    case DELETE_ACTION_SUCCESS: {
      return {
        ...state,
        actionsData: {
          ...state.actionsData,
          actions: state.actionsData.actions.map(action => {
            if (action.id === payload.id) {
              return { ...action, deleted: true };
            }
            return action;
          })
        }
      };
    }
    case SET_ACTIONS_CATEGORY_INDEX:
      return {
        ...state,
        currentCategoryIndex: payload
      };
    case SET_ACTIONS_STATUS_ID:
      return {
        ...state,
        currentStatusId: payload
      };
    case LOAD_PROGRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_PROGRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        progress: payload.progress
      };
    case SET_PROGRESS_TIMEFRAME:
      return {
        ...state,
        timeFrame: payload.timeFrame
      };
    case SHOW_ACTION_DETAILS:
      return {
        ...state,
        currentActionId: payload.actionId,
        showLaunchDetails: payload.showLaunchDetails
      };
    case SHOW_FILTERS:
      return {
        ...state,
        showFilters: payload.showFilters
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: payload.filters || {
          kpis: []
        }
      };
    case VOTE_ACTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case VOTE_ACTION_SUCCESS:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
