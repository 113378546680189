export const max = (numbers: Array<number>): number | undefined => {
  if (numbers.length === 0) {
    return undefined;
  }
  return Math.max.apply(null, numbers);
};

export const sum = (numbers: Array<number>): number | undefined => {
  if (numbers.length === 0) {
    return undefined;
  }
  return numbers.reduce((total, number) => total + number, 0);
};

export const avg = (numbers: Array<number>): number | undefined => {
  const total = sum(numbers);
  if (total === undefined) {
    return undefined;
  }
  return total / numbers.length;
};

export const flatten = (array: Array<any>): Array<any> => {
  let result: Array<any> = [];
  array.forEach(value => {
    if (Array.isArray(value)) {
      result = result.concat(flatten(value));
    } else {
      result.push(value);
    }
  });
  return result;
};

export const sortAlphabetically =
  (key: string) =>
  (a: any, b: any): any =>
    a[key].localeCompare(b[key]);
