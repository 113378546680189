import update from 'immutability-helper';

import { Action } from '@/redux/types';

import {
  SET_USER_COMPANY,
  UPDATE_USER_DATA,
  SET_IS_LOADING,
  LOAD_USER_INFO_SUCCESS,
  UPDATE_USER_AVATAR_URL,
  UPDATE_COMPANY_AVATAR_URL,
  UserState,
  LOAD_USER_PREFERENCES_SUCCESS,
  SET_IS_LOADING_PREFERENCES,
  UPDATE_FREQ_PREFERENCES_BUILDS_LIBRARY_SORT,
  UPDATE_FREQ_PREFERENCES_BUILDS_LIBRARY_VIEW,
  SET_ASSESSMENT_FTUE_STEP,
  UPDATE_NAV_PREFERENCES_ASSESSMENT_LIST_SORT,
  UPDATE_USER_HAVE_ASSESSMENTS,
  UPDATE_USER_HAVE_GAMES,
  SET_CONSENT,
  RESET_USER,
  SET_EXPLORER_STATE,
  SET_TRAIT_DATA_CONFIG_STATE
} from './types';

export const initialState: UserState = {
  haveAssessments: true,
  haveDashboards: true,
  assessmentFTUEStep: 0,
  isLoading: true,
  isLoadingPreferences: true,
  company: {
    id: '',
    name: '',
    subdomain: '',
    companyType: null,
    heroGenreId: null,
    enableNavigator: false,
    isDemo: false,
    avatarUrl: '',
    billingAddress: '',
    billingAddressDetails: {
      streetNumber: {
        longName: '',
        shortName: ''
      },
      route: {
        longName: '',
        shortName: ''
      },
      locality: {
        longName: '',
        shortName: ''
      },
      administrativeAreaLevel1: {
        longName: '',
        shortName: ''
      },
      administrativeAreaLevel2: {
        longName: '',
        shortName: ''
      },
      country: {
        longName: '',
        shortName: ''
      },
      postalCode: {
        longName: '',
        shortName: ''
      }
    },
    primaryOwnerId: '',
    primaryOwnerInfo: {
      id: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      active: false,
      role: '',
      jobTitle: '',
      createdAt: null,
      company: '',
      avatarUrl: '',
      permissions: {
        isCompanyOwner: false,
        dashboardPermissions: null
      }
    },
    frequencyPurchased: false,
    frequencyPermissions: {
      creativesLimit: null,
      campaignsLimit: null,
      readOnly: false,
      superAdminOnly: false,
      tagBuilderSuperadminOnly: false,
      reviewsSuperadminOnly: false
    },
    enableResonanceScore: false,
    resonanceModelName: '',
    maxAllowedFrequencyReviews: null,
    socialListeningEnabled: false,
    socialListeningSuperadminOnly: false,
    isPreview: false,
    csmAdminUserId: '',
    csmAdminUser: {
      id: '',
      role: '',
      email: '',
      password: '',
      csmCalendlyUrl: ''
    }
  },
  info: {
    id: '',
    firstName: '',
    lastName: '',
    avatarUrl: '',
    email: '',
    datetimeNumbersFormat: '',
    jobTitle: '',
    name: '',
    companyDetails: {
      name: '',
      avatarUrl: '',
      isDemo: false,
      isPreview: false,
      socialListeningEnabled: false,
      frequencyPermissions: {
        readOnly: false
      }
    },
    consent: {
      consent: false,
      consentDate: null,
      email: '',
      marketingEmails: true,
      partnerLoggedInUserId: ''
    },
    permissions: {
      isCompanyOwner: null,
      dashboardPermissions: [],
      featurePermissions: {}
    },
    timezone: ''
  },
  preferences: null
};

export default (state = initialState, action: Action): UserState => {
  switch (action.type) {
    case SET_USER_COMPANY:
      return {
        ...state,
        company: action.payload.company
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case SET_IS_LOADING_PREFERENCES:
      return {
        ...state,
        isLoadingPreferences: action.payload
      };
    case SET_ASSESSMENT_FTUE_STEP:
      return {
        ...state,
        assessmentFTUEStep: action.payload
      };
    case LOAD_USER_INFO_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload.data
        }
      };
    case UPDATE_USER_AVATAR_URL:
      return {
        ...state,
        info: {
          ...state.info,
          avatarUrl: action.payload
        }
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload.data
        }
      };
    case UPDATE_COMPANY_AVATAR_URL:
      return {
        ...state,
        info: {
          ...state.info,
          companyDetails: {
            ...state.info.companyDetails,
            avatarUrl: action.payload
          }
        }
      };
    case LOAD_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferences: action.payload.data
      };

    case SET_EXPLORER_STATE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          traits: {
            ...state.preferences?.traits,
            explorerConfig: action.payload
          }
        }
      };

    case SET_TRAIT_DATA_CONFIG_STATE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          traits: {
            ...state.preferences?.traits,
            traitsDataConfig: action.payload
          }
        }
      };
    case UPDATE_FREQ_PREFERENCES_BUILDS_LIBRARY_SORT:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          frequency: {
            ...state.preferences?.frequency,
            buildsLibrarySort: {
              ...state.preferences?.frequency.buildsLibrarySort,
              ...action.payload.data
            }
          }
        }
      };
    case UPDATE_NAV_PREFERENCES_ASSESSMENT_LIST_SORT:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          navigator: {
            ...state.preferences?.navigator,
            assessmentListSort: {
              ...state.preferences?.navigator.assessmentListSort,
              ...action.payload.data
            }
          }
        }
      };
    case UPDATE_FREQ_PREFERENCES_BUILDS_LIBRARY_VIEW: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          frequency: {
            ...state.preferences?.frequency,
            buildsView: action.payload.view
          }
        }
      };
    }
    case UPDATE_USER_HAVE_ASSESSMENTS: {
      return {
        ...state,
        haveAssessments: action.payload
      };
    }
    case UPDATE_USER_HAVE_GAMES: {
      return {
        ...state,
        haveDashboards: action.payload
      };
    }
    case SET_CONSENT: {
      return update(state, {
        info: {
          consent: {
            consent: { $set: action?.payload?.consent ?? false },
            consentDate: {
              $set: action?.payload?.consentDate ?? Math.floor(new Date().getTime() / 1000)
            },
            ...(action?.payload?.hasOwnProperty?.('marketingEmails') && {
              marketingEmails: { $set: action?.payload?.marketingEmails }
            }),
            ...(action?.payload?.hasOwnProperty?.('email') && {
              email: { $set: action?.payload?.email }
            }),
            ...(action?.payload?.hasOwnProperty?.('partnerLoggedInUserId') && {
              partnerLoggedInUserId: { $set: action?.payload?.partnerLoggedInUserId }
            })
          }
        }
      });
    }
    case RESET_USER: {
      return initialState;
    }
    default:
      return state;
  }
};
