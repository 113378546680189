import { getLocalTimeZone, today } from '@internationalized/date';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import queryString from 'query-string';
import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import PenguinIcon from 'ui-lib-12traits/src/Icons/svg/common/blue-penguin.svg?component';
import BackIcon from 'ui-lib-12traits/src/Icons/svg/frequency/arrow-back.svg?component';
import { ButtonIcon, Dictionary, Spinner, ViewMore } from 'ui-lib-12traits/src/index';
import { useAppSelector } from '@/hooks/useApp';
import DocumentTitle from '@/hocs/DocumentTitle';
import { usePrevious } from '@/hooks/usePrevious';
import { useI18n } from '@/i18n';
import { traitOverviewPath } from '@/route/paths';
import { getConversationThreads } from '@/services/Api/SocialListeningApi/index';
import { selectGameSlug } from '@/redux/game/selectors';
import { selectCompanyName } from '@/redux/user/selectors';
import ConversationThreadsListItem from '@/components/Trait/ConversationThreads/ConversationThreadsListItem/ConversationThreadsListItem';
import {
  getScoreColorClassName,
  getUrlValue
} from '@/components/Trait/ConversationThreads/helpers';
import TopSection from '@/components/Trait/ConversationThreads/TopSection';

import ConversationThreadsPageI18n from './ConversationThreadsPage.i18n';
import styles from './ConversationThreadsPage.module.scss';
import { ConversationThreads, QueryConversationThreadDetail } from './types';

type I18nProps = ReturnType<typeof ConversationThreadsPageI18n>;

function ConversationThreadsPage(): ReactElement {
  const i18n: I18nProps = useI18n(ConversationThreadsPageI18n);
  const history = useHistory();
  const gameSlug = useAppSelector(selectGameSlug);
  const companyName = useAppSelector(selectCompanyName);

  const [conversationThreads, setConversationThreads] = useState<ConversationThreads>({
    totalCount: 0,
    results: []
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [availableHighlightedTerms, setAvailableHighlightedTerms] = useState<string[]>([]);
  const [averageScores, setAverageScores] = useState<{
    informativeness: number;
    sentiment: number;
  }>({ informativeness: 0, sentiment: 0 });

  const goBack = (): void => history.push(generatePath(traitOverviewPath, { gameSlug }));
  const {
    highlightedTerms = [],
    sources = [],
    searchQuery = '',
    dateFrom = getUrlValue(today(getLocalTimeZone()).subtract({ weeks: 1 })),
    dateTo = getUrlValue(today(getLocalTimeZone())),
    sort = '',
    sortDirection = '',
    clusters = []
  } = queryString.parse(history.location.search, {
    arrayFormat: 'bracket'
  }) as unknown as QueryConversationThreadDetail;

  const prevTerms = usePrevious(highlightedTerms);
  const prevSources = usePrevious(sources);
  const prevQuery = usePrevious(searchQuery);
  const prevDateFrom = usePrevious(dateFrom);
  const prevDateTo = usePrevious(dateTo);
  const prevSort = usePrevious(sort);
  const prevSortDirection = usePrevious(sortDirection);
  const prevGame = usePrevious(gameSlug);
  const prevClusters = usePrevious(clusters);

  const fetchData = async (
    loadingFnc: (value: React.SetStateAction<boolean>) => void
  ): Promise<void> => {
    loadingFnc(true);
    const { data } = await getConversationThreads({
      limit: 10 + (conversationThreads?.results?.length || 0),
      offset: 0,
      dateFrom,
      dateTo,
      sources,
      highlightedTerms,
      searchQuery,
      sort,
      sortDirection,
      clusters
    });
    setConversationThreads(data);
    setAverageScores({
      informativeness: data?.informativenessAverage ? data.informativenessAverage * 100 : 0,
      sentiment: data?.sentimentAverage ? data.sentimentAverage * 100 : 0
    });
    setAvailableHighlightedTerms(data?.availableHighlightedTerms);
    loadingFnc(false);
  };

  useEffect(() => {
    if (
      !isEqual(prevTerms, highlightedTerms) ||
      !isEqual(prevSources, sources) ||
      !isEqual(prevQuery, searchQuery) ||
      !isEqual(prevDateFrom, dateFrom) ||
      !isEqual(prevDateTo, dateTo) ||
      !isEqual(prevSort, sort) ||
      !isEqual(prevSortDirection, sortDirection) ||
      !isEqual(gameSlug, prevGame) ||
      !isEqual(clusters, prevClusters)
    ) {
      fetchData(setIsLoading);
    }
  }, [
    gameSlug,
    searchQuery,
    sources,
    highlightedTerms,
    dateFrom,
    dateTo,
    sort,
    sortDirection,
    clusters
  ]);

  const html = useMemo(
    () => ({
      informativeness: {
        description: {
          __html: i18n.definitions.informativeness.description
        }
      },
      sentiment: {
        description: {
          __html: i18n.definitions.sentiment.description
        }
      }
    }),
    [i18n]
  );

  const SCORE_DEFINITIONS = {
    sentiment: (
      <div className={styles.definition}>
        <div data-testid="definition-title" className={styles.definition__title}>
          {i18n.definitions.sentiment.title}
        </div>
        <div dangerouslySetInnerHTML={html.sentiment.description} />
        <p data-testid="definition-footnote" className={styles.definition__footnote}>
          {i18n.definitions.sentiment.footnote}
        </p>
      </div>
    ),
    informativeness: (
      <div className={styles.definition}>
        <div data-testid="definition-title" className={styles.definition__title}>
          {i18n.definitions.informativeness.title}
        </div>
        <div dangerouslySetInnerHTML={html.informativeness.description} />
      </div>
    )
  };

  const renderScoreWidgets = (): ReactNode => {
    const scores = Object.keys(averageScores);
    return (
      <div className={styles.widgets}>
        {scores.map(score => {
          const title = i18n.score.title({ score });

          return (
            <div
              key={score}
              className={styles['score-widget']}
              data-testid={`page-score-widget-${score}`}
            >
              <div className={styles['score-widget__header']}>
                <div
                  className={styles['score-widget__title']}
                  data-testid={`page-score-widget-${score}-title`} // TODO: Should these be static?
                >
                  {title}
                </div>
                <Dictionary
                  className={styles['score-widget__dictionary']}
                  definition={SCORE_DEFINITIONS[score]}
                  modalHeaderClassName={styles['dictionary-overlay-header']}
                  closeBtnClassName={styles['dictionary-overlay-close']}
                />
              </div>
              <div
                className={classNames(
                  styles['score-widget__value'],
                  styles[
                    `score-widget__value_${getScoreColorClassName(averageScores[score].toFixed(0))}`
                  ]
                )}
                data-testid={`page-score-widget-${score}-score`}
              >
                {averageScores[score].toFixed(0)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const { document, pagination } = useMemo(
    () => ({
      document: { title: i18n.document.title({ companyName }) },
      pagination: i18n.pagination({
        page: conversationThreads?.results?.length,
        total: conversationThreads?.totalCount
      })
    }),
    [companyName, conversationThreads, i18n]
  );

  return (
    <DocumentTitle title={document.title}>
      <div className={styles.page__wrapper}>
        <div className={styles.page__header}>
          <ButtonIcon onClick={goBack} className={styles['back-icon']} testid="page-back-button">
            <BackIcon />
          </ButtonIcon>
          <div className={styles.page__title} data-testid="page-title">
            {i18n.title}
          </div>
        </div>
        <TopSection
          highlightedTerms={highlightedTerms}
          channels={sources}
          searchQuery={searchQuery}
          dateFrom={dateFrom}
          dateTo={dateTo}
          sort={sort}
          sortDirection={sortDirection}
          availableHighlightedTerms={availableHighlightedTerms}
          clusters={clusters}
        />
        <div className={styles.content__wrapper}>
          {!!conversationThreads?.results && (
            <div className={styles['items-count']} data-testid="page-showing-title">
              {pagination}
            </div>
          )}

          <div className={styles.content__list}>
            {isLoading ? (
              <div className={styles.loader}>
                <Spinner width={30} height={30} />
              </div>
            ) : conversationThreads?.results ? (
              <>
                <div>
                  {renderScoreWidgets()}
                  {conversationThreads?.results.map(item => (
                    <ConversationThreadsListItem key={item.timestamp} item={item} />
                  ))}
                </div>
                <div>
                  {conversationThreads?.totalCount > conversationThreads.results.length &&
                    (isLoadingMore ? (
                      <div className={styles.loader}>
                        <Spinner width={30} height={30} />
                      </div>
                    ) : (
                      <ViewMore
                        label={i18n.more.title}
                        onClick={(): Promise<void> => fetchData(setIsLoadingMore)}
                      />
                    ))}
                </div>
              </>
            ) : (
              <div className={styles.content_empty}>
                <PenguinIcon className={styles.penguin} />
                <div className={styles.content__title}>{i18n.empty.title}</div>
                <div className={styles.content__subtitle}>{i18n.empty.description}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
}

export default ConversationThreadsPage;
