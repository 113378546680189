import { selectSegments } from '@/redux/game/selectors';

import { RootState } from '../rootStore';
import { Segment } from '../types';

export const selectDefaultSegment = (state: RootState): Segment | undefined =>
  selectSegments(state).find(({ isDefault }) => isDefault);

export const selectDefaultSegmentId = (state: RootState): string => {
  const defaultSegment = selectDefaultSegment(state);
  return defaultSegment ? defaultSegment.id : '';
};

const selectDefaultSegmentName = (state: RootState): string => {
  const defaultSegment = selectDefaultSegment(state);
  return defaultSegment ? defaultSegment.title : '';
};

export const selectCurrentSegment = (state: RootState): Segment | undefined =>
  selectSegments(state).find(({ id }) => id === state.segment.currentId);

export const selectCurrentSegmentId = (state: RootState): string => {
  const segments = selectSegments(state);
  const defaultSegmentId = segments.length ? segments[0].id : '';
  const currentSegment = selectCurrentSegment(state);
  return currentSegment && currentSegment.id ? currentSegment.id : defaultSegmentId;
};

export const selectCurrentSegmentIsDefault = (state: RootState): boolean => {
  const currentSegment = selectCurrentSegment(state);
  return currentSegment ? currentSegment.isDefault : true;
};

export const selectCurrentSegmentName = (state: RootState): string => {
  const currentSegment = selectCurrentSegment(state);
  const defaultSegmentName = selectDefaultSegmentName(state);

  return currentSegment ? currentSegment.title : defaultSegmentName;
};
