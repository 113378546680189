import { createCalendar } from '@internationalized/date';
import { ReactElement, useRef } from 'react';

import { useRangeCalendar, useLocale } from 'react-aria';
import { useRangeCalendarState } from 'react-stately';

import { CalendarGrid } from './components/CalendarGrid';
import { CalendarHeader } from './components/CalendarHeader';
import styles from './components/styles.module.scss';

function DateRangePicker(props): ReactElement {
  const { locale } = useLocale();
  const state = useRangeCalendarState({
    ...props,
    visibleDuration: { months: 2 },
    locale,
    createCalendar
  });

  const ref = useRef();
  const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(props, state, ref);

  return (
    <div
      data-testid="calendar-wrapper"
      {...calendarProps}
      ref={ref}
      className={styles.calendar__wrapper}
    >
      <div data-testid="calendar-header">
        <CalendarHeader
          state={state}
          calendarProps={calendarProps}
          prevButtonProps={prevButtonProps}
          nextButtonProps={nextButtonProps}
        />
      </div>
      <div data-testid="calendar" className={styles.calendar}>
        <CalendarGrid state={state} />
        <CalendarGrid state={state} offset={{ months: 1 }} />
      </div>
    </div>
  );
}

export default DateRangePicker;
