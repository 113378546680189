import { Store } from '@reduxjs/toolkit';

class StoreRegistry {
  store: null | Store;

  constructor() {
    this.store = null;
  }

  register(store: Store): void {
    this.store = store;
  }

  getState(): Store | {} {
    if (this.store) {
      return this.store.getState();
    }
    return {};
  }
}

export const storeRegistry = new StoreRegistry();
